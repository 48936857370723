<template>
  <div class="developers">
    <ul class="developers__list">
      <li
        v-if="isReadmeUrlExist"
        class="developers__list-item"
      >
        <a
          target="_blank"
          :href="readmeUrl"
          class="developers__list-item-link"
        >
          <i class="icon icon-book developers__list-item-link-image" />
          <p class="developers__list-item-link-text">
            {{ $t('partner-portal.header.developer-routes.api-reference') }}
          </p>
        </a>
      </li>
      <li
        v-if="shouldDisplayCopyButton"
        class="developers__list-item"
      >
        <a
          href="#"
          class="developers__list-item-link"
          @click="copyApiKey"
        >
          <i class="icon icon-copy developers__list-item-link-image" />
          <p class="developers__list-item-link-text">
            {{ $t('partner-portal.menus.developers.copy') }} {{ activeProductName }}
          </p>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DevelopersCardMobile',

  props: {
    readmeUrl: {
      type: String,
      required: true,
    },
    shouldDisplayCopyButton: {
      type: Boolean,
      required: true,
    },
    copyApiKey: {
      type: Function,
      required: true,
    },
    activeProductName: {
      type: String,
      default: '',
    },
  },

  computed: {
    isReadmeUrlExist() {
      return this.readmeUrl.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.developers {
  display: block;

  @media (min-width: $tablet-breakpoint) {
    display: none;
  }

  &__list {
    padding: 20px 0;
  }

  &__list-item {
    display: flex;
  }

  &__list-item-link {
    display: flex;
    align-items: center;
    color: $primary;
    font-size: 15px;
    line-height: 18px;
    text-decoration: none;
  }

  &__list-item-link-image {
    width: 16px;
    height: 16px;
  }

  &__list-item-link-text {
    margin: 12px 0 8px 14px;
  }
}
</style>
