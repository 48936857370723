<template>
  <div class="auth">
    <span
      v-if="errorText"
      class="auth__error"
    >
      {{ errorText }}
    </span>

    <div class="auth__container">
      <Login
        v-show="isLoginAuthState"
        :errors="errors"
        :is-loading="isLoading"
        :is-captcha-visible="isCaptchaVisible"
        :is-captcha-enabled="isCaptchaEnabled"
        :should-render-captcha="shouldRenderCaptcha"
        @set-captcha-visibility="isCaptchaVisible = true"
        @set-captcha-token="captchaToken = $event"
        @login="login"
      />

      <NewPassword
        v-show="isNewPasswordAuthState"
        :errors="errors"
        :is-loading="isLoading"
        :user-email="userEmail"
        @set-new-password="setNewPassword"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { AWS_CHALLENGE_NAMES } from './Auth.constants';

import { validateAwsErrorResponse, validateNewPasswordAwsResponse } from './Auth.utils';

import LocalStorageService from '@/services/LocalStorageService';

import Login from '@/components/Auth/Login';
import NewPassword from '@/components/Auth/NewPassword';

export default {
  name: 'Auth',

  components: {
    Login,
    NewPassword,
  },

  data: () => ({
    authState: 'login',

    isLoading: false,

    errors: [],
    errorText: '',

    captchaToken: '',

    isCaptchaVisible: false,
    isCaptchaEnabled: JSON.parse(CAPTCHA_ENABLE),
    shouldRenderCaptcha: false,
  }),

  computed: {
    ...mapGetters('userStore', {
      user: 'user',
    }),

    userEmail() {
      return this.user?.challengeParam?.userAttributes?.email;
    },

    isLoginAuthState() {
      return this.authState === 'login';
    },
    isNewPasswordAuthState() {
      return this.authState === 'newPassword';
    },
  },

  mounted() {
    LocalStorageService.removeItem('activeProduct');
  },

  methods: {
    ...mapActions('userStore', {
      loginWithAmplify: 'login',
      setNewPasswordAmplify: 'setNewPassword',
    }),

    clearErrors() {
      this.errors = [];
      this.errorText = '';
    },

    validateFieldsEmptiness(fieldsList) {
      fieldsList.forEach(({ key, value }) => {
        if (!value) {
          this.errors.push({
            key, error: this.$t(`partner-portal.errors.user-missing-${key}`),
          });
        }
      });
    },

    setAuthState(newAuthState) {
      this.authState = newAuthState;
    },

    login(user) {
      this.clearErrors();

      this.isLoading = true;

      this.validateFieldsEmptiness([{
        key: 'email',
        value: user.email,
      }, {
        key: 'password',
        value: user.password,
      }]);

      const { isCaptchaEnabled, isCaptchaVisible } = this;

      if (isCaptchaEnabled && isCaptchaVisible && !this.captchaToken) {
        this.errors.push({
          key: 'email',
          error: this.$t('partner-portal.errors.user-missing-captcha'),
        }, {
          key: 'password',
          error: this.$t('partner-portal.errors.user-missing-captcha'),
        });
      }

      if (this.errors.length) {
        this.isLoading = false;
        return;
      }

      const { email, password } = user;

      this.loginWithAmplify({
        email: email.trim(),
        password: password.trim(),
        token: this.captchaToken,
      })
        .then(response => {
          localStorage.removeItem('displayCaptcha');
          if (response?.challengeName === AWS_CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED) {
            this.setAuthState('newPassword');
          } else {
            const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin');

            if (redirectAfterLogin) {
              this.$router.push({
                name: redirectAfterLogin,
              });
            } else {
              this.$router.push('/');
            }
          }
        })
        .catch(err => {
          const errorData = validateAwsErrorResponse(err);

          if (Array.isArray(errorData)) {
            this.errors = errorData;
          } else {
            this.errorText = errorData;
          }

          if (!this.isCaptchaVisible && this.isCaptchaEnabled) {
            this.shouldRenderCaptcha = true;
          } else {
            if (this.isCaptchaEnabled) window.grecaptcha.reset();
            this.captchaToken = '';
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    setNewPassword(user) {
      this.clearErrors();
      this.isLoading = true;

      this.validateFieldsEmptiness([{
        key: 'new-password',
        value: user.newPassword,
      }, {
        key: 'new-password-confirmation',
        value: user.newPasswordConfirmation,
      }]);

      if (this.errors.length) {
        this.isLoading = false;
        return;
      }

      if (user.newPassword !== user.newPasswordConfirmation) {
        this.isLoading = false;
        this.errors.push({
          key: 'new-password',
          error: this.$t('partner-portal.errors.password-must-match'),
        });

        return;
      }

      this.setNewPasswordAmplify({
        password: user.newPassword.trim(),
      })
        .then(() => {
          const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin');

          if (redirectAfterLogin) {
            this.$router.push({
              name: redirectAfterLogin,
            });
          } else {
            this.$router.push('/');
          }
        })
        .catch(err => {
          const errorsList = validateNewPasswordAwsResponse(err);
          this.errors = errorsList;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
@import '~@/assets/scss/mixins';

.auth {
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: auto !important;

  &__error {
    font: 600 14px/16px $font-regular;
    color: $primary;
    text-align: center;
    width: 100%;
    max-width: 350px;
    padding: 12px 16px 36px;
    margin-bottom: -24px;
    background-color: $error-color;
    border-radius: 8px 8px 0 0;
  }

  &__container {
    width: 100%;
    max-width: 350px;
    background: $primary;
    border-radius: 8px;
    padding: 36px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // Hack for chaning the width of google recaptcha box
  &__form-captcha-container {
    transform: scale(0.94);
    transform-origin: 0 0;
  }

  &__ssl {
    margin-top: 24px;
    font-size: 14px;
    line-height: 16px;
    font-family: $font-regular;
  }

  &__form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__form-page-name {
    font-size: 18px;
    line-height: 22px;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  &__form-icon {
    min-height: 32px;
    max-height: 32px;
    min-width: 32px;
    max-width: 32px;

    &_back {
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(-90deg);
      margin-bottom: 0;

      @include hover {
        cursor: pointer;
      }
    }
  }

  &__form-input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    width: 100%;
  }

  &__form-input-group_text {
      .input-field {
        .input-field__input {
          -webkit-text-security: disc;
        }
      }
  }

  &__form-label-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }

  &__form-label {
    font-size: 13px;
    line-height: 16px;
    font-family: $font-regular;
  }

  &__form-label-forgot-password {
    font-size: 13px;
    line-height: 16px;
    text-decoration: none;
    font-family: $font-regular;
    color: $secondary-text-color;
  }

  &__form-captcha {
    height: 84px;
    background: $background-color;
  }

  &__form-footer {
    width: 100%;
    margin-top: 20px;
  }

  &__form-footer-button {
    min-width: auto;
    width: 100%;
  }
}
</style>
