<template>
  <div class="payouts-filter">
    <div class="payouts-filter__input-group">
      <div
        class="payouts-filter__label"
      >
        {{ $t('partner-portal.payouts.filter.title') }}
      </div>

      <div class="payouts-filter__value">
        <i class="icon icon-calendar payouts-filter__value-image" />
        <div class="payouts-filter__value-text">
          {{ $t('partner-portal.payouts.filter.dateRanges.week') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayoutSidebarFilter',
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/icons';

.payouts-filter {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 24px;

  &__label {
    font-size: 15px;
    margin-bottom: 6px;
    font-family: $font-regular;
  }

  &__value {
    display: flex;
    align-items: center;
  }

  &__value-image {
    height: 16px;
    width: 16px;
  }

  &__value-text {
    margin-left: 8px;
  }

  &__input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__input-title {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
    margin-bottom: 6px;
  }

  &__date-range {
    display: flex;
    align-items: center;
  }

  &__date-range-image {
    height: 16px;
    width: 16px;
  }

  &__date-range-text {
    margin-left: 10px;
  }
}
</style>
