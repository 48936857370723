import { apiClient } from '@/network';
import LoggerService from '@/services/LoggerService';

const doesRoleGiveAccess = (rolesList, roleKey) => {
  const roleByKey = rolesList.find(role => role.name === roleKey);
  if (!roleByKey) return false;

  return roleByKey.enabled;
};

const TRANSACTIONS_MONITORING_ROLE = 'transactions';
const PAYOUTS_MONITORING_ROLE = 'payouts';
const WIDGET_SETTINGS_MONITORING_ROLE = 'fees';
const PROFILE_SETTINGS_MONITORING_ROLE = 'profile_settings';
const API_KEYS_MONITORING_ROLE = 'api_keys';
const VIEW_ONLY_ROLE = 'view_only';

export default {
  namespaced: true,

  state: {
    roles: [],
  },

  getters: {
    isTransactionsMonitoringAllowed: state => doesRoleGiveAccess(state.roles, TRANSACTIONS_MONITORING_ROLE),
    isPayoutsMonitoringAllowed: state => doesRoleGiveAccess(state.roles, PAYOUTS_MONITORING_ROLE),
    isWidgetSettingsMonitoringAllowed: state => doesRoleGiveAccess(state.roles, WIDGET_SETTINGS_MONITORING_ROLE),
    isProfileSettingsMonitoringAllowed: state => doesRoleGiveAccess(state.roles, PROFILE_SETTINGS_MONITORING_ROLE),

    isApiKeysMonitoringAllowed: state => doesRoleGiveAccess(state.roles, API_KEYS_MONITORING_ROLE),
    isViewOnlyMonitoringAllowed: state => doesRoleGiveAccess(state.roles, VIEW_ONLY_ROLE),

    rolesList: state => state.roles,
    isRolesListEmpty: state => !state.roles.length,
  },

  mutations: {
    setRoles(state, payload) {
      state.roles = payload;
    },
  },

  actions: {
    getRoles: async ({ commit }) => {
      try {
        const { data: { data: roles } } = await apiClient.get('/v1/roles');

        commit('setRoles', roles);
      } catch (error) {
        LoggerService.captureSentryError(error, 'Was trying to get current roles');
      }
    },
  },
};
