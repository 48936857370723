<template>
  <div class="transactions-filter">
    <div class="transactions-filter__input-group transactions-filter__input-group_filter">
      <div class="transactions-filter__input-group-wrap">
        <input
          v-model="filterQuery"
          type="text"
          :placeholder="$t('partner-portal.transaction.filter.inputPlaceholder')"
          data-testid="transactions-search-input"
          class="transactions-filter__input"
          @keyup="setTransactionsQuery"
        >

        <div class="transactions-filter__input-prompt">
          {{ $t('partner-portal.transaction.sidebar.search-prompt') }}
        </div>
      </div>

      <button
        class="transactions-filter__input-group-button"
        :class="{'transactions-filter__input-group-button_open': !collapsed}"
        @click="collapsed = !collapsed"
      >
        <i class="icon icon-filter" />
      </button>
    </div>

    <template v-if="!isFiltersCollapsed">
      <div class="transactions-filter__input-group">
        <DateRangePicker
          data-test-id="date-range-dropdown-filter"
          :value="filterDateRange"
          :label="$t('partner-portal.common.date-range')"
          :min-date="minDate"
          @date-change="applyDateFilter"
        />
      </div>
      <div class="transactions-filter__input-group">
        <div class="transactions-filter__input-title">
          {{ $t('partner-portal.transaction.filter.status') }}
        </div>
        <DropdownFilter
          data-testid="status-dropdown-filter"
          class="sidebar-filter__dropdown-content"
          :title="$t('partner-portal.transaction.filter.statuses.all')"
          :items="transactionStatusesList"
          :active-items="selectedStatusesList"
          @apply-changes="applyTransactionsStatusFilter"
          @discard-changes="discardTransactionsStatusFilter"
        />
      </div>
      <div class="transactions-filter__input-group">
        <div class="transactions-filter__input-title">
          {{ $t('partner-portal.transaction.filter.crypto') }}
        </div>
        <DropdownFilter
          data-testid="cryptos-dropdown-filter"
          class="sidebar-filter__dropdown-content"
          :title="$t('partner-portal.transaction.filter.cryptos.all')"
          :items="currenciesList"
          :active-items="selectedCurrenciesList"
          @apply-changes="applyCurrenciesFilter"
          @discard-changes="discardCurrenciesFilter"
        />
      </div>
      <div
        v-if="isPaymentMethodsEnabled"
        class="transactions-filter__input-group"
      >
        <div class="transactions-filter__input-title">
          {{ $t('partner-portal.analytics.sidebar.filters.payment-method') }}
        </div>

        <DropdownFilter
          data-testid="payment-method-dropdown-filter"
          class="sidebar-filter__dropdown-content"
          :title="$t('partner-portal.analytics.sidebar.filters.all-payment-methods')"
          :items="paymentMethodsList"
          :active-items="selectedPaymentMethodsList"
          @apply-changes="applyPaymentMethodsFilter"
          @discard-changes="discardPaymentMethodsFilter"
        />
      </div>
      <div class="transactions-filter__input-group">
        <div class="transactions-filter__input-title">
          {{ $t('partner-portal.analytics.sidebar.filters.payment-attempts') }}
        </div>

        <DropdownFilter
          data-testid="payments-attempts-dropdown-filter"
          class="sidebar-filter__dropdown-content"
          :title="$t('partner-portal.analytics.sidebar.filters.all-payment-attempts')"
          :items="paymentAttemptsList"
          :active-items="selectedPaymentAttemptsList"
          @apply-changes="applyPaymentAttemptsFilter"
          @discard-changes="discardPaymentAttemptsFilter"
        />
      </div>
    </template>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters, mapActions } from 'vuex';
import DateRangePicker from '@/components/DateRangePicker';
import DropdownFilter from '@/components/DropdownFilter';

const DEBOUNCE_TIME = 1000;
const QUERY_MINIMAL_LENGTH = 2;
const QUERY_MAX_LENGTH = 100;

export default {
  name: 'SidebarFilter',
  components: {
    DateRangePicker,
    DropdownFilter,
  },

  data() {
    return {
      filterQuery: '',
      dateRange: null,
      collapsed: true,
      isMobile: false,
    };
  },

  computed: {
    ...mapGetters('filterStore', {
      transactionStatusesList: 'transactionStatusesList',
      currenciesList: 'currenciesList',
      paymentMethodsList: 'paymentMethodsList',
      paymentAttemptsList: 'paymentAttemptsList',
      minDate: 'minDate',
    }),
    ...mapGetters('transactionsStore', {
      selectedDateFilter: 'selectedDateFilter',
      selectedStatusesList: 'selectedStatusesList',
      selectedCurrenciesList: 'selectedCurrenciesList',
      selectedPaymentMethodsList: 'selectedPaymentMethodsList',
      selectedPaymentAttemptsList: 'selectedPaymentAttemptsList',
    }),
    ...mapGetters('featureFlagsStore', {
      isPaymentMethodsEnabled: 'isPaymentMethodsEnabled',
    }),

    filterDateRange() {
      if (this.dateRange) return this.dateRange;
      return this.selectedDateFilter;
    },

    isFiltersCollapsed() {
      return this.collapsed && this.isMobile;
    },
  },

  mounted() {
    this.determineIsScreenSizeMobile();
    window.addEventListener('resize', this.determineIsScreenSizeMobile);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.determineIsScreenSizeMobile);
  },

  methods: {
    ...mapActions('transactionsStore', {
      setQuery: 'setQuery',
      setDateFilter: 'setDateFilter',
      setTransactionsStatusFilter: 'setTransactionsStatusFilter',
      setCurrenciesFilter: 'setCurrenciesFilter',
      setPaymentMethodsFilter: 'setPaymentMethodsFilter',
      setPaymentAttemptsFilter: 'setPaymentAttemptsFilter',
      setPaymentAttemptsList: 'setPaymentAttemptsList',
      loadTransactions: 'loadTransactions',
    }),

    setTransactionsQuery: debounce(function () {
      this.filterQuery = this.filterQuery.replaceAll(' ', '');

      const queryLength = this.filterQuery.length;
      const isQueryLengthFitsLimit = queryLength >= QUERY_MINIMAL_LENGTH
        && queryLength <= QUERY_MAX_LENGTH;

      if (!queryLength || isQueryLengthFitsLimit) {
        const encodedFilterQuery = encodeURIComponent(this.filterQuery);
        this.setQuery(encodedFilterQuery)
          .then(() => this.loadTransactions());
      }
    }, DEBOUNCE_TIME),

    applyDateFilter(dateRange) {
      this.dateRange = dateRange;

      this.setDateFilter({
        from: dateRange.start, to: dateRange.end,
      })
        .then(() => this.loadTransactions());
    },

    applyTransactionsStatusFilter(changes) {
      this.setTransactionsStatusFilter(changes)
        .then(() => this.loadTransactions());
    },

    discardTransactionsStatusFilter() {
      this.setTransactionsStatusFilter([])
        .then(() => this.loadTransactions());
    },

    applyCurrenciesFilter(changes) {
      this.setCurrenciesFilter(changes)
        .then(() => this.loadTransactions());
    },

    discardCurrenciesFilter() {
      this.setCurrenciesFilter([])
        .then(() => this.loadTransactions());
    },

    applyPaymentMethodsFilter(changes) {
      this.setPaymentMethodsFilter(changes)
        .then(() => this.loadTransactions());
    },

    discardPaymentMethodsFilter() {
      this.setPaymentMethodsFilter([])
        .then(() => this.loadTransactions());
    },

    applyPaymentAttemptsFilter(changes) {
      this.setPaymentAttemptsList(changes);

      let attemptsFilter;
      const shoulClearAttemptsFilter = changes.length === 0 || changes.length > 1;

      if (shoulClearAttemptsFilter) attemptsFilter = '';

      if (changes.length === 1) [attemptsFilter] = changes;

      this.setPaymentAttemptsFilter(attemptsFilter)
        .then(() => this.loadTransactions());
    },

    discardPaymentAttemptsFilter() {
      this.setPaymentAttemptsList([]);
      this.setPaymentAttemptsFilter('')
        .then(() => this.loadTransactions());
    },

    determineIsScreenSizeMobile() {
      this.isMobile = window.innerWidth < 768;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.transactions-filter {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 24px;

  &__input-group {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__input-group_filter {
    flex-direction: row;
  }

  &__input-group-wrap {
    width: 100%;
  }

  &__input-group-button {
    height: 36px;
    min-width: 36px;
    max-width: 36px;
    margin-left: 8px;
    border-radius: 6px;

    .icon {
      width: 16px;
      height: 16px;
      margin: auto;
    }
  }

  &__input-group-button_open {
    background: rgba($secondary, 0.06);
  }

  &__input {
    width: 100%;
    height: 36px;
    background: $primary url('~@/assets/icons/search.svg') no-repeat 13px center;
    padding-left: 36px;
    box-sizing: border-box;
    border: 0;
    border-radius: 6px;
    font-size: 15px;
    font-family: $font-regular;

    &:focus {
      outline: none;
    }
  }

  &__input-prompt {
    font-size: 12px;
    font-family: $font-regular;
    margin-top: 4px;
  }

  &__input-title {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
    margin-bottom: 6px;
  }

  &__date-range {
    display: flex;
  }

  &__date-range-image {
    height: 16px;
    width: 16px;
  }

  &__date-range-text {
    margin-left: 8px;
  }

  &__dropdown-text {
    display: flex;

    &:after {
      display: flex;
      content: ' ';
      height: 16px;
      width: 16px;
      background: url('~@/assets/icons/bottom-arrow.svg') no-repeat center center;
    }
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .transactions-filter__input-group-button {
    display: none;
  }
}
</style>
