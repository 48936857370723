<template>
  <div
    data-testid="analytics-filters-sidebar"
    class="sidebar-filter"
  >
    <div class="sidebar-filter__input-group">
      <div class="sidebar-filter__input-title">
        {{ $t('partner-portal.analytics.sidebar.filters.countries') }}
      </div>

      <DropdownFilter
        data-testid="countries-dropdown-filter"
        class="sidebar-filter__dropdown-content"
        :title="$t('partner-portal.analytics.sidebar.filters.all-countries')"
        :items="countriesList"
        :active-items="selectedCountriesList"
        @apply-changes="applyCountriesFilter"
        @discard-changes="discardCountriesFilter"
      />
    </div>

    <div class="sidebar-filter__input-group">
      <DateRangePicker
        data-test-id="date-range-dropdown-filter"
        :value="filterDateRange"
        :label="$t('partner-portal.common.date-range')"
        :min-date="minDate"
        @date-change="applyDatesFilter"
      />
    </div>

    <div class="sidebar-filter__input-group">
      <div class="sidebar-filter__input-title">
        {{ $t('partner-portal.analytics.sidebar.filters.gateway') }}
      </div>

      <DropdownFilter
        data-testid="gateway-dropdown-filter"
        class="sidebar-filter__dropdown-content"
        :title="$t('partner-portal.analytics.sidebar.filters.all-gateways')"
        :items="gatewaysList"
        :active-items="selectedGatewaysList"
        @apply-changes="applyGatewaysFilter"
        @discard-changes="discardGatewaysFilter"
      />
    </div>

    <div class="sidebar-filter__input-group">
      <div class="sidebar-filter__input-title">
        {{ $t('partner-portal.analytics.sidebar.filters.cryptocurrencies') }}
      </div>

      <DropdownFilter
        data-testid="cryptos-dropdown-filter"
        class="sidebar-filter__dropdown-content"
        :title="$t('partner-portal.analytics.sidebar.filters.all-cryptocurrencies')"
        :items="currenciesList"
        :active-items="selectedCurrenciesList"
        @apply-changes="applyCurrenciesFilter"
        @discard-changes="discardCurrenciesFilter"
      />
    </div>

    <div
      v-if="isPaymentMethodsEnabled"
      class="sidebar-filter__input-group"
    >
      <div class="sidebar-filter__input-title">
        {{ $t('partner-portal.analytics.sidebar.filters.payment-method') }}
      </div>

      <DropdownFilter
        data-testid="payment-method-dropdown-filter"
        class="sidebar-filter__dropdown-content"
        :title="$t('partner-portal.analytics.sidebar.filters.all-payment-methods')"
        :items="paymentMethodsList"
        :active-items="selectedPaymentMethodsList"
        @apply-changes="applyPaymentMethodsFilter"
        @discard-changes="discardPaymentMethodsFilter"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DropdownFilter from '@/components/DropdownFilter';
import DateRangePicker from '@/components/DateRangePicker';

export default {
  name: 'AnalyticsSidebarFilter',

  components: {
    DropdownFilter,
    DateRangePicker,
  },

  data: () => ({
    dateRange: null,
  }),

  computed: {
    ...mapGetters('filterStore', {
      gatewaysList: 'gatewaysList',
      countriesList: 'countriesList',
      currenciesList: 'currenciesList',
      paymentMethodsList: 'paymentMethodsList',
      minDate: 'minDate',
    }),
    ...mapGetters('analyticsStore', {
      selectedCountriesList: 'selectedCountriesList',
      selectedDateRange: 'selectedDateRange',
      selectedGatewaysList: 'selectedGatewaysList',
      selectedCurrenciesList: 'selectedCurrenciesList',
      selectedPaymentMethodsList: 'selectedPaymentMethodsList',
    }),
    ...mapGetters('featureFlagsStore', {
      isPaymentMethodsEnabled: 'isPaymentMethodsEnabled',
    }),

    filterDateRange() {
      if (this.dateRange) return this.dateRange;
      return this.selectedDateRange;
    },
  },

  methods: {
    ...mapActions('analyticsStore', {
      setCountriesFilter: 'setCountriesFilter',
      setDatesFilter: 'setDatesFilter',
      setGatewaysFilter: 'setGatewaysFilter',
      setCurrenciesFilter: 'setCurrenciesFilter',
      setPaymentMethodsFilter: 'setPaymentMethodsFilter',

      getAggregatedStats: 'getAggregatedStats',
      getProfitAndTurnoverChartData: 'getProfitAndTurnoverChartData',
      getTransactionsChartData: 'getTransactionsChartData',
      getSuccessRateChartData: 'getSuccessRateChartData',
      getTopCountries: 'getTopCountries',
      getTopCurrencies: 'getTopCurrencies',
      getTransactions: 'getTransactions',
    }),

    requestDataWithUpdatedFilters() {
      this.getAggregatedStats();
      this.getProfitAndTurnoverChartData();
      this.getTransactionsChartData();
      this.getSuccessRateChartData();
      // TODO: Fetch sales funnel data when we have such chart developed
      this.getTopCountries();
      this.getTopCurrencies();
      this.getTransactions();
    },

    applyCountriesFilter(changes) {
      this.setCountriesFilter(changes)
        .then(this.requestDataWithUpdatedFilters);
    },

    discardCountriesFilter() {
      this.setCountriesFilter([])
        .then(this.requestDataWithUpdatedFilters);
    },

    applyDatesFilter({ start: from, end: to }) {
      this.dateRange = {
        start: from, end: to,
      };

      this.setDatesFilter({
        from, to,
      })
        .then(this.requestDataWithUpdatedFilters);
    },

    applyGatewaysFilter(changes) {
      this.setGatewaysFilter(changes)
        .then(this.requestDataWithUpdatedFilters);
    },

    discardGatewaysFilter() {
      this.setGatewaysFilter([])
        .then(this.requestDataWithUpdatedFilters);
    },

    applyCurrenciesFilter(changes) {
      this.setCurrenciesFilter(changes)
        .then(this.requestDataWithUpdatedFilters);
    },

    discardCurrenciesFilter() {
      this.setCurrenciesFilter([])
        .then(this.requestDataWithUpdatedFilters);
    },

    applyPaymentMethodsFilter(changes) {
      this.setPaymentMethodsFilter(changes)
        .then(this.requestDataWithUpdatedFilters);
    },

    discardPaymentMethodsFilter() {
      this.setPaymentMethodsFilter([])
        .then(this.requestDataWithUpdatedFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.sidebar-filter {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 24px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px $secondary-border-color;

  &__input-group {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__input-title {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
    margin-bottom: 6px;
  }

  &__date-range {
    display: flex;
  }

  &__calendar-button {
    height: 16px;
    width: 16px;
    margin-right: 10px;
  }
}
</style>
