<template>
  <div
    class="checkbox__wrapper"
    :class="{
      'has-error': error,
      'is-disabled': disabled,
    }"
  >
    <label class="checkbox__fauxbox">
      <input
        :value="value"
        :name="name"
        :disabled="disabled"
        :checked="checked"
        type="checkbox"
        v-on="$listeners"
      >

      <span />

      <div
        v-if="label"
        class="checkbox__label"
      >
        {{ label }}
      </div>
    </label>

    <div
      v-if="description"
      class="checkbox__description"
    >
      {{ description }}
    </div>

    <span
      v-if="error"
      class="checkbox__error"
    >
      {{ error }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',

  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.checkbox {
  &__wrapper {
    position: relative;
    display: inline-flex;
  }

  &__fauxbox {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    min-width: 16px;
    min-height: 16px;
  }

  &__fauxbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__fauxbox span {
    position: absolute;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid $checkbox-border-color;
    background-color: $background-color;
    border-radius: 2px;
    transition: border-color 0.2s linear;
    box-sizing: content-box;
  }

  &__fauxbox input:checked ~ span:after {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    background: url(~@/assets/icons/check-black.svg) center no-repeat;
  }

  &__fauxbox:hover span {
    border-color: $checkbox-border-hover-color;
  }

  &__wrapper.has-error &__fauxbox span {
    border-color: $error-color;
  }

  &__wrapper.has-error &__fauxbox:hover span {
    border-color: $checkbox-errored-hover-color;
  }

  &__label {
    font-family: $font-regular;
    margin-left: 26px;
    line-height: 1.25;
  }

  &__description {
    color: $secondary-text-color;
    margin-top: 2px;
  }

  &__wrapper.is-disabled &__label {
    color: $secondary-text-color;
  }

  &__wrapper.is-disabled &__fauxbox {
    pointer-events: none;
  }

  &__wrapper.is-disabled &__fauxbox span {
    background-color: $background-color;
    border-color: $background-color;
  }

  &__error {
    display: block;
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.33;
    color: $error-color;
  }
}
</style>
