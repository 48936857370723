<template>
  <header class="header">
    <router-link
      to="/"
      class="icon icon-logo-black header__logo-image"
    />

    <div
      v-if="activeRouteName"
      class="header__mobile-active-route"
    >
      {{ activeRouteName }}
    </div>

    <div
      v-if="isUserSignedIn"
      class="header__nav"
      :class="{ 'header__nav--mobile-opened': displayMobileNav }"
    >
      <div class="header__mobile-nav">
        <div class="header__mobile-nav-logo">
          <i class="icon icon-logo-white header__mobile-nav-logo-image" />
        </div>

        <div
          class="header__mobile-nav-cross-trigger"
          @click="toggleMobileNav"
        >
          <i class="icon icon-cross header__mobile-nav-cross" />
        </div>
      </div>

      <div class="header__nav_left">
        <Products v-if="isProductsShown" />

        <Nav class="header__nav-wrapper" />
      </div>

      <CompanyNav class="header__company-nav-wrapper" />
    </div>

    <div
      v-if="isUserSignedIn"
      data-testid="company-menu"
      class="icon icon-burger header__burger-image"
      @click="toggleMobileNav"
    />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

import Products from '@/components/AppHeader/Products';
import CompanyNav from '@/components/AppHeader/CompanyNav';
import Nav from '@/components/AppHeader/Nav';

export default {
  name: 'AppHeader',

  components: {
    Nav,
    CompanyNav,
    Products,
  },

  provide() {
    return {
      'Header.CloseMobileNav': this.closeMobileNav,
    };
  },

  data() {
    return {
      displayMobileNav: false,
    };
  },

  computed: {
    ...mapGetters({
      isUserSignedIn: 'userStore/isUserSignedIn',
      accountProducts: 'accountProductsStore/products',
    }),
    ...mapGetters('rolesStore', {
      isTransactionsMonitoringAllowed: 'isTransactionsMonitoringAllowed',
      isViewOnlyMonitoringAllowed: 'isViewOnlyMonitoringAllowed',
      isWidgetSettingsMonitoringAllowed: 'isWidgetSettingsMonitoringAllowed',
      isApiKeysMonitoringAllowed: 'isApiKeysMonitoringAllowed',
    }),
    activeRouteName() {
      return this.$t(this.$route.meta.title);
    },
    isProductsShown() {
      const permittedRolesForProductDisplay = [
        this.isTransactionsMonitoringAllowed,
        this.isViewOnlyMonitoringAllowed,
        this.isWidgetSettingsMonitoringAllowed,
        this.isApiKeysMonitoringAllowed,
      ];

      return permittedRolesForProductDisplay.some(role => role) && this.accountProducts.length;
    },
  },

  watch: {
    $route() {
      this.closeMobileNav();
    },
  },

  methods: {
    toggleMobileNav() {
      this.displayMobileNav = !this.displayMobileNav;
    },
    closeMobileNav() {
      this.displayMobileNav = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  &__nav {
    display: none;
  }

  &__nav--mobile-opened {
    display: flex;
    flex-direction: column;
    position: fixed;
    overflow-y: auto;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $mobile-company-background;
  }

  &__logo-image {
    display: flex;
    min-width: 14px;
    max-width: 14px;
    height: 26px;
    background-size: cover;
    background-position: 100%;
  }

  &__mobile-active-route {
    font-size: 18px;
    line-height: 22px;
  }

  &__mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    max-height: 64px;
    padding: 0 16px;
    background-color: $secondary;
  }

  &__mobile-nav-logo {
    width: 98px;
    height: 26px;
  }

  &__mobile-nav-cross {
    width: 24px;
    height: 24px;
  }

  &__burger-image {
    display: flex;
    height: 24px;
    width: 24px;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .header {
    height: 80px;

    &__logo-image {
      display: none;
      min-width: 98px;
      max-width: 98px;
      background-size: 100%;
      background-position: 0;
    }

    &__mobile-active-route {
      display: none;
    }

    &__mobile-nav {
      display: none;
    }

    &__burger-image {
      display: none;
    }

    &__nav {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;

      &_left {
        display: flex;
        align-items: center;
      }
    }

    &__nav-products {
      margin: 0 32px;
    }

    &__nav--mobile-opened {
      position: relative;
      flex-direction: row;
      background: none;
      overflow-y: visible;
    }
  }
}

@media screen and (min-width: $desktop-breakpoint) {
  .header {
    &__logo-image {
      display: flex;
    }

    &__nav_left {
      margin-left: 32px;
    }
  }
}
</style>
