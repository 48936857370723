<template>
  <div class="payment-methods">
    <Select
      class="payment-methods__select"
      :label="$t('partner-portal.widget-settings.payment-method')"
      :value="selectedPaymentMethod"
      :options="selectFieldOptions"
      :is-loading="isLoading"
      @update="updatePaymentMethod"
    />
  </div>
</template>

<script>
import Select from '@/components/Select';

const CRYPTO_TYPE = 'crypto';

export default {
  name: 'PaymentMethodsSelector',

  components: {
    Select,
  },

  props: {
    selectedPaymentMethod: {
      type: String,
      required: true,
    },
    paymentMethods: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    selectFieldOptions() {
      const { paymentMethods } = this;
      const formattedPaymentMethods = paymentMethods.map(paymentMethod => {
        const { id, name, type, asset } = paymentMethod;
        return {
          key: id,
          value: type === CRYPTO_TYPE ? asset.name : name,
        };
      });

      return formattedPaymentMethods;
    },
  },

  methods: {
    updatePaymentMethod({ value: paymentMethod, key: paymentMethodId }) {
      this.$emit('change-payment-method', {
        paymentMethod, paymentMethodId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-methods {
  &__checkbox {
    display: flex;
  }

  &__select {
    display: flex;
  }
}
</style>
