import { apiClient } from '@/network';

import LoggerService from '@/services/LoggerService';
import LocalStorageService from '@/services/LocalStorageService';
import NotificationService from '@/services/NotificationService';

export default {
  namespaced: true,

  state: {
    products: [],

    activeProduct: {},
  },

  getters: {
    products: state => state.products,
    activeProduct: state => state.activeProduct,
    activeProductId: state => state.activeProduct?.id,
    activeProductName: state => state.activeProduct?.name,
  },

  mutations: {
    clearStore(state) {
      state.products = [];
      state.activeProduct = {};
    },

    setProducts(state, products) {
      state.products = products;
    },

    setActiveProduct(state, product) {
      state.activeProduct = product;
      LocalStorageService.setStringifyItem('activeProduct', product);
    },
  },

  actions: {
    async getProducts({ commit }) {
      try {
        const { data: { products } } = await apiClient.get('/v1/account-products');
        const isProductsEmpty = products.length === 0;

        if (isProductsEmpty) {
          NotificationService.notifyAboutError('partner-portal.notifications.no-active-products', {
            position: 'top-center',
            duration: null,
          });

          return;
        }

        const setActiveProductFromLocaleStorage = LocalStorageService.getParseItem('activeProduct');

        commit('setProducts', products);
        commit('setActiveProduct', setActiveProductFromLocaleStorage ?? products[0]);
      } catch (error) {
        LoggerService.logToConsole(error);
      }
    },
  },
};
