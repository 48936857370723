import store from '@/store';

const dashboardRoute = {
  link: '/',
  name: 'partner-portal.header.basic-routes.dashboard',
  live: true,
  testId: 'dashboard-nav-item',
};

const analyticsRoute = {
  link: '/analytics',
  name: 'partner-portal.header.basic-routes.analytics',
  testId: 'analytics-nav-item',
};

const transactionsRoute = {
  link: '/transactions',
  name: 'partner-portal.header.basic-routes.transactions',
  testId: 'transactions-nav-item',
};

const widgetSettingsRoute = {
  link: '/company/widget',
  name: 'partner-portal.header.company-routes.widget',
  testId: 'widget-settings-nav-item',
};

export const buildNavigationTree = () => {
  const navigationTree = [];

  const isTransactionsMonitoringAllowed = store.getters['rolesStore/isTransactionsMonitoringAllowed'];
  const isViewOnlyMonitoringAllowed = store.getters['rolesStore/isViewOnlyMonitoringAllowed'];

  if (isViewOnlyMonitoringAllowed) {
    const navigationRoutes = [dashboardRoute, analyticsRoute, transactionsRoute, widgetSettingsRoute];
    navigationRoutes.forEach(route => navigationTree.push(route));

    return navigationTree;
  }

  if (isTransactionsMonitoringAllowed) {
    const transactionsMonitoringRoutes = [dashboardRoute, analyticsRoute, transactionsRoute];
    transactionsMonitoringRoutes.forEach(route => navigationTree.push(route));
  }

  const isWidgetSettingsMonitoringAllowed = store.getters['rolesStore/isWidgetSettingsMonitoringAllowed'];
  if (isWidgetSettingsMonitoringAllowed) navigationTree.push(widgetSettingsRoute);

  return navigationTree;
};
