<template>
  <section class="payment-settings">
    <div class="payment-settings__block">
      <h1 class="payment-settings__header">
        {{ $t('partner-portal.payout-settings.title') }}
      </h1>

      <Loader
        v-if="isLoading"
        class="payment-settings__loader"
      />

      <div
        v-else
        class="payment-settings__currencies-list"
      >
        <WalletAddress
          v-for="(coin, index) in paymentSettings"
          :key="coin.currency"
          :coin="coin"
          :error="currencyErrorMessage(index)"
          :meta="meta.currencies"
          @set-default="setAsDefaultPaymentMethod"
        />
      </div>

      <footer class="payment-settings__footer">
        <AppButton
          type="button"
          class="btn btn--primary payment-settings__action-btn"
          :text="$t('partner-portal.buttons.save')"
          :is-loading="isUpdatingPaymentSettings"
          :is-disabled="isViewOnlyMonitoringAllowed"
          @click="saveChanges"
        />
        <AppButton
          type="button"
          class="btn btn--secondary payment-settings__action-btn"
          :text="$t('partner-portal.buttons.cancel')"
          :is-disabled="isCancelButtonDisabled"
          @click="showCancelChangesModal = true"
        />
      </footer>
    </div>

    <Modal
      :show.sync="showBeforeLeaveModal"
      :component="$options.modals.PaymentSettingsBeforeLeaveModal"
      @confirm="onConfirmBeforeLeaveModal"
      @reject="onRejectBeforeLeaveModal"
    />

    <Modal
      :show.sync="showCancelChangesModal"
      :component="$options.modals.PaymentSettingsCancelChangesModal"
      @confirm="onConfirmCancelChangesModal"
      @reject="onRejectCancelChangesModal"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { beforeUnloadListener, getPaymentSettings, savePaymentSettings } from './PaymentSettings.utils';
import { Modal, PaymentSettingsBeforeLeaveModal, PaymentSettingsCancelChangesModal } from '@/components/Modal';
import WalletAddress from '@/components/WalletAddress';
import AppButton from '@/components/AppButton';
import Loader from '@/components/Loader';

const WALLET_ADDRESS_MAX_LENGTH = 100;

export default {
  name: 'PaymentSettings',

  BASE_PAYMENT_SETTINGS: [],

  components: {
    AppButton,
    WalletAddress,
    Loader,
    Modal,
  },

  modals: {
    PaymentSettingsBeforeLeaveModal,
    PaymentSettingsCancelChangesModal,
  },

  data: () => ({
    isLoading: true,
    areWalletsUpdated: false,

    showBeforeLeaveModal: false,
    showCancelChangesModal: false,

    isUpdatingPaymentSettings: false,

    routeToRedirect: null,

    errors: [],

    paymentSettings: [],
    meta: [],
  }),

  computed: {
    ...mapGetters('rolesStore', {
      isViewOnlyMonitoringAllowed: 'isViewOnlyMonitoringAllowed',
    }),

    isCancelButtonDisabled() {
      return this.isViewOnlyMonitoringAllowed || this.isUpdatingPaymentSettings;
    },
  },

  watch: {
    paymentSettings: {
      handler(newValue, oldValue) {
        if (!oldValue.length) return;
        this.areWalletsUpdated = true;

        const isAnyWalletExceedsLimits = newValue.some(
          settings => settings.wallet.address.length > WALLET_ADDRESS_MAX_LENGTH
            || settings.wallet.destination_tag.length > WALLET_ADDRESS_MAX_LENGTH,
        );

        let normalisedWallets = newValue;
        if (isAnyWalletExceedsLimits) {
          normalisedWallets = newValue.map((settings, index) => {
            const isTooLongAddress = settings.wallet.address.length > WALLET_ADDRESS_MAX_LENGTH;
            const isTooLongMemo = settings.wallet.destination_tag.length
              > WALLET_ADDRESS_MAX_LENGTH;

            if (isTooLongAddress || isTooLongMemo) {
              let errorKey;

              if (isTooLongAddress) {
                errorKey = 'partner-portal.errors.wallet-address-max-length';
              } else if (isTooLongMemo) {
                errorKey = 'partner-portal.errors.wallet-memo-max-length';
              }

              this.errors.push({
                property_path: `${index}`,
                message: this.$t(errorKey),
              });

              return {
                ...settings,
                wallet: {
                  ...settings.wallet,
                  address: settings.wallet.address.slice(0, WALLET_ADDRESS_MAX_LENGTH),
                  destination_tag: settings.wallet.destination_tag.slice(
                    0, WALLET_ADDRESS_MAX_LENGTH,
                  ),
                },
              };
            }

            return settings;
          });
        }

        this.paymentSettings = normalisedWallets;

        const { BASE_PAYMENT_SETTINGS } = this.$options;
        const modifiedPaymentSettings = JSON.stringify(newValue);

        const noChangesDetected = BASE_PAYMENT_SETTINGS === modifiedPaymentSettings;

        if (noChangesDetected) {
          this.areWalletsUpdated = false;
          window.onbeforeunload = null;
        } else {
          this.areWalletsUpdated = true;
          window.onbeforeunload = beforeUnloadListener;
        }
      },
      deep: true,
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.areWalletsUpdated) {
      this.showBeforeLeaveModal = true;
      this.routeToRedirect = to.name;

      return next(false);
    }

    return next();
  },

  async mounted() {
    this.fetchPaymentSettings();
  },

  destroyed() {
    window.onbeforeunload = null;
  },

  methods: {
    fetchPaymentSettings() {
      this.isUpdatingPaymentSettings = true;

      getPaymentSettings()
        .then(({ paymentSettings, meta }) => {
          this.paymentSettings = paymentSettings;
          this.$options.BASE_PAYMENT_SETTINGS = JSON.stringify(paymentSettings);

          this.meta = meta;
        })
        .finally(() => {
          this.isLoading = false;
          this.isUpdatingPaymentSettings = false;
        });
    },

    currencyErrorMessage(index) {
      const { errors } = this;

      if (!errors.length) return '';

      const errorWithGivenIndex = errors.find(error => {
        const { property_path: propertyPath } = error;
        const walletIndex = propertyPath.match(/\d/g)[0];

        return +walletIndex === index;
      });

      if (!errorWithGivenIndex) return '';

      return errorWithGivenIndex.message;
    },

    dropErrors() {
      this.errors = [];
    },

    setAsDefaultPaymentMethod(currency) {
      const updatedPaymentSettings = this.paymentSettings.map(settings => {
        const { currency: settingsCurrency } = settings;
        let { is_default: isDefault } = settings;

        if (isDefault) isDefault = false;
        if (settingsCurrency === currency) isDefault = true;

        return {
          ...settings,
          is_default: isDefault,
        };
      });

      this.paymentSettings = updatedPaymentSettings;
    },

    saveChanges() {
      if (this.isUpdatingPaymentSettings) return;

      this.dropErrors();

      const { paymentSettings } = this;
      this.isUpdatingPaymentSettings = true;

      savePaymentSettings(paymentSettings)
        .then(() => {
          this.areWalletsUpdated = false;
          window.onbeforeunload = null;

          this.fetchPaymentSettings();
        })
        .catch(error => {
          this.showBeforeLeaveModal = false;
          this.showCancelChangesModal = false;

          this.errors = error.errors;
        })
        .finally(() => {
          this.isUpdatingPaymentSettings = false;
        });
    },

    onConfirmCancelChangesModal() {
      this.dropErrors();

      this.paymentSettings = JSON.parse(this.$options.BASE_PAYMENT_SETTINGS);

      this.showCancelChangesModal = false;
      this.areWalletsUpdated = false;
      window.onbeforeunload = null;
    },

    onRejectCancelChangesModal() {
      this.showCancelChangesModal = false;
    },

    redirectAfterModalClose() {
      if (this.routeToRedirect) {
        this.$router.push({
          name: this.routeToRedirect,
        });
      }
    },

    async onConfirmBeforeLeaveModal() {
      window.onbeforeunload = null;
      this.areWalletsUpdated = false;
      this.showBeforeLeaveModal = false;

      this.$nextTick(this.redirectAfterModalClose);
    },

    async onRejectBeforeLeaveModal() {
      this.showBeforeLeaveModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.payment-settings {
  width: 100%;
  padding: 24px 20px 32px;
  background-color: $primary;
  box-shadow: 0px 2px 8px $secondary-border-color;
  border-radius: 8px;

  @media screen and (min-width: $desktop-breakpoint) {
    padding: 64px 150px;
  }

  &__loader {
    display: flex;
    align-items: center;
    height: 500px;
  }

  &__header {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 32px;

    @media screen and (min-width: $tablet-breakpoint) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  &__currency {
    padding: 24px 0;
    border-bottom: 1px solid $list-item-border-color;
  }

  &__currency-topline {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__currency-header {
    font-size: 15px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: none;
    flex-direction: column;

    @media screen and (min-width: $tablet-breakpoint) {
      max-width: 500px;
      align-items: center;
      flex-direction: row;
    }
  }

  &__footer {
    display: flex;
    margin-top: 32px;
    flex-direction: column;

    @media screen and (min-width: $tablet-breakpoint) {
      flex-direction: row;
    }
  }

  &__action-btn {
    min-width: 144px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    @media screen and (min-width: $tablet-breakpoint) {
      &:not(:last-child) {
        margin-right: 14px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
