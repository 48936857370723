export const DATEPICKER_ATTRS = [
  {
    key: 'today',
    dates: new Date(),
    highlight: {
      contentStyle: {
        backgroundColor: '#000000',
        borderRadius: '3px',
        color: '#ffffff',
      },
    },
  },
];

export const DATEPICKER_FORMATS = {
  title: 'MMMM YYYY',
  weekdays: 'W',
  navMonths: 'MMM',
  input: ['DD.MM.YYYY', 'DD.MM.YYYY'],
};

export const DATEPICKER_DRAG_ATTRS = {
  highlight: {
    contentStyle: {
      backgroundColor: 'rgba(164, 167, 227, 0.15)',
      color: '#000000',
      borderRadius: '3px',
    },
  },
};

export const DATEPICKER_SELECT_ATTRS = {
  highlight: {
    class: 'base',
    start: {
      fillMode: 'solid',
      contentStyle: {
        borderRadius: '3px',
        backgroundColor: '#A4A7E3',
        color: '#000000',
      },
    },
    base: {
      fillMode: 'light',
      style: {
        backgroundColor: '#A4A7E3',
        opacity: '0.1',
        color: '#000000',
      },
    },
    end: {
      fillMode: 'solid',
      contentStyle: {
        borderRadius: '3px',
        backgroundColor: '#A4A7E3',
        color: '#000000',
      },
    },
  },
};
