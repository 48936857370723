import { apiClient } from '@/network';
import LoggerService from '@/services/LoggerService';
import NotificationService from '@/services/NotificationService';
import { CRYPTOS_THAT_REQUIRE_MEMO } from '@/views/PaymentSettings/PaymentSettings.constants';

export const beforeUnloadListener = event => {
  event.preventDefault();
  event.returnValue = 'Are you sure you want to exit?';
};

export const formatMinPayoutValue = value => {
  const numberedValue = parseFloat(value);
  const isAmountFloating = numberedValue > 0 && numberedValue < 1;

  if (isAmountFloating) {
    let precision = 0;
    const precisePart = value.split('.')[1];
    for (let i = 0; i < precisePart.length; i += 1) {
      const isNonZeroDigit = +precisePart[i] > 0;
      if (isNonZeroDigit) precision = i + 1;
    }

    return numberedValue.toFixed(precision);
  }

  return numberedValue;
};

export const getPaymentSettings = async () => {
  try {
    const { data: { data: paymentSettings, meta } } = await apiClient.get('/v1/payment-settings');

    const paymentSettingsList = paymentSettings.map(paymentMethod => {
      const { currency, min_amount: minAmount, wallet } = paymentMethod;

      let nonNullWallet = wallet;

      if (!wallet) {
        const { currencies } = meta;
        const appropriateBlockchain = currencies.find(
          metaCurrency => metaCurrency.code === currency,
        ).blockchains[0];

        nonNullWallet = {
          blockchain: appropriateBlockchain,
          address: '',
          destination_tag: '',
        };
      }

      if (nonNullWallet.destination_tag === null) {
        nonNullWallet.destination_tag = '';
      }

      return {
        ...paymentMethod,
        min_amount: formatMinPayoutValue(minAmount),
        wallet: nonNullWallet,
      };
    });

    return {
      paymentSettings: paymentSettingsList,
      meta,
    };
  } catch (error) {
    return LoggerService.captureSentryError(error);
  }
};

const beforeSettingsSave = settings => {
  const settingsWithFormattedWallets = settings.map(paymentMethod => {
    const { currency } = paymentMethod;
    let { wallet } = paymentMethod;

    const isMemoPresent = !!wallet?.destination_tag;
    const isMemoRequired = CRYPTOS_THAT_REQUIRE_MEMO.find(
      crypto => crypto.currency === currency && crypto.blockchain === wallet.blockchain,
    );

    if (isMemoPresent && !isMemoRequired) {
      wallet.destination_tag = null;
    }

    if (!wallet.address) {
      wallet = null;
    }

    return {
      currency,
      wallet,
      is_default: paymentMethod.is_default,
    };
  });

  return settingsWithFormattedWallets;
};

export const savePaymentSettings = modifiedSettings => {
  const modifiedPaymentSettings = beforeSettingsSave(modifiedSettings);

  return new Promise((resolve, reject) => {
    apiClient.patch('/v1/payment-settings', {
      data: [
        ...modifiedPaymentSettings,
      ],
    })
      .then(response => {
        NotificationService.notifyAboutSuccess('partner-portal.notifications.payment-settings-saved');
        resolve(response);
      })
      .catch(error => {
        NotificationService.notifyAboutError('partner-portal.notifications.error-updating-payment-settings');
        reject(error.response.data);
      });
  });
};
