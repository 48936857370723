<template>
  <ul class="sidebar-menu">
    <li
      v-for="route in routes"
      :key="route.name"
      class="sidebar-menu__item"
    >
      <router-link
        class="sidebar-menu__item-text"
        :class="{
          'sidebar-menu__item-text--active': isRouteActive(route.name),
        }"
        :to="route.link"
      >
        <i
          class="sidebar-menu__item-icon"
          :class="['icon', route.name === 'payouts' ? `icon-${route.icon}-black` : `icon-${route.icon}`]"
        />
        {{ route.title }}

        <button
          v-if="route.children"
          class="sidebar-menu__toggler"
          @click.prevent="toggleSubmenu(route.name)"
        >
          <i
            class="icon icon-bottom-arrow sidebar-menu__toggler-icon"
            :class="{
              'sidebar-menu__toggler-icon--opened': isSubmenuOpened(route.name),
            }"
          />
        </button>
      </router-link>

      <ul
        v-if="route.children && isSubmenuOpened(route.name)"
        class="sidebar-menu__submenu"
      >
        <li
          v-for="subroute in route.children"
          :key="subroute.name"
          class="sidebar-menu__submenu-item"
        >
          <router-link
            class="sidebar-menu__item-text"
            :to="subroute.link"
          >
            {{ subroute.title }}
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SidebarMenuDesktop',

  props: {
    routes: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    activeSubmenu: null,
  }),

  methods: {
    toggleSubmenu(routeName) {
      if (this.activeSubmenu === routeName) {
        this.activeSubmenu = null;
        return;
      }

      this.activeSubmenu = routeName;
    },

    isSubmenuOpened(routeName) {
      return this.activeSubmenu === routeName;
    },

    isRouteActive(routeName) {
      return this.$route.name === routeName;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.sidebar-menu {
  display: none;
  padding: 12px;
  margin-top: 0;

  &__submenu {
    padding-left: 0;
  }

  &__item,
  &__submenu-item {
    list-style-type: none;
    font-size: 15px;
  }

  &__submenu-item {
    padding-left: 32px;
  }

  &__item-text {
    color: $secondary;
    display: flex;
    padding: 12px;
    text-decoration: none;
    transition: all 250ms linear;
    position: relative;
    border-radius: 8px;

    &--active,
    &:hover {
      background-color: $item-active-background;
    }
  }

  &__item-icon {
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }

  &__toggler {
    height: 100%;
    width: 40px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__toggler-icon {
    width: 16px;
    height: 16px;
    margin-left: auto;
    margin-right: auto;

    transform: none;

    &--opened {
      transform: rotate(-180deg);
    }
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .sidebar-menu {
    display: block;
  }
}
</style>
