import store from '@/store';

import { i18n } from '@/i18n';

const translate = string => i18n.tc(string);

const companyProfileRoute = {
  link: '/company/profile',
  name: 'profileSettings',
  title: translate('partner-portal.menus.company.profile'),
  icon: 'briefcase',
};

const payoutsRoute = {
  link: '/payouts',
  name: 'payouts',
  title: translate('partner-portal.header.basic-routes.payouts'),
  icon: 'payouts',
};

const companyPayoutRoute = {
  link: '/company/payment',
  name: 'paymentSettings',
  title: translate('partner-portal.menus.company.payout-settings'),
  icon: 'payouts-settings-black',
};

const companyChangePasswordRoute = {
  link: '/company/change-password',
  name: 'changePassword',
  title: translate('partner-portal.menus.company.change-password'),
  icon: 'change-password-black',
};

class NavigationTreeBuilder {
  navigationTree = []

  constructor(getters) {
    this.getters = getters;
  }

  addCompanyProfile() {
    const isProfileSettingsPageVisible = this.getters['featureFlagsStore/isProfileSettingsPageVisible'];

    if (isProfileSettingsPageVisible) this.navigationTree.push(companyProfileRoute);

    return this;
  }

  addPayoutsRoute() {
    const isViewOnlyMonitoringAllowed = this.getters['rolesStore/isViewOnlyMonitoringAllowed'];
    const isPayoutsMonitoringAllowed = store.getters['rolesStore/isPayoutsMonitoringAllowed'];

    const canShowPayoutsRoute = isViewOnlyMonitoringAllowed || isPayoutsMonitoringAllowed;

    if (canShowPayoutsRoute) this.navigationTree.push(payoutsRoute);

    return this;
  }

  addCompanyPayoutRoute() {
    const isViewOnlyMonitoringAllowed = this.getters['rolesStore/isViewOnlyMonitoringAllowed'];
    const isPayoutsMonitoringAllowed = this.getters['rolesStore/isPayoutsMonitoringAllowed'];

    const canShowCompanyPayoutRoute = isViewOnlyMonitoringAllowed || isPayoutsMonitoringAllowed;

    if (canShowCompanyPayoutRoute) this.navigationTree.push(companyPayoutRoute);

    return this;
  }

  addCompanyChangePasswordRoute() {
    this.navigationTree.push(companyChangePasswordRoute);

    return this;
  }

  getNavigation() {
    return this.navigationTree;
  }
}

export const buildNavigationTree = () => {
  const { getters } = store;
  const navigationBuilder = new NavigationTreeBuilder(getters);

  return navigationBuilder
    .addPayoutsRoute()
    .addCompanyPayoutRoute()
    .addCompanyProfile()
    .addCompanyChangePasswordRoute()
    .getNavigation();
};
