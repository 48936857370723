<template>
  <aside class="sidebar">
    <SidebarMenu
      class="sidebar__card"
      :routes="routes"
    />
    <PayoutSidebarFilter
      data-testid="period-sidebar"
      class="sidebar__card sidebar__card--primary"
    />
    <SidebarBalanceInfo
      data-testid="unpaid-balance-sidebar"
      :title="$t('partner-portal.dashboard.unpaid-balance')"
      :amount="totalUnpaidAmount"
      :is-expandable="areUnpaidBalancesNotEmpty"
      :is-loading="isUnpaidBalanceLoading"
      class="sidebar__card"
    >
      <SidebarBalanceDetails
        :items="unpaidBalances"
        :is-all-currencies-link-visible="false"
      />
    </SidebarBalanceInfo>

    <SidebarBalanceInfo
      data-testid="total-profit-sidebar"
      :title="$t('partner-portal.dashboard.total-profit')"
      :amount="totalProfitAmount"
      :is-expandable="areProfitBalancesNotEmpty"
      :is-loading="isTotalProfitBalanceLoading"
      class="sidebar__card"
    >
      <SidebarBalanceDetails
        :items="profitBalances"
        :is-all-currencies-link-visible="false"
      />
    </SidebarBalanceInfo>

    <SidebarBalanceInfo
      data-testid="total-sales-sidebar"
      :title="$t('partner-portal.dashboard.total-sales')"
      :amount="totalSalesAmount"
      :is-loading="isTotalRevenueBalanceLoading"
      class="sidebar__card"
    />
    <ManagerCard
      class="sidebar__card sidebar__card--secondary"
    />
    <DevelopersCard class="sidebar__card sidebar__card--secondary" />
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import SidebarMenu from '@/components/SidebarMenu';
import PayoutSidebarFilter from '../PayoutSidebarFilter';
import SidebarBalanceInfo from '@/components/SidebarBalanceInfo';
import SidebarBalanceDetails from '@/components/SidebarBalanceDetails';
import DevelopersCard from '@/components/DevelopersCard';
import ManagerCard from '@/components/ManagerCard';

import { formatCurrency } from '@/utils';
import { buildNavigationTree } from './PayoutSidebar.utils';

export default {
  name: 'PayoutsSidebar',

  components: {
    SidebarMenu,
    PayoutSidebarFilter,
    SidebarBalanceInfo,
    SidebarBalanceDetails,
    DevelopersCard,
    ManagerCard,
  },

  data: () => ({
    isTotalProfitBalanceLoading: true,
    isTotalRevenueBalanceLoading: true,
    isUnpaidBalanceLoading: true,
  }),

  computed: {
    ...mapGetters('dashboardStore', {
      unpaidTotal: 'unpaidTotal',
      unpaidBalances: 'unpaidBalances',
      profitTotal: 'profitTotal',
      profitBalances: 'profitBalances',
      totalSales: 'totalSales',
    }),

    routes() {
      return buildNavigationTree();
    },

    areUnpaidBalancesNotEmpty() {
      return !!this.unpaidBalances?.filter(balance => Number(balance.amount) > 0).length;
    },

    areProfitBalancesNotEmpty() {
      return !!this.profitBalances?.filter(balance => Number(balance.amount) > 0).length;
    },

    totalUnpaidAmount() {
      const { amount = 0, currency = 'USD' } = this.unpaidTotal || {};
      return formatCurrency(amount, currency);
    },

    totalProfitAmount() {
      const { amount = 0, currency = 'USD' } = this.profitTotal || {};
      return formatCurrency(amount, currency);
    },

    totalSalesAmount() {
      const { amount = 0, currency = 'USD' } = this.totalSales || {};
      return formatCurrency(amount, currency);
    },
  },

  mounted() {
    this.getAggregatedStats(false)
      .finally(() => {
        this.isTotalProfitBalanceLoading = false;
        this.isUnpaidBalanceLoading = false;
        this.isTotalRevenueBalanceLoading = false;
      });
  },

  methods: {
    ...mapActions('dashboardStore', {
      getAggregatedStats: 'getAggregatedStats',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';

.sidebar {
  &__card {
    @include sidebar-card();
  }
}
</style>
