import Vue from 'vue';

import store from '@/store';

import ManagerCardMobile from './ManagerCard.mobile.vue';
import ManagerCardDesktop from './ManagerCard.desktop.vue';

const getSocialMediaLink = (type, value) => {
  if (type === 'email') return `mailto:${value}`;
  if (type === 'phone') return `tel:${value}`;
  if (type === 'viber') return `viber://pa?chatURI=${value}`;
  if (type === 'whats_app') return `whatsapp://send?abid=${value}`;
  if (type === 'qq') return `https://wpa.qq.com/msgrd?v=3&uin=${value}`;
  if (type === 'we_chat') return `weixin://dl/chat?${value}`;
  if (type === 'telegram') return `https://t.me/${value}`;

  return `${type}:${value}`;
};

const setSocialMediaLinkTarget = type => {
  if (type === 'telegram') return '_blank';

  return null;
};

const buildManagerCardRoutes = contacts => {
  const nonEmptyContacts = contacts.filter(contact => !!contact.value);
  const builtRoutes = nonEmptyContacts.map(({ contact_type: type, value }) => ({
    link: `${getSocialMediaLink(type, value)}`,
    name: value,
    icon: type,
    target: setSocialMediaLinkTarget(type),
  }));

  return builtRoutes;
};

export default Vue.component('ManagerCard', {
  functional: true,
  render(h, context) {
    const manager = store.getters['accountInfoStore/manager'];
    if (manager) {
      const { first_name: firstName, last_name: lastName, contacts, photo = null } = manager;

      const managerCardProps = {
        props: {
          avatar: photo,
          name: `${firstName} ${lastName}`,
          routes: buildManagerCardRoutes(contacts),
        },
      };

      return h('div', context.data, [
        h(ManagerCardMobile, managerCardProps),
        h(ManagerCardDesktop, managerCardProps),
      ]);
    }

    return null;
  },
});
