// TODO: Remove this constant when backend find way
// to determine which wallet requires memo or destination tag
// and which doesn't

export const CRYPTOS_THAT_REQUIRE_MEMO = [{
  currency: 'XRP',
  blockchain: 'ripple',
}, {
  currency: 'BNB',
  blockchain: 'binance-chain',
}];
