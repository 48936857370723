<template>
  <section class="api-documentation">
    <article class="api-documentation__article">
      <h1 class="api-documentation__header">
        API key authentication
      </h1>
      <p class="api-documentation__paragraph">
        API Key authentication should only be used to access your own account. If your
        application requires access to other Coinbase users’ accounts, do not use API Key.
        To securely access other Coinbase users’ accounts, use Coinbase Connect (OAuth2).
      </p>
      <h2 class="api-documentation__subheader">
        Signing requests
      </h2>
      <p class="api-documentation__paragraph">
        API key authentication requires each request to be signed (enhanced security measure).
        You can create and activate new API keys in your API settings. Your API keys should be
        assigned to access only accounts and permission scopes that are necessary for your app
        to function. For more detail on which scopes are required to access corresponding
        endpoints and functionality, see the API reference.
      </p>

      <h2 class="api-documentation__subheader">
        Making a request
      </h2>
      <p class="api-documentation__paragraph">
        All REST requests must contain the following headers:
      </p>

      <ul class="api-documentation__list list">
        <li class="list__item">
          <code class="api-documentation__code">CB-ACCESS-KEY API</code> key as a string
        </li>
        <li class="list__item">
          <code class="api-documentation__code">CB-ACCESS-SIGN</code>
          Message signature (see below)
        </li>
        <li class="list__item">
          <code class="api-documentation__code">CB-ACCESS-TIMESTAMP</code>
          Timestamp for your request
        </li>
      </ul>

      <p class="api-documentation__paragraph">
        All request bodies should have content type application/json and be valid JSON.
      </p>
      <p class="api-documentation__paragraph">
        Example request:
      </p>

      <pre class="api-documentation__sample">curl https://api.coinbase.com/v2/user \
--header "CB-ACCESS-KEY: &lt;your api key>" \
--header "CB-ACCESS-SIGN: &lt;the user generated message signature>" \
--header "CB-ACCESS-TIMESTAMP: &lt;a timestamp for your request>"</pre>

      <p class="api-documentation__paragraph">
        The <code class="api-documentation__code">CB-ACCESS-SIGN</code>
        header is generated by creating a sha256 HMAC using the secret key on he prehash string
        timestamp + method + requestPath + body (where + represents string concatenation). The
        timestamp value is the same as the
        <code class="api-documentation__code">CB-ACCESS-TIMESTAMP</code> header.
      </p>

      <p class="api-documentation__paragraph">
        The body is the request body string. It is omitted if there is no request body (
        typically for GET requests).
      </p>

      <p class="api-documentation__paragraph">
        The method should be UPPER CASE.
      </p>

      <p class="api-documentation__paragraph">
        The requestPath is the full path and query parameters of the URL, e.g.:
        /v2/exchange-rates?currency=USD.
      </p>

      <p class="api-documentation__paragraph">
        The <code class="api-documentation__code">CB-ACCESS-TIMESTAMP</code>
        header MUST be number of seconds since Unix Epoch in UTC.
      </p>

      <p class="api-documentation__paragraph">
        Your timestamp must be within 30 seconds of the API service time, or your request will
        be considered expired and rejected. We recommend using the time API endpoint to query
        for the API server time if you believe there may be a time skew between your server
        and the API servers.
      </p>

      <p class="api-documentation__paragraph">
        Here are some examples on how to build authentication in different languages:
      </p>

      <!-- TODO discuss about preformatted text injections -->
      <pre class="api-documentation__sample">
# Requires python-requests. Install with pip:
#
#   pip install requests
#
# or, with easy-install:
#
#   easy_install requests

import json, hmac, hashlib, time, requests
from requests.auth import AuthBase

# Before implementation, set environmental variables with the names API_KEY and API_SECRET
API_KEY = 'API_KEY'
API_SECRET = 'API_SECRET'

# Create custom authentication for Coinbase API
class CoinbaseWalletAuth(AuthBase):
  def __init__(self, api_key, secret_key):
      self.api_key = api_key
      self.secret_key = secret_key

  def __call__(self, request):
      timestamp = str(int(time.time()))
      message = timestamp + request.method + request.path_url + (request.body or '')
      signature = hmac.new(self.secret_key, message, hashlib.sha256).hexdigest()

      request.headers.update({
          'CB-ACCESS-SIGN': signature,
          'CB-ACCESS-TIMESTAMP': timestamp,
          'CB-ACCESS-KEY': self.api_key,
      })
      return request

api_url = 'https://api.coinbase.com/v2/'
auth = CoinbaseWalletAuth(API_KEY, API_SECRET)

# Get current user
r = requests.get(api_url + 'user', auth=auth)
print r.json()
# {u'data': {u'username': None, u'resource': u'user', u'name': u'User'...

# Send funds
tx = {
  'type': 'send',
  'to': 'user@example.com',
  'amount': '10.0',
  'currency': 'USD',
}
r = requests.post(api_url + 'accounts/primary/transactions', json=tx, auth=auth)
print r.json()
# {u'data': {u'status': u'pending', u'amount': {u'currency': u'BTC'...</pre>
    </article>

    <article class="api-documentation__article">
      <h1 class="api-documentation__header">
        Security Best Practices
      </h1>
      <h2 class="api-documentation__subheader">
        Storing Credentials Securely
      </h2>

      <p class="api-documentation__paragraph">
        You should take great care to store your credentials securely. If someone obtains your
        api_secret with the wallet:transactions:send permission, they will be able to send all
        the digital currency out of your account.
      </p>

      <p class="api-documentation__paragraph">
        You should avoid storing API keys in your code base (which gets added to version
        control). The recommended best practice is to store them in environment variables.
        Learn more about environment variables here. Separating credentials from your code base
        and database is always good practice.
      </p>

      <p class="api-documentation__paragraph">
        API Key access is turned off by default on all accounts. To implement an API Key
        integration, you therefore must first enable it,and then take necessary precautions to
        store the API Key securely. You can always regenerate your API Key (or disable it) if
        you feel it has been compromised.
      </p>

      <h2 class="api-documentation__subheader">
        Validating SSL Certificates
      </h2>

      <p class="api-documentation__paragraph">
        It is also very important that your application validates our SSL certificate when it
        connects over https. This helps prevent a man in the middle attack. If you are using a
        client library, this may be turned on by default, but you should confirm this. Whenever
        you see ‘verify SSL’ you should always ensure it is set to true.
      </p>

      <h2 class="api-documentation__subheader">
        Additional Security for API Keys
      </h2>

      <p class="api-documentation__paragraph">
        For enhanced API Key security, we recommend that you whitelist IP addresses that are
        permitted to make requests with a particular API Key.
      </p>

      <p class="api-documentation__paragraph">
        You can specify IP addresses to whitelist when creating a new API Key or editing an
        existing one.
      </p>
    </article>
  </section>
</template>

<script>
export default {
  name: 'ApiDocumentation',
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

$text-page-background: $primary;
// TODO think about generic text page style to prevent code duplicate
.api-documentation {
  font-size: 15px;

  padding: 24px 32px;
  background-color: $text-page-background;
  box-shadow: 0px 2px 8px $secondary-border-color;
  border-radius: 8px;

  @media screen and (min-width: $desktop-breakpoint) {
    padding: 64px 168px;
  }

  &__header {
    font-size: 24px;
    margin-bottom: 24px;

    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  &__subheader {
    font-size: 18px;
    margin-bottom: 20px;

    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  &__article {
    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  &__paragraph {
    font-family: $font-regular;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  &__list {
    font-family: $font-regular;
    margin: 0 0 20px 0;
  }

  &__code {
    font-family: 'IBM Plex Mono', monospace;
    background-color: $background-color;
    padding: 2px 4px;
    font-size: 13px;
  }

  &__sample {
    font-family: 'IBM Plex Mono', monospace;
    background-color: $background-color;
    padding: 12px;
    font-size: 13px;
    line-height: 1.2;
    margin: 0 0 20px 0;
    white-space: pre-wrap;
  }
}
</style>
