var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-datepicker",class:{
    'has-error': _vm.error,
    'is-disabled': _vm.isDisabled,
    'has-tip': _vm.hasTipSlot
  }},[(_vm.isLabelVisible)?_c('div',{staticClass:"form-datepicker__top"},[(_vm.label)?_c('label',{staticClass:"form-datepicker__label",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('span',{staticClass:"form-datepicker__tip"},[_vm._t("tip")],2)]):_vm._e(),_c('v-date-picker',{attrs:{"mode":"date","value":_vm.value,"is-range":true,"columns":_vm.$screens({ default: 1, md: 2 }),"rows":_vm.$screens ({ default: 2, md: 1 }),"max-date":_vm.maxDate,"min-date":_vm.minDate,"attributes":_vm.attrs,"select-attribute":_vm.selectAttribute,"drag-attribute":_vm.dragAttribute,"show-day-popover":false,"formats":_vm.formats,"first-day-of-week":2,"locale":"en-US","timezone":"UTC"},on:{"input":_vm.updateValue},scopedSlots:_vm._u([{key:"header-title",fn:function(ref){
  var monthLabel = ref.monthLabel;
  var yearLabel = ref.yearLabel;
return [_c('div',{staticClass:"form-datepicker__calendar-title"},[_c('span',{staticClass:"form-datepicker__calendar-title-text"},[_vm._v(_vm._s(monthLabel)+" "+_vm._s(yearLabel))]),_c('i',{staticClass:"icon icon-bottom-arrow form-datepicker__calendar-title-image"})])]}}])},[_c('template',{slot:"header-left-button"},[_c('div',{staticClass:"form-datepicker__arrow form-datepicker__arrow--left"},[_c('i',{staticClass:"icon icon-arrow-left form-datepicker__arrow-image"})])]),_c('template',{slot:"header-right-button"},[_c('div',{staticClass:"form-datepicker__arrow form-datepicker__arrow--right"},[_c('i',{staticClass:"icon icon-arrow-right form-datepicker__arrow-image"})])])],2),(_vm.error)?_c('span',{staticClass:"form-datepicker__error"},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }