import modalManager from './modalManager';

export default {
  name: 'Modal',

  props: {
    component: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      required: true,
    },
    target: {
      type: String,
      default: 'default',
    },
  },

  data: () => ({
    modalId: Symbol('modalId'),
  }),

  created() {
    const holderDismiss = () => {
      this.$emit('holder-dismiss');
      this.$emit('update:show', false);
    };

    // this is needed for passing value by link
    const self = this;
    const wrappedComponent = {
      functional: true,
      target: this.target,
      holderDismiss,
      render(h) {
        return h(self.component, {
          on: self.$listeners,
          props: self.$attrs,
        });
      },
    };

    modalManager.registerModal({
      key: this.modalId,
      component: wrappedComponent,
    });
  },

  watch: {
    show(value) {
      modalManager.setShownState({
        target: this.target,
        key: this.modalId,
        showState: value,
      });
    },
  },

  render() {
    return null;
  },

  beforeDestroy() {
    modalManager.unregisterModal({
      key: this.modalId,
    });
  },
};
