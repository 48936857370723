export const beforeUnloadListener = event => {
  event.preventDefault();
  event.returnValue = 'Are you sure you want to exit?';
};

export const formatDataBeforeSave = widgetSettings => {
  const onlyAvailableWidgetSettings = widgetSettings.filter(
    ({ is_available: isAvailable }) => !!isAvailable,
  );

  const formattedWidgetSettings = onlyAvailableWidgetSettings.map(settings => {
    const { payment_method: paymentMethod, is_active: isActive, base_currencies: baseCurrencies } = settings;
    const { id } = paymentMethod;

    const onlyActiveBaseCurrencies = baseCurrencies.filter(
      ({ is_available: isAvailable, partners_fee: partnersFee }) => isAvailable && partnersFee !== null,
    );

    return {
      id,
      is_active: isActive,
      base_currencies: onlyActiveBaseCurrencies.map(baseCurrency => {
        const { currency, partners_fee: partnersFee } = baseCurrency;
        return {
          currency: currency.code,
          partners_fee: partnersFee || '',
        };
      }),
    };
  });

  return formattedWidgetSettings.filter(
    ({ base_currencies: baseCurrencies }) => baseCurrencies.length > 0,
  );
};

export const formatPaymentMethodOptions = paymentMethodOptions => {
  const paymentOptionWithId = paymentMethodOptions.map(paymentMethodOption => {
    const { payment_method: paymentMethod, base_currencies: baseCurrencies } = paymentMethodOption;
    const { id: paymentMethodId } = paymentMethod;

    const baseCurrenciesWithIds = baseCurrencies.map(baseCurrency => ({
      ...baseCurrency,
      parentId: paymentMethodId,
      error: '',
    }));
    const maxPartnersFee = Math.max(...baseCurrencies.map(currency => currency.partners_fee)).toString();

    return {
      ...paymentMethodOption,
      id: paymentMethodId,
      partners_fee: maxPartnersFee,
      base_currencies: baseCurrenciesWithIds,
    };
  });

  return paymentOptionWithId;
};

export const attachErrorsForPaymentMethodOptions = (errors, paymentMethodOptions) => {
  const optionsWithErrors = paymentMethodOptions.map((paymentMethodOption, parentIndex) => {
    const isAnyErrorWithThisIndex = errors.some(error => {
      const { property_path: propPath } = error;
      const splitted = propPath.split('.');
      const errorParentIndex = splitted[0].match(/\d+/);

      if (!errorParentIndex[0]) return false;

      return +(errorParentIndex[0]) === parentIndex;
    });

    if (isAnyErrorWithThisIndex) {
      const errorsByParentIndex = errors.filter(({ property_path: propPath }) => propPath.startsWith(`data[${parentIndex}`));
      const childrenIndexes = errorsByParentIndex.map(error => {
        const { property_path: propPath } = error;
        const splitted = propPath.split('.');
        const childIndexWithError = splitted[1].match(/\d+/);

        return +(childIndexWithError[0]);
      });

      const { base_currencies: baseCurrencies } = paymentMethodOption;

      const modifiedBaseCurrencies = baseCurrencies.map((baseCurrency, childIndex) => {
        if (childrenIndexes.includes(childIndex)) {
          const { message: errorMessage } = errors.find(error => {
            const { property_path: propPath } = error;
            return propPath.startsWith(`data[${parentIndex}].baseCurrencies[${childIndex}]`);
          });

          return {
            ...baseCurrency,
            error: errorMessage,
          };
        }

        return baseCurrency;
      });

      return {
        ...paymentMethodOption,
        base_currencies: modifiedBaseCurrencies,
      };
    }

    return paymentMethodOption;
  });

  return optionsWithErrors;
};
