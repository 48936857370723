/* eslint-disable consistent-return */
import TokenService from '@/services/TokenService';

export default {
  request: {
    fulfilled: async config => {
      if (config.skipJwt) return config;

      const token = await TokenService.getUserToken();

      if (!token) return;

      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Accept = 'application/json';

      return config;
    },
  },
};
