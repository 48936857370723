<template>
  <main>
    <TopChartsSidebar />
    <router-view />
  </main>
</template>

<script>
import TopChartsSidebar from './TopChartsSidebar';

export default {
  name: 'TopChartsLayout',
  components: {
    TopChartsSidebar,
  },
};
</script>
