<template>
  <div class="sidebar-menu">
    <div
      class="sidebar-menu__active-route"
      :class="{ 'sidebar-menu__active-route--opened': showRoutes }"
      @click="toggleSubmenu"
    >
      <div
        v-if="activeRoute && activeRoute.icon"
        class="sidebar-menu__active-route-title"
      >
        <i :class="`icon icon-${activeRoute.icon} sidebar-menu__active-route-icon`" />
        <span class="sidebar-menu__active-route-name">{{ activeRoute.title }}</span>
      </div>
      <i
        class="icon icon-bottom-arrow sidebar-menu__active-route-caret"
        :class="{ 'sidebar-menu__active-route-caret--active': showRoutes }"
      />
    </div>
    <div
      class="sidebar-menu__routes"
      :class="{ 'sidebar-menu__routes--opened': showRoutes }"
    >
      <router-link
        v-for="route in routes"
        :key="route.name"
        :to="route.link"
        class="sidebar-menu__routes-route"
        @click.native.stop="closeSubmenu"
      >
        <i
          class="sidebar-menu__routes-route-icon"
          :class="['icon', route.name === 'payouts' ? `icon-${route.icon}-black` : `icon-${route.icon}`]"
        />
        <span class="sidebar-menu__routes-route-name">{{ route.title }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarMenuMobile',
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    showRoutes: false,
  }),
  computed: {
    activeRoute() {
      const routeName = this.$route.name;
      const activeRoute = this.routes.find(route => route.name === routeName);
      return activeRoute;
    },
  },
  watch: {
    $route() {
      this.closeSubmenu();
    },
  },
  methods: {
    toggleSubmenu() {
      this.showRoutes = !this.showRoutes;
    },
    closeSubmenu() {
      this.showRoutes = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/icons';

.sidebar-menu {
  width: 100%;

  &__active-route {
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    height: 42px;
    border-radius: 8px;
    padding: 0 12px;
    justify-content: space-between;

    &--opened {
      .sidebar-menu__active-route-caret {
        transform: rotate(180deg);
      }
    }
  }

  &__active-route-title {
    display: flex;
    align-items: center;
  }

  &__active-route-name {
    margin-left: 16px;
  }

  &__active-route-icon,
  &__active-route-caret,
  &__routes-route-icon {
    width: 16px;
    height: 16px;
  }

  &__routes {
    display: none;

    &--opened {
      display: flex;
      flex-direction: column;
    }
  }

  &__routes-route {
    font-size: 15px;
    line-height: 18px;
    height: 42px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    text-decoration: none;

    &.router-link-exact-active {
      background: $item-active-background;
    }
  }

  &__routes-route-name {
    margin-left: 16px;
    color: $secondary;
  }

}

@media screen and (min-width: $tablet-breakpoint) {
  .sidebar-menu {
    display: none;
  }
}
</style>
