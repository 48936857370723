import { apiClient } from '@/network';
import LoggerService from '@/services/LoggerService';
import TokenService from '@/services/TokenService';
import LocalStorageService from '@/services/LocalStorageService';

const ACTIVITY_EVENTS = ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll', 'click'];
const ACTIVITY_CHECK_INTERVAL = 1000 * 60;
let sessionId;
let autoLogoutInterval;

const updateUserLastActivity = async () => {
  try {
    await apiClient.put('/v1/account-session', {
      session_id: sessionId,
    });
  } catch (error) {
    LoggerService.logToConsole(error);
  }
};

const activityWatcher = async () => {
  let whetherUserWasActive = false;

  autoLogoutInterval = setInterval(async () => {
    if (whetherUserWasActive) {
      await updateUserLastActivity();

      whetherUserWasActive = false;
    }
  }, ACTIVITY_CHECK_INTERVAL);

  function setUserActivity() {
    whetherUserWasActive = true;
  }

  ACTIVITY_EVENTS.forEach(eventName => {
    document.addEventListener(eventName, setUserActivity, {
      passive: true,
    });
  });
};

export const clearAutoLogoutInterval = () => clearInterval(autoLogoutInterval);

export const initialiseUserSession = async () => {
  sessionId = LocalStorageService.getItem('accountSessionId');

  try {
    if (sessionId) {
      activityWatcher();

      return;
    }

    const refreshToken = await TokenService.getUserRefreshToken();
    const { data: { id: accountSessionId } } = await apiClient.post('/v1/account-session', {
      refresh_token: refreshToken,
    });

    sessionId = accountSessionId;

    LocalStorageService.setItem('accountSessionId', accountSessionId);
    activityWatcher();
  } catch (error) {
    LoggerService.logToConsole(error);
  }
};
