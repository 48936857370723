<template>
  <form
    class="auth__form"
    @submit.prevent="$emit('set-new-password', user)"
  >
    <input
      id="username"
      :value="userEmail"
      hidden
      type="text"
      autocomplete="username"
    >
    <i
      v-if="backButton"
      class="icon icon-caret auth__form-icon auth__form-icon_back"
      @click="$emit('go-back')"
    />
    <i class="icon icon-new-password auth__form-icon" />
    <div class="auth__form-page-name">
      {{ $t('partner-portal.login.set-new-password') }}
    </div>
    <div
      class="auth__form-input-group"
      :class="{'auth__form-input-group_text': changeInputdType}"
    >
      <div class="auth__form-label-group">
        <label class="auth__form-label">{{ $t('partner-portal.login.new-password') }}</label>
      </div>
      <AppInput
        v-model="user.newPassword"
        :is-disabled="isLoading"
        :errors="errors"
        :type="inputType"
        name="new-password"
        autocomplete="new-password"
      />
    </div>

    <div
      class="auth__form-input-group"
      :class="{'auth__form-input-group_text': changeInputdType}"
    >
      <div class="auth__form-label-group">
        <label class="auth__form-label">
          {{ $t('partner-portal.login.new-password-repeat') }}
        </label>
      </div>
      <AppInput
        v-model="user.newPasswordConfirmation"
        :is-disabled="isLoading"
        :errors="errors"
        :type="inputType"
        name="new-password-confirmation"
        autocomplete="new-password"
      />
    </div>

    <div class="auth__form-footer">
      <AppButton
        type="submit"
        :text="$t('partner-portal.login.save-password')"
        :is-loading="isLoading"
        class="btn--primary auth__form-footer-button"
      />
    </div>
  </form>
</template>

<script>
import AppButton from '@/components/AppButton';
import AppInput from '@/components/AppInput';

export default {
  name: 'NewPassword',

  components: {
    AppButton,
    AppInput,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: () => [],
    },

    backButton: {
      type: Boolean,
      default: false,
    },

    changeInputdType: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'password',
    },

    userEmail: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      user: {
        newPassword: '',
        newPasswordConfirmation: '',
      },
    };
  },

  computed: {
    inputType() {
      return this.changeInputdType ? 'text' : this.type;
    },
  },
};
</script>
