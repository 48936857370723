export default {
  setItem(itemName, itemData) {
    localStorage.setItem(itemName, itemData);
  },

  setStringifyItem(itemName, itemData) {
    localStorage.setItem(itemName, JSON.stringify(itemData));
  },

  getItem(itemName) {
    return localStorage.getItem(itemName);
  },

  getParseItem(itemName) {
    try {
      return JSON.parse(localStorage.getItem(itemName));
    } catch (error) {
      return null;
    }
  },

  removeItem(itemName) {
    localStorage.removeItem(itemName);
  },
};
