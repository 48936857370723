<template>
  <div
    :data-testid="dataTestId"
    class="wallet-address"
  >
    <div class="wallet-address__header">
      <div class="wallet-address__header-coin-info">
        <div class="wallet-address__header-coin">
          <i
            class="wallet-address__header-icon"
            :class="`icon icon-${coin.currency.toLowerCase()}`"
          />

          <div class="wallet-address__header-name">
            {{ currentAsset.name }}
          </div>

          <div class="wallet-address__header-ticker">
            {{ currentAsset.currency.code }}
          </div>

          <div class="wallet-address__header-blockchain">
            ({{ getActiveBlockchain().name }})
          </div>
        </div>
        <div class="wallet-address__min-payout">
          {{
            $t('partner-portal.payout-settings.min-payout', {
              amount: coin.min_amount,
              currency: coin.currency,
            })
          }}
        </div>
      </div>

      <div class="wallet-address__default">
        <div
          v-if="coin.is_default"
          class="wallet-address__default-wallet"
        >
          {{ $t('partner-portal.payout-settings.default') }}

          <Tooltip
            center
            class="wallet-address__default-wallet-tooltip"
          >
            {{ $t('partner-portal.payout-settings.tooltip') }}
          </Tooltip>
        </div>

        <div
          v-else
          class="link wallet-address__default-wallet-link"
          @click="setDefault"
        >
          {{ $t('partner-portal.payout-settings.set-as-default') }}
        </div>
      </div>
    </div>
    <div class="wallet-address__input-group">
      <input
        v-model="coin.wallet.address"
        :data-testid="dataTestIdInput"
        class="wallet-address__input"
        :class="{
          'wallet-address__input--select': isMultichainCurrency,
          'wallet-address__input--error': hasError,
        }"
        name="wallet"
        :placeholder="$t('partner-portal.payout-settings.wallet-address-placeholder', {
          currency: coin.currency,
        })"
        @change="trimValue"
      >

      <div
        v-if="isMultichainCurrency"
        :data-testid="dataTestIdDropdown"
        class="wallet-address__dropdown"
        :class="{
          'wallet-address__dropdown--error': hasError,
        }"
      >
        <div
          v-click-outside="closeDropdown"
          class="wallet-address__dropdown-value"
          @click="toggleDropdown"
        >
          <span>{{ getActiveBlockchain().name }}</span>
          <i class="icon icon-bottom-arrow wallet-address__dropdown-arrow" />
        </div>
        <div
          v-show="isDropdownOpened"
          class="wallet-address__dropdown-list"
        >
          <div
            v-for="blockchain in walletBlockchains"
            :key="blockchain.id"
            class="wallet-address__dropdown-list-item"
            @click="changeBlockchain(blockchain)"
          >
            {{ blockchain.name }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isMemoFieldRequired"
      class="wallet-address__input-group"
    >
      <input
        v-model="coin.wallet.destination_tag"
        :data-testid="dataTestIdMemo"
        class="wallet-address__input wallet-address__input--memo"
        :class="{
          'wallet-address__input--error': hasError,
        }"
        name="memo"
        :placeholder="$t('partner-portal.payout-settings.wallet-memo-placeholder', {
          currency: coin.currency,
        })"
        @change="trimValue"
      >
    </div>

    <div class="wallet-address__input-error">
      {{ error }}
    </div>

    <div class="wallet-address__min-payout--mobile">
      {{
        $t('partner-portal.payout-settings.min-payout', {
          amount: coin.min_amount,
          currency: coin.currency,
        })
      }}
    </div>
  </div>
</template>

<script>
import Tooltip from '@/components/Tooltip';

import { CRYPTOS_THAT_REQUIRE_MEMO } from '@/views/PaymentSettings/PaymentSettings.constants';

export default {
  name: 'WalletAddress',

  components: {
    Tooltip,
  },

  props: {
    coin: {
      type: Object,
      default: () => ({}),
    },
    error: {
      type: String,
      defautl: '',
    },
    meta: {
      type: Array,
      default: () => ([]),
    },
  },

  data: () => ({
    isDropdownOpened: false,
  }),

  computed: {
    hasError() {
      return !!this.error;
    },

    isMemoFieldRequired() {
      const activeBlockchain = this.getActiveBlockchain();
      const { currency } = this.coin;

      const isMemoRequired = CRYPTOS_THAT_REQUIRE_MEMO.some(
        crypto => crypto.currency === currency && crypto.blockchain === activeBlockchain.id,
      );

      return isMemoRequired;
    },

    walletBlockchains() {
      const { currency } = this.coin;
      const currentMeta = this.meta.find(blockchain => blockchain.code === currency);
      const blockchains = currentMeta.assets.map(asset => asset.blockchain);

      return blockchains;
    },

    isMultichainCurrency() {
      const { walletBlockchains } = this;
      return walletBlockchains.length > 1;
    },

    currentAsset() {
      const currentMeta = this.meta.find(blockchain => blockchain.code === this.coin.currency);
      const currentAsset = currentMeta.assets.find(asset => asset.blockchain.id === this.coin.wallet.blockchain);

      return currentAsset;
    },

    dataTestId() {
      return `${this.coin.currency.toLowerCase()}-wallet`;
    },

    dataTestIdInput() {
      return `${this.dataTestId}-input`;
    },

    dataTestIdDropdown() {
      return `${this.dataTestId}-dropdown`;
    },

    dataTestIdMemo() {
      return `${this.dataTestId}-memo`;
    },
  },

  methods: {
    getActiveBlockchain() {
      const blockchain = this.walletBlockchains.find(({ id }) => id === this.coin.wallet.blockchain);

      if (!blockchain) {
        const firstAvailableBlockchain = this.walletBlockchains[0];
        this.coin.wallet.blockchain = firstAvailableBlockchain.id;

        return firstAvailableBlockchain;
      }

      return blockchain;
    },

    trimValue() {
      const { wallet: { address, destination_tag: destinationTag } } = this.coin;
      this.coin.wallet.address = address?.trim();

      if (destinationTag.length > 0) {
        this.coin.wallet.destination_tag = destinationTag.trim();
      }
    },

    changeBlockchain(blockchain) {
      this.coin.wallet.blockchain = blockchain.id;

      if (!this.isMemoFieldRequired && this.coin.wallet.destination_tag) {
        this.coin.wallet.destination_tag = '';
      }

      this.closeDropdown();
    },

    getCurrencyBlockchains(currency) {
      const { blockchains } = this.meta.currencies.find(({ code }) => code === currency);
      return blockchains;
    },

    setDefault() {
      const { currency } = this.coin;
      this.$emit('set-default', currency);
    },

    closeDropdown() {
      this.isDropdownOpened = false;
    },

    toggleDropdown() {
      this.isDropdownOpened = !this.isDropdownOpened;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.wallet-address {
  padding: 24px 0;
  border-bottom: 1px solid $list-item-border-color;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__header-coin-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: auto;

    @media screen and (min-width: $wide-desktop-breakpoint) {
      width: 500px;
    }
  }

  &__header-coin {
    position: relative;
    display: flex;
    gap: 4px 8px;
    flex-wrap: wrap;
    align-items: center;
    white-space: nowrap;
    padding-left: 24px;

    @media screen and (min-width: $wide-desktop-breakpoint) {
      max-width: 260px;
    }
  }

  &__header-blockchain {
    font-family: $font-regular;
    color: $label-info-color;
  }

  &__header-icon {
    position: absolute;
    top: 1px;
    left: 0;
    width: 16px;
    height: 16px;
  }

  &__header-name {
    font-size: 15px;
  }

  &__header-ticker {
    font-size: 15px;
    font-family: $font-regular;
  }

  &__min-payout {
    display: none;
    flex-wrap: wrap;
    justify-content: flex-end;
    white-space: nowrap;
    color: $label-info-color;
    font-family: $font-regular;

    &--mobile {
      display: flex;
      color: $label-info-color;
      font-family: $font-regular;
      margin-top: 5px;
    }

    @media screen and (min-width: $wide-desktop-breakpoint) {
      display: flex;

      &--mobile {
        display: none;
      }
    }
  }

  &__input-group {
    display: flex;
    width: auto;

    @media screen and (min-width: $wide-desktop-breakpoint) {
      width: 500px;
    }
  }

  &__input {
    color: black;
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
    padding: 7px 4px 7px 8px;
    border: 1px solid $input-border-grey;
    width: 100%;
    border-radius: 6px;
    flex-grow: 1;

    &--memo {
      margin-top: 8px;
    }

    &--error {
      border-color: $error-color;
    }

    &--select {
      flex: 0 0 70%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:focus {
      outline: none;
    }
  }

  &__input-error {
    font-size: 15px;
    padding-top: 5px;
    color: $error-color;
    font-family: $font-regular;
  }

  &__default {
    font-family: $font-regular;
    margin-left: 10px;
  }

  &__default-wallet {
    display: flex;
    font-size: 15px;
    align-items: center;
  }

  ::v-deep .tooltip__content-wrapper,
  ::v-deep .tooltip__content {
    width: 135px;
    text-align: center;
    margin-left: 0;
    right: 0;
    left: auto;

    @media screen and (min-width: $wide-desktop-breakpoint) {
      margin-left: -30px;
      right: auto;
      left: auto;
    }
  }

  &__default-wallet-tooltip {
    margin-left: 4px;
  }

  &__default-wallet-link {
    font-size: 15px;
    cursor: pointer;
    text-align: right;
    white-space: nowrap;

    @media screen and (min-width: $wide-desktop-breakpoint) {
      text-align: left;
    }
  }

  &__dropdown {
    flex: 0 0 30%;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid $input-border-grey;
    border-left: 0;
    padding: 0 7px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    &--error {
      border-color: $error-color;
    }
  }

  &__dropdown-arrow {
    width: 16px;
    height: 16px;
  }

  &__dropdown-value {
    padding: 7px 0;
    width: 100%;
    font-family: $font-regular;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &__dropdown-list {
    position: absolute;
    top: 100%;
    left: -1px;
    width: 100%;
    background: white;
    border: 1px solid $input-border-grey;
  }

  &__dropdown-list-item {
    padding: 4px 7px;
    font-size: 15px;
    font-family: $font-regular;
    cursor: pointer;

    &:hover {
      background: $input-border-grey;
    }
  }
}
</style>
