import { apiClient, paybisClient } from '@/network';
import LoggerService from '@/services/LoggerService';

export default {
  namespaced: true,

  state: {
    gateways: null,
    transactionStatuses: null,
    countries: null,
    currencies: null,
    marketCapCurrencies: null,
    paymentMethods: null,
    paymentAttempts: null,
  },

  getters: {
    gatewaysList: state => state.gateways,
    transactionStatusesList: state => state.transactionStatuses,
    countriesList: state => state.countries,
    currenciesList: state => state.currencies,
    marketCapCurrenciesList: state => state.marketCapCurrencies,
    paymentMethodsList: state => state.paymentMethods,
    paymentAttemptsList: state => state.paymentAttempts,
    minDate: (state, getters, rootState, rootGetters) => rootGetters['accountInfoStore/accountCreatedDate'],
    activeProductId: (state, getters, rootState, rootGetters) => rootGetters['accountProductsStore/activeProductId'],
    shouldLoadAllFilters: (state, getters, rootState, rootGetters) => {
      const isTransactionsMonitoringAllowed = rootGetters['rolesStore/isTransactionsMonitoringAllowed'];
      const isViewOnlyMonitoringAllowed = rootGetters['rolesStore/isViewOnlyMonitoringAllowed'];

      return isViewOnlyMonitoringAllowed || isTransactionsMonitoringAllowed;
    },
  },

  mutations: {
    setGateways(state, payload) {
      state.gateways = payload;
    },
    setTransactionStatuses(state, payload) {
      state.transactionStatuses = payload;
    },
    setCountries(state, payload) {
      state.countries = payload;
    },
    setCurrencies(state, payload) {
      state.currencies = payload;
    },
    setMarketCapCurrencies(state, payload) {
      state.marketCapCurrencies = payload;
    },
    setPaymentMethods(state, payload) {
      state.paymentMethods = payload;
    },
    setPaymentAttempts(state, payload) {
      state.paymentAttempts = payload;
    },
  },

  actions: {
    async getAllFilters({ commit, dispatch, state, getters }) {
      try {
        const { data: { gateways,
          transaction_statuses: transactionStatuses,
          countries,
          currencies,
          payment_methods: paymentMethods,
          with_payment: paymentAttempts } } = await apiClient.get('/v1/analytics/filters', {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        let sortedCurrencies;

        try {
          await dispatch('getMarketCapCryptoCurrencies');

          sortedCurrencies = currencies
            .map(currency => {
              let currencyName;

              if (currency.key.split('-')[0] === 'BNBSC') {
                currencyName = 'BNB';
              } else {
                [currencyName] = currency.key.split('-');
              }

              const marketCapCurrency = state.marketCapCurrencies?.find(marketcapCurrency => marketcapCurrency.code === currencyName);

              return {
                currency,
                mktcap: marketCapCurrency?.mktcap || 0,
              };
            })
            .sort((a, b) => b.mktcap - a.mktcap)
            .map(item => item.currency);
        } catch (error) {
          LoggerService.logToConsole(error);

          sortedCurrencies = currencies.sort((a, b) => a.value.localeCompare(b.value));
        }

        const sortedCountries = countries.sort((a, b) => a.value.localeCompare(b.value));

        commit('setGateways', gateways);
        commit('setTransactionStatuses', transactionStatuses);
        commit('setCountries', sortedCountries);
        commit('setCurrencies', sortedCurrencies);
        commit('setPaymentMethods', paymentMethods);
        commit('setPaymentAttempts', paymentAttempts);
      } catch (error) {
        LoggerService.logToConsole(error);
      }
    },

    async getMarketCapCryptoCurrencies({ commit }) {
      try {
        const { data } = await paybisClient.get('/public/seo-prices/cryptos/CCCAGG/USD');

        commit('setMarketCapCurrencies', data);
      } catch (error) {
        LoggerService.logToConsole(error);
      }
    },
  },
};
