import Vue from 'vue';

import router from '@/router';
import store from '@/store';

import { buildNavigationTree } from './CompanyNav.utils';

const CompanyNavDesktop = () => import('./CompanyNav.desktop.vue');
const CompanyNavMobile = () => import('./CompanyNav.mobile.vue');

const logout = () => {
  store.dispatch('userStore/logout').then(() => {
    router.push({
      name: 'auth',
    }).catch(() => {});
  });
};

export default Vue.component('CompanyNav', {
  functional: true,
  render(h) {
    const accountName = store.getters['accountInfoStore/accountName'];
    const companyNavRoutes = buildNavigationTree();

    const companyNavsProps = {
      props: {
        name: accountName || '',
        routes: companyNavRoutes,
        logout,
      },
    };

    return h('div', [
      h(CompanyNavDesktop, companyNavsProps),
      h(CompanyNavMobile, companyNavsProps),
    ]);
  },
});
