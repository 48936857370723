<template>
  <section class="password-restore">
    <span
      v-if="sessionExpiredError"
      class="password-restore__form-error"
    >
      {{ sessionExpiredError }}
    </span>

    <div class="password-restore__container">
      <PasswordRestoreEmailForm
        v-if="currentStep === 1"
        :is-loading="isLoading"
        :errors="errors"
        @send-email="sendEmail(false, $event)"
      />

      <PasswordRestoreOtpForm
        v-else-if="currentStep === 2"
        :otp="otp"
        :is-loading="isLoading"
        :errors="errors"
        @go-back="setCurrentStep(1)"
        @resend-otp-code="sendEmail(true)"
        @send-otp-code-with-id="sendOtpWithId"
      />

      <NewPassword
        v-else-if="currentStep === 3"
        :errors="errors"
        :is-loading="isLoading"
        back-button
        :user-email="userEmail"
        @go-back="setCurrentStep(1)"
        @set-new-password="setPassword"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';

import LocalStorageService from '@/services/LocalStorageService';
import NotificationService from '@/services/NotificationService';

import PasswordRestoreEmailForm from '@/components/PasswordRestore/PasswordRestoreEmailForm/PasswordRestoreEmailForm.vue';
import PasswordRestoreOtpForm from '@/components/PasswordRestore/PasswordRestoreOtpForm';
import NewPassword from '@/components/Auth/NewPassword';

export default {
  name: 'PasswordRestore',

  components: {
    PasswordRestoreEmailForm,
    PasswordRestoreOtpForm,
    NewPassword,
  },

  computed: {
    ...mapGetters('passwordRestoreStore', {
      otp: 'otp',
      isLoading: 'isLoading',
      errors: 'errors',
      sessionExpiredError: 'sessionExpiredError',
      currentStep: 'currentStep',
    }),

    userEmail() {
      return LocalStorageService.getItem('passwordRestoreEmail');
    },
  },

  mounted() {
    this.clearErrors();
    this.setOtp(LocalStorageService.getParseItem('passwordRestoreOtp') || null);
    this.detectAndSetCurrentStep();
  },

  beforeDestroy() {
    this.setSessionExpiredError('');
  },

  methods: {
    ...mapActions('passwordRestoreStore', {
      sendPasswordRecoveryEmail: 'sendPasswordRecoveryEmail',
      sendPasswordRecoveryOtp: 'sendPasswordRecoveryOtp',
      setNewPassword: 'setNewPassword',
      clearLocalStorage: 'clearLocalStorage',
    }),

    ...mapMutations('passwordRestoreStore', {
      setOtp: 'setOtp',
      setLoading: 'setLoading',
      setErrors: 'setErrors',
      clearErrors: 'clearErrors',
      setSessionExpiredError: 'setSessionExpiredError',
      setCurrentStep: 'setCurrentStep',
    }),

    async sendEmail(getEmailFromLocalStorage = false, email) {
      const userEmail = getEmailFromLocalStorage ? LocalStorageService.getItem('passwordRestoreEmail') : email;

      this.clearErrors();
      this.setSessionExpiredError('');
      this.setLoading(true);

      this.validateFieldsEmptiness([{
        key: 'email',
        value: userEmail,
      }]);

      if (this.errors?.length) {
        this.setLoading(false);

        return;
      }

      try {
        await this.sendPasswordRecoveryEmail(userEmail);

        LocalStorageService.setItem('isOtpSent', false);
      } catch (error) {
        const errorMessage = error?.response?.data?.message || '';

        this.setErrors({
          key: 'email', error: errorMessage || this.$t('partner-portal.errors.password-restore-email'),
        });
      }
    },

    async sendOtpWithId({ id, code }) {
      this.clearErrors();
      this.setLoading(true);

      this.validateFieldsEmptiness([{
        key: 'code',
        value: code,
      }]);

      if (this.errors?.length) {
        this.setLoading(false);

        return;
      }

      try {
        await this.sendPasswordRecoveryOtp({
          id, code,
        });

        LocalStorageService.setItem('isOtpSent', true);
      } catch (error) {
        const errorStatusCode = error?.response?.data?.status;

        if (errorStatusCode === 404) {
          this.setSessionExpiredError(this.$t('partner-portal.errors.password-restore-session-expired'));
          this.moveToFirstStepAndClearLocalStorage();

          return;
        }

        if (errorStatusCode === 429) {
          NotificationService.notifyAboutError('partner-portal.notifications.password-restore-otp-attemps-exceeded');

          return;
        }

        const errorMessage = error?.response?.data?.errors[0]?.message || '';

        this.setErrors({
          key: 'code', error: errorMessage,
        });
      }
    },

    async setPassword(user) {
      const { newPassword, newPasswordConfirmation } = user;
      const passwords = ['new-password', 'new-password-confirmation'];

      this.clearErrors();
      this.setLoading(true);

      this.validateFieldsEmptiness([{
        key: 'new-password',
        value: newPassword,
      }, {
        key: 'new-password-confirmation',
        value: newPasswordConfirmation,
      }]);

      if (newPassword !== newPasswordConfirmation) {
        passwords.forEach(key => {
          this.setErrors({
            key,
            error: this.$t('partner-portal.errors.password-must-match'),
          });
        });
      }

      if (this.errors?.length) {
        this.setLoading(false);

        return;
      }

      try {
        await this.setNewPassword(newPassword);

        this.$router.push({
          name: 'auth',
        });
        LocalStorageService.removeItem('displayCaptcha');
      } catch (error) {
        const errorStatusCode = error?.response?.data?.status || '';

        if (errorStatusCode === 404) {
          this.setSessionExpiredError(this.$t('partner-portal.errors.password-restore-session-expired'));
          this.moveToFirstStepAndClearLocalStorage();

          return;
        }

        const errorMessage = error?.response?.data?.errors[0]?.message || '';

        passwords.forEach(key => {
          this.setErrors({
            key,
            error: errorMessage,
          });
        });
      }
    },

    detectAndSetCurrentStep() {
      if (!this.otp?.expires_at) return;

      const now = new Date().getTime();
      const expiresAt = new Date(this.otp?.expires_at).getTime();

      const userEmail = LocalStorageService.getItem('passwordRestoreEmail');
      const isOtpSent = LocalStorageService.getParseItem('isOtpSent');

      if (expiresAt < now) {
        this.moveToFirstStepAndClearLocalStorage();

        return;
      }

      if (isOtpSent) {
        this.setCurrentStep(3);
      } else if (userEmail) {
        this.setCurrentStep(2);
      }
    },

    moveToFirstStepAndClearLocalStorage() {
      this.setCurrentStep(1);
      this.clearLocalStorage();
    },

    validateFieldsEmptiness(fieldsList) {
      fieldsList.forEach(({ key, value }) => {
        if (!value) {
          this.setErrors({
            key, error: this.$t(`partner-portal.errors.user-missing-${key}`),
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
@import '~@/assets/scss/mixins';

.password-restore {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  flex-flow: column !important;
  margin-bottom: auto !important;

  &__container {
    overflow: hidden;
    width: 100%;
    max-width: 350px;
    background: $primary;
    border-radius: 8px;
    padding: 36px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__form-icon {
    min-height: 32px;
    max-height: 32px;
    min-width: 32px;
    max-width: 32px;
    margin-bottom: 20px;

    &_back {
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(-90deg);
      margin-bottom: 0;

      @include hover {
        cursor: pointer;
      }
    }
  }

  &__form-title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 24px;
  }

  &__form-input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    width: 100%;
  }

  &__form-label-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }

  &__form-label {
    font-size: 13px;
    line-height: 16px;
    font-family: $font-regular;
  }

  &__form-resend-time {
    font-size: 13px;
    line-height: 16px;
    font-family: $font-regular;
  }

  &__form-resend-info {
    width: 100%;
  }

  &__form-resend-button,
  &__form-resend-time {
    font-size: 13px;
    line-height: 16px;
    font-family: $font-regular;
  }

  &__form-resend-button {
    margin-right: 8px;
    border-bottom: 1px solid rgba($secondary, 0.15);

    @include hover {
      cursor: pointer;
    }

    &_disabled {
      pointer-events: none;
      color: $label-info-color;
      border-bottom: none;
    }
  }

  &__form-resend-time {
    color: $label-info-color;
  }

  &__form-button {
    margin-top: 20px;
    min-width: auto;
    width: 100%;
  }

  &__form-error {
    font: 600 14px/16px $font-regular;
    color: $primary;
    text-align: center;
    width: 100%;
    max-width: 350px;
    padding: 12px 16px 36px;
    margin-bottom: -24px;
    background-color: $error-color;
    border-radius: 8px 8px 0 0;
  }
}
</style>
