<template>
  <section class="toplist-chart">
    <div
      v-if="!showBackButton"
      class="toplist-chart__title-header"
    >
      <h2
        class="toplist-chart__title"
      >
        <router-link
          v-if="headerLink"
          class="link"
          :to="{ name: headerLink }"
        >
          {{ title }}
        </router-link>

        <div
          v-else
          class="toplist-chart__title-text"
        >
          {{ title }}
        </div>
      </h2>

      <p
        v-if="description"
        class="toplist-chart__description"
      >
        {{ description }}
      </p>
    </div>

    <div
      v-if="showBackButton"
      class="toplist-chart__header"
    >
      <div class="toplist-chart__header-title">
        <div
          class="toplist-chart__header-title-back"
          @click="goBack"
        >
          <i class="icon icon-back toplist-chart__header-title-back-image" />
        </div>
        <div class="toplist-chart__header-title-text">
          {{ title }}
        </div>
      </div>

      <div
        v-if="canShowDownloadCsv"
        class="toplist-chart__header-csv"
        @click="downloadReport"
      >
        <i class="icon icon-download toplist-chart__header-csv-image" />
        <div class="toplist-chart__header-csv-text">
          {{ $t('partner-portal.top-charts.titles.download') }}
        </div>
        <div class="toplist-chart__header-csv-text toplist-chart__header-csv-text_mobile">
          {{ $t('partner-portal.top-charts.titles.csv') }}
        </div>
      </div>

      <div v-if="isLoadingReport">
        <i class="icon icon-spinner-black toplist-chart__header-report-loader" />
      </div>

      <a
        ref="downloadButton"
        class="toplist-chart__anchor"
        donwload="top-coutries-report.csv"
        :href="reportUrl"
      />
    </div>

    <div
      v-if="isLoading"
      class="toplist-chart__items toplist-chart__items--loading"
    >
      <i class="icon icon-spinner-black toplist-chart__loader" />
    </div>

    <div
      v-else-if="hasToplistData"
      class="toplist-chart__items"
    >
      <div
        v-for="(item, index) in topItems"
        :key="`${item.name}_${index}`"
        class="toplist-chart__item"
      >
        <div
          class="toplist-chart__item-background-fill"
          :style="{ 'width': `${item.percentage || 0}%` }"
        />
        <span class="toplist-chart__item-title">
          <i
            class="toplist-chart__item-icon"
            :class="`icon icon-${item.code || 'undefined'}`"
          />
          {{ item.name }}
        </span>
        <span class="toplist-chart__item-amount">{{ item.value }}</span>
      </div>
    </div>

    <MissingDataPlaceholder v-else />

    <div
      v-if="canShowLoadMore"
      class="toplist-chart__load-more"
      :class="{
        'toplist-chart__load-more--loading': isLoadingMore,
      }"
    >
      <div
        v-if="isLoadingMore"
        class="toplist-chart__load-more-content--loading"
      >
        <i class="icon icon-spinner-black toplist-chart__load-more-content-spinner-image" />
      </div>

      <div
        v-else
        class="toplist-chart__load-more-content"
        @click="loadMore"
      >
        <i class="icon icon-load-more toplist-chart__load-more-content-image" />
        <div class="toplist-chart__load-more-content-text">
          {{ $t('partner-portal.common.load-more') }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MissingDataPlaceholder from '@/components/MissingDataPlaceholder';

import { formatCurrency } from '@/utils';

export default {
  name: 'ToplistChart',

  components: {
    MissingDataPlaceholder,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      default: '',
    },

    headerLink: {
      type: String,
      default: null,
    },

    showBackButton: {
      type: Boolean,
      default: false,
    },

    showLoadMore: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      default: () => [],
    },

    isLoading: {
      type: Boolean,
      required: true,
    },

    isLoadingMore: {
      type: Boolean,
      default: false,
    },

    showDownloadCsv: {
      type: Boolean,
      default: false,
    },

    isLoadingReport: {
      type: Boolean,
      default: false,
    },

    reportUrl: {
      type: String,
      default: '',
    },
  },

  computed: {
    hasToplistData() {
      return this.items?.length;
    },

    canShowDownloadCsv() {
      return this.showDownloadCsv && this.hasToplistData && !this.isLoadingReport;
    },

    canShowLoadMore() {
      return this.hasToplistData && this.showLoadMore && !this.isLoading;
    },

    topItems() {
      const { items } = this;
      if (!items?.length) return [];

      const sortedItems = items.sort((a, b) => Number(b.value) - Number(a.value));
      const biggestAmount = sortedItems[0].value;

      const topItems = sortedItems.map(item => {
        const { name, code, value } = item;
        const percentageAmount = value / biggestAmount;
        const itemPercentage = percentageAmount * 100;

        return {
          ...item,
          name: name || this.$t('partner-portal.common.undefined'),
          code: code.toLowerCase(),
          value: formatCurrency(value),
          percentage: itemPercentage,
        };
      });

      return topItems;
    },
  },

  watch: {
    reportUrl(updatedReportUrl) {
      if (updatedReportUrl) {
        this.$nextTick(() => {
          this.$refs.downloadButton.click();
        });
      }
    },
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    loadMore() {
      this.$emit('load-more');
    },

    downloadReport() {
      this.$emit('download-report');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";
@import "~@/assets/scss/mixins";

.toplist-chart {
  background-color: $primary;
  box-shadow: 0px 2px 8px $secondary-border-color;
  border-radius: 8px;
  padding: 16px 20px;

  @media screen and (min-width: $tablet-breakpoint) {
    padding: 20px 24px 24px;
  }

  &__loader {
    width: 32px;
    height: 32px;
    animation: spin 1s linear infinite;
  }

  &__title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__description {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
    color: $secondary-text-color;
    margin: 0;
  }

  &__load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0 1px;
    width: 100%;

    @media screen and (min-width: $tablet-breakpoint) {
      padding: 25px 0 1px;
    }

    &--loading {
      padding: 16px 0 1px;

      @media screen and (min-width: $tablet-breakpoint) {
        padding: 12px 0 1px;
      }
    }
  }

  &__load-more-content {
    display: flex;

    @include hover {
      cursor: pointer;
    }

    &-image {
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }

    &-spinner-image {
      height: 19px;
      width: 19px;
      animation: spin 1s linear infinite;

      @media screen and (min-width: $tablet-breakpoint) {
        height: 32px;
        width: 32px;
      }
    }

    &-text {
      font-size: 15px;
      line-height: 18px;
      font-family: $font-regular;
      border-bottom: 1px solid $link-border-color;
    }
  }

  &__title {
    font-size: 15px;
    color: $secondary;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 24px;
  }

  &__header-title {
    display: flex;
    align-items: center;

    &-text {
      font-size: 15px;
      line-height: 18px;
      font-family: $font-bold;

      @media screen and (min-width: $tablet-breakpoint) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  &__header-title-back {
    height: 24px;
    width: 24px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: $secondary-grey;

    @include hover {
      cursor: pointer;
    }

    @media screen and (min-width: $tablet-breakpoint) {
      height: 32px;
      width: 32px;
      margin-right: 20px;
    }
  }

  &__header-title-back-image {
    width: 16px;
    height: 16px;
  }

  &__header-csv {
    display: flex;
    align-items: center;
  }

  &__header-csv-image {
    width: 16px;
    height: 16px;
  }

  &__header-csv-text {
    display: none;
    margin-left: 4px;
    font-size: 15px;
    line-height: 18px;
    border-bottom: 1px solid $link-border-color;
    font-family: $font-regular;

    @include hover {
      cursor: pointer;
    }

    @media screen and (min-width: $tablet-breakpoint) {
      display: block;
    }
  }

  &__header-csv-text_mobile {
    display: block;

    @media screen and (min-width: $tablet-breakpoint) {
      display: none;
    }
  }

  &__header-report-loader {
    width: 22px;
    height: 22px;
    animation: spin 1s linear infinite;
  }

  &__anchor {
    position: absolute;
    top: -10000px;
    left: -10000px;
  }

  &__items {
    font-size: 15px;
  }

  &__items--loading {
    padding: 90px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $list-item-border-color;
    height: 44px;
    padding-right: 16px;
    background-repeat: no-repeat;
    position: relative;
  }

  &__item-background-fill {
    position: absolute;
    left: 0;
    top: 0;
    height: 44px;
    background: linear-gradient(90deg, rgba(95, 112, 219, 0) 0%, rgba(95, 112, 219, 0.08) 100%);
  }

  &__item-title {
    display: flex;
    align-items: center;
    font-family: $font-regular;
  }

  &__item-icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  &__item-amount {
    margin-left: auto;
  }
}
</style>
