<template>
  <main class="payouts">
    <PayoutSidebar />
    <div class="payouts__content">
      <PayoutList
        :title="$t('partner-portal.payouts.table.title')"
        :can-load-more-payouts="canLoadMorePayouts"
        :payouts="payoutsList"

        :is-loading-payouts="isLoadingPayouts"
        :is-loading-more="isLoadingMorePayouts"
      />
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PayoutSidebar from './PayoutSidebar';
import PayoutList from '@/components/PayoutList';

export default {
  name: 'Payouts',

  components: {
    PayoutSidebar,
    PayoutList,
  },

  computed: {
    ...mapGetters('payoutsStore', {
      payoutsList: 'payoutsList',
      canLoadMorePayouts: 'canLoadMorePayouts',

      isLoadingPayouts: 'isLoadingPayouts',
      isLoadingMorePayouts: 'isLoadingMorePayouts',
    }),
  },

  mounted() {
    this.loadPayouts();
  },

  methods: {
    ...mapActions('payoutsStore', {
      loadPayouts: 'loadPayouts',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.payouts {
  flex-wrap: wrap;

  &__content {
    width: 100%;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .payouts {
    flex-wrap: nowrap;

    &__content {
      flex: 1 0;
      min-width: 0;
    }
  }
}
</style>
