<template>
  <div class="select">
    <div
      v-if="label"
      class="select__label"
    >
      {{ label }}
    </div>
    <div
      v-click-outside="closeOptions"
      class="select__input"
      :class="{
        'select__input--disabled': isDisabled,
        'select__input--loading': isLoading,
      }"
      @click="toggleOptions"
    >
      <div class="select__input-value">
        {{ getValue(value) }}
      </div>
      <i
        class="icon icon-bottom-arrow select__input-triange"
        :class="{ 'select__input-triangle--active': showOptions }"
      />
      <div
        class="select__options"
        :class="{ 'select__options--active': showOptions }"
      >
        <div
          v-for="option in options"
          :key="option.key"
          class="select__options-option"
          @click="passValueToParent(option.value, option.key)"
        >
          {{ option.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Select',

  props: {
    label: {
      type: String,
    },

    value: {
      type: String,
      required: true,
    },

    isLoading: {
      type: Boolean,
      required: true,
    },

    isDisabled: {
      type: Boolean,
      deafult: false,
    },

    options: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    showOptions: false,
  }),

  methods: {
    getValue() {
      const { options, value } = this;
      const valueObject = options.find(option => option.value === value);
      return valueObject.value;
    },

    toggleOptions() {
      this.showOptions = !this.showOptions;
    },

    passValueToParent(value, key) {
      this.$emit('update', {
        value, key,
      });
    },

    closeOptions() {
      this.showOptions = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/icons';

.select {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 304px;
  z-index: 2;

  &__label {
    font-size: 13px;
    line-height: 16px;
    font-family: $font-regular;
    margin-bottom: 4px;
  }

  &__input {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 42px;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border: 2px solid $background-color;
    border-radius: 6px;
    cursor: pointer;
    position: relative;

    &--loading {
      &:before {
        position: absolute;
        content: ' ';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $primary;
        z-index: 0;
      }

      &:after {
        @extend .icon;
        @extend .icon-spinner-black;

        position: absolute;
        content: ' ';
        left: 15px;
        height: 24px;
        width: 24px;
        animation: spin 1s linear infinite;
      }
    }

    &--disabled {
      pointer-events: none;
      background: $background-color;
    }
  }

  &__input-triange {
    height: 16px;
    width: 16px;

    &--active {
      transform: rotate(180deg);
    }
  }

  &__input-value {
    font-size: 15px;
    line-height: 16px;
    font-family: $font-regular;
  }

  &__options {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }

  &__options--active {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: $primary;
    padding: 10px;
    border: 2px solid $background-color;
    border-radius: 6px;
  }

  &__options-option {
    font-size: 15px;
    line-height: 16px;
    padding: 7px 10px;
    font-family: $font-regular;

    &:hover {
      background: $item-active-background;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .select {
    width: 100%;
    box-sizing: border-box;
  }
}
</style>
