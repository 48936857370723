<template>
  <div class="status">
    <div
      v-if="isServiceActive"
      class="status__active"
    >
      {{ $t('partner-portal.widget-settings.statuses.active') }}
    </div>
    <div
      v-else
      class="status__inactive"
    >
      {{ $t('partner-portal.widget-settings.statuses.inactive') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusCell',

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isServiceActive() {
      const { is_available: isAvailable } = this.value;
      return isAvailable;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.status {
  font-size: 15px;
  line-height: 18px;
  font-family: $font-regular;

  &__active {
    color: $success-color;
  }

  &__inactive {
    color: $label-info-color;
  }
}
</style>
