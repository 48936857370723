export const AWS_ERROR_CODES = {
  USER_NOT_FOUND: 'UserNotFoundException',
  USER_NOT_CONFIRMED: 'UserNotConfirmedException',
  PASSWORD_RESET_REQUIRED: 'PasswordResetRequiredException',
  NOT_AUTHORIZED: 'NotAuthorizedException',
  INVALID_PASSWORD_EXCEPTION: 'InvalidPasswordException',
};

export const AWS_ERROR_MESSAGES = {
  ATTEMPS_EXCEEDED: 'Password attempts exceeded',
  INVALID_SESSION: 'Invalid session for the user, session is expired.',
  INCORRECT_CREDENTIALS: 'Incorrect username or password.',
};

export const AWS_CHALLENGE_NAMES = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
};

export const AWS_NEW_PASSWORD_CODES = {
  INVALID_PASSWORD_EXCEPTION: 'InvalidPasswordException',
  NOT_AUTHORIZED: 'NotAuthorizedException',
};

export const AWS_CHANGE_PASSWORD_CODES = {
  NOT_AUTHORIZED: 'NotAuthorizedException',
  LIMIT_EXCEEDED: 'LimitExceededException',
};
