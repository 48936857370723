import { tooltipHandler } from './ChartCanvas.tooltip';

const TABLET_BREAKPOINT = 767;
const SMALL_POINT_RADIUS = 3;
const NORMAL_POINT_RADIUS = 5;

export const STACKED_CHART_SCALES = {
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y0: {
      beginAtZero: true,
      position: 'right',
      grid: {
        display: true,
        drawBorder: false,
        borderDash: [3, 4],
        color: 'rgba(0, 0, 0, 0.15)',
        drawOnChartArea: true,
      },
      ticks: {
        color: 'rgba(0, 0, 0, 0.25)',
        font: {
          size: 14,
        },
      },
    },
    y1: {
      beginAtZero: true,
      position: 'left',
      grid: {
        display: false,
        drawBorder: false,
        borderDash: [3, 4],
        color: 'rgba(0, 0, 0, 0.15)',
        drawOnChartArea: true,
      },
      ticks: {
        color: 'rgba(0, 0, 0, 0.25)',
        font: {
          size: 14,
        },
      },
    },
  },
};

export const LINEAR_CHART_SCALES = {
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      position: 'right',
      grid: {
        display: true,
        drawBorder: false,
        borderDash: [3, 4],
        color: 'rgba(0, 0, 0, 0.15)',
        drawOnChartArea: true,
      },
      ticks: {
        color: 'rgba(0, 0, 0, 0.25)',
        font: {
          size: 14,
        },
      },
    },
  },
};

export const COMMON_CANVAS_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  onResize: (chart, newSize) => {
    if (newSize.width > TABLET_BREAKPOINT) {
      chart.data.datasets.forEach(dataset => {
        dataset.pointRadius = NORMAL_POINT_RADIUS;
        dataset.pointHoverRadius = NORMAL_POINT_RADIUS;
      });
    } else {
      chart.data.datasets.forEach(dataset => {
        dataset.pointRadius = SMALL_POINT_RADIUS;
        dataset.pointHoverRadius = SMALL_POINT_RADIUS;
      });
    }
  },
  scaleShowVerticalLines: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: tooltipHandler,
    },
    legend: {
      display: false,
    },
  },
};

export const COMMON_CANVAS_STYLES = {
  borderWidth: 2,
  fill: true,
  pointBackgroundColor: '#fff',
  pointRadius: 5,
};
