var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wallet-address",attrs:{"data-testid":_vm.dataTestId}},[_c('div',{staticClass:"wallet-address__header"},[_c('div',{staticClass:"wallet-address__header-coin-info"},[_c('div',{staticClass:"wallet-address__header-coin"},[_c('i',{staticClass:"wallet-address__header-icon",class:("icon icon-" + (_vm.coin.currency.toLowerCase()))}),_c('div',{staticClass:"wallet-address__header-name"},[_vm._v(" "+_vm._s(_vm.currentAsset.name)+" ")]),_c('div',{staticClass:"wallet-address__header-ticker"},[_vm._v(" "+_vm._s(_vm.currentAsset.currency.code)+" ")]),_c('div',{staticClass:"wallet-address__header-blockchain"},[_vm._v(" ("+_vm._s(_vm.getActiveBlockchain().name)+") ")])]),_c('div',{staticClass:"wallet-address__min-payout"},[_vm._v(" "+_vm._s(_vm.$t('partner-portal.payout-settings.min-payout', { amount: _vm.coin.min_amount, currency: _vm.coin.currency, }))+" ")])]),_c('div',{staticClass:"wallet-address__default"},[(_vm.coin.is_default)?_c('div',{staticClass:"wallet-address__default-wallet"},[_vm._v(" "+_vm._s(_vm.$t('partner-portal.payout-settings.default'))+" "),_c('Tooltip',{staticClass:"wallet-address__default-wallet-tooltip",attrs:{"center":""}},[_vm._v(" "+_vm._s(_vm.$t('partner-portal.payout-settings.tooltip'))+" ")])],1):_c('div',{staticClass:"link wallet-address__default-wallet-link",on:{"click":_vm.setDefault}},[_vm._v(" "+_vm._s(_vm.$t('partner-portal.payout-settings.set-as-default'))+" ")])])]),_c('div',{staticClass:"wallet-address__input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.coin.wallet.address),expression:"coin.wallet.address"}],staticClass:"wallet-address__input",class:{
        'wallet-address__input--select': _vm.isMultichainCurrency,
        'wallet-address__input--error': _vm.hasError,
      },attrs:{"data-testid":_vm.dataTestIdInput,"name":"wallet","placeholder":_vm.$t('partner-portal.payout-settings.wallet-address-placeholder', {
        currency: _vm.coin.currency,
      })},domProps:{"value":(_vm.coin.wallet.address)},on:{"change":_vm.trimValue,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.coin.wallet, "address", $event.target.value)}}}),(_vm.isMultichainCurrency)?_c('div',{staticClass:"wallet-address__dropdown",class:{
        'wallet-address__dropdown--error': _vm.hasError,
      },attrs:{"data-testid":_vm.dataTestIdDropdown}},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"wallet-address__dropdown-value",on:{"click":_vm.toggleDropdown}},[_c('span',[_vm._v(_vm._s(_vm.getActiveBlockchain().name))]),_c('i',{staticClass:"icon icon-bottom-arrow wallet-address__dropdown-arrow"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDropdownOpened),expression:"isDropdownOpened"}],staticClass:"wallet-address__dropdown-list"},_vm._l((_vm.walletBlockchains),function(blockchain){return _c('div',{key:blockchain.id,staticClass:"wallet-address__dropdown-list-item",on:{"click":function($event){return _vm.changeBlockchain(blockchain)}}},[_vm._v(" "+_vm._s(blockchain.name)+" ")])}),0)]):_vm._e()]),(_vm.isMemoFieldRequired)?_c('div',{staticClass:"wallet-address__input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.coin.wallet.destination_tag),expression:"coin.wallet.destination_tag"}],staticClass:"wallet-address__input wallet-address__input--memo",class:{
        'wallet-address__input--error': _vm.hasError,
      },attrs:{"data-testid":_vm.dataTestIdMemo,"name":"memo","placeholder":_vm.$t('partner-portal.payout-settings.wallet-memo-placeholder', {
        currency: _vm.coin.currency,
      })},domProps:{"value":(_vm.coin.wallet.destination_tag)},on:{"change":_vm.trimValue,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.coin.wallet, "destination_tag", $event.target.value)}}})]):_vm._e(),_c('div',{staticClass:"wallet-address__input-error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('div',{staticClass:"wallet-address__min-payout--mobile"},[_vm._v(" "+_vm._s(_vm.$t('partner-portal.payout-settings.min-payout', { amount: _vm.coin.min_amount, currency: _vm.coin.currency, }))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }