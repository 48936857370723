import Vue from 'vue';
import VueRouter from 'vue-router';
import TopChartsLayout from '@/views/TopChartsLayout';
import CompanyLayout from '@/views/CompanyLayout';
import DevelopersLayout from '@/views/DevelopersLayout';
import Dashboard from '@/views/Dashboard';
import NotFound from '@/views/NotFound';
import Transactions from '@/views/Transactions';
import Payout from '@/views/Payout';
import ApiDocumentation from '@/views/ApiDocumentation';
import Analytics from '@/views/Analytics';
import TopChartsCountries from '@/views/TopChartsCountries';
import TopChartsCryptos from '@/views/TopChartsCryptos';
import WidgetSettings from '@/views/WidgetSettings';
import ProfileSettings from '@/views/ProfileSettings';
import PaymentSettings from '@/views/PaymentSettings';
import Forbiden from '@/views/Forbiden';
import Auth from '@/views/Auth';
import PasswordRestore from '@/views/PasswordRestore';
import ChangePassword from '@/views/ChangePassword';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'partner-portal.routes.dashboard',
    },
  },
  {
    path: '/forbiden',
    name: 'forbiden',
    component: Forbiden,
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
  },
  {
    path: '/password-restore',
    name: 'passwordRestore',
    component: PasswordRestore,
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: Analytics,
    meta: {
      title: 'partner-portal.routes.analytics',
    },
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: Transactions,
    meta: {
      title: 'partner-portal.routes.transactions',
    },
  },
  {
    path: '/payouts',
    name: 'payouts',
    component: Payout,
    meta: {
      title: 'partner-portal.routes.payouts',
    },
  },
  {
    path: '/company',
    name: 'companySettings',
    component: CompanyLayout,
    children: [{
      path: 'profile',
      name: 'profileSettings',
      component: ProfileSettings,
      meta: {
        title: 'partner-portal.routes.profile',
      },
    }, {
      path: 'widget',
      name: 'widgetSettings',
      component: WidgetSettings,
      meta: {
        title: 'partner-portal.routes.widget-settings',
        hideSidebarMenu: true,
      },
    }, {
      path: 'payment',
      name: 'paymentSettings',
      component: PaymentSettings,
      meta: {
        title: 'partner-portal.routes.payout-settings',
      },
    }, {
      path: 'change-password',
      name: 'changePassword',
      component: ChangePassword,
      meta: {
        title: 'partner-portal.routes.change-password',
      },
    }],
  },
  {
    path: '/top-charts',
    name: 'topCharts',
    component: TopChartsLayout,
    children: [{
      path: 'countries',
      name: 'topChartsCountries',
      component: TopChartsCountries,
      meta: {
        title: 'partner-portal.routes.top-countries',
      },
    }, {
      path: 'cryptos',
      name: 'topChartsCryptos',
      component: TopChartsCryptos,
      meta: {
        title: 'partner-portal.routes.top-cryptos',
      },
    }],
  },
  {
    path: '/developers',
    name: 'developers',
    component: DevelopersLayout,
    children: [{
      path: 'documentation',
      name: 'developersDocumentation',
      component: ApiDocumentation,
      meta: {
        title: 'partner-portal.routes.documentation',
      },
    }],
  },
  {
    path: '/not-found',
    name: 'notFound',
    component: NotFound,
  },
];

const router = new VueRouter({
  base: '/',
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
