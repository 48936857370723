import Vue from 'vue';
import Vuex from 'vuex';

import AuthService from '@/services/AuthService';
import { initialiseUserSession, clearAutoLogoutInterval } from '@/services/AutoLogoutService';

import { HIDE_LOADER_EVENT, SHOW_LOADER_EVENT } from '@/utils/loader';

import userStore from '@/store/userStore';
import accountInfoStore from '@/store/accountInfoStore';
import dashboardStore from '@/store/dashboardStore';
import transactionsStore from '@/store/transactionsStore';
import payoutsStore from '@/store/payoutsStore';
import filterStore from '@/store/filterStore';
import featureFlagsStore from '@/store/featureFlagsStore';
import widgetSettingsStore from '@/store/widgetSettingsStore';
import analyticsStore from '@/store/analyticsStore';
import rolesStore from '@/store/rolesStore';
import topCountriesStore from '@/store/topCountriesStore';
import topCryptosStore from '@/store/topCryptosStore';
import profileSettingsStore from '@/store/profileSettingsStore';
import passwordRestoreStore from '@/store/passwordRestoreStore';
import accountProductsStore from '@/store/accountProductsStore';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},

  getters: {},

  mutations: {},

  actions: {
    async init({ dispatch }) {
      try {
        window.dispatchEvent(SHOW_LOADER_EVENT);

        dispatch('userStore/setUserLoading', true);

        const user = await AuthService.getCurrentUser();

        await dispatch('accountProductsStore/getProducts');
        await dispatch('featureFlagsStore/getFeatureFlags');
        await dispatch('rolesStore/getRoles');

        await initialiseUserSession();

        dispatch('userStore/setUser', user);

        return user;
      } catch (error) {
        return error;
      } finally {
        window.dispatchEvent(HIDE_LOADER_EVENT);

        dispatch('userStore/setUserLoading', false);
      }
    },
    clearStores: {
      root: true,
      handler: ({ commit }) => {
        commit('accountInfoStore/clearStore');
        commit('dashboardStore/clearStore');
        commit('analyticsStore/clearStore');
        commit('transactionsStore/clearStore');
        commit('payoutsStore/clearStore');
        commit('topCountriesStore/clearStore');
        commit('topCryptosStore/clearStore');
        commit('profileSettingsStore/clearStore');
        commit('accountProductsStore/clearStore');
      },
    },
  },

  modules: {
    userStore,
    accountInfoStore,
    dashboardStore,
    transactionsStore,
    payoutsStore,
    filterStore,
    featureFlagsStore,
    widgetSettingsStore,
    analyticsStore,
    rolesStore,
    topCountriesStore,
    topCryptosStore,
    profileSettingsStore,
    passwordRestoreStore,
    accountProductsStore,
  },
});

store.watch(
  (state, getters) => {
    const isUserSignedIn = getters['userStore/isUserSignedIn'];
    const shouldLoadApiKey = getters['accountInfoStore/shouldLoadApiKey'];
    const apiKey = getters['accountInfoStore/apiKey'];
    const shouldLoadAllFilters = getters['filterStore/shouldLoadAllFilters'];

    return {
      isUserSignedIn,
      shouldLoadApiKey,
      apiKey,
      shouldLoadAllFilters,
    };
  },
  updatedState => {
    const { isUserSignedIn, shouldLoadApiKey, apiKey, shouldLoadAllFilters } = updatedState;
    const canGetApiKey = !!shouldLoadApiKey && !!apiKey === false;

    if (!isUserSignedIn) {
      clearAutoLogoutInterval();

      return;
    }

    if (isUserSignedIn) {
      store.dispatch('accountInfoStore/init').finally(async () => {
        if (canGetApiKey) await store.dispatch('accountInfoStore/getApiKey');
        if (shouldLoadAllFilters) store.dispatch('filterStore/getAllFilters');
      });
    }
  },
  {
    immediate: true,
  },
);

export default store;
