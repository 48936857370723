<template>
  <div class="text-cell">
    {{ textValue }}
  </div>
</template>

<script>
export default {
  name: 'TextCell',
  props: {
    value: {
      type: Object,
      required: true,
    },
    format: {
      type: Function,
      default: value => value,
    },
  },
  computed: {
    textValue() {
      return this.format(this.value);
    },
  },
};
</script>
