<template>
  <div class="input-field">
    <input
      :id="id"
      v-model="inputValue"
      :disabled="isDisabled"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      class="input-field__input"
      :class="{
        'input-field__input--error': hasError
      }"
    >
    <p
      v-if="shouldShowErrorMessage"
      class="input-field__error"
    >
      {{ errorText }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'AppInput',

  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: null,
    },
  },

  computed: {
    hasError() {
      const { name, errors } = this;
      if (!errors.length) return false;

      return errors.some(error => error.key === name);
    },

    errorText() {
      if (!this.hasError) return '';

      const { name, errors: errorsList } = this;
      const relevantError = errorsList.find(errorItem => errorItem.key === name);
      if (!relevantError) return '';

      return relevantError.error;
    },

    shouldShowErrorMessage() {
      return this.hasError && this.errorText;
    },

    inputValue: {
      get() {
        return this.value;
      },
      set(modifiedInputValue) {
        this.$emit('input', modifiedInputValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.input-field {
  &__input {
    height: 42px;
    border: 2px solid $input-border-grey;
    border-radius: 6px;
    font-size: 16px;
    line-height: 18px;
    font-family: $font-regular;
    padding-left: 12px;
    width: 100%;

    &:focus,
    &:active {
      outline: none;
    }

    &--error {
      border-color: $error-color;
    }
  }

  &__error {
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    font-family: $font-regular;
    color: $error-color;
  }
}
</style>
