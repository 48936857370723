const formatDateForChart = chartDatesArray => chartDatesArray.map(chartElement => {
  const chartDate = chartElement.x.replace(/ /g, 'T');
  const dateWithTimeRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/g;
  const dateIncludesTime = dateWithTimeRegex.test(chartDate);

  let date;

  if (dateIncludesTime) {
    date = new Date(chartDate);
  } else {
    const chardDateWithTime = `${chartDate}T00:00`;

    date = new Date(chardDateWithTime);
  }

  const month = date.toLocaleString('en-EN', {
    month: 'short',
  });
  const day = date.toLocaleString('en-EN', {
    day: 'numeric',
  });

  if (dateIncludesTime) {
    const utcHours = date.getHours();
    const utcMinutes = date.getMinutes();
    const hours = String(utcHours).padStart(2, '0');
    const minutes = String(utcMinutes).padStart(2, '0');
    const formattedHours = hours === '24' ? '00' : hours;
    if (chartDatesArray.length === 48) return `${month} ${day} ${formattedHours}:${minutes}`;
    return `${formattedHours}:${minutes}`;
  }

  return `${month.slice(0, 3)} ${day}`;
});

const hexToRgb = color => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result ? {
    red: parseInt(result[1], 16),
    green: parseInt(result[2], 16),
    blue: parseInt(result[3], 16),
  } : null;
};

const formatRgba = (red, green, blue, alpha) => `rgba(${red}, ${green}, ${blue}, ${alpha})`;

export const createGradient = (context, color) => {
  const { chart: { ctx: chartContext, chartArea } } = context;
  if (!chartArea) return null;

  const { red, green, blue } = hexToRgb(color);
  const gradient = chartContext.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
  gradient.addColorStop(1, formatRgba(red, green, blue, 0.7));
  gradient.addColorStop(0.7, formatRgba(red, green, blue, 0.15));
  gradient.addColorStop(0, formatRgba(red, green, blue, 0.1));

  return gradient;
};

export const formatSeriesForChart = chartsData => {
  if (!chartsData) return null;

  const labels = formatDateForChart(chartsData);
  const data = chartsData.map(chartElement => Number(chartElement.y));

  return {
    labels,
    data,
  };
};
