<template>
  <section class="change-password">
    <div class="change-password__wrap">
      <h1 class="change-password__title">
        {{ $t('partner-portal.change-password.title') }}
      </h1>

      <form
        class="change-password__form"
        @submit="changePassword"
      >
        <input
          id="username"
          :value="userEmail"
          hidden
          type="text"
          autocomplete="username"
        >
        <div
          v-for="field in changePasswordFormFields"
          :key="field.name"
          class="change-password__form-input-group"
        >
          <div class="change-password__form-label-group">
            <label
              :for="field.id"
              class="change-password__form-label"
            >
              {{ $t(`partner-portal.change-password.${field.name}`) }}
            </label>
          </div>

          <AppInput
            :id="field.id"
            v-model="passwords[field.id]"
            :is-disabled="isLoading"
            :errors="errors"
            :name="field.name"
            :type="field.type"
            :autocomplete="field.autocomplete"
            :placeholder="field.placeholder"
            class="change-password__form-input"
          />
        </div>

        <span
          v-if="showLimitError"
          class="change-password__form-limit-error"
        >
          {{ $t('partner-portal.errors.user-password-limit-exceeded') }}
        </span>

        <div class="change-password__form-footer">
          <AppButton
            type="submit"
            :is-loading="isLoading"
            :text="$t('partner-portal.modals.confirm')"
            class="btn--primary change-password__form-button"
          />

          <AppButton
            type="button"
            :is-disabled="isLoading"
            :text="$t('partner-portal.modals.cancel')"
            class="btn--secondary change-password__form-button"
            @click="clearFields"
          />
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { validateChangePasswordAwsResponse } from '@/views/Auth/Auth.utils';

import NotificationService from '@/services/NotificationService';

import AppInput from '@/components/AppInput';
import AppButton from '@/components/AppButton';

export default {
  name: 'ChangePassword',

  components: {
    AppInput,
    AppButton,
  },

  data() {
    return {
      changePasswordFormFields: [
        {
          id: 'currentPassword',
          name: 'password',
          type: 'password',
          autocomplete: 'current-password',
        },
        {
          id: 'newPassword',
          name: 'new-password',
          type: 'password',
          autocomplete: 'new-password',
          placeholder: this.$t('partner-portal.change-password.new-password-min-length'),
        },
        {
          id: 'newPasswordConfirmation',
          name: 'new-password-confirmation',
          type: 'password',
          autocomplete: 'new-password',
        },
      ],

      passwords: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      },

      isLoading: false,

      errors: [],

      showLimitError: false,

      minPasswordLength: 8,
    };
  },

  computed: {
    ...mapGetters('userStore', {
      user: 'user',
    }),

    userEmail() {
      return this.user?.attributes?.email;
    },

    validationFieldsList() {
      return this.changePasswordFormFields.map(field => ({
        key: field.name,
        value: this.passwords[field.id],
      }));
    },
  },

  methods: {
    ...mapActions('userStore', {
      changePasswordWithAmplify: 'changePassword',
    }),

    changePassword(event) {
      event.preventDefault();
      this.clearErrors();
      this.trimValues();

      this.isLoading = true;

      this.validateFields();

      if (this.errors.length) {
        this.isLoading = false;

        return;
      }

      const { currentPassword: oldPassword, newPassword } = this.passwords;

      this.changePasswordWithAmplify({
        oldPassword, newPassword,
      })
        .then(() => {
          this.clearFields();
          NotificationService.notifyAboutSuccess('partner-portal.notifications.user-password-changed');
        })
        .catch(err => {
          const errorsList = validateChangePasswordAwsResponse(err);

          errorsList.forEach(error => {
            if (error.key === 'limit') {
              this.showLimitError = true;
            } else {
              this.errors.push(error);
            }
          });
        })
        .finally(() => {
          this.isLoading = false;

          return false;
        });
    },

    clearErrors() {
      this.errors = [];
      this.showLimitError = false;
    },

    trimValues() {
      this.changePasswordFormFields.forEach(field => {
        this.passwords[field.id] = this.passwords[field.id].trim();
      });
    },

    validateFields() {
      const isValueContainsNumber = str => /\d/.test(str);

      this.validationFieldsList.forEach(({ key, value }) => {
        if (!value) {
          this.errors.push({
            key, error: this.$t(`partner-portal.errors.user-missing-${key}`),
          });
        }

        if (key !== 'password') {
          const isPasswordPolicyInvalid = value.length < this.minPasswordLength || !isValueContainsNumber(value);

          if (isPasswordPolicyInvalid) {
            this.errors.push({
              key, error: this.$t('partner-portal.errors.invalid-password-policy'),
            });
          }
        }
      });

      if (this.passwords.newPassword !== this.passwords.newPasswordConfirmation) {
        const confirmationPasswords = ['new-password', 'new-password-confirmation'];

        confirmationPasswords.forEach(password => {
          this.errors.push({
            key: password,
            error: this.$t('partner-portal.errors.password-must-match'),
          });
        });
      }
    },

    clearFields() {
      const fields = ['currentPassword', 'newPassword', 'newPasswordConfirmation'];

      fields.forEach(field => {
        this.passwords[field] = '';
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.change-password {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 24px 20px;
  border-radius: 8px;
  background: $primary;

  &__wrap {
    width: 100%;
    max-width: 100%;
  }

  &__title {
    font: 600 22px/26px $font-bold;
    margin-bottom: 40px;
  }

  &__form-input-group {
    margin-bottom: 12px;

    &:nth-child(4) {
      margin-bottom: 0;
    }
  }

  &__form-label-group {
    margin-bottom: 4px;
  }

  &__form-label,
  &__form-limit-error {
    font: 400 13px/16px $font-regular;
  }

  &__form-limit-error {
    color: $error-color;
    text-align: center;
  }

  &__form-footer {
    flex-direction: column;
    margin-top: 32px;
  }

  &__form-button {
    min-width: 100%;

    &:not(:last-of-type) {
      margin: 0 0 12px 0;
    }
  }
}

@media screen and (min-width: $desktop-breakpoint) {
  .change-password {
    padding: 60px 0;

    &__wrap {
      max-width: 472px;
    }

    &__title {
      font: 600 24px/32px $font-bold;
      margin-bottom: 48px;
    }

    &__form-footer {
      display: flex;
      flex-direction: row;
      margin-top: 48px;
    }

    &__form-button {
      min-width: 144px;

      &:not(:last-of-type) {
        margin: 0 16px 0 0;
      }
    }
  }
}
</style>
