import Vue from 'vue';

Vue.directive('click-outside', {
  bind: (element, binding, vnode) => {
    element.clickOutsideListener = event => {
      // Do nothing if clicking element or descendent elements
      if (element === event.target || element.contains(event.target)) {
        return;
      }

      // Ensure we have proper `this` in passed handler
      binding.value.call(vnode.context);
    };

    document.body.addEventListener('click', element.clickOutsideListener);
    document.body.addEventListener('touch', element.clickOutsideListener);
  },

  unbind: element => {
    document.body.removeEventListener('click', element.clickOutsideListener);
    document.body.removeEventListener('touch', element.clickOutsideListener);
  },
});
