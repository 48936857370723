<template>
  <p>Error 404</p>
</template>

<script>
// TODO: Make this page work right
export default {
  name: 'Page404',
};
</script>
