<template>
  <section class="profile-settings">
    <ProfileSettingsForm
      :contact-persons="contactPersons"
      :errors="errors"

      :is-loading="isPersonsLoading"
      :is-updating="isPersonsUpdating"

      :reset-persons-forms="resetPersonsForms"
      :show-persons-forms="canShowPersonsForms"
      :is-view-only="isViewOnlyMonitoringAllowed"

      @show-cancel-changes-modal="showCancelChangesModal = true"
      @set-persons-data-changed-status="isPersonsDataChanged = $event"

      @drop-errors="dropErrors"

      @save="saveChanges"
    />

    <Modal
      :show.sync="showBeforeLeaveModal"
      :component="$options.modals.ProfileSettingsBeforeLeaveModal"
      @confirm="onConfirmBeforeLeaveModal"
      @reject="showBeforeLeaveModal = false"
    />

    <Modal
      :show.sync="showCancelChangesModal"
      :component="$options.modals.ProfileSettingsCancelChangesModal"
      @confirm="onConfirmCancelChangesModal"
      @reject="showCancelChangesModal = false"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import NotificationService from '@/services/NotificationService';

import ProfileSettingsForm from '@/components/ProfileSettingsForm';
import { Modal, ProfileSettingsBeforeLeaveModal, ProfileSettingsCancelChangesModal } from '@/components/Modal';

export default {
  name: 'ProfileSettings',

  components: {
    ProfileSettingsForm,
    Modal,
  },

  modals: {
    ProfileSettingsBeforeLeaveModal,
    ProfileSettingsCancelChangesModal,
  },

  data() {
    return {
      errors: [],

      isPersonsLoading: false,
      isPersonsUpdating: false,

      isPersonsDataChanged: false,

      showBeforeLeaveModal: false,
      showCancelChangesModal: false,

      resetPersonsForms: false,

      routeToRedirect: null,
    };
  },

  computed: {
    ...mapGetters({
      contactPersons: 'profileSettingsStore/persons',

      isContactPersonsEnable: 'featureFlagsStore/isContactPersonsEnable',
    }),
    ...mapGetters('rolesStore', {
      isViewOnlyMonitoringAllowed: 'isViewOnlyMonitoringAllowed',
      isProfileSettingsMonitoringAllowed: 'isProfileSettingsMonitoringAllowed',
    }),

    canShowPersonsForms() {
      if (!this.isContactPersonsEnable) return false;

      return this.isProfileSettingsMonitoringAllowed || this.isViewOnlyMonitoringAllowed;
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.isPersonsDataChanged) {
      this.showBeforeLeaveModal = true;
      this.routeToRedirect = to.name;

      return next(false);
    }

    return next();
  },

  async mounted() {
    if (this.canShowPersonsForms) {
      this.isPersonsLoading = true;

      await this.getPersons();

      this.isPersonsLoading = false;
    }
  },

  destroyed() {
    this.clearStore();
  },

  methods: {
    ...mapActions('profileSettingsStore', {
      getPersons: 'getPersons',
      updatePersons: 'updatePersons',

      clearStore: 'clearStore',
    }),

    async saveChanges(updatedPersons) {
      this.isPersonsUpdating = true;

      this.dropErrors();

      try {
        await this.updatePersons(updatedPersons);

        NotificationService.notifyAboutSuccess('partner-portal.notifications.profile-settings-updated');
      } catch (error) {
        this.errors = error?.errors || [];
        NotificationService.notifyAboutError('partner-portal.notifications.profile-settings-error');
      } finally {
        this.isPersonsUpdating = false;
      }
    },

    async onConfirmCancelChangesModal() {
      this.dropErrors();

      window.onbeforeunload = null;
      this.resetPersonsForms = true;
      this.showCancelChangesModal = false;

      await this.$nextTick();

      this.resetPersonsForms = false;
    },

    async onConfirmBeforeLeaveModal() {
      this.dropErrors();

      window.onbeforeunload = null;
      this.isPersonsDataChanged = false;
      this.showBeforeLeaveModal = false;

      await this.$nextTick();

      this.redirectAfterModalClose();
    },

    redirectAfterModalClose() {
      if (this.routeToRedirect) {
        this.$router.push({
          name: this.routeToRedirect,
        });
      }
    },

    dropErrors() {
      this.errors = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-settings {
  flex: 1 0;
  width: 100%;
}
</style>
