<template>
  <main class="transactions">
    <TransactionsSidebar />
    <div class="transactions__content">
      <TransactionsList
        :title="$t('partner-portal.transaction.table.title')"
        :show-link="false"
        :show-download="true"

        :can-load-more-transactions="canLoadMoreTransactions"
        :transactions="transactionsList"
        :report-url="transactionsReportUrl"

        :is-loading-transactions="isTransactionsListLoading"
        :is-loading-report="isLoadingReport"
        :is-loading-more="isLoadingMore"

        :settings="transactionsListSettings"
        :load-transaction-details="loadTransactionDetails"
        @load-more="loadMoreTransactions"
        @download-report="downloadTransactionsReport"
      />
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TransactionsSidebar from './TransactionsSidebar';
import TransactionsList from '@/components/TransactionsList';

import { getLastMonth } from '@/utils/charts';

export default {
  name: 'Transactions',

  components: {
    TransactionsSidebar,
    TransactionsList,
  },

  computed: {
    ...mapGetters('transactionsStore', {
      isTransactionsListLoading: 'isLoadingTransactions',
      isLoadingMore: 'isLoadingMore',
      isLoadingReport: 'isLoadingReport',

      transactionsList: 'transactionsList',
      canLoadMoreTransactions: 'canLoadMoreTransactions',

      transactionsReportUrl: 'reportUrl',
    }),

    transactionsListSettings() {
      return {
        limit: 20,
      };
    },
  },

  mounted() {
    const lastMonthRange = getLastMonth();

    this.setDateFilter(lastMonthRange);
    this.loadTransactions();
  },

  destroyed() {
    this.unsubscribeFromTransactionsReport();
    this.clearStore();
  },

  methods: {
    ...mapActions('transactionsStore', {
      loadMoreTransactions: 'loadMore',
      loadTransactionDetails: 'loadTransactionDetails',
      loadTransactions: 'loadTransactions',
      downloadTransactionsReport: 'downloadTransactionsReport',
      unsubscribeFromTransactionsReport: 'unsubscribeFromTransactionsReport',

      setDateFilter: 'setDateFilter',
      clearStore: 'clearStore',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.transactions {
  flex-wrap: wrap;

  &__content {
    width: 100%;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .transactions {
    flex-wrap: nowrap;

    &__content {
      flex: 1 0;
      min-width: 0;
    }
  }
}
</style>
