import axiosLib from 'axios';
import AxiosConfigurator from './AxiosConfigurator';
import { logger, auth } from './interceptors';

import * as mocks from './mocks';

const axiosConfigurator = new AxiosConfigurator(axiosLib);

if (IS_DEVELOPMENT) {
  const mocksList = Object.values(mocks);
  axiosConfigurator.mock(mocksList);
}

const apiConfigurator = axiosConfigurator.create({
  baseURL: PARTNER_PORTAL_API_URL,
});

const paybisConfigurator = axiosConfigurator.create({
  baseURL: SEO_PRICES_HOST,
});

const paybisStaticConfigurator = axiosConfigurator.create({
  baseURL: PAYBIS_STATIC_HOST,
});

apiConfigurator.attach(auth);

if (IS_DEVELOPMENT) {
  apiConfigurator.attach(logger);
}

export const apiClient = apiConfigurator.eject();

export const paybisClient = paybisConfigurator.eject();

export const paybisStaticClient = paybisStaticConfigurator.eject();
