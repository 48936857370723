<template>
  <canvas
    ref="canvas"
    class="canvas"
  />
</template>

<script>
import { Chart, registerables } from 'chart.js';
import { createGradient, formatSeriesForChart } from './ChartCanvas.utils';
import { COMMON_CANVAS_OPTIONS, COMMON_CANVAS_STYLES, LINEAR_CHART_SCALES } from './ChartCanvas.constants';

Chart.register(...registerables);

export default {
  name: 'LinearChart',

  props: {
    chartData: {
      type: Array,
      default: () => [],
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  data: () => ({
    chart: null,
  }),

  watch: {
    chartData(updatedChartData) {
      const { labels, data } = formatSeriesForChart(updatedChartData);
      this.chart.data.labels = labels;
      this.chart.data.datasets[0].data = data;

      this.chart.update();
    },
  },

  mounted() {
    const { canvas } = this.$refs;
    const { label, chartColor, styles } = this.chartOptions;
    const ctx = canvas.getContext('2d');
    const { labels, data } = formatSeriesForChart(this.chartData);

    const mergedStyles = {
      ...COMMON_CANVAS_STYLES, ...styles,
    };

    const chartSettings = {
      labels,
      datasets: [{
        label,
        data,
        ...mergedStyles,
        borderColor: chartColor,
        backgroundColor(context) {
          return createGradient(context, chartColor);
        },
      }],
    };

    const chartOptions = {
      ...COMMON_CANVAS_OPTIONS, ...LINEAR_CHART_SCALES,
    };

    this.chart = new Chart(ctx, {
      type: 'line',
      data: chartSettings,
      options: chartOptions,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.canvas {
  width: 100%;
  margin-left: -16px;
  margin-right: -16px;
}

@media screen and (min-width: $desktop-breakpoint) {
  .canvas {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
