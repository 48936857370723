<template>
  <div
    class="widget-settings"
    :class="{'widget-settings--empty': isWidgetSettingsEmpty}"
  >
    <h1 class="widget-settings__title">
      {{ $t('partner-portal.widget-settings.title') }}
    </h1>
    <div
      class="widget-settings__tabs"
    >
      <div
        v-for="tab in tabs"
        :key="tab"
        :class="[
          'widget-settings__tab',
          { 'widget-settings__tab--active': isActiveTab(tab) }
        ]"
        @click="activateTab(tab)"
      >
        {{ $t(`partner-portal.gateways.${tab}`) }}
      </div>
    </div>

    <PaymentMethodsSelector
      v-if="hasPaymentMethods"
      class="widget-settings__payment-form"
      :selected-payment-method="selectedPaymentMethod"
      :payment-methods="paymentMethods"
      @change-payment-method="changePaymentMethod"
    />

    <WidgetGeneralControls
      v-if="hasPaymentMethodOptions"
      class="widget-settings__currency-settings"
      :title="$t('partner-portal.widget-settings.currency-settings')"
      @enable-all-payment-options="enableAllPaymentOptions"
      @set-fee-for-payment-options="setFeeForPaymentOptions"
    />

    <Table
      v-if="hasPaymentMethodOptions"
      class="widget-settings__table"
      :columns="parentColumns"
      :rows="paymentMethodOptions"
      @toggle-payment-option-availability="togglePaymentOptionAvailability"
      @set-partner-fee="setPartnerFee"
    >
      <template slot-scope="{ row }">
        <div class="widget-settings__internal-table">
          <div class="widget-settings__internal-table-title">
            {{ row.payment_method.name }}:
            {{ $t('partner-portal.widget-settings.child-table.fiat-settings') }}
          </div>
          <InnerTable
            class="widget-settings__inner-table"
            :show-scroll-shadow="false"
            :columns="childrenColumns"
            :rows="row.base_currencies"
            @toggle-payment-option-availability="togglePaymentOptionAvailability"
            @set-partner-fee="setPartnerFee"
          />
        </div>
      </template>
    </Table>

    <Loader
      v-if="isLoading"
      class="widget-settings__loader"
    />

    <MissingDataPlaceholder
      v-if="isWidgetSettingsEmpty"
      class="widget-settings__missing-data"
    />

    <div
      v-if="hasPaymentMethodOptions"
      class="widget-settings__footer"
    >
      <AppButton
        class="btn btn--primary"
        :text="$t('partner-portal.buttons.save')"
        :is-loading="isUpdatingPaymentMethodOptions"
        :is-disabled="isViewOnlyMonitoringAllowed"
        @click="saveWidgetSettingsChanges"
      />

      <AppButton
        class="btn btn--secondary"
        :text="$t('partner-portal.buttons.cancel')"
        :is-disabled="isCancelButtonDisabled"
        @click="showCancelChangesModal = true"
      />
    </div>

    <Modal
      :show.sync="showBeforeLeaveModal"
      :component="$options.modals.WidgetSettingsBeforeLeaveModal"
      @confirm="onConfirmBeforeLeaveModal"
      @reject="onRejectBeforeLeaveModal"
    />

    <Modal
      :show.sync="showCancelChangesModal"
      :component="$options.modals.WidgetSettingsCancelChangesModal"
      @confirm="onConfirmCancelChangesModal"
      @reject="onRejectCancelChangesModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import NotificationService from '@/services/NotificationService';
import PaymentMethodsSelector from './PaymentMethodsSelector';
import WidgetGeneralControls from './WidgetGeneralControls';
import Table from '@/components/Table';
import Loader from '@/components/Loader';
import { Modal, WidgetSettingsBeforeLeaveModal, WidgetSettingsCancelChangesModal } from '@/components/Modal';
import { ServiceStatusCell,
  TickerCell,
  ExpandCell,
  InputCell } from './Cell';
import AppButton from '@/components/AppButton';
import MissingDataPlaceholder from '@/components/MissingDataPlaceholder';

import { formatDataBeforeSave,
  beforeUnloadListener,
  formatPaymentMethodOptions,
  attachErrorsForPaymentMethodOptions } from './WidgetSettings.utils';

const DEFAULT_TABS = [
  'fiat_to_crypto',
  'crypto_to_fiat',
];

const CRYPTO_TYPE = 'crypto';

const FEE_RESTRICTIONS = {
  min: 0, max: 100,
};

// NOTE:
// For parent statuses:
// is_available = status which is triggered by admin in admin panel
// is_active = status which is triggered by partner using UI of widget settings

export default {
  name: 'WidgetSettings',

  components: {
    Loader,
    PaymentMethodsSelector,
    Table,
    InnerTable: Table,
    Modal,
    WidgetGeneralControls,
    AppButton,
    MissingDataPlaceholder,
  },

  modals: {
    WidgetSettingsBeforeLeaveModal,
    WidgetSettingsCancelChangesModal,
  },

  data: () => ({
    activeTab: DEFAULT_TABS[0],

    BASE_PAYMENT_METHOD_OPTIONS: {},

    selectedPaymentMethod: '',
    selectedPaymentMethodId: null,

    paymentMethods: [],
    paymentMethodOptions: [],

    arePaymentMethodOptionsUpdated: false,

    showBeforeLeaveModal: false,
    showCancelChangesModal: false,

    routeToRedirect: null,

    isLoadingPaymentMethods: true,
    isLoadingPaymentMethodOptions: true,
  }),

  computed: {
    ...mapGetters({
      isUpdatingPaymentMethodOptions: 'widgetSettingsStore/isUpdatingPaymentMethodOptions',
      isViewOnlyMonitoringAllowed: 'rolesStore/isViewOnlyMonitoringAllowed',
    }),

    hasPaymentMethods() {
      return !this.isLoadingPaymentMethods && this.paymentMethods?.length > 0;
    },

    hasPaymentMethodOptions() {
      return !this.isLoading && this.paymentMethodOptions?.length > 0;
    },

    isLoading() {
      return this.isLoadingPaymentMethods || this.isLoadingPaymentMethodOptions;
    },

    isWidgetSettingsEmpty() {
      return (!this.hasPaymentMethods || !this.hasPaymentMethodOptions) && !this.isLoading;
    },

    tabs() {
      return DEFAULT_TABS;
    },

    parentColumns() {
      return [{
        name: this.$t(`partner-portal.widget-settings.table.head.${this.activeTab}`),
        key: 'crypto',
        component: TickerCell,
      }, {
        name: this.$t('partner-portal.widget-settings.table.head.status'),
        key: 'status',
        component: ServiceStatusCell,
      }, {
        name: this.$t('partner-portal.widget-settings.table.head.partner-fee'),
        key: 'partnerFee',
        component: InputCell,
      }, {
        name: this.$t('partner-portal.widget-settings.table.head.gateway-fee'),
        key: 'gatewayFee',
        format: row => {
          const { is_available: isAvailable } = row;

          if (!isAvailable) return '';

          let minGatewayFee = Math.min(...row.base_currencies
            .map(currency => currency.gateway_fee)
            .filter(Number));

          if (minGatewayFee === Infinity) {
            minGatewayFee = 0;
          }

          return `${minGatewayFee.toString()}%`;
        },
      }, {
        name: '',
        key: 'expand',
        component: ExpandCell,
      }];
    },

    childrenColumns() {
      return [{
        name: this.$t(`partner-portal.widget-settings.child-table.head.${this.activeTab}`),
        key: 'service',
        component: TickerCell,
      }, {
        name: this.$t('partner-portal.widget-settings.child-table.head.status'),
        key: 'status',
        component: ServiceStatusCell,
      }, {
        name: this.$t('partner-portal.widget-settings.child-table.head.partner-fee'),
        key: 'partnerFee',
        component: InputCell,
      }, {
        name: this.$t('partner-portal.widget-settings.child-table.head.gateway-fee'),
        key: 'gatewayFee',
        format: row => {
          const { is_available: isAvailable } = row;

          if (!isAvailable) return '';

          return `${row.gateway_fee}%`;
        },
      }];
    },

    isCancelButtonDisabled() {
      return this.isViewOnlyMonitoringAllowed || this.isUpdatingPaymentMethodOptions;
    },
  },

  watch: {
    paymentMethodOptions: {
      handler(newValue, oldValue) {
        if (!oldValue?.length) return;

        const basePaymentMethodOptions = this.BASE_PAYMENT_METHOD_OPTIONS;
        const stringifiedPaymentMethodOptions = JSON.stringify(newValue);
        if (basePaymentMethodOptions[this.activeTab] !== stringifiedPaymentMethodOptions) {
          this.arePaymentMethodOptionsUpdated = true;
          window.onbeforeunload = beforeUnloadListener;
        } else {
          this.arePaymentMethodOptionsUpdated = false;
          window.onbeforeunload = undefined;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.arePaymentMethodOptionsUpdated) {
      this.showBeforeLeaveModal = true;
      this.routeToRedirect = to.name;

      return next(false);
    }

    return next();
  },

  async mounted() {
    this.fetchAndFormatWidgetSettings();
  },

  destroyed() {
    window.onbeforeunload = undefined;
  },

  methods: {
    ...mapActions('widgetSettingsStore', {
      getPaymentMethods: 'getPaymentMethods',
      getPaymentMethodOptions: 'getPaymentMethodOptions',
      updatePaymentMethodOptions: 'updatePaymentMethodOptions',
    }),

    ...mapMutations('widgetSettingsStore', {
      setUpdatingPaymentMethodOptions: 'setUpdatingPaymentMethodOptions',
    }),

    removeWindowUpdateListeners() {
      this.arePaymentMethodOptionsUpdated = false;
      window.onbeforeunload = undefined;
    },

    fetchPaymentMethodOptions() {
      this.isLoadingPaymentMethodOptions = true;
      this.getPaymentMethodOptions(this.selectedPaymentMethodId)
        .then(paymentMethodOptions => {
          this.paymentMethodOptions = formatPaymentMethodOptions(paymentMethodOptions);
          this.BASE_PAYMENT_METHOD_OPTIONS[this.activeTab] = JSON.stringify(this.paymentMethodOptions);
        })
        .catch(() => {
          this.paymentMethodOptions = [];
        })
        .finally(() => {
          this.isLoadingPaymentMethodOptions = false;
        });
    },

    fetchAndFormatWidgetSettings() {
      this.isLoadingPaymentMethods = true;

      this.getPaymentMethods({
        gateway: this.activeTab,
      })
        .then(paymentMethods => {
          if (!paymentMethods || !paymentMethods.length) {
            this.isLoadingPaymentMethods = false;
            this.isLoadingPaymentMethodOptions = false;
            this.paymentMethodOptions = [];

            return;
          }

          this.paymentMethods = paymentMethods;

          const { id, name, type, asset } = paymentMethods[0];

          if (!this.selectedPaymentMethod) {
            this.selectedPaymentMethod = type === CRYPTO_TYPE ? asset.name : name;
            this.selectedPaymentMethodId = id;
          }

          this.fetchPaymentMethodOptions();
        })
        .finally(() => {
          this.isLoadingPaymentMethods = false;
        });
    },

    activateTab(tab) {
      this.activeTab = tab;
      this.selectedPaymentMethod = '';
      this.selectedPaymentMethodId = null;
      this.fetchAndFormatWidgetSettings();
    },

    onConfirmCancelChangesModal() {
      this.setUpdatingPaymentMethodOptions(true);

      this.dropErrors();
      this.removeWindowUpdateListeners();
      this.paymentMethodOptions = JSON.parse(this.BASE_PAYMENT_METHOD_OPTIONS[this.activeTab]);
      this.showCancelChangesModal = false;

      this.setUpdatingPaymentMethodOptions(false);
    },

    onRejectCancelChangesModal() {
      this.showCancelChangesModal = false;
    },

    redirectAfterModalClose() {
      if (this.routeToRedirect) {
        this.$router.push({
          name: this.routeToRedirect,
        });
      }
    },

    async onConfirmBeforeLeaveModal() {
      this.arePaymentMethodOptionsUpdated = false;
      this.showBeforeLeaveModal = false;

      this.$nextTick(this.redirectAfterModalClose);
    },

    async onRejectBeforeLeaveModal() {
      this.showBeforeLeaveModal = false;
    },

    dropErrors() {
      const paymentMethodOptionsWithoutErrors = this.paymentMethodOptions.map(paymentMethod => {
        const { base_currencies: baseCurrencies } = paymentMethod;
        const baseCurrenciesWithoutErrors = baseCurrencies.map(baseCurrency => ({
          ...baseCurrency,
          error: '',
        }));

        return {
          ...paymentMethod,
          base_currencies: baseCurrenciesWithoutErrors,
        };
      });

      this.paymentMethodOptions = paymentMethodOptionsWithoutErrors;
    },

    saveWidgetSettingsChanges() {
      if (this.isUpdatingPaymentMethodOptions) return;

      this.setUpdatingPaymentMethodOptions(true);
      this.dropErrors();

      const formattedWidgetSettingsData = formatDataBeforeSave(this.paymentMethodOptions);

      this.updatePaymentMethodOptions({
        paymentMethodId: this.selectedPaymentMethodId,
        updatedOptions: formattedWidgetSettingsData,
      })
        .then(() => {
          NotificationService.notifyAboutSuccess('partner-portal.notifications.widget-settings-updated');

          const updatedPaymentMethodOptions = formatPaymentMethodOptions(this.paymentMethodOptions);
          this.BASE_PAYMENT_METHOD_OPTIONS[this.activeTab] = JSON.stringify(updatedPaymentMethodOptions);

          this.removeWindowUpdateListeners();
        })
        .catch(({ errors }) => {
          NotificationService.notifyAboutError('partner-portal.notifications.widget-settings-error');

          const paymentMethodOptionsWithErrors = attachErrorsForPaymentMethodOptions(
            errors, this.paymentMethodOptions,
          );

          this.paymentMethodOptions = paymentMethodOptionsWithErrors;
        })
        .finally(() => {
          this.setUpdatingPaymentMethodOptions(false);
        });
    },

    setPartnerFee(options) {
      const { paymentMethodOptions } = this;
      const { id = null, parentId = null, fee, code } = options;

      const isParentCurrency = !!id;
      const isChildCurrency = !!parentId;

      const modifiedPaymentMethodOptions = paymentMethodOptions.map(paymentMethod => {
        const { id: paymentMethodId, base_currencies: baseCurrencies } = paymentMethod;

        if (paymentMethodId !== id && paymentMethodId !== parentId) return paymentMethod;

        const modifiedBaseCurrencies = baseCurrencies.map(baseCurrency => {
          if (isParentCurrency) {
            const { is_available: isAvailable, partners_fee: partnersFee } = baseCurrency;
            if (partnersFee === null) return baseCurrency;

            const canModifyPartnersFee = isAvailable && partnersFee !== null;
            if (canModifyPartnersFee) {
              return {
                ...baseCurrency,
                partners_fee: fee,
              };
            }

            return baseCurrency;
          }

          if (isChildCurrency) {
            const { currency: { code: currencyCode } } = baseCurrency;
            if (currencyCode === code) {
              return {
                ...baseCurrency,
                partners_fee: fee,
              };
            }

            return baseCurrency;
          }

          return baseCurrency;
        });

        if (isParentCurrency) {
          return {
            ...paymentMethod,
            partners_fee: String(fee),
            base_currencies: modifiedBaseCurrencies,
          };
        }

        if (isChildCurrency) {
          return {
            ...paymentMethod,
            base_currencies: modifiedBaseCurrencies,
          };
        }

        return paymentMethod;
      });

      this.paymentMethodOptions = modifiedPaymentMethodOptions;
    },

    togglePaymentOptionAvailability(options) {
      const { paymentMethodOptions } = this;
      const { id = null, checked } = options;

      const modifiedPaymentMethodOptions = paymentMethodOptions.map(paymentMethod => {
        const { id: paymentMethodId } = paymentMethod;

        if (paymentMethodId !== id) return paymentMethod;

        return {
          ...paymentMethod,
          is_active: checked,
        };
      });

      this.paymentMethodOptions = modifiedPaymentMethodOptions;
    },

    enableAllPaymentOptions() {
      const enabledPaymentMethodOptions = this.paymentMethodOptions.map(paymentOption => ({
        ...paymentOption,
        is_active: true,
      }));

      this.paymentMethodOptions = enabledPaymentMethodOptions;
    },

    setFeeForPaymentOptions(fee) {
      const feeSize = +fee;
      const isFeeSizeZero = feeSize === 0;

      if (!feeSize && !isFeeSizeZero) return;
      // TODO: Should we pass the error in here for this input?
      if (FEE_RESTRICTIONS.min > feeSize || FEE_RESTRICTIONS.max < feeSize) return;

      const updatedPaymentMethodOptions = this.paymentMethodOptions.map(paymentOption => {
        const { base_currencies: baseCurrencies } = paymentOption;

        if (!paymentOption.is_available) return paymentOption;

        const optionWithUpdatedFees = baseCurrencies.map(baseCurrency => {
          const { partners_fee: partnersFee, is_available: isAvailable } = baseCurrency;

          if (partnersFee === null) return baseCurrency;

          const canModifyPartnersFee = isAvailable && partnersFee !== null;
          if (canModifyPartnersFee) {
            return {
              ...baseCurrency,
              partners_fee: fee,
            };
          }

          return baseCurrency;
        });

        return {
          ...paymentOption,
          partners_fee: fee,
          base_currencies: optionWithUpdatedFees,
        };
      });

      this.paymentMethodOptions = updatedPaymentMethodOptions;
    },

    changePaymentMethod({ paymentMethod, paymentMethodId }) {
      this.selectedPaymentMethod = paymentMethod;
      this.selectedPaymentMethodId = paymentMethodId;

      this.fetchPaymentMethodOptions();
    },

    isActiveTab(tab) {
      return this.activeTab === tab;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.widget-settings {
  flex: 1 0;
  width: 100%;
  background: $primary;
  border-radius: 8px;
  padding: 20px 20px 80px;

  &--empty {
    height: max-content;
  }

  &__loader {
    display: flex;
    align-items: center;
    height: 500px;
  }

  &__missing-data {
    padding: 56px 16px 16px;
  }

  &__title {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 24px;

    @media screen and (min-width: $tablet-breakpoint) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__tabs {
    display: flex;
  }

  &__tab {
    display: flex;
    flex: 0 0 33%;
    align-items: center;
    padding: 6px 20px;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    border-radius: 8px;
    transition: background-color .3s;
    cursor: pointer;

    &:hover,
    &--active {
      background: $item-active-background;
    }
  }

  &__table {
    padding-top: 13px;
  }

  &__payment-form {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
  }

  &__inner-table {
    overflow-x: visible;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    .btn--primary {
      margin-bottom: 12px;
    }
  }

  ::v-deep .table__td {
    height: 52px;
  }

  ::v-deep .table__th,
  ::v-deep .table__td {
    &--crypto {
      flex: 0 0 340px;
    }

    &--service {
      flex: 0 0 316px;
    }

    &--status {
      flex: 0 0 93px;
      justify-content: flex-end;
    }

    &--partnerFee {
      flex: 0 0 115px;
      justify-content: flex-end;
    }

    &--gatewayFee {
      flex: 0 0 102px;
      justify-content: flex-end;
    }

    &--expand {
      justify-content: flex-end;
      flex: 1 0 48px;
      opacity: .5;
      transition: .3s;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__internal-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    background: $background-color;
    margin-top: 10px;
    min-width: 700px;
  }

  &__internal-table-title {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-bold;
    margin-bottom: 24px;
  }

  &__currency-settings {
    margin-top: 40px;

    &--loading {
      opacity: 0;
    }
  }
}

@media screen and (min-width: $desktop-breakpoint) {
  .widget-settings {
    padding: 64px 138px;

    &__tab {
      font-size: 15px;
      line-height: 18px;
      text-align: left;
      flex: 0 0 auto;
    }

    &__currency-settings {
      margin-top: 64px;
    }

    &__table-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }

    &__footer {
      display: flex;
      flex-direction: row;
      margin-top: 62px;

      .btn--primary {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }

    &__table-controls-option {
      margin-bottom: 0;
    }
  }
}
</style>
