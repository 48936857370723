export const HIDE_LOADER_EVENT = new CustomEvent('hide-loader', {
  detail: {
    hide: true,
  },
});

export const SHOW_LOADER_EVENT = new CustomEvent('show-loader', {
  detail: {
    show: true,
  },
});
