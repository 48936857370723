<template>
  <div class="transaction-details-modal">
    <div
      v-if="isTransactionLoading"
      class="transaction-details-modal__loader"
    >
      <i class="transaction-details-modal__loader-icon icon icon-spinner-black" />
    </div>

    <div
      v-else
      class="transaction-details-modal__wrapper"
    >
      <div class="transaction-details-modal__general">
        <div class="transaction-details-modal__header">
          <div class="transaction-details-modal__header-timing">
            <div class="transaction-details-modal__header-date">
              {{ transactionDate.date.month }}
              {{ transactionDate.date.day }}
              {{ transactionDate.date.year }}
            </div>
            <div class="transaction-details-modal__header-time">
              {{ transactionDate.time.hours }}:{{ transactionDate.time.minutes }}
            </div>
          </div>
          <div
            class="transaction-details-modal__header-close"
            @click="closeModal"
          >
            <i
              class="icon icon-close-black transaction-details-modal__header-close-image"
            />
          </div>
        </div>
        <div class="transaction-details-modal__exchange">
          <div class="transaction-details-modal__exchange-from">
            <div class="transaction-details-modal__exchange-value">
              {{ exchangeRate.from.value }}
            </div>
            <div class="transaction-details-modal__exchange-description">
              {{ exchangeRate.from.description }}
            </div>
          </div>
          <i class="icon icon-exchange-arrow transaction-details-modal__exchange-arrow" />
          <div class="transaction-details-modal__exchange-to">
            <div class="transaction-details-modal__exchange-value">
              {{ exchangeRate.to.value }}
            </div>
            <div class="transaction-details-modal__exchange-description">
              {{ exchangeRate.to.description }}
            </div>
          </div>
        </div>
        <div class="transaction-details-modal__exchange-details">
          <div
            v-for="exchangeDetail in transactionExchangeDetails"
            :key="exchangeDetail.translationKey"
            class="transaction-details-modal__exchange-details-item"
          >
            <div class="transaction-details-modal__exchange-details-item-heading">
              {{ $t(exchangeDetail.translationKey) }}
            </div>
            <div class="transaction-details-modal__exchange-details-item-value">
              {{ exchangeDetail.value }}
            </div>
          </div>
        </div>
      </div>
      <div class="transaction-details-modal__details">
        <div class="transaction-details-modal__details-header">
          <div class="transaction-details-modal__details-title">
            {{ $t('partner-portal.modals.transaction-details.title') }}
          </div>
          <div
            class="transaction-details-modal__close"
            @click="closeModal"
          >
            <i class="icon icon-close-black transaction-details-modal__close-image" />
          </div>
        </div>
        <div
          v-for="detail in transactionDetails"
          :key="detail.translationKey"
          class="transaction-details-modal__details-item"
        >
          <div class="transaction-details-modal__details-item-heading">
            {{ $t(detail.translationKey) }}
          </div>
          <div class="transaction-details-modal__details-item-value">
            <div
              v-if="detail.icon"
              class="transaction-details-modal__details-item-icon"
            >
              <i
                class="icon"
                :class="`icon-${detail.icon}`"
              />
            </div>
            <span>{{ detail.value }}</span>
          </div>
        </div>
        <div
          v-if="transaction.explorer_link"
          class="transaction-details-modal__details-item"
        >
          <div class="transaction-details-modal__details-item-heading">
            {{ $t('partner-portal.modals.transaction-details.link-to-explorer') }}
          </div>
          <div class="transaction-details-modal__details-item-value">
            <a
              :href="transaction.explorer_link"
              class="transaction-details-modal__details-link"
            >BlockExplorer</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getValueAndCurrency, isFiatToCrypto } from '@/utils/transactions';
import { formatDate } from '@/utils';

const TRANSACTION_COMPLETE_STATUS = 'completed';

export default {
  name: 'TransactionDetailsModal',

  props: {
    transaction: {
      type: Object,
      required: true,
    },

    isTransactionLoading: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    transactionDate() {
      const { created_at: createdAt } = this.transaction;
      return formatDate(createdAt);
    },

    exchangeRate() {
      const { amounts, from, to, gateway } = this.transaction;
      return {
        from: {
          value: getValueAndCurrency(amounts.spent_original),
          description: isFiatToCrypto(gateway) ? from.money_service_name : from.account,
        },
        to: {
          value: getValueAndCurrency(amounts.received_original),
          description: isFiatToCrypto(gateway) ? to.account : to.money_service_name,
        },
      };
    },

    transactionExchangeDetails() {
      const { amounts, from, to, exchange_rate: exchangeRate } = this.transaction;

      const currencyTo = getValueAndCurrency(exchangeRate.currency_to);
      const currencyFrom = getValueAndCurrency(exchangeRate.currency_from);

      const exchangeDetails = [{
        translationKey: 'partner-portal.modals.transaction-details.payment-method',
        value: from.money_service_name,
      }, {
        translationKey: 'partner-portal.modals.transaction-details.receiving-currency',
        value: to.money_service_name,
      }, {
        translationKey: 'partner-portal.modals.transaction-details.original-amount-spent',
        value: getValueAndCurrency(amounts.spent_original),
      }, {
        translationKey: 'partner-portal.modals.transaction-details.spent-in-usd',
        value: getValueAndCurrency(amounts.spent_fiat),
      }, {
        translationKey: 'partner-portal.modals.transaction-details.amount-received',
        value: getValueAndCurrency(amounts.received_original),
      }, {
        translationKey: 'partner-portal.modals.transaction-details.exchange-rate',
        value: `${currencyTo} @ ${currencyFrom}`,
      }];

      return exchangeDetails.filter(detail => !!detail.value);
    },

    transactionDetails() {
      const { id, user, to, amounts, status, gateway, hash, with_payment: paymentAttempts, payment_error_code: paymentErrorCode } = this.transaction;

      let partnersProfit = '';
      if (status === TRANSACTION_COMPLETE_STATUS) {
        partnersProfit = getValueAndCurrency(amounts.fee_original);
        const partnersProfitInUsd = getValueAndCurrency(amounts.fee_fiat);
        if (partnersProfitInUsd) partnersProfit += ` (${partnersProfitInUsd})`;
      }

      const transactionDetails = [{
        translationKey: 'partner-portal.modals.transaction-details.transaction-id',
        value: id,
      }, {
        translationKey: 'partner-portal.modals.transaction-details.gateway',
        value: this.$t(`partner-portal.gateways.${gateway}`),
      }, {
        translationKey: 'partner-portal.modals.transaction-details.user-email',
        value: user?.email,
      }, {
        translationKey: 'partner-portal.modals.transaction-details.user-country',
        value: user?.country?.name,
      }, {
        translationKey: 'partner-portal.modals.transaction-details.user-wallet-address',
        value: to.account,
      }, {
        translationKey: 'partner-portal.modals.transaction-details.destination-tag-memo',
        value: to.destination_tag,
      }, {
        translationKey: 'partner-portal.modals.transaction-details.original-amount-spent',
        value: getValueAndCurrency(amounts.spent_original),
      }, {
        translationKey: 'partner-portal.modals.transaction-details.amount-spent-in-usd',
        value: getValueAndCurrency(amounts.spent_fiat),
      }, {
        translationKey: 'partner-portal.modals.transaction-details.amount-received',
        value: getValueAndCurrency(amounts.received_original),
      }, {
        translationKey: 'partner-portal.modals.transaction-details.partners-profit',
        value: partnersProfit,
      }, {
        translationKey: 'partner-portal.modals.transaction-details.transaction-status',
        value: this.$t(`partner-portal.transaction.table.statuses.${status}`),
        icon: status,
      }, {
        translationKey: 'partner-portal.modals.transaction-details.reject-reason',
        value: paymentErrorCode,
      }, {
        translationKey: 'partner-portal.modals.transaction-details.payment-attempts',
        value: paymentAttempts ? this.$t('partner-portal.modals.yes') : this.$t('partner-portal.modals.no'),
      }, {
        translationKey: 'partner-portal.modals.transaction-details.transaction-hash',
        value: hash,
      }];

      return transactionDetails.filter(details => !!details.value);
    },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/mixins';

$mobile-modal-height: 92%;
$content-padding: 20px;
$modal-mobile-border-radius: 16px;
$modal-desktop-border-radius: 8px;

.transaction-details-modal {
  position: absolute;
  bottom: 0;
  background-color: $background-color;
  border-radius: $modal-mobile-border-radius;
  height: $mobile-modal-height;
  width: 100%;
  max-width: $desktop-breakpoint;
  overflow-y: auto;
  transition: all .3s;

  @include hide-scrollbar;

  @media screen and (min-width: $desktop-breakpoint) {
    max-height: 100%;
    height: auto;
    position: inherit;
    bottom: initial;
    border-radius: $modal-desktop-border-radius;
    overflow-y: auto;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 675px;
  }

  &__loader-icon {
    height: 32px;
    width: 32px;
    animation: spin 1s linear infinite;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: $desktop-breakpoint) {
      flex-direction: row;
    }
  }

  &__general {
    display: flex;
    background: $primary;
    flex: 1 0 576px;
    flex-direction: column;
  }

  &__header {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    line-height: 18px;
    padding: 28px 32px;
    color: $secondary;
    font-family: $font-regular;
    justify-content: space-between;
  }

  &__header-close-image,
  &__close-image {
    height: 24px;
    width: 24px;
    cursor: pointer;
  }

  &__header-close-image {
    display: flex;

    @media screen and (min-width: $desktop-breakpoint) {
      display: none;
    }
  }

  &__close-image {
    display: none;

    @media screen and (min-width: $desktop-breakpoint) {
      display: flex;
    }
  }

  &__exchange {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 76px 32px 124px;
    background: $primary;
  }

  &__exchange-value {
    font-size: 32px;
    line-height: 38px;
    padding-bottom: 4px;
  }

  &__exchange-description {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
    word-break: break-all;
  }

  &__exchange-arrow {
    width: 16px;
    height: 16px;
    margin: 16px 0;
  }

  &__exchange-details {
    display: flex;
    flex-wrap: wrap;
    background: $primary;
    padding: 0 32px 28px;
    // Safari < v15 fix;
    flex: 1 0 auto;
  }

  &__exchange-details-item {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    padding-top: 24px;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      border-top: 1px solid $secondary-grey;
    }
  }

  &__exchange-details-item-heading {
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 4px;
    text-transform: uppercase;
  }

  &__exchange-details-item-value {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
  }

  &__details {
    display: flex;
    // Safari < v15 fix
    flex: 1 0 auto;
    background: $background-color;
    padding: 32px;
    box-sizing: border-box;
    flex-direction: column;

    @media screen and (min-width: $desktop-breakpoint) {
      flex: 0 0 416px;
      min-height: 657px;
      border-radius: 0;
    }
  }

  &__details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__details-item {
    display: flex;
    flex-direction: row;
    min-height: 42px;
    padding: 12px 0;
    width: 100%;
    align-items: flex-start;
    font-family: $font-regular;
    border-top: 1px solid $secondary-grey;

    @media screen and (min-width: $desktop-breakpoint) {
      max-width: 352px;
    }
  }

  &__details-title {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__details-link {
    color: $secondary;
    display: flex;
  }

  &__details-item-heading,
  &__details-item-value {
    flex: 0 0 50%;
    word-break: break-all;
    font-family: $font-regular;
    font-size: 15px;
    line-height: 18px;
  }

  &__details-item-heading {
    word-break: break-word;
    padding-right: 10px;
  }

  &__details-item-value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__details-item-icon {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }
}
</style>
