<template>
  <div class="table">
    <div class="table__head">
      <div class="table__row">
        <div class="table__row-parent">
          <div
            v-for="column in columns"
            :key="column.name"
            :class="['table__th', `table__th--${column.key}` ]"
          >
            {{ column.name }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="rowsHaveFunction"
      class="table__body"
    >
      <div
        v-for="row in rows"
        :key="row.id"
        class="table__row table__row--with-function"
        @click="row.rowFunction"
      >
        <div
          class="table__row-parent"
        >
          <div
            v-for="column in normalizedColumns"
            :key="column.key"
            :class="['table__td', `table__td--${column.key}` ]"
          >
            <component
              :is="column.component"
              :value="row"
              :format="column.format"
              :is-child-opened="isRowVisible(row.id)"
              v-on="$listeners"
            />
          </div>
        </div>
        <div
          v-if="hasCollapsibleBlock"
          class="table__row-child"
        >
          <slot
            v-if="isRowVisible(row.id)"
            :row="row"
          />
        </div>
      </div>
    </div>

    <div
      v-else
      class="table__body"
    >
      <div
        v-for="row in rows"
        :key="row.id"
        class="table__row"
      >
        <div
          class="table__row-parent"
        >
          <div
            v-for="column in normalizedColumns"
            :key="column.key"
            :class="['table__td', `table__td--${column.key}` ]"
          >
            <component
              :is="column.component"
              :value="row"
              :format="column.format"
              :is-child-opened="isRowVisible(row.id)"
              v-on="$listeners"
            />
          </div>
        </div>
        <div
          v-if="hasCollapsibleBlock"
          class="table__row-child"
        >
          <slot
            v-if="isRowVisible(row.id)"
            :row="row"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextCell from './TextCell';
import { attachShadowToTable, removeAllListeners } from './Table.utils';

export default {
  name: 'Table',
  props: {
    columns: {
      type: Array,
      required: true,
    },
    showScrollShadow: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    expandableComponent: {
      type: Object,
    },
    expandableOptions: {
      type: Object,
    },
  },

  data: () => ({
    selectedRowId: null,
  }),

  provide() {
    return {
      'Table.ToggleSubTable': this.toggleSubTable,
    };
  },

  computed: {
    normalizedColumns() {
      const normalizedCols = this.columns.map(column => {
        column.component = column.component || TextCell;
        column.format = column.format || (row => row[column.key]);
        return column;
      });

      return normalizedCols;
    },

    hasCollapsibleBlock() {
      return this.$scopedSlots.default;
    },

    rowsHaveFunction() {
      const { rows } = this;
      return rows.some(row => !!row.rowFunction);
    },
  },

  mounted() {
    if (this.showScrollShadow) attachShadowToTable(this.$el);
  },

  beforeDestroy() {
    removeAllListeners();
  },

  methods: {
    toggleSubTable(id = null) {
      if (id) this.selectedRowId = this.selectedRowId === id ? null : id;
      if (!id) this.selectedRowId = id;
    },

    isRowVisible(id) {
      if (!id) return false;
      return this.selectedRowId === id;
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';

.table {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  font-family: $font-regular;
  overflow-x: auto;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;

  &--right-shadow {
    box-shadow: inset -7px 0 12px -7px $checkbox-border-hover-color;
  }

  &--left-shadow {
    box-shadow: inset 7px 0 9px -7px $checkbox-border-hover-color;
  }

  &--both-shadow {
    box-shadow: inset 7px 0 9px -7px $checkbox-border-hover-color,
      inset -7px 0 9px -7px $checkbox-border-hover-color;
  }

  &__head {
    width: 100%;
    display: flex;
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__row {
    width: 100%;
    flex-direction: column;

    &--with-function {
      cursor: pointer;
    }

    &-parent,
    &-child {
      display: flex;
      width: 100%;
    }
  }

  &__th {
    display: flex;
    font-size: 12px;
    line-height: 16px;
    height: 44px;
    align-items: center;
    color: $table-th-color;
    font-family: $font-bold;
    text-transform: uppercase;
    border-bottom: 1px solid $secondary-grey;
    padding: 8px 0;
  }

  &__td {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    height: 44px;
    align-items: center;
    color: $secondary;
    word-break: break-all;
    border-bottom: 1px solid $secondary-grey;
    padding: 8px 0;
  }

  &__internal-row {
    flex: 1 0 100%;
  }
}
</style>
