<template>
  <main class="forbiden">
    <aside class="sidebar">
      <ManagerCard class="sidebar__card sidebar__card--secondary" />
      <DevelopersCard class="sidebar__card sidebar__card--secondary" />
    </aside>
  </main>
</template>

<script>
export default {
  name: 'Forbiden',
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/mixins";

.forbiden {
  flex-wrap: wrap;
}

.sidebar {
  &__card {
    @include sidebar-card();
  }
}
</style>
