import store from '@/store';

const profileSettingsRoute = {
  link: '/company/profile',
  name: 'partner-portal.header.company-routes.profile',
  icon: 'profile',
  testId: 'company-menu-profile',
};

const payoutsRoute = {
  link: '/payouts',
  name: 'partner-portal.header.basic-routes.payouts',
  icon: 'payouts',
  testId: 'company-menu-payouts',
};

const payoutSettingsRoute = {
  link: '/company/payment',
  name: 'partner-portal.header.company-routes.payout',
  icon: 'payouts-settings',
  testId: 'company-menu-payout-settings',
};

const changePasswordRoute = {
  link: '/company/change-password',
  name: 'partner-portal.header.company-routes.change-password',
  icon: 'change-password',
  testId: 'company-menu-change-password',
};

class NavigationTreeBuilder {
  navigationTree = []

  constructor(getters) {
    this.getters = getters;
  }

  addProfileSettings() {
    const isProfileSettingsPageVisible = this.getters['featureFlagsStore/isProfileSettingsPageVisible'];

    if (isProfileSettingsPageVisible) this.navigationTree.push(profileSettingsRoute);

    return this;
  }

  addPayoutsRoute() {
    const isViewOnlyMonitoringAllowed = this.getters['rolesStore/isViewOnlyMonitoringAllowed'];
    const isPayoutsMonitoringAllowed = store.getters['rolesStore/isPayoutsMonitoringAllowed'];

    const canShowPayoutsRoute = isViewOnlyMonitoringAllowed || isPayoutsMonitoringAllowed;

    if (canShowPayoutsRoute) this.navigationTree.push(payoutsRoute);

    return this;
  }

  addPayoutsSettings() {
    const isViewOnlyMonitoringAllowed = this.getters['rolesStore/isViewOnlyMonitoringAllowed'];
    const isPayoutsMonitoringAllowed = this.getters['rolesStore/isPayoutsMonitoringAllowed'];

    const canShowPayoutsSettingsRoute = isViewOnlyMonitoringAllowed || isPayoutsMonitoringAllowed;

    if (canShowPayoutsSettingsRoute) this.navigationTree.push(payoutSettingsRoute);

    return this;
  }

  addChangePassword() {
    this.navigationTree.push(changePasswordRoute);

    return this;
  }

  getNavigation() {
    return this.navigationTree;
  }
}

export const buildNavigationTree = () => {
  const { getters } = store;
  const navigationBuilder = new NavigationTreeBuilder(getters);

  return navigationBuilder
    .addPayoutsRoute()
    .addPayoutsSettings()
    .addProfileSettings()
    .addChangePassword()
    .getNavigation();
};
