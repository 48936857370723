// TODO: Remove mock adapter when we're finished with mocks and all data is
// being received from BE of the partner portal.
import MockAdapter from 'axios-mock-adapter';

const noop = config => config;

function getMockedMethod(method) {
  switch (method) {
    case 'POST':
      return this.onPost.bind(this);
    case 'GET':
      return this.onGet.bind(this);
    case 'PUT':
      return this.onPut.bind(this);
    case 'PATCH':
      return this.onPatch.bind(this);
    case 'DELETE':
      return this.onDelete.bind(this);
    default:
      throw new Error(`Unknown method ${method}`);
  }
}

export default class AxiosConfigurator {
  constructor(axios) {
    this.axios = axios;
    this.mockAdapter = new MockAdapter(this.axios, {
      onNoMatch: 'passthrough',
    });
  }

  create(config) {
    const subModule = this.axios.create(config);
    return new AxiosConfigurator(subModule);
  }

  eject() {
    return this.axios;
  }

  attach(...interceptors) {
    interceptors.forEach(({ request, response }) => {
      if (!request && !response) {
        throw new Error(
          'Interceptors should contain \'request\' and \'response\' function',
        );
      }

      if (request) {
        const { fulfilled = noop, rejected = noop } = request;
        this.axios.interceptors.request.use(fulfilled, rejected);
      }

      if (response) {
        const { fulfilled = noop, rejected = noop } = response;
        this.axios.interceptors.response.use(fulfilled, rejected);
      }

      return this;
    });
  }

  mock(configsList) {
    configsList.forEach(({ url, method, body = {}, responseFn }) => {
      const request = getMockedMethod.call(this.mockAdapter, method);
      request(url, body).reply(async config => {
        const patchedConfig = Object.assign(config, {
          isMocked: true,
        });

        try {
          const data = await responseFn(patchedConfig);

          return data;
        } catch (error) {
          return [400, {
            success: false,
          }];
        }
      });
    });

    return this;
  }
}
