import NavigationGuardService from '@/services/NavigationGuardService';

export default {
  install(Vue, { store, router }) {
    const NavigationGuard = new NavigationGuardService(router, store);

    router.beforeEach((to, from, next) => NavigationGuard.setRedirectRoute(to, from, next));

    router.beforeEach((to, from, next) => NavigationGuard.goToAuthRoute(to, from, next));

    router.beforeEach((to, from, next) => NavigationGuard.stayOnCurrentRoute(to, from, next));

    router.beforeEach((to, from, next) => NavigationGuard.goToAllowedRoute(to, from, next));

    // Simple watcher in case user has logged out
    store.watch(
      (state, getters) => {
        const isUserSignedIn = getters['userStore/isUserSignedIn'];
        const isUserLoading = getters['userStore/isUserLoading'];
        const isRolesListEmpty = getters['rolesStore/isRolesListEmpty'];
        const isFeatureFlagsListEmpty = getters['featureFlagsStore/isFeatureFlagsListEmpty'];

        return {
          isUserSignedIn,
          isUserLoading,
          isRolesListEmpty,
          isFeatureFlagsListEmpty,
        };
      },
      updatedState => {
        NavigationGuard.handleUserStoreStateUpdate(updatedState);

        if (!updatedState.isUserSignedIn && !updatedState.isUserLoading) {
          router.push({
            name: 'auth',
          }).catch(() => {});
        }
      },
      {
        immediate: true,
      },
    );
  },
};
