<template>
  <footer
    class="footer__copyright"
    :class="{ 'footer__copyright--black': !isUserSignedIn }"
  >
    {{ $t('partner-portal.footer.address', { currentYear }) }}
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppFooter',

  computed: {
    ...mapGetters('userStore', {
      isUserSignedIn: 'isUserSignedIn',
    }),
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.footer__copyright {
  font-size: 13px;
  line-height: 16px;
  font-family: $font-regular;
  color: $secondary-text-color;

  &--black {
    text-align: center;
    color: $secondary;
  }
}
</style>
