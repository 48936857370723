<template>
  <!-- TODO: Find out why input[type="number"] isn't hiding it's arrows via appearance tag? -->
  <div
    v-if="isServiceActive"
    class="input-cell"
    :class="{
      'input-cell--empty': isEmptyCell,
    }"
  >
    <Tooltip
      v-if="componentHasError"
      left
      class="input-cell__error-tooltip"
    >
      <span v-if="childHasError">{{ $t('partner-portal.errors.check-child-element') }}</span>
      <span v-else>{{ value.error }}</span>
    </Tooltip>

    <input
      v-model="inputCellValue"
      type="text"
      inputmode="decimal"
      class="input-cell__input"
      :class="{ 'input-cell__input--error': componentHasError }"
      @change="updatePartnerFee"
      @keypress="handleNumericOnly"
      @blur="formatInputCellValue"
    >

    <div
      v-if="childrenElementsDiffer"
      class="input-cell__tooltip"
    >
      <div
        class="input-cell__tooltip-trigger"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
      >
        *
      </div>

      <div
        v-if="isTooltipVisible"
        class="input-cell__tooltip-content"
        :class="{
          'input-cell__tooltip-content--parent': !isChild,
        }"
      >
        {{ $t('partner-portal.errors.values-differ-inside-table') }}
      </div>
    </div>
  </div>
</template>

<script>
import inputCell from '@/views/WidgetSettings/Cell/InputCell/InputCell.utils';

import Tooltip from '@/components/Tooltip';

export default {
  name: 'InputCell',

  components: {
    Tooltip,
  },

  mixins: [inputCell],

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    isTooltipVisible: false,
  }),

  computed: {
    componentHasError() {
      return this.isError || this.childHasError;
    },

    childrenElementsDiffer() {
      const { parentId = false } = this.value;
      if (parentId) return false;

      const { base_currencies: baseCurrencies } = this.value;
      const firstActiveElement = baseCurrencies.find(baseCurrency => {
        const { is_available: isAvailable, partners_fee: fee } = baseCurrency;
        return isAvailable && !!fee;
      });

      // Scenario when you have removed fees leaving parent input empty
      if (!firstActiveElement) return false;

      const randomFeeFromCurrencies = firstActiveElement.partners_fee;
      const anyOtherElementsDontMatch = baseCurrencies.some(baseCurrency => {
        const { is_available: isAvailable, partners_fee: fee } = baseCurrency;
        if (!fee) return false;

        const hasDifferentFee = randomFeeFromCurrencies !== fee;
        return isAvailable && hasDifferentFee;
      });

      return anyOtherElementsDontMatch;
    },

    isError() {
      const { error } = this.value;
      return !!error;
    },

    childHasError() {
      const { parentId = false } = this.value;
      if (parentId) return false;

      const { base_currencies: baseCurrencies } = this.value;
      return baseCurrencies.some(({ error }) => !!error);
    },

    isServiceActive() {
      const { is_available: isAvailable } = this.value;
      return isAvailable;
    },

    isEmptyCell() {
      const { partners_fee: fee } = this.value;
      return !fee.length;
    },

    isChild() {
      const { parentId = null } = this.value;
      if (parentId) return true;

      return false;
    },
  },

  watch: {
    'value.partners_fee': {
      handler(updatedPartnersFee) {
        this.inputCellValue = updatedPartnersFee;
      },
      immediate: true,
    },
  },

  methods: {
    handleMouseEnter() {
      this.isTooltipVisible = true;
    },

    handleMouseLeave() {
      this.isTooltipVisible = false;
    },

    updatePartnerFee() {
      this.formatInputCellValue();
      const feeValue = this.inputCellValue;
      const { id = null, parentId = null, currency = null } = this.value;

      this.$emit('set-partner-fee', {
        id, parentId, code: currency?.code, fee: feeValue,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

$empty-percentage-color: $label-info-color;

.input-cell {
  position: relative;
  display: flex;
  align-items: center;

  &__error-tooltip {
    margin-right: 3px;

    ::v-deep .tooltip__content {
      width: 100px;
      word-break: break-word;
    }
  }

  &__tooltip {
    display: flex;
    position: absolute;
    right: -15px;
    top: 0;
    height: 100%;
    cursor: pointer;
  }

  &__tooltip-trigger {
    color: $error-color;
    width: 15px;
    font-size: 18px;
    padding-top: 4px;
    text-align: center;
  }

  &__tooltip-content {
    position: absolute;
    left: 0;
    bottom: calc(100% + 2px);
    transform: translateX(-50%);
    border-radius: 6px;
    background: $tooltip-background;
    box-shadow: $tooltip-shadow;
    color: $primary;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 16px;
    word-break: break-word;
    z-index: 1;
    width: 150px;

    &--parent {
      width: 200px;
      height: 64px;
    }
  }

  &__input {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
    padding: 0 20px 0 0;
    box-sizing: border-box;
    width: 60px;
    height: 32px;
    text-align: right;
    appearance: textfield;
    border: 1px solid $input-border-grey;
    border-radius: 6px;
    z-index: 1;

    &--error {
      border-color: $error-color;
    }

    &:focus {
      outline: none;
    }
  }

  &:after {
    content: '%';
    display: flex;
    position: absolute;
    font-size: 15px;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  &--empty {
    color: $empty-percentage-color;
  }
}
</style>
