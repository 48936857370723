<template>
  <section class="manager">
    <header class="manager__info">
      <div class="manager__photo-container">
        <img
          v-if="avatar"
          :srcset="`${avatar}, ${avatar} 2x`"
          :src="avatar"
          class="manager__photo"
        >
        <img
          v-else
          class="manager__photo"
          src="@/assets/images/manager-avatar.svg"
        >
      </div>
      <h2 class="manager__title">
        {{ name }}
      </h2>
      <span class="manager__description">
        {{ $t('partner-portal.manager-card.description') }}
      </span>
    </header>

    <div
      v-for="(route, index) in routes"
      :key="`desktop-${route.name}-${index}`"
      class="manager__contact"
    >
      <a
        :href="route.link"
        :target="route.target"
        class="manager__contact-link"
      >
        <i :class="`icon icon-${route.icon}-black manager__icon`" />
        <p class="manager__contact-link-text">{{ route.name }}</p>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ManagerCardDesktop',
  props: {
    avatar: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    routes: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.manager {
  display: none;

  border: 1px solid $sidebar-secondary-card-border-color;
  padding: 32px 40px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px $secondary-border-color;

  @media (min-width: $tablet-breakpoint) {
    display: block;
  }

  &__info {
    font-size: 15px;
    text-align: center;

    margin-bottom: 24px;
  }

  &__photo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__photo {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    margin-bottom: 16px;
  }

  &__title {
    font-size: 18px;
    margin-bottom: 4px;
  }

  &__description {
    font-family: $font-regular;
  }

  &__contact {
    font-family: $font-regular;
    margin-bottom: 12px;
  }

  &__contact-link {
    display: flex;
    align-items: center;
    color: $secondary;
    text-decoration: none;
  }

  &__contact-link-text {
    display: flex;
    margin: 0;
    word-break: break-all;
    flex: 1 0;
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}
</style>
