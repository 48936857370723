<template>
  <div class="profit-and-turnover-chart">
    <canvas
      ref="canvas"
      class="profit-and-turnover-chart__canvas"
    />
    <div
      v-if="isLegendVisible"
      class="profit-and-turnover-chart__legends"
    >
      <div
        v-for="legend in chartOptions"
        :key="legend.chartColor"
        class="profit-and-turnover-chart__legend"
      >
        <span
          class="profit-and-turnover-chart__legend-color"
          :style="{ 'background-color': legend.chartColor }"
        />
        <span class="profit-and-turnover-chart__legend-name">{{ legend.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import { createGradient, formatSeriesForChart } from './ChartCanvas.utils';
import { COMMON_CANVAS_OPTIONS, COMMON_CANVAS_STYLES, STACKED_CHART_SCALES } from './ChartCanvas.constants';

Chart.register(...registerables);

export default {
  name: 'ProfitAndTurnoverChart',

  props: {
    chartData: {
      type: Array,
      default: () => [],
      required: true,
    },

    chartOptions: {
      type: Array,
      default: () => [],
      required: true,
    },

    isLegendVisible: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    chart: null,
  }),

  watch: {
    chartData(updatedChartData) {
      const { labels } = formatSeriesForChart(updatedChartData[0]);
      this.chart.data.labels = labels;
      this.chart.data.datasets.forEach((dataset, index) => {
        const { data } = formatSeriesForChart(updatedChartData[index]);
        dataset.data = data;
      });

      this.chart.update();
    },
  },

  mounted() {
    const { canvas } = this.$refs;
    const ctx = canvas.getContext('2d');
    const { labels } = formatSeriesForChart(this.chartData[0]);

    const datasets = this.chartData.map((dataObject, index) => {
      const { label, chartColor, styles } = this.chartOptions[index];
      const { data } = formatSeriesForChart(dataObject);

      const mergedStyles = {
        ...COMMON_CANVAS_STYLES, ...styles,
      };

      return {
        label,
        data,
        ...mergedStyles,
        borderColor: chartColor,
        backgroundColor(context) {
          return createGradient(context, chartColor);
        },
        yAxisID: `y${index}`,
      };
    });

    const chartSettings = {
      labels,
      datasets,
    };

    const chartOptions = {
      ...COMMON_CANVAS_OPTIONS, ...STACKED_CHART_SCALES,
    };

    const [turnoverData] = this.chartData;
    const turnoverValues = turnoverData.map(item => +item.y);
    const turnoverHighestValue = Math.max(...turnoverValues);
    const turnoverMaxPoint = Math.ceil(turnoverHighestValue / 100) * 100;
    const profitMaxPoint = turnoverMaxPoint / 40;

    chartOptions.scales.y0.suggestedMax = turnoverMaxPoint;
    chartOptions.scales.y1.suggestedMax = profitMaxPoint;

    this.chart = new Chart(ctx, {
      type: 'line',
      data: chartSettings,
      options: chartOptions,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.profit-and-turnover-chart {
  width: 100%;
  max-height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__canvas {
    width: 100%;
  }

  &__legends {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
  }

  &__legend {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-right: 20px;
    }
  }

  &__legend-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  &__legend-name {
    margin-left: 8px;
  }
}
</style>
