<template>
  <section class="developers">
    <header class="developers__header">
      <div class="developers__pic" />
      <h2 class="developers__title">
        {{ $t('partner-portal.developers-card.title') }}
      </h2>
    </header>

    <div
      v-if="isReadmeUrlExist"
      class="developers__item"
    >
      <i class="icon icon-book-black developers__icon" />
      <a
        target="_blank"
        :href="readmeUrl"
        class="developers__item-text link"
      >
        {{ $t('partner-portal.header.developer-routes.api-reference') }}
      </a>
    </div>

    <div
      v-if="shouldDisplayCopyButton"
      class="developers__item"
      @click="copyApiKey"
    >
      <i class="icon icon-copy-black developers__icon" />
      <p class="developers__item-text link">
        {{ $t('partner-portal.menus.developers.copy') }} {{ activeProductName }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DevelopersCardDesktop',

  props: {
    readmeUrl: {
      type: String,
      default: '',
    },
    shouldDisplayCopyButton: {
      type: Boolean,
      required: true,
    },
    copyApiKey: {
      type: Function,
      required: true,
    },
    activeProductName: {
      type: String,
      default: '',
    },
  },

  computed: {
    isReadmeUrlExist() {
      return this.readmeUrl.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.developers {
  display: none;
  border: 1px solid $sidebar-secondary-card-border-color;
  padding: 32px 40px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px $secondary-border-color;

  @media (min-width: $tablet-breakpoint) {
    display: block;
  }

  &__header {
    font-size: 15px;
    text-align: center;

    margin-bottom: 24px;
  }

  &__title {
    margin: 0;
    font-size: 18px;
  }

  &__pic {
    display: inline-block;
    width: 56px;
    height: 56px;
    border-radius: 50%;

    margin-bottom: 16px;

    background: url(~@/assets/images/developers.svg) center no-repeat;
    background-size: cover;
  }

  &__item {
    font-family: $font-regular;
    margin-top: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__item-text {
    margin: 0;
    line-height: 22px;
    text-decoration: underline;
    text-decoration-color: rgba($secondary, 0.15);
    text-underline-position: under;
    text-decoration-thickness: 1.5px;
    border-bottom: none;

  }

  &__icon {
    height: 16px;
    min-width: 16px;
    max-width: 16px;
    margin-right: 8px;
  }
}
</style>
