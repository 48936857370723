<template>
  <div
    class="profit-cell"
    :class="{ 'profit-cell--bold': greaterThanZero }"
  >
    {{ amount }}
  </div>
</template>

<script>
export default {
  name: 'CryptoAmountCell',
  props: {
    value: {
      type: Object,
      required: true,
    },
    format: {
      type: Function,
      default: value => value,
    },
  },
  computed: {
    amount() {
      return this.format(this.value);
    },
    greaterThanZero() {
      const numberedAmount = parseFloat(this.amount, 10);
      return +numberedAmount > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.profit-cell {
  font-family: $font-regular;
  &--bold {
    font-family: $font-bold;
  }
}
</style>
