import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

export const initSentry = router => {
  Sentry.init({
    Vue,
    dsn: SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracesSampleRate: 1.0,
        ignoreErrors: ['ReportingObserver [deprecation]', 'Request aborted', 'Non-Error promise rejection captured', 'Non-Error exception captured', 'undefined'],
        ignoreUrls: [
          /extensions\//i,
          /^chrome:\/\//i,
          /^chrome-extensions:\/\//i,
          /^safari-extension:\/\//i,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
  });
};
