import Vue from 'vue';

import store from '@/store';

const ProductsDesktop = () => import('./Products.desktop.vue');
const ProductsMobile = () => import('./Products.mobile.vue');

const setActiveProduct = product => store.commit('accountProductsStore/setActiveProduct', product);

export default Vue.component('Products', {
  functional: true,
  render(h) {
    const products = store.getters['accountProductsStore/products'];
    const activeProduct = store.getters['accountProductsStore/activeProduct'];

    const accountProductsProps = {
      props: {
        products,
        activeProduct,
        setActiveProduct,
      },
    };

    return h('div', [
      h(ProductsDesktop, accountProductsProps),
      h(ProductsMobile, accountProductsProps),
    ]);
  },
});
