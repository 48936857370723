<template>
  <div class="top-charts">
    <ToplistChart
      class="top-charts__section"
      :title="$t('partner-portal.top-charts.titles.top-cryptos')"

      :items="topCryptosList"
      :report-url="topCryptosReportUrl"

      :show-back-button="true"
      :show-load-more="canLoadMoreTopCryptos"
      :show-download-csv="true"

      :is-loading="isLoadingTopCryptos"
      :is-loading-more="isTopCryptosLoadingMore"
      :is-loading-report="isLoadingReport"

      @load-more="getMoreTopCryptos"
      @download-report="downloadTopCryptosReport"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ToplistChart from '@/components/ToplistChart';

import { getLastMonth } from '@/utils/charts';

export default {
  name: 'TopChartsCryptos',

  components: {
    ToplistChart,
  },

  computed: {
    ...mapGetters('topCryptosStore', {
      topCryptosList: 'topCryptosList',

      isLoadingTopCryptos: 'isLoadingTopCryptos',
      isTopCryptosLoadingMore: 'isTopCryptosLoadingMore',
      isLoadingReport: 'isLoadingReport',

      topCryptosReportUrl: 'reportUrl',

      canLoadMoreTopCryptos: 'canLoadMoreTopCryptos',
    }),
  },

  mounted() {
    const lastMonthRange = getLastMonth();

    this.setDateFilter(lastMonthRange);
    this.loadTopCryptos();
  },

  destroyed() {
    this.unsubscribeFromTopCryptosReport();
    this.clearStore();
  },

  methods: {
    ...mapActions('topCryptosStore', {
      clearStore: 'clearStore',

      setDateFilter: 'setDateFilter',

      loadTopCryptos: 'loadTopCryptos',
      getMoreTopCryptos: 'getMoreTopCryptos',
      downloadTopCryptosReport: 'downloadTopCryptosReport',
      unsubscribeFromTopCryptosReport: 'unsubscribeFromTopCryptosReport',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.top-charts {
  flex-grow: 1;
  width: 100%;
}
</style>
