import { TIMERANGES } from '@/components/DateFilters';

const dayOffset = 24 * 60 * 60 * 1000;

export const getDateInISO = (date, dateBegin = false) => {
  const hours = dateBegin ? 0 : 23;
  const minutes = dateBegin ? 0 : 59;
  const seconds = dateBegin ? 0 : 59;
  const milliseconds = dateBegin ? 0 : 999;

  const dateInstance = new Date(date);

  dateInstance.setUTCHours(hours);
  dateInstance.setUTCMinutes(minutes);
  dateInstance.setUTCSeconds(seconds);
  dateInstance.setUTCMilliseconds(milliseconds);

  const isoString = dateInstance.toISOString();
  return isoString;
};

export const getLastMonth = () => {
  const now = new Date();

  now.setUTCHours(23);
  now.setUTCMinutes(59);
  now.setUTCSeconds(59);

  const monthAgo = new Date(new Date().getTime() - 30 * dayOffset);

  monthAgo.setUTCHours(0);
  monthAgo.setUTCMinutes(0);
  monthAgo.setUTCSeconds(0);

  return {
    from: monthAgo,
    to: now,
  };
};

const getTodayRange = today => ({
  from: getDateInISO(today, true),
  to: getDateInISO(today, false),
});

const getYesterdaysRange = () => {
  const yesterday = new Date(new Date().getTime() - dayOffset);
  return {
    from: getDateInISO(yesterday, true),
    to: getDateInISO(yesterday, false),
  };
};

const getThisWeekRange = today => {
  let todaysDayOfTheWeek = today.getDay() - 1;
  if (todaysDayOfTheWeek < 0) todaysDayOfTheWeek = 6;

  const startOfTheWeekOffset = dayOffset * todaysDayOfTheWeek;
  const weekAgo = new Date(new Date().getTime() - startOfTheWeekOffset);
  return {
    from: getDateInISO(weekAgo, true),
    to: getDateInISO(today, false),
  };
};

const getThisMonthsRange = today => {
  const todaysDayOfTheMonth = today.getDate() - 1;
  const startOfTheMonthOffset = dayOffset * todaysDayOfTheMonth;
  const monthAgo = new Date(new Date().getTime() - startOfTheMonthOffset);
  return {
    from: getDateInISO(monthAgo, true),
    to: getDateInISO(today, false),
  };
};

export const convertDateIntoChartISO = (filtersOptions = null) => {
  const { type, dateRange } = filtersOptions;
  const today = new Date();

  if (!filtersOptions) return getThisWeekRange(today);
  if (type === TIMERANGES.TODAY) return getTodayRange(today);
  if (type === TIMERANGES.YESTERDAY) return getYesterdaysRange();
  if (type === TIMERANGES.WEEK) return getThisWeekRange(today);
  if (type === TIMERANGES.MONTH) return getThisMonthsRange(today);

  if (type === TIMERANGES.CUSTOM) {
    const { start, end } = dateRange;
    return {
      from: getDateInISO(start, true),
      to: getDateInISO(end, false),
    };
  }

  return null;
};
