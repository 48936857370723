<template>
  <section class="balance-info">
    <div
      class="balance-info__wrapper"
      :class="{ 'balance-info__wrapper--pointer': isExpandable }"
      @click="toggleInfo"
    >
      <h2 class="balance-info__header">
        {{ title }}
      </h2>

      <div
        v-if="isLoading"
        class="icon icon-spinner-black balance-info__loader"
      />

      <div
        v-else
        class="balance-info__total-amount"
      >
        {{ amount }}

        <button
          v-if="isExpandable"
          type="button"
          class="icon icon-caret balance-info__toggler"
          :class="{
            'balance-info__toggler--opened': isInfoVisible,
          }"
        />
      </div>
    </div>
    <div
      v-if="isInfoVisible"
      class="balance-info__details"
    >
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'SidebarBalanceInfo',

  props: {
    title: {
      type: String,
      required: true,
    },

    amount: {
      type: String,
      required: true,
    },

    isExpandable: {
      type: Boolean,
    },

    isLoading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    isInfoVisible: false,
  }),

  methods: {
    toggleInfo() {
      if (this.isExpandable) this.isInfoVisible = !this.isInfoVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

$common-balance-background: $primary;

.balance-info {
  background-color: $common-balance-background;
  padding: 20px 24px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px $secondary-border-color;
  min-height: 81px;

  @media screen and (min-width: $tablet-breakpoint) {
    min-height: 95px;
  }

  &__wrapper {
    &--pointer {
      cursor: pointer;
    }
  }

  &__loader {
    height: 28px;
    width: 28px;
    animation: spin 1s linear infinite;
  }

  @media (min-width: $tablet-breakpoint) {
    margin-bottom: 16px;
  }

  &__details {
    font-family: $font-regular;
    font-size: 15px;
    margin-top: 5px;
  }

  &__toggler {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: auto;
    transform: rotate(-180deg);

    &--opened {
      transform: none;
    }
  }

  &__header {
    font-size: 15px;
    line-height: 18px;
    margin: 0 0 9px 0;
  }

  &__total-amount {
    font-size: 22px;
    line-height: 26px;

    font-family: $font-bold;

    display: flex;
    align-items: center;

    @media screen and (min-width: $tablet-breakpoint) {
      font-size: 24px;
      line-height: 28px;
    }
  }
}
</style>
