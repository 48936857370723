<template>
  <section
    class="unpaid-balance-details"
  >
    <div class="unpaid-balance-details__currencies-list-label">
      {{ $t('partner-portal.common.including') }}
    </div>

    <div
      v-for="balance in balances"
      :key="balance.currency"
      class="unpaid-balance-details__currency-amount"
    >
      <i
        class="icon unpaid-balance-details__currency-icon"
        :class="`icon-${balance.icon}`"
      />
      {{ balance.amount }} {{ balance.currency }}
    </div>

    <a
      v-if="isAllCurrenciesLinkVisible"
      href="#"
      class="link unpaid-balance-details__all-currencies"
    >
      {{ $t('partner-portal.common.all-currencies') }}
    </a>
  </section>
</template>

<script>

const formatBalanceDetails = balances => {
  if (!balances) return [];
  const nonZeroBalances = balances.filter(balance => Number(balance.amount) > 0);
  return nonZeroBalances.map(balance => ({
    ...balance,
    icon: balance.currency.toLowerCase(),
  }));
};

export default {
  name: 'SidebarBalanceDetails',

  props: {
    items: {
      type: Array,
      required: true,
    },
    isAllCurrenciesLinkVisible: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    balances() {
      return formatBalanceDetails(this.items);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.unpaid-balance-details {
  &__currencies-list-label {
    padding: 12px 0;
    border-bottom: 1px solid $list-item-border-color;
  }

  &__currency-amount {
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid $list-item-border-color;
  }

  &__currency-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  &__all-currencies {
    margin-top: 16px;
  }
}
</style>
