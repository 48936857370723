import { apiClient } from '@/network';
import LoggerService from '@/services/LoggerService';

import { getDateInISO } from '@/utils/charts';
import { buildQueryWithFilters, buildBodyRequestForReport } from '@/utils/filters';
import ReportService from '@/services/ReportService';
import NotificationService from '@/services/NotificationService';
import SubscriptionService from '@/services/SubscriptionService';

const TRANSACTIONS_TYPE_REPORT = 'transactions';
const ANALYTICS_TRANSACTIONS_SUBSCRIPTION_KEY = 'analytics-transactions';

const BASE_TRANSACTIONS_LIMIT = 10;

export default {
  namespaced: true,

  state: {
    filters: {
      from: null,
      to: null,
      countries: [],
      gateways: [],
      currencies: [],
      payment_methods: [],
    },

    aggregation: {
      isLoading: false,

      sales: null,
      profit: null,
      transactions: null,
    },

    profitAndTurnoverChart: {
      isLoading: false,

      profitTitle: null,
      profitChartData: [],

      turnoverTitle: null,
      turnoverChartData: null,
    },

    transactionsChart: {
      isLoading: false,

      title: null,
      data: [],
    },

    successRateChart: {
      isLoading: false,

      title: null,
      data: [],
    },

    salesFunnelChart: {
      isLoading: false,

      title: null,
      data: [],
    },

    topCountries: {
      isLoading: false,
      data: [],
    },
    topCurrencies: {
      isLoading: false,
      data: [],
    },

    transactions: {
      isReportLoading: false,
      reportUrl: null,

      isLoading: false,
      isLoadingMore: false,

      data: [],
      meta: null,
    },
  },

  getters: {
    selectedCountriesList: state => state.filters.countries,
    selectedDateRange: state => ({
      start: state.filters.from, end: state.filters.to,
    }),
    selectedGatewaysList: state => state.filters.gateways,
    selectedCurrenciesList: state => state.filters.currencies,
    selectedPaymentMethodsList: state => state.filters.payment_methods,

    aggregatedSales: state => state.aggregation.sales,
    aggregatedProfit: state => state.aggregation.profit,
    aggregatedTransactions: state => state.aggregation.transactions,
    isAggregationLoading: state => state.aggregation.isLoading,

    isProfitAndTurnoverChartLoading: state => state.profitAndTurnoverChart.isLoading,
    profitChartTitle: state => state.profitAndTurnoverChart.profitTitle,
    turnoverChartTitle: state => state.profitAndTurnoverChart.turnoverTitle,
    profitChartData: state => state.profitAndTurnoverChart.profitChartData,
    turnoverChartData: state => state.profitAndTurnoverChart.turnoverChartData,

    isTransactionsChartLoading: state => state.transactionsChart.isLoading,
    transactionsChartTitle: state => state.transactionsChart.title,
    transactionsChartData: state => state.transactionsChart.data,

    isSuccessRateChartLoading: state => state.successRateChart.isLoading,
    successRateChartTitle: state => state.successRateChart.title,
    successRateChartData: state => state.successRateChart.data,

    isSalesFunnelChartLoading: state => state.salesFunnelChart.isLoading,
    salesFunnelChartTitle: state => state.salesFunnelChart.title,
    salesFunnelChartData: state => state.salesFunnelChart.data,

    isTopCountriesListLoading: state => state.topCountries.isLoading,
    topCountriesList: state => state.topCountries.data,

    isTopCurrenciesListLoading: state => state.topCurrencies.isLoading,
    topCurrenciesList: state => state.topCurrencies.data,

    isTransactionsListLoading: state => state.transactions.isLoading,
    isTransactionsListLoadingMore: state => state.transactions.isLoadingMore,
    isTransactionsReportLoading: state => state.transactions.isReportLoading,
    transactionsReportUrl: state => state.transactions.reportUrl,
    transactionsList: state => state.transactions.data,
    canLoadMoreTransactions: state => !!state.transactions.meta?.cursors.next,

    activeProductId: (state, getters, rootState, rootGetters) => rootGetters['accountProductsStore/activeProductId'],
  },

  mutations: {
    clearStore(state) {
      state.filters = {
        from: null,
        to: null,
        countries: [],
        gateways: [],
        currencies: [],
        payment_methods: [],
      };

      state.aggregation = {
        isLoading: false,

        sales: null,
        profit: null,
        transactions: null,
      };

      state.profitAndTurnoverChart = {
        isLoading: false,

        profitTitle: null,
        profitChartData: [],

        turnoverTitle: null,
        turnoverChartData: null,
      };

      state.transactionsChart = {
        isLoading: false,

        title: null,
        data: [],
      };

      state.successRateChart = {
        isLoading: false,

        title: null,
        data: [],
      };

      state.salesFunnelChart = {
        isLoading: false,

        title: null,
        data: [],
      };

      state.topCountries = {
        isLoading: false,
        data: [],
      };

      state.topCurrencies = {
        isLoading: false,
        data: [],
      };

      state.transactions = {
        isReportLoading: false,
        reportUrl: null,

        isLoading: false,
        isLoadingMore: false,

        data: [],
        meta: null,
      };
    },

    setCountries(state, payload) {
      state.filters.countries = payload;
    },

    setDates(state, { from, to }) {
      state.filters.from = getDateInISO(from, true);
      state.filters.to = getDateInISO(to, false);
    },

    setGateways(state, payload) {
      state.filters.gateways = payload;
    },

    setCurrencies(state, payload) {
      state.filters.currencies = payload;
    },

    setPaymentMethods(state, payload) {
      state.filters.payment_methods = payload;
    },

    setAggregationStats(state, { sales, profit, transactions }) {
      state.aggregation.sales = sales;
      state.aggregation.profit = profit;
      state.aggregation.transactions = transactions;
    },

    setAggregationLoading(state, payload) {
      state.aggregation.isLoading = payload;
    },

    setProfitAndTurnoverChartLoading(state, payload) {
      state.profitAndTurnoverChart.isLoading = payload;
    },

    setProfitChartTitle(state, payload) {
      state.profitAndTurnoverChart.profitTitle = payload;
    },

    setTurnoverChartTitle(state, payload) {
      state.profitAndTurnoverChart.turnoverTitle = payload;
    },

    setProfitChartData(state, payload) {
      state.profitAndTurnoverChart.profitChartData = payload;
    },

    setTurnoverChartData(state, payload) {
      state.profitAndTurnoverChart.turnoverChartData = payload;
    },

    setTransactionsChartTitle(state, payload) {
      state.transactionsChart.title = payload;
    },

    setTransactionsChartData(state, payload) {
      state.transactionsChart.data = payload;
    },

    setTransactionsChartLoading(state, payload) {
      state.transactionsChart.isLoading = payload;
    },

    setSuccessRateChartTitle(state, payload) {
      state.successRateChart.title = payload;
    },

    setSuccessRateChartLoading(state, payload) {
      state.successRateChart.isLoading = payload;
    },

    setSuccessRateChartData(state, payload) {
      state.successRateChart.data = payload;
    },

    setTopCountriesLoading(state, payload) {
      state.topCountries.isLoading = payload;
    },

    setTopCountriesList(state, payload) {
      state.topCountries.data = payload;
    },

    setTopCurrenciesLoading(state, payload) {
      state.topCurrencies.isLoading = payload;
    },

    setTopCurrenciesList(state, payload) {
      state.topCurrencies.data = payload;
    },

    setTransactionsListLoading(state, payload) {
      state.transactions.isLoading = payload;
    },

    setTransactionsListLoadingMore(state, payload) {
      state.transactions.isLoadingMore = payload;
    },

    setTransactionsList(state, payload) {
      state.transactions.data = payload;
    },

    setTransactionsMeta(state, payload) {
      state.transactions.meta = payload;
    },

    appendTransactions(state, payload) {
      const transactionsList = state.transactions.data.concat(payload);
      state.transactions.data = transactionsList;
    },

    setReportUrl(state, payload) {
      state.transactions.reportUrl = payload;
    },

    clearReportUrl(state) {
      state.transactions.reportUrl = null;
    },

    setReportLoading(state, payload) {
      state.transactions.isReportLoading = payload;
    },
  },

  actions: {
    clearStore({ commit }) {
      commit('clearStore');
    },

    async downloadTransactionsReport({ commit, state: { filters }, getters }) {
      commit('setReportLoading', true);
      commit('clearReportUrl');

      const reportFilters = buildBodyRequestForReport(filters);

      await ReportService.requestReport({
        type: TRANSACTIONS_TYPE_REPORT,
        subscriptionKey: ANALYTICS_TRANSACTIONS_SUBSCRIPTION_KEY,
        filters: reportFilters,
        productHeaderId: getters.activeProductId,
        callback: (error, url) => {
          commit('setReportLoading', false);

          if (error) {
            NotificationService.notifyAboutError('partner-portal.errors.error-downloading-report');
            return null;
          }

          commit('setReportUrl', url);
          return url;
        },
      });
    },

    unsubscribeFromTransactionsReport() {
      ReportService.clearExpirationTimerId();
      SubscriptionService.unsubscribe(ANALYTICS_TRANSACTIONS_SUBSCRIPTION_KEY);
    },

    setCountriesFilter({ commit }, countriesFilter) {
      commit('setCountries', countriesFilter);
    },

    setDatesFilter({ commit }, datesFilter) {
      commit('setDates', datesFilter);
    },

    setGatewaysFilter({ commit }, gatewaysFilter) {
      commit('setGateways', gatewaysFilter);
    },

    setCurrenciesFilter({ commit }, currenciesFilter) {
      commit('setCurrencies', currenciesFilter);
    },

    setPaymentMethodsFilter({ commit }, paymentMethodsFilter) {
      commit('setPaymentMethods', paymentMethodsFilter);
    },

    async getAggregatedStats({ commit, state, getters }) {
      commit('setAggregationLoading', true);

      try {
        const query = buildQueryWithFilters(state.filters);
        const { data: { sales,
          profit,
          transactions_count: transactionsCount } } = await apiClient.get(`/v1/analytics/aggregated-statistics?${query}`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        commit('setAggregationStats', {
          sales,
          profit,
          transactions: transactionsCount,
        });
      } catch (error) {
        LoggerService.logToConsole(error);
      } finally {
        commit('setAggregationLoading', false);
      }
    },

    async getProfitAndTurnoverChartData({ commit, state, getters }) {
      commit('setProfitAndTurnoverChartLoading', true);

      try {
        const query = buildQueryWithFilters(state.filters);
        const { data: { datasets } } = await apiClient.get(`/v1/analytics/profit-turnover?${query}`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        const profitDataset = datasets.find(dataset => dataset.type === 'profit');
        const turnoverDataset = datasets.find(dataset => dataset.type === 'turnover');

        commit('setProfitChartTitle', profitDataset.aggregated);
        commit('setTurnoverChartTitle', turnoverDataset.aggregated);
        commit('setProfitChartData', profitDataset.data);
        commit('setTurnoverChartData', turnoverDataset.data);
      } catch (error) {
        LoggerService.logToConsole(error);
      } finally {
        commit('setProfitAndTurnoverChartLoading', false);
      }
    },

    async getTransactionsChartData({ commit, state, getters }) {
      commit('setTransactionsChartLoading', true);

      try {
        const query = buildQueryWithFilters(state.filters);
        const { data: { datasets } } = await apiClient.get(`/v1/analytics/transactions?${query}`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        commit('setTransactionsChartTitle', datasets[0].aggregated);
        commit('setTransactionsChartData', datasets[0].data);
      } catch (error) {
        LoggerService.logToConsole(error);
      } finally {
        commit('setTransactionsChartLoading', false);
      }
    },

    async getSuccessRateChartData({ commit, state, getters }) {
      commit('setSuccessRateChartLoading', true);

      try {
        const query = buildQueryWithFilters(state.filters);
        const { data: { datasets } } = await apiClient.get(`/v1/analytics/success-rate?${query}`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        commit('setSuccessRateChartTitle', datasets[0].aggregated);
        commit('setSuccessRateChartData', datasets[0].data);
      } catch (error) {
        LoggerService.logToConsole(error);
      } finally {
        commit('setSuccessRateChartLoading', false);
      }
    },

    async getTopCountries({ commit, state, getters }) {
      commit('setTopCountriesLoading', true);

      try {
        const query = buildQueryWithFilters(state.filters);
        const { data: { data: topCountries } } = await apiClient.get(`/v1/analytics/top-countries?${query}`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        commit('setTopCountriesList', topCountries);
      } catch (error) {
        LoggerService.logToConsole(error);
      } finally {
        commit('setTopCountriesLoading', false);
      }
    },

    async getTopCurrencies({ commit, state, getters }) {
      commit('setTopCurrenciesLoading', true);

      try {
        const query = buildQueryWithFilters(state.filters);
        const { data: { data: topCurrencies } } = await apiClient.get(`/v1/analytics/top-currencies?${query}`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        commit('setTopCurrenciesList', topCurrencies);
      } catch (error) {
        LoggerService.logToConsole(error);
      } finally {
        commit('setTopCurrenciesLoading', false);
      }
    },

    async getTransactions({ commit, state, getters }) {
      commit('setTransactionsListLoading', true);

      try {
        const query = buildQueryWithFilters(state.filters);
        const { data: { data: transactions, meta } } = await apiClient.get(`/v1/transactions?${query}`, {
          params: {
            limit: BASE_TRANSACTIONS_LIMIT,
          },
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        commit('setTransactionsList', transactions);
        commit('setTransactionsMeta', meta);
      } catch (error) {
        LoggerService.logToConsole(error);
      } finally {
        commit('setTransactionsListLoading', false);
      }
    },

    async getMoreTransactions({ commit, state, getters }) {
      commit('setTransactionsListLoadingMore', true);

      try {
        const cursor = state.transactions.meta.cursors.next;

        const query = buildQueryWithFilters(state.filters);
        const { data: { data: transactions, meta } } = await apiClient.get(`/v1/transactions?${query}`, {
          params: {
            limit: BASE_TRANSACTIONS_LIMIT,
            cursor,
          },
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        commit('appendTransactions', transactions);
        commit('setTransactionsMeta', meta);
      } catch (error) {
        LoggerService.logToConsole(error);
      } finally {
        commit('setTransactionsListLoadingMore', false);
      }
    },

    async getTransactionDetails({ getters }, id) {
      try {
        const { data } = await apiClient.get(`/v1/transactions/${id}`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });
        return data;
      } catch (error) {
        return LoggerService.logToConsole(error);
      }
    },
  },

};
