<template>
  <main>
    <AnalyticsSidebar />
    <div class="analytics__content">
      <AnalyticsMain />
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex';
import AnalyticsSidebar from './AnalyticsSidebar';
import AnalyticsMain from './AnalyticsMain';

import { getLastMonth } from '@/utils/charts';

export default {
  name: 'AnalyticsView',

  components: {
    AnalyticsSidebar,
    AnalyticsMain,
  },

  mounted() {
    const lastMonthRange = getLastMonth();
    this.setDatesFilter(lastMonthRange);

    this.requestInitialData();
  },

  destroyed() {
    this.clearStore();
  },

  methods: {
    ...mapActions('analyticsStore', {
      clearStore: 'clearStore',

      setDatesFilter: 'setDatesFilter',

      getAggregatedStats: 'getAggregatedStats',
      getProfitAndTurnoverChartData: 'getProfitAndTurnoverChartData',
      getTransactionsChartData: 'getTransactionsChartData',
      getSuccessRateChartData: 'getSuccessRateChartData',
      getTopCountries: 'getTopCountries',
      getTopCurrencies: 'getTopCurrencies',
      getTransactions: 'getTransactions',
    }),

    requestInitialData() {
      this.getAggregatedStats();
      this.getProfitAndTurnoverChartData();
      this.getTransactionsChartData();
      this.getSuccessRateChartData();
      // TODO: Get sales funnel data when we have such chart
      this.getTopCountries();
      this.getTopCurrencies();
      this.getTransactions();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.analytics__content {
  width: 100%;
}

@media screen and (min-width: $tablet-breakpoint) {
  .analytics__content {
    flex: 1 0;
    min-width: 0;
  }
}
</style>
