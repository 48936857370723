<template>
  <aside class="sidebar">
    <SidebarMenu
      class="sidebar__card"
      :routes="routes"
    />
    <ManagerCard class="sidebar__card--secondary" />
  </aside>
</template>

<script>
import SidebarMenu from '@/components/SidebarMenu';
import ManagerCard from '@/components/ManagerCard';

export default {
  name: 'DevelopersLayoutSidebar',
  components: {
    SidebarMenu,
    ManagerCard,
  },
  computed: {
    routes() {
      return [
        {
          link: '/developers/status',
          name: 'status',
          title: this.$t('partner-portal.menus.developers.status'),
          icon: 'clipboard-black',
        },
        {
          link: '/developers/documentation',
          name: 'documentation',
          title: this.$t('partner-portal.menus.developers.api-documentation'),
          icon: 'book-black',
        },
        {
          link: '/developers/webhooks',
          name: 'webhooks',
          title: this.$t('partner-portal.menus.developers.webhooks'),
          icon: 'rss-black',
        },
        {
          link: '/developers/changelog',
          name: 'changelog',
          title: this.$t('partner-portal.menus.developers.changelog'),
          icon: 'undo-black',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';

.sidebar {
  &__card {
    @include sidebar-card();
  }
}
</style>
