<template>
  <form
    class="auth__form"
    @submit.prevent="$emit('login', user)"
  >
    <i class="icon icon-login auth__form-icon" />

    <div class="auth__form-page-name">
      {{ $t('partner-portal.login.sign-in') }}
    </div>

    <div class="auth__form-input-group">
      <div class="auth__form-label-group">
        <label class="auth__form-label">{{ $t('partner-portal.common.email') }}</label>
      </div>

      <AppInput
        v-model="user.email"
        :is-disabled="isLoading"
        :errors="errors"
        class="auth__form-input"
        name="email"
        type="email"
        @keydown.native.space.prevent
      />
    </div>

    <div class="auth__form-input-group">
      <div class="auth__form-label-group">
        <label class="auth__form-label">{{ $t('partner-portal.common.password') }}</label>

        <router-link
          to="/password-restore"
          class="auth__form-label-forgot-password"
        >
          {{ $t('partner-portal.login.forgot-password') }}
        </router-link>
      </div>

      <AppInput
        v-model="user.password"
        :is-disabled="isLoading"
        :errors="errors"
        name="password"
        type="password"
        @keydown.native.space.prevent
      />
    </div>

    <div
      v-show="isCaptchaVisible"
      class="auth__form-input-group"
    >
      <div
        ref="captchaContainer"
        class="auth__form-captcha-container"
      />
    </div>

    <div class="auth__form-footer">
      <AppButton
        type="submit"
        :text="$t('partner-portal.login.sign-in')"
        :is-loading="isLoading"
        class="btn--primary auth__form-footer-button"
      />
    </div>
  </form>
</template>

<script>
import AppButton from '@/components/AppButton';
import AppInput from '@/components/AppInput';

export default {
  name: 'Login',

  components: {
    AppButton,
    AppInput,
  },

  props: {
    isCaptchaVisible: {
      type: Boolean,
      default: false,
    },

    isCaptchaEnabled: {
      type: Boolean,
      default: true,
    },

    shouldRenderCaptcha: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      capchaWorkedOut: false,
    };
  },

  watch: {
    shouldRenderCaptcha(render) {
      if (render) this.renderCaptcha();
    },
  },

  async updated() {
    await this.$nextTick();

    if (!this.capchaWorkedOut) window.captchaOnload();
  },

  mounted() {
    if (this.isCaptchaEnabled) {
      window.captchaOnload = () => {
        const shouldDisplayCaptcha = localStorage.getItem('displayCaptcha');
        this.capchaWorkedOut = true;
        if (shouldDisplayCaptcha) this.renderCaptcha();
      };
    }
  },

  methods: {
    renderCaptcha() {
      const captcha = window.grecaptcha;

      if (!captcha) return;

      captcha.render(this.$refs.captchaContainer, {
        sitekey: CAPTCHA_SITE_KEY,
        size: 'normal',
        callback: token => {
          this.$emit('set-captcha-token', token);
        },
        'expired-callback': () => {
          this.$emit('set-captcha-token', '');
        },
        'error-callback': () => {
          this.$emit('set-captcha-token', '');
        },
      });

      this.$emit('set-captcha-visibility', true);
      localStorage.setItem('displayCaptcha', true);
    },
  },
};
</script>
