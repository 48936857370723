<template>
  <div class="controls">
    <div class="controls__title">
      {{ title }}
    </div>
    <div class="controls__options">
      <div class="controls__option">
        <div
          class="controls__option-trigger"
          @click="enableAllPaymentOptions"
        >
          {{ $t('partner-portal.widget-settings.table.all-available') }}
        </div>
      </div>
      <div class="controls__option">
        <div class="controls__option-label">
          {{ $t('partner-portal.widget-settings.table.set-for-all') }}
        </div>
        <div
          class="controls__option-input-container"
          :class="{
            'controls__option-input-container--empty': isEmptyInput,
          }"
        >
          <input
            v-model="inputCellValue"
            type="text"
            inputmode="decimal"
            class="controls__option-input"
            @keypress="handleNumericOnly"
            @change="setGeneralPartnerFee"
            @blur="formatInputCellValue"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import inputCell from '@/views/WidgetSettings/Cell/InputCell/InputCell.utils';

export default {
  name: 'WidgetGeneralControls',

  mixins: [inputCell],

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    isEmptyInput() {
      return this.inputCellValue.length === 0;
    },
  },

  methods: {
    enableAllPaymentOptions() {
      this.$emit('enable-all-payment-options');
    },

    setGeneralPartnerFee() {
      this.formatInputCellValue();
      this.$emit('set-fee-for-payment-options', this.inputCellValue);
    },
  },

};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

$empty-percentage-color: $label-info-color;

.controls {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  &__options {
    display: flex;
    flex: 0 1 100%;
    flex-wrap: wrap;
    flex-direction: column;

    @media screen and (min-width: $desktop-breakpoint) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 13px;

    @media screen and (min-width: $desktop-breakpoint) {
      width: auto;
      margin-bottom: 0;
    }
  }

  &__option-label {
    font-size: 15px;
    line-height: 18px;
    margin-right: 12px;
  }

  &__option-trigger {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
    cursor: pointer;
    border-bottom: 1px solid $input-border-grey;
  }

  &__option-label {
    font-family: $font-regular;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    margin-right: 12px;
  }

  &__option-input-container {
    position: relative;
    font-family: $font-regular;

    &--empty {
      color: $empty-percentage-color;
    }

    &:after {
      content: '%';
      display: flex;
      position: absolute;
      font-size: 15px;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__option-input {
    display: flex;
    font-size: 15px;
    padding-right: 20px;
    line-height: 18px;
    width: 60px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid $input-border-grey;
    text-align: right;
    font-family: $font-regular;

    &:focus {
      outline: none;
    }
  }
}
</style>
