<template>
  <section
    class="chart"
    :class="{
      'chart--legend': isLegendVisible,
    }"
  >
    <header class="chart__header">
      <h2 class="chart__title">
        <span>{{ title }}</span>
        <span class="chart__title-symbol">:</span>
        <span
          v-if="digitsTitle"
          class="chart__digits-title"
        >
          {{ digitsTitle }}
        </span>
      </h2>

      <DateFilters
        v-if="areFiltersVisible"
        class="chart__filters"
        :value="selectedTimeRange"
        :date-range="customDateRange"
        @change="onDateFilterChange"
      />
    </header>

    <div
      class="chart__body"
      :class="{
        'chart__body--loading': isLoading,
      }"
    >
      <div
        v-if="isLoading"
        class="icon icon-spinner-black chart__body-icon"
      />

      <slot v-if="isChartVisible" />

      <MissingDataPlaceholder v-if="isDataMissing" />
    </div>
  </section>
</template>

<script>
import MissingDataPlaceholder from '@/components/MissingDataPlaceholder';
import DateFilters, { TIMERANGES } from '@/components/DateFilters';

export default {
  name: 'ChartWrapper',

  components: {
    DateFilters,
    MissingDataPlaceholder,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    digitsTitle: {
      type: String,
      default: '',
    },
    areFiltersEnabled: {
      type: Boolean,
      default: true,
    },
    hasChartData: {
      type: Boolean,
      default: false,
    },
    filtersChanged: {
      type: Function,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isLegendVisible: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    selectedTimeRange: TIMERANGES.WEEK,
    customDateRange: null,
  }),

  computed: {
    areFiltersVisible() {
      return this.areFiltersEnabled && !this.isLoading;
    },
    isChartVisible() {
      return this.hasChartData && !this.isLoading;
    },
    isDataMissing() {
      return !this.hasChartData && !this.isLoading;
    },
  },

  methods: {
    onDateFilterChange(value, dateRange) {
      if (value === TIMERANGES.CUSTOM) {
        this.customDateRange = dateRange;
      } else if (this.customDateRange) {
        this.customDateRange = null;
      }

      this.selectedTimeRange = value;

      this.$emit('filters-changed', {
        type: value,
        dateRange,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.chart {
  background-color: $primary;
  box-shadow: 0px 2px 8px $secondary-border-color;
  border-radius: 8px;
  padding: 16px;
  height: 249px;

  &--legend {
    height: 283px;

    .chart__body {
      height: 170px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
    min-height: 26px;
  }

  &__title {
    font-size: 15px;
  }

  &__digits-title,
  &__title-symbol {
    display: none;
  }

  &__filters {
    margin-left: auto;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;

    &--loading {
      padding: 90px 0;
    }
  }

  &__body-icon {
    height: 32px;
    width: 32px;
    animation: spin 1s linear infinite;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .chart {
    padding: 24px;
    height: 366px;

    &--legend {
      height: 400px;

      & .chart__body {
        height: 275px;
      }
    }

    &__body {
      height: 266px;
    }

    &__digits-title {
      display: inline;
    }

    &__title-symbol {
      display: inline-block;
      margin-right: 4px;
    }

    &__title {
      font-size: 18px;
    }
  }
}
</style>
