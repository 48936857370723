<template>
  <div
    v-click-outside="closeCalendar"
    class="date-range-picker"
    @click="openCalendar"
  >
    <div
      v-if="label"
      class="date-range-picker__label"
    >
      {{ label }}
    </div>
    <DatePicker
      v-if="isCalendarShown"
      :data-testid="dataTestId"
      class="date-range-picker__module"
      :value="value"
      is-range
      :min-date="minDate"
      @input="emitDateChange"
    />
    <div class="date-range-picker__value">
      <i class="icon icon-calendar date-range-picker__value-image" />
      <div class="date-range-picker__value-text">
        {{ dateRangeValue }}
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/Datepicker';

import { formatDateRange } from '@/utils';

export default {
  name: 'DateRangePicker',
  components: {
    DatePicker,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [Object, String],
      default: null,
    },
    dateChange: {
      type: Function,
      default: () => ({}),
    },
    minDate: {
      type: String,
      default: undefined,
    },
    dataTestId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isCalendarShown: false,
    };
  },

  computed: {
    dateRangeValue() {
      const { value } = this;
      if (value) return formatDateRange(value.start, value.end);

      return this.$t('partner-portal.top-charts.sidebar.filters.select-date');
    },
  },

  methods: {
    openCalendar() {
      this.isCalendarShown = true;
    },
    closeCalendar() {
      this.isCalendarShown = false;
    },
    emitDateChange(dateRange) {
      this.$emit('date-change', dateRange);
      this.isCalendarShown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/icons';
@import '~@/assets/scss/variables';

.date-range-picker {
  position: relative;

  &__label {
    font-size: 15px;
    margin-bottom: 6px;
    font-family: $font-regular;
  }

  &__module {
    position: absolute;
    left: 0;
    top: 18px;
    z-index: 1;
  }

  &__value {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__value-image {
    height: 16px;
    width: 16px;
  }

  &__value-text {
    margin-left: 8px;
  }
}
</style>
