const config = {
  aws_project_region: AWS_REGION,
  aws_cognito_region: AWS_REGION,
  aws_user_pools_id: AWS_COGNITO_USER_POOLS_ID,
  aws_user_pools_web_client_id: AWS_COGNITO_USER_POOLS_WEB_CLIENT_ID,
  endpoint: AWS_COGNITO_ENDPOINT,
  aws_cognito_username_attributes: [
    'EMAIL',
  ],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [
    'EMAIL',
  ],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [
    'SMS',
  ],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: [
    'EMAIL',
  ],
};

export default config;
