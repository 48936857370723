export const getAccountInformation = {
  url: `${PARTNER_PORTAL_API_URL}/v1/account-info`,
  method: 'GET',
  responseFn: async () => [200, await import('./account.mock.json')],
};

export const getEmptyAccountInformation = {
  url: `${PARTNER_PORTAL_API_URL}/v1/account-info/empty`,
  method: 'GET',
  responseFn: async () => [200, await import('./account-empty.mock.json')],
};

export const getDashboardAggregatedStats = {
  url: `${PARTNER_PORTAL_API_URL}/v1/analytics/aggregated-statistics`,
  method: 'GET',
  responseFn: async () => [200, await import('./dashboard-aggregation.mock.json')],
};

export const getEmptyDashboardAggregatedStats = {
  url: `${PARTNER_PORTAL_API_URL}/v1/analytics/aggregated-statistics/empty`,
  method: 'GET',
  responseFn: async () => [200, await import('./dashboard-aggregation-empty.mock.json')],
};

export const getDashboardProfitAndTurnover = {
  url: `${PARTNER_PORTAL_API_URL}/v1/analytics/profit-turnover`,
  method: 'GET',
  responseFn: async () => [200, await import('./dashboard-profit-turnover.mock.json')],
};

export const getEmptyDashboardProfitAndTurnover = {
  url: `${PARTNER_PORTAL_API_URL}/v1/analytics/profit-turnover/empty`,
  method: 'GET',
  responseFn: async () => [200, await import('./dashboard-profit-turnover-empty.mock.json')],
};

export const getDashboardTransactions = {
  url: `${PARTNER_PORTAL_API_URL}/v1/analytics/transactions`,
  method: 'GET',
  responseFn: async () => [200, await import('./dashboard-transactions.mock.json')],
};

export const getEmptyDashboardTransactions = {
  url: `${PARTNER_PORTAL_API_URL}/v1/analytics/transactions/empty`,
  method: 'GET',
  responseFn: async () => [200, await import('./dashboard-transactions-empty.mock.json')],
};

export const getDashboardSuccessRate = {
  url: `${PARTNER_PORTAL_API_URL}/v1/analytics/success-rate`,
  method: 'GET',
  responseFn: async () => [200, await import('./dashboard-success-rate.mock.json')],
};

export const getDashboardTopCountries = {
  url: `${PARTNER_PORTAL_API_URL}/v1/analytics/top-countries`,
  method: 'GET',
  responseFn: async () => [200, await import('./dashboard-top-countries.mock.json')],
};

export const getEmptyDashboardTopCountries = {
  url: `${PARTNER_PORTAL_API_URL}/v1/analytics/top-countries/empty`,
  method: 'GET',
  responseFn: async () => [200, await import('./dashboard-top-countries-empty.mock.json')],
};

export const getDashboardTopCurrencies = {
  url: `${PARTNER_PORTAL_API_URL}/v1/analytics/top-currencies`,
  method: 'GET',
  responseFn: async () => [200, await import('./dashboard-top-currencies.mock.json')],
};

export const getEmptyDashboardTopCurrencies = {
  url: `${PARTNER_PORTAL_API_URL}/v1/analytics/top-currencies`,
  method: 'GET',
  responseFn: async () => [200, await import('./dashboard-top-currencies-empty.mock.json')],
};

export const getDashboardTransactionsList = {
  url: `${PARTNER_PORTAL_API_URL}/v1/transactions`,
  method: 'GET',
  responseFn: async () => [200, await import('./dashboard-transactions-list.mock.json')],
};

export const getEmptyDashboardTransactionsList = {
  url: `${PARTNER_PORTAL_API_URL}/v1/transactions/empty`,
  method: 'GET',
  responseFn: async () => [200, await import('./dashboard-transactions-list-empty.mock.json')],
};

export const getTransactionDetails = {
  url: `${PARTNER_PORTAL_API_URL}/v1/transactions/PBQA22031596TX4`,
  method: 'GET',
  responseFn: async () => [200, await import('./transactions-details.mock.json')],
};

export const getApiKey = {
  url: `${PARTNER_PORTAL_API_URL}/v1/api-keys`,
  method: 'GET',
  responseFn: async () => [200, await import('./api-keys.mock.json')],
};

export const getFilters = {
  url: `${PARTNER_PORTAL_API_URL}/v1/analytics/filters`,
  method: 'GET',
  responseFn: async () => [200, await import('./filters.mock.json')],
};

export const getPayouts = {
  url: `${PARTNER_PORTAL_API_URL}/v1/payouts`,
  method: 'GET',
  responseFn: async () => [200, await import('./payouts.mock.json')],
};

export const getPayoutsConfirmation = {
  url: `${PARTNER_PORTAL_API_URL}/v1/payouts/101/confirmation-link`,
  method: 'GET',
  responseFn: async () => [200, await import('./payouts-confirmation.mock.json')],
};

export const getPayoutsTransactions = {
  url: `${PARTNER_PORTAL_API_URL}/v1/payouts/101/transactions`,
  method: 'GET',
  responseFn: async () => [200, await import('./payouts-transactions.mock.json')],
};

export const getFeatureFlags = {
  url: `${PARTNER_PORTAL_API_URL}/v1/feature-flags`,
  method: 'GET',
  responseFn: async () => [200, await import('./feature-flags.mock.json')],
};

export const savePaymentSettings = {
  url: `${PARTNER_PORTAL_API_URL}/v1/payment-settings`,
  method: 'PATCH',
  responseFn: async () => [400, await import('./payment-settings-error.mock.json')],
};

export const getPaymentMethods = {
  url: `${PARTNER_PORTAL_API_URL}/v1/payment-methods`,
  method: 'GET',
  responseFn: async () => [200, await import('./payment-methods.mock.json')],
};

export const getPaymentMethodOptions = {
  url: `${PARTNER_PORTAL_API_URL}/v1/payment-methods/133/settings`,
  method: 'GET',
  responseFn: async () => [200, await import('./payment-method-options.mock.json')],
};

export const getReports = {
  url: `${PARTNER_PORTAL_API_URL}/v1/reports`,
  method: 'POST',
  body: {
    type: 'top_countries',
    currencies: [],
    from: '2022-08-01T00:00:00.000Z',
    gateways: [],
    to: '2022-08-08T23:59:59.999Z',
  },
  responseFn: async () => [202, await import('./download-report.mock.json')],
};

export const getSingleReport = {
  url: `${PARTNER_PORTAL_API_URL}/v1/reports/d41d8cd98f00b204e9800998ecf8427e`,
  method: 'GET',
  responseFn: async () => [200, await import('./download-report.mock.json')],
};

export const getRolesList = {
  url: `${PARTNER_PORTAL_API_URL}/v1/roles`,
  method: 'GET',
  responseFn: async () => [200, await import('./roles-list.mock.json')],
};
