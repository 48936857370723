<template>
  <div
    class="profit-cell"
    :class="{ 'profit-cell--bold': greaterThanZero }"
  >
    {{ amount }}
  </div>
</template>

<script>
import { parseAmount } from './AmountCell.utils';

export default {
  name: 'AmountCell',
  props: {
    value: {
      type: Object,
      required: true,
    },
    format: {
      type: Function,
      default: value => value,
    },
  },
  computed: {
    amount() {
      const formattedValue = this.format(this.value);
      const parsedAmount = parseAmount(formattedValue);
      if (!parsedAmount) return 0;

      return `${this.value.amounts.fee_fiat.value} ${this.value.amounts.fee_fiat.currency}`;
    },
    greaterThanZero() {
      const digitsFromAmount = parseAmount(this.amount);
      return +digitsFromAmount > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.profit-cell {
  font-family: $font-regular;
  &--bold {
    font-family: $font-bold;
  }
}
</style>
