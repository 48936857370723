<template>
  <div
    class="form-datepicker"
    :class="{
      'has-error': error,
      'is-disabled': isDisabled,
      'has-tip': hasTipSlot
    }"
  >
    <div
      v-if="isLabelVisible"
      class="form-datepicker__top"
    >
      <label
        v-if="label"
        :for="id"
        class="form-datepicker__label"
      >
        {{ label }}
      </label>

      <span class="form-datepicker__tip">
        <slot name="tip" />
      </span>
    </div>

    <v-date-picker
      mode="date"
      :value="value"
      :is-range="true"
      :columns="$screens({ default: 1, md: 2 })"
      :rows="$screens ({ default: 2, md: 1 })"
      :max-date="maxDate"
      :min-date="minDate"
      :attributes="attrs"
      :select-attribute="selectAttribute"
      :drag-attribute="dragAttribute"
      :show-day-popover="false"
      :formats="formats"
      :first-day-of-week="2"
      locale="en-US"
      timezone="UTC"
      @input="updateValue"
    >
      <template slot="header-left-button">
        <div class="form-datepicker__arrow form-datepicker__arrow--left">
          <i class="icon icon-arrow-left form-datepicker__arrow-image" />
        </div>
      </template>
      <template
        slot="header-title"
        slot-scope="{ monthLabel, yearLabel }"
      >
        <div class="form-datepicker__calendar-title">
          <span class="form-datepicker__calendar-title-text">{{ monthLabel }} {{ yearLabel }}</span>
          <i class="icon icon-bottom-arrow form-datepicker__calendar-title-image" />
        </div>
      </template>
      <template slot="header-right-button">
        <div class="form-datepicker__arrow form-datepicker__arrow--right">
          <i class="icon icon-arrow-right form-datepicker__arrow-image" />
        </div>
      </template>
    </v-date-picker>

    <span
      v-if="error"
      class="form-datepicker__error"
    >{{ error }}</span>
  </div>
</template>

<script>
import { DATEPICKER_ATTRS,
  DATEPICKER_FORMATS,
  DATEPICKER_DRAG_ATTRS,
  DATEPICKER_SELECT_ATTRS } from './Datepicker.constants';

// TODO rework this Scada component, because it's hard to use and maintain
// @see https://paybis.atlassian.net/browse/PD-4396
export default {
  name: 'DatePicker',

  props: {
    id: {
      type: String, required: false, default: '',
    },
    name: {
      type: String, required: false, default: '',
    },
    placeholder: {
      type: String, required: false, default: '',
    },
    label: {
      type: String, required: false, default: '',
    },
    error: {
      type: String, required: false, default: '',
    },
    isRange: {
      type: Boolean, required: false, default: false,
    },
    isDisabled: {
      type: Boolean, required: false, default: false,
    },
    value: {
      type: [Date, Object],
      required: false,
      default() {
        return null;
      },
    },
    minDate: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      inputProps: {
        class: 'form-datepicker__input',
        placeholder: this.placeholder,
        readonly: true,
        id: this.id,
        name: this.name,
        disabled: this.isDisabled,
        style: {
          width: '100%',
          color: '#000000',
        },
      },

      attrs: DATEPICKER_ATTRS,
      formats: DATEPICKER_FORMATS,
      dragAttribute: DATEPICKER_DRAG_ATTRS,
      selectAttribute: DATEPICKER_SELECT_ATTRS,

      // modelConfig: {
      //   start: {
      //     timeAdjust: '00:00:00',
      //   },
      //   end: {
      //     timeAdjust: '23:59:59',
      //   },
      // },
    };
  },

  computed: {
    isLabelVisible() {
      return this.label || this.hasTipSlot;
    },
    hasTipSlot() {
      return !!this.$slots.tip;
    },
    maxDate() {
      return new Date();
    },
  },

  methods: {
    updateValue(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";
@import '~@/assets/scss/icons';

.form-datepicker {
  position: relative;
  width: 100%;

  &.is-disabled {
    pointer-events: none;
  }

  &__header {
    font-size: 14px;
    line-height: 16px;
    font-family: $font-bold;
    text-align: center;
    padding: 16px 0 24px;
  }

  &__arrow {
    height: 30px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__arrow-image {
    width: 16px;
    height: 16px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    font-size: 16px;
    line-height: 1.25;
  }

  &__tip {
    color: $secondary-text-color;
    font-size: 14px;
    line-height: 1.14;
  }

  .is-disabled &__label {
    color: $label-info-color;
  }

  &__top {
    margin-bottom: 4px;
  }

  &__calendar-title {
    font-size: 14px;
    line-height: 16px;
    font-family: $font-bold;
    display: flex;
    height: 30px;
    align-items: center;
    text-transform: capitalize;
  }

  &__calendar-title-image {
    height: 16px;
    width: 16px;
  }

  ::v-deep &__input {
    position: relative;
    display: block;
    width: 100%;
    background-color: $primary;
    border: none;
    border-radius: 6px;
    font-family: $font-bold;
    font-size: 16px;
    line-height: 1.25;
    padding: 14px 28px 14px 14px;
    box-shadow: 0 0 0 2px $secondary-border-color;
    appearance: none;
    cursor: pointer;
    transition: box-shadow 0.2s linear;
  }

  ::v-deep [dir='rtl'] &__input {
    padding: 14px 14px 14px 28px;
  }

  ::v-deep &__input:hover {
    box-shadow: 0 0 0 2px $calendar-border-color-hover;
  }

  ::v-deep &__input:focus {
    outline: none;
    box-shadow: 0 0 0 1px $calendar-border-color-active;
  }

  ::v-deep &__input:disabled {
    background-color: $background-color;
    color: $secondary-text-color;
    box-shadow: 0 0 0 2px $background-color;
  }

  ::v-deep &__input::-webkit-input-placeholder {
    font-family: $font-regular;
    color: $label-info-color;
  }

  ::v-deep &__input:-ms-input-placeholder {
    font-family: $font-regular;
    color: $label-info-color;
  }

  ::v-deep &__input::placeholder {
    font-family: $font-regular;
    color: $label-info-color;
  }

  @media screen and (max-width: $desktop-breakpoint) {
    &.has-tip {
      padding-bottom: 28px;
    }

    &.has-tip &__tip {
      position: absolute;
      bottom: 2px;
    }
  }
}
</style>

<style lang="scss">
@import "~@/assets/scss/variables";

.popover-content-wrapper {
  padding: 0 !important;
}

.c-header {
  & .c-title {
    font-family: $font-bold;
  }

  & .popover-content {
    border-radius: 10px;
    box-shadow: 0px 3px 6px $popover-border-color;
    border: 1px solid $secondary;
    background-color: $primary;
    font-family: $font-bold;
    font-size: 14px;
    overflow: hidden;
  }

  & .c-arrow {
    height: 16px;
  }

  [dir='rtl'] & .c-arrow {
    transform: scaleX(-1);
  }

  & .c-table-cell {
    font-size: 14px;
    border-radius: 4px;
  }

  & .c-table-cell:hover {
    background-color: $background-color;
  }

  & .c-table-cell.c-active {
    background-color: $item-active-background;
  }
}
</style>
