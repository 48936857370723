<template>
  <main class="dashboard">
    <DashboardSidebar />
    <div class="dashboard__content">
      <DashboardMain />
    </div>
  </main>
</template>

<script>
import DashboardSidebar from './DashboardSidebar';
import DashboardMain from './DashboardMain';

export default {
  name: 'DashboardView',

  components: {
    DashboardSidebar,
    DashboardMain,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.dashboard {
  flex-wrap: wrap;

  &__content {
    width: 100%;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .dashboard {
    flex-wrap: nowrap;

    &__content {
      flex: 1 0;
      min-width: 0;
    }
  }
}
</style>
