<template>
  <div class="analytics">
    <ChartWrapper
      data-testid="profit-turnover-section"
      :title="$t('partner-portal.charts.profit-chart')"
      :digits-title="profitAndTurnoverTitle"
      :has-chart-data="profitAndTurnovevChartHasChartData"
      :are-filters-enabled="false"
      :is-loading="isProfitAndTurnoverChartLoading"
      :is-legend-visible="true"
      class="analytics__section"
    >
      <ProfitAndTurnoverChart
        :chart-options="profitAndTurnoverChartOptions"
        :chart-data="profitAndTurnoverChartData"
        :is-legend-visible="true"
      />
    </ChartWrapper>

    <ChartWrapper
      data-testid="transactions-section"
      :title="$t('partner-portal.charts.transactions-chart')"
      :digits-title="transactionsChartTitle"
      :has-chart-data="transactionsChartHasChartData"
      :is-loading="isTransactionsChartLoading"
      :are-filters-enabled="false"
      class="analytics__section"
    >
      <LinearChart
        :chart-options="transactionsChartOptions"
        :chart-data="storeTransactionsChartData"
      />
    </ChartWrapper>

    <ChartWrapper
      data-testid="success-rate-section"
      :title="$t('partner-portal.charts.success-rate-chart')"
      :digits-title="successRateTitle"
      :has-chart-data="successRateChartHasChartData"
      :is-loading="isSuccessRateChartLoading"
      :are-filters-enabled="false"
      class="analytics__section"
    >
      <LinearChart
        :chart-options="successRateChartOptions"
        :chart-data="storeSuccessRateChartData"
      />
    </ChartWrapper>

    <ChartWrapper
      v-if="isSalesFunnelChartVisible"
      :title="$t('partner-portal.charts.sales-funnel')"
      :has-chart-data="salesFunnelChartHasChartData"
      :is-loading="isSalesFunnelChartLoading"
      :are-filters-enabled="false"
      class="analytics__section"
    />

    <div class="analytics__section toplist-charts">
      <ToplistChart
        data-testid="top-countries-chart"
        class="toplist-charts__chart"
        :title="$t('partner-portal.dashboard.top-countries')"
        :items="storeTopCountriesList"
        :is-loading="isTopCountriesListLoading"
        header-link="topChartsCountries"
      />

      <ToplistChart
        data-testid="top-currencies-chart"
        class="toplist-charts__chart"
        :title="$t('partner-portal.dashboard.top-cryptocurrencies')"
        :items="storeTopCurrenciesList"
        :is-loading="isTopCurrenciesListLoading"
        header-link="topChartsCryptos"
      />
    </div>

    <TransactionsList
      data-testid="transaction-history-chart"
      class="analytics__section"
      :title="$t('partner-portal.dashboard.transaction-history')"

      :can-load-more-transactions="canLoadMoreTransactions"
      :transactions="storeTransactionsList"

      :show-link="true"
      :show-download="true"

      :settings="transactionsListSettings"

      :report-url="storeTransactionsReportUrl"

      :is-loading-transactions="isTransactionsListLoading"
      :is-loading-more="isTransactionsListLoadingMore"
      :is-loading-report="isTransactionsReportLoading"

      :load-transaction-details="getTransactionDetails"
      @load-more="getMoreTransactions"
      @download-report="downloadTransactionsReport"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ChartWrapper from '@/components/ChartWrapper';
import ToplistChart from '@/components/ToplistChart';
import TransactionsList from '@/components/TransactionsList';

import { ProfitAndTurnoverChart, LinearChart } from '@/components/ChartCanvas';

import { formatCurrency } from '@/utils';

export default {
  name: 'AnalyticsMain',

  components: {
    ChartWrapper,
    ToplistChart,
    TransactionsList,

    LinearChart,
    ProfitAndTurnoverChart,
  },

  data: () => ({
    // TODO: Remove this fix when we build funner chart
    isSalesFunnelChartVisible: false,
  }),

  computed: {
    ...mapGetters('analyticsStore', {
      isProfitAndTurnoverChartLoading: 'isProfitAndTurnoverChartLoading',
      storeProfitTitle: 'profitChartTitle',
      storeTurnoverTitle: 'turnoverChartTitle',
      storeProfitChartData: 'profitChartData',
      storeTurnoverChartData: 'turnoverChartData',

      isTransactionsChartLoading: 'isTransactionsChartLoading',
      storeTransactionsChartTitle: 'transactionsChartTitle',
      storeTransactionsChartData: 'transactionsChartData',

      isSuccessRateChartLoading: 'isSuccessRateChartLoading',
      storeSuccessRateChartTitle: 'successRateChartTitle',
      storeSuccessRateChartData: 'successRateChartData',

      isSalesFunnelChartLoading: 'isSalesFunnelChartLoading',
      storeSalesFunnelChartData: 'salesFunnelChartData',

      isTopCountriesListLoading: 'isTopCountriesListLoading',
      storeTopCountriesList: 'topCountriesList',

      isTopCurrenciesListLoading: 'isTopCurrenciesListLoading',
      storeTopCurrenciesList: 'topCurrenciesList',

      isTransactionsListLoading: 'isTransactionsListLoading',
      isTransactionsListLoadingMore: 'isTransactionsListLoadingMore',
      isTransactionsReportLoading: 'isTransactionsReportLoading',
      storeTransactionsReportUrl: 'transactionsReportUrl',
      storeTransactionsList: 'transactionsList',
      canLoadMoreTransactions: 'canLoadMoreTransactions',
    }),

    transactionsListSettings() {
      return {
        limit: 10,
      };
    },

    profitAndTurnoverTitle() {
      if (this.isProfitAndTurnoverChartLoading) return '';

      const { value: profitAmount = 0, unit: profitCurrency = 'USD' } = this.storeProfitTitle || {};
      const { value: turnoverAmount = 0, unit: turnoverCurrency = 'USD' } = this.storeTurnoverTitle || {};

      const profitTitle = formatCurrency(profitAmount, profitCurrency);
      const turnoverTitle = formatCurrency(turnoverAmount, turnoverCurrency);

      return `${profitTitle} / ${turnoverTitle}`;
    },

    profitAndTurnovevChartHasChartData() {
      const hasProfitData = this.storeProfitChartData?.some(series => Number(series.y) > 0);
      const hasTurnoverData = this.storeTurnoverChartData?.some(series => Number(series.y) > 0);
      return hasProfitData || hasTurnoverData;
    },

    profitAndTurnoverChartData() {
      return [
        this.storeTurnoverChartData,
        this.storeProfitChartData,
      ];
    },

    profitAndTurnoverChartOptions() {
      return [{
        label: this.$t('partner-portal.charts.turnover'),
        chartColor: '#5F70DB',
        styles: {
          pointHoverBackgroundColor: '#5F70DB',
          pointHoverRadius: 5,
        },
      }, {
        label: this.$t('partner-portal.charts.profit'),
        chartColor: '#C75CF9',
        styles: {
          pointHoverBackgroundColor: '#C75CF9',
          pointHoverRadius: 5,
        },
      }];
    },

    transactionsChartTitle() {
      if (this.isTransactionsChartLoading) return '';

      return this.storeTransactionsChartTitle?.value || '0';
    },

    transactionsChartOptions() {
      return {
        label: this.$t('partner-portal.charts.transactions-chart'),
        chartColor: '#27AE60',
        styles: {
          pointHoverBackgroundColor: '#27AE60',
          pointHoverRadius: 5,
        },
      };
    },

    transactionsChartHasChartData() {
      return this.storeTransactionsChartData?.some(series => Number(series.y) > 0);
    },

    successRateTitle() {
      if (this.isSuccessRateChartLoading) return '';

      if (!this.storeSuccessRateChartTitle) return '0%';

      return `${this.storeSuccessRateChartTitle?.value}%`;
    },

    successRateChartOptions() {
      return {
        label: this.$t('partner-portal.charts.success-rate-chart'),
        chartColor: '#ED3DDC',
        styles: {
          pointHoverBackgroundColor: '#ED3DDC',
          pointHoverRadius: 5,
        },
      };
    },

    successRateChartHasChartData() {
      return this.storeSuccessRateChartData?.some(series => Number(series.y) > 0);
    },

    salesFunnelChartOptions() {
      return {};
    },

    salesFunnelChartHasChartData() {
      return false;
    },
  },

  destroyed() {
    this.unsubscribeFromTransactionsReport();
  },

  methods: {
    ...mapActions('analyticsStore', {
      getMoreTransactions: 'getMoreTransactions',
      getTransactionDetails: 'getTransactionDetails',

      downloadTransactionsReport: 'downloadTransactionsReport',
      unsubscribeFromTransactionsReport: 'unsubscribeFromTransactionsReport',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.analytics {
  flex-wrap: wrap;
  &__section {
    margin-bottom: 32px;

    box-shadow: 0px 2px 8px $secondary-border-color;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .analytics {
    flex-wrap: nowrap;
    flex: 1 0;
    min-width: 0;
  }
}

.toplist-charts {
  display: flex;
  flex-wrap: wrap;

  &__chart {
    width: 100%;

    &:not(:first-child) {
      margin-top: 32px;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      width: calc(50% - 16px);

      &:not(:first-child) {
        margin-top: 0;
      }

      &:not(:last-child) {
        margin-right: 32px;
      }
    }
  }
}
</style>
