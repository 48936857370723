<template>
  <div class="payout-txs-cell">
    <a
      ref="transactionAnchor"
      :href="transactionLink"
      class="payout-txs-cell__link"
      download="payouts-transactions-report.csv"
    />

    <span @click="getPayoutTransactionsLink">{{ $t('partner-portal.payouts.table.links.txs') }}</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PayoutTxsCell',

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    transactionLink: null,
  }),

  methods: {
    ...mapActions('payoutsStore', {
      loadPayoutTransactions: 'loadTransactions',
    }),

    getPayoutTransactionsLink() {
      const { id } = this.value;
      this.loadPayoutTransactions(id)
        .then(link => {
          this.transactionLink = link;
          this.$nextTick(() => {
            this.$refs.transactionAnchor.click();
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.payout-txs-cell {
  cursor: pointer;
  border-bottom: 1px solid $link-border-color;

  &__link {
    position: absolute;
    left: -100000px;
    top: -100000px;
  }
}
</style>
