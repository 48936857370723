<template>
  <div
    v-click-outside="closePopovers"
    class="date-filters"
  >
    <button
      type="button"
      class="date-filters__mobile-item"
      @click="toggleDropdown"
    >
      {{ getFilterItemLabel(currentItem) }}

      <i
        class="icon icon-bottom-arrow date-filters__toggle-icon"
        :class="{
          'date-filters__toggle-icon--opened': isDropdownOpened,
        }"
      />
    </button>

    <button
      v-for="item in items"
      :key="`desktop-${item.value}`"
      type="button"
      class="date-filters__desktop-item"
      :class="{
        'date-filters__desktop-item--active': value === item.value,
      }"
      @click="onDateChange(item.value)"
    >
      {{ getFilterItemLabel(item) }}

      <i
        v-if="isCustomRange(item.value)"
        class="icon icon-bottom-arrow-grey date-filters__toggle-icon"
      />
    </button>

    <div
      v-if="isDropdownOpened"
      class="date-filters__dropdown"
    >
      <button
        v-for="item in items"
        :key="`mobile-${item.value}`"
        type="button"
        class="date-filters__dropdown-item"
        :class="{
          'date-filters__dropdown-item--active': value === item.value,
        }"
        @click="onDateChange(item.value)"
      >
        {{ item.title }}
      </button>
    </div>

    <Datepicker
      v-show="isCalendarShown"
      class="date-filters__calendar"
      :value="dateRange"
      is-range
      :min-date="minDate"
      @input="onCustomDateRangeChange"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Datepicker from '@/components/Datepicker';
import { formatDateRange } from '@/utils';

import { TIMERANGES } from './DateFilters.constants';
import { isCustomRange } from './DateFilters.utils';

export default {
  name: 'DateFiltersDesktop',

  components: {
    Datepicker,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    dateRange: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    isDropdownOpened: false,
    isCalendarShown: false,
  }),

  computed: {
    ...mapGetters('filterStore', {
      minDate: 'minDate',
    }),
    items() {
      return [
        {
          title: this.$t('partner-portal.date.today'),
          value: TIMERANGES.TODAY,
        },
        {
          title: this.$t('partner-portal.date.yesterday'),
          value: TIMERANGES.YESTERDAY,
        },
        {
          title: this.$t('partner-portal.date.week'),
          value: TIMERANGES.WEEK,
        },
        {
          title: this.$t('partner-portal.date.month'),
          value: TIMERANGES.MONTH,
        },
        {
          title: this.$t('partner-portal.common.custom'),
          value: TIMERANGES.CUSTOM,
        },
      ];
    },
    customDateRangeLabel() {
      if (this.dateRange) {
        return formatDateRange(this.dateRange.start, this.dateRange.end);
      }

      return this.$t('partner-portal.common.custom');
    },
    currentItem() {
      return this.items.find(item => item.value === this.value);
    },
  },

  methods: {
    onDateChange(value) {
      if (isCustomRange(value)) {
        this.toggleCalendar();
        return;
      }

      if (this.isCalendarShown) {
        this.isCalendarShown = false;
      }

      if (this.isDropdownOpened) {
        this.isDropdownOpened = false;
      }

      this.$emit('change', value);
    },
    onCustomDateRangeChange({ start, end }) {
      this.isCalendarShown = false;
      this.isDropdownOpened = false;
      this.$emit('change', TIMERANGES.CUSTOM, {
        start,
        end,
      });
    },
    toggleDropdown() {
      this.isDropdownOpened = !this.isDropdownOpened;
    },
    toggleCalendar() {
      this.isCalendarShown = !this.isCalendarShown;
    },
    closePopovers() {
      this.isDropdownOpened = false;
      this.isCalendarShown = false;
    },
    getFilterItemLabel(item) {
      return isCustomRange(item.value) ? this.customDateRangeLabel : item.title;
    },
    isCustomRange,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
.date-filters {
  font-size: 15px;
  position: relative;
  display: block;

  @media screen and (min-width: $desktop-breakpoint) {
    display: flex;
  }

  &__calendar {
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 1;
    width: auto;
  }

  &__toggle-icon {
    width: 16px;
    height: 16px;
    margin-left: 2px;

    &--opened {
      transform: rotate(-180deg);
    }
  }

  &__desktop-item {
    display: none;
    color: $secondary-text-color;
    padding: 4px 8px;
    cursor: pointer;
    transition: color 250ms linear;

    &:hover {
      color: $secondary;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      display: flex;
    }

    &--active {
      background: $item-active-background;
      color: $secondary;
      border-radius: 4px;
    }
  }

  &__mobile-item {
    display: flex;
    color: $secondary;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
    transition: background 250ms linear;

    &:hover {
      background: $item-active-background;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      display: none;
    }
  }

  &__dropdown {
    position: absolute;
    width: 160px;
    border-radius: 12px;
    padding: 8px;
    background: $primary;
    border: 1px solid $secondary;
    z-index: 1;
    top: 40px;
    right: 0;

    @media screen and (min-width: $desktop-breakpoint) {
      display: none;
    }
  }

  &__dropdown-item {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    color: $secondary-text-color;
    cursor: pointer;
    transition: all 250ms linear;

    &--active {
      background: $item-active-background;
      color: $secondary;
    }

    &:hover {
      color: $secondary;
    }
  }
}
</style>
