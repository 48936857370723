<template>
  <div class="ticker">
    <Checkbox
      v-if="isPaymentMethod"
      class="ticker__checkbox"
      :disabled="isCheckboxDisabled"
      :checked="isPaymentMethodAvailable"
      @change="updateServiceStatus"
    />

    <div
      v-if="isCryptoType"
      class="ticker__crypto"
    >
      <div class="ticker__crypto_top">
        <div class="ticker__name">
          {{ currentAsset.name }}
        </div>

        <div class="ticker__short">
          {{ currentAsset.currency.code }}
        </div>
      </div>

      <div class="ticker__blockchain">
        ({{ currentAsset.blockchain.name }})
      </div>
    </div>

    <div
      v-else
      class="ticker__crypto"
    >
      <div class="ticket__name">
        {{ fiatName }}
      </div>
    </div>
  </div>
</template>

<script>

import Checkbox from '@/components/Checkbox';

const CRYPTO_TYPE = 'crypto';

export default {
  name: 'TickerCell',

  components: {
    Checkbox,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isCheckboxDisabled() {
      const { is_available: isAvailable } = this.value;
      return !isAvailable;
    },

    isCryptoType() {
      return this.isPaymentMethod && this.paymentMethod.type === CRYPTO_TYPE;
    },

    isPaymentMethodAvailable() {
      // isActive = true by default because so far
      // we don't use this status inside base_currencies
      // which store only is_available status
      const { is_active: isActive = true, is_available: isAvailable } = this.value;
      return isActive && isAvailable;
    },

    currency() {
      const { currency } = this.value;
      return currency;
    },

    isPaymentMethod() {
      return typeof this.paymentMethod === 'object';
    },

    paymentMethod() {
      const { payment_method: paymentMethod } = this.value;
      return paymentMethod;
    },

    currentAsset() {
      return this.paymentMethod.asset;
    },

    fiatName() {
      return this.isPaymentMethod ? this.paymentMethod.name : this.currency.code;
    },
  },

  methods: {
    updateServiceStatus(event) {
      const { target: { checked } } = event;
      const { id = null } = this.value;

      this.$emit('toggle-payment-option-availability', {
        id, checked,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.ticker {
  display: flex;
  align-items: center;

  &__checkbox {
    margin-right: 12px;
  }

  &__crypto {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    line-height: 20px;
    font-family: $font-regular;

    &_top {
      display: flex;
      align-items: center;
    }
  }

  &__short,
  &__blockchain {
    color: $secondary-text-color;
  }

  &__short {
    margin-left: 8px;
  }
}
</style>
