import Vue from 'vue';

import SidebarMenuMobile from './SidebarMenu.mobile.vue';
import SidebarMenuDesktop from './SidebarMenu.desktop.vue';

export default Vue.component('SidebarMenu', {
  functional: true,
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
  render(h, ctx) {
    return h('div', ctx.data, [
      h(SidebarMenuMobile, {
        props: {
          routes: ctx.props.routes,
        },
      }),
      h(SidebarMenuDesktop, {
        props: {
          routes: ctx.props.routes,
        },
      }),
    ]);
  },
});
