<template>
  <form
    class="password-restore__form"
    @submit.prevent="sendOtpCodeWithId"
  >
    <i
      class="icon icon-caret password-restore__form-icon password-restore__form-icon_back"
      @click="$emit('go-back')"
    />

    <i class="icon icon-email-otp password-restore__form-icon" />

    <span class="password-restore__form-title">
      {{ $t('partner-portal.password-restore.code-from-email') }}
    </span>

    <div class="password-restore__form-input-group">
      <div class="password-restore__form-label-group">
        <label class="password-restore__form-label">
          {{ $t('partner-portal.password-restore.enter-code') }}
        </label>
      </div>

      <AppInput
        v-model="code"
        :is-disabled="isLoading"
        :errors="errors"
        class="password-restore__form-input"
        name="code"
        type="text"
        @keydown.native.space.prevent
      />
    </div>

    <div class="password-restore__form-resend-info">
      <button
        type="button"
        class="password-restore__form-resend-button"
        :class="{'password-restore__form-resend-button_disabled': isResentDisabled}"
        @click.stop="resendOtpCode"
      >
        {{ $t('partner-portal.password-restore.resend-code') }}
      </button>

      <span
        v-if="expirationTime"
        class="password-restore__form-resend-time"
      >
        {{ expirationTime }}
      </span>
    </div>

    <AppButton
      type="submit"
      :text="$t('partner-portal.password-restore.send')"
      :is-loading="isLoading"
      class="btn--primary password-restore__form-button"
    />
  </form>
</template>

<script>
import AppInput from '@/components/AppInput';
import AppButton from '@/components/AppButton';

export default {
  name: 'PasswordRestoreOtpForm',

  components: {
    AppInput,
    AppButton,
  },

  props: {
    otp: {
      type: Object,
      default: () => {},
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      code: '',

      expirationTime: null,
      codeExpirationInterval: null,
      disableResentAferClick: false,
    };
  },

  computed: {
    isResentDisabled() {
      return this.expirationTime || this.isLoading || this.disableResentAferClick;
    },
  },

  watch: {
    'otp.can_be_resent_at': {
      immediate: true,
      handler(canBeResentAt) {
        const now = new Date().getTime();
        const otpCanBeResentAt = new Date(canBeResentAt).getTime();

        if (otpCanBeResentAt > now) this.countDownTimer();
      },
    },
  },

  beforeDestroy() {
    clearInterval(this.codeExpirationInterval);
  },

  methods: {
    countDownTimer() {
      this.disableResentAferClick = true;

      const countDownDate = new Date(this.otp.can_be_resent_at).getTime();

      this.codeExpirationInterval = setInterval(() => {
        const now = new Date().getTime();
        const distance = countDownDate - now;

        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000) + 1;

        this.expirationTime = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

        if (distance < 0) {
          clearInterval(this.codeExpirationInterval);
          this.expirationTime = null;
          this.disableResentAferClick = false;
        }
      }, 1000);
    },

    sendOtpCodeWithId() {
      this.$emit('send-otp-code-with-id', {
        id: this.otp.id, code: this.code.trim(),
      });
    },

    resendOtpCode() {
      this.code = '';
      this.$emit('resend-otp-code');
    },
  },
};
</script>
