/* eslint-disable consistent-return */
import AuthService from '@/services/AuthService';
import LoggerService from '@/services/LoggerService';

export default {
  getUserToken: async () => {
    try {
      const session = await AuthService.getCurrentSession();
      if (!session) return;

      const token = session.idToken.jwtToken;
      return token;
    } catch (error) {
      LoggerService.logToConsole(error);
    }
  },

  getUserRefreshToken: async () => {
    try {
      const session = await AuthService.getCurrentSession();
      if (!session) return;

      const refreshToken = session.refreshToken.token;
      return refreshToken;
    } catch (error) {
      LoggerService.logToConsole(error);
    }
  },
};
