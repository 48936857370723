import Vue from 'vue';

const componentsRegistry = {};
const DummyComponent = () => ({});

export default {
  targets: {},

  setShownState({ key, showState, target: targetName }) {
    this.targets[targetName].component = showState ? componentsRegistry[key] : DummyComponent;
    this.targets[targetName].isShown = showState;
  },

  registerTarget({ name }) {
    this.targets[name] = Vue.observable({
      isShown: false,
      isLoading: false,
      component: DummyComponent,
    });
    return this.targets[name];
  },

  unregisterTarget({ name }) {
    delete this.targets[name];
  },

  registerModal({ key, component }) {
    componentsRegistry[key] = component;
  },

  unregisterModal({ key }) {
    delete componentsRegistry[key];
  },
};
