<template>
  <div
    v-click-outside="closeDropdown"
    class="dropdown-filter"
  >
    <button
      class="dropdown-filter__button"
      @click="toggleDropdown"
    >
      <span class="dropdown-filter__button-label">{{ dropdownTitle }}</span>

      <i class="icon icon-bottom-arrow dropdown-filter__button-icon" />
    </button>

    <div
      v-if="isDropdownVisible"
      class="dropdown-filter__content"
    >
      <div class="dropdown-filter__search">
        <input
          v-model="searchQuery"
          type="text"
          :placeholder="$t('partner-portal.common.search')"
          class="dropdown-filter__search-input"
        >
      </div>
      <div class="dropdown-filter__selectors">
        <button
          class="dropdown-filter__selectors-button"
          @click="selectAllFilters"
        >
          {{ $t('partner-portal.general.filters.select-all') }}
        </button>
        <button
          class="dropdown-filter__selectors-button"
          @click="deselectAllFilters"
        >
          {{ $t('partner-portal.general.filters.deselect-all') }}
        </button>
      </div>
      <div class="dropdown-filter__list">
        <Checkbox
          v-for="item in visibleItems"
          :key="item.key"
          class="dropdown-filter__list-item"
          :disabled="item.disabled"
          :label="item.value"
          :checked="isSelectedItem(item.key)"
          @change="toggleElement(item)"
        />
      </div>
      <div class="dropdown-filter__footer">
        <button
          type="button"
          class="btn btn--sm btn--primary dropdown-filter__action-btn"
          @click="applyChanges"
        >
          {{ $t('partner-portal.buttons.apply') }}
        </button>
        <button
          type="button"
          class="btn btn--sm dropdown-filter__action-btn dropdown-filter__action-btn--reset"
          @click="discardChanges"
        >
          {{ $t('partner-portal.buttons.reset') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox';

export default {
  name: 'DropdownFilter',

  components: {
    Checkbox,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    activeItems: {
      type: Array,
      default: () => ([]),
    },
  },

  data: () => ({
    searchQuery: '',
    isDropdownVisible: false,

    selectedElements: [],
  }),

  computed: {
    dropdownTitle() {
      if (!this.activeItems.length || !this.items || !this.items.length) return this.title;

      const firstItem = this.items.find(item => item.key === this.activeItems[0]);
      const restCount = this.activeItems.length - 1;

      let titleString = `${firstItem.value} `;
      if (restCount) {
        titleString += this.$t('partner-portal.general.and-more', {
          count: restCount,
        });
      }

      return titleString;
    },
    visibleItems() {
      const { searchQuery } = this;
      if (searchQuery) {
        const queryRegex = new RegExp(searchQuery, 'ig');
        return this.items.filter(element => element.value.match(queryRegex) || element.key.match(queryRegex));
      }

      return this.items;
    },
  },

  methods: {
    closeDropdown() {
      this.selectedElements = [];
      this.isDropdownVisible = false;
    },

    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
      this.fillSelectedFields();
    },

    fillSelectedFields() {
      this.selectedElements = [...this.activeItems];
    },

    isSelectedItem(elementKey) {
      return this.selectedElements.includes(elementKey);
    },

    toggleElement(item) {
      if (this.isSelectedItem(item.key)) {
        this.selectedElements = this.selectedElements.filter(element => element !== item.key);
      } else {
        this.selectedElements = [...this.selectedElements, item.key];
      }
    },

    selectAllFilters() {
      this.selectedElements = this.items.map(item => item.key);
    },

    deselectAllFilters() {
      this.selectedElements = [];
    },

    applyChanges() {
      this.searchQuery = '';
      this.$emit('apply-changes', this.selectedElements);
      this.toggleDropdown();
    },
    discardChanges() {
      this.searchQuery = '';
      this.selectedElements = [];
      this.$emit('discard-changes');
      this.toggleDropdown();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

$dropdown-background-color: $primary;
$dropdown-border-color: $secondary;
$dropdown-scrollbar-color: $secondary;

.dropdown-filter {
  position: relative;

  &__content {
    position: absolute;
    border: 1px solid $dropdown-border-color;
    box-sizing: border-box;
    border-radius: 12px;
    background: $dropdown-background-color;
    padding: 8px;
    margin-top: 10px;
    width: 240px;
    z-index: 100;
  }

  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__button-label {
    color: $secondary;
  }

  &__button-icon {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }

  &__search {
    padding-bottom: 8px;
    background: $dropdown-background-color;
  }

  &__search-input {
    width: 100%;
    height: 36px;
    background: $primary url('~@/assets/icons/search.svg') no-repeat 13px center;
    padding: 8px 12px 8px 36px;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 15px;
    font-family: $font-regular;
    border: 1px solid $dropdown-search-input-border-color;

    &:focus {
      outline: none;
    }
  }

  &__selectors {
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    padding: 0 10px 6px;
  }

  &__selectors-button {
    font-size: 13px;
    cursor: pointer;
    line-height: 16px;
    font-family: $font-regular;

    &:hover {
      text-decoration: underline;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    max-height: 210px;
    overflow-y: auto;
    scrollbar-width: 4px;
    scrollbar-gutter: stable;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $dropdown-scrollbar-color;
      border-radius: 11px;
    }
  }

  &__list-item {
    padding: 9px;

    &:hover {
      background-color: $background-color;
    }
  }

  &__footer {
    padding-top: 8px;
    background: $dropdown-background-color;
    display: flex;
    justify-content: space-between;
  }

  &__action-btn {
    flex-basis: 50%;
    min-width: auto;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &--reset {
      color: $secondary;
      background-color: $background-color;
      border: 2px solid transparent;

      &:hover,
      &:active {
        background: $primary;
        color: $secondary;
        border: 2px solid $secondary;
      }
    }
  }
}
</style>
