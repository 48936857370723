<template>
  <ul class="desktop-nav">
    <li
      v-for="route in routes"
      :key="route.name"
      class="desktop-nav__item"
    >
      <router-link
        v-t="route.name"
        :to="route.link"
        :data-testid="route.testId"
        exact-active-class="desktop-nav__item-link--active"
        class="desktop-nav__item-link"
        :class="{ 'desktop-nav__item-link--live': route.live }"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavDesktop',
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/icons';
@import '~@/assets/scss/variables';

.desktop-nav {
  display: none;
  align-items: center;

  &__item {
    display: flex;
  }

  &__item-link {
    font-size: 15px;
    color: $secondary;
    padding: 5px 12px;
    border-radius: 4px;
    text-decoration: none;
    margin-right: 8px;
    transition: .3s;
    position: relative;

    &--active,
    &:hover {
      color: $primary;
      background: $secondary;
    }

    &--live {
      &:after {
        @extend .icon;
        @extend .icon-live;

        content: ' ';
        position: absolute;
        top: -4px;
        right: -5px;
        width: 22px;
        height: 10px;
      }
    }
  }

}

@media screen and (min-width: $tablet-breakpoint) {
  .desktop-nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
  }
}
</style>
