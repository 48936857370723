<template>
  <div
    class="transactions-list"
    :class="{ 'transactions-list--full': !canLoadMoreTransactions }"
  >
    <div class="transactions-list__header">
      <router-link
        v-if="showLink"
        to="transactions"
        class="transactions-list__title transactions-list__title--link"
      >
        {{ title }}
      </router-link>

      <div
        v-else
        class="transactions-list__title"
      >
        {{ title }}
      </div>

      <div
        v-if="shouldDisplayDownloadButton"
        class="transactions-list__csv"
        @click="downloadReport"
      >
        <i class="icon icon-download transactions-list__csv-image" />
        <div class="transactions-list__csv-text">
          {{ $t('partner-portal.transaction.table.download') }}
        </div>
        <div class="transactions-list__csv-text transactions-list__csv-text_mobile">
          {{ $t('partner-portal.transaction.table.csv') }}
        </div>
      </div>

      <a
        ref="downloadButton"
        class="transactions-list__anchor"
        donwload="transactions-report.csv"
        :href="reportUrl"
      />

      <div v-if="isLoadingReport">
        <i class="icon icon-spinner-black transactions-list__report-loader" />
      </div>
    </div>

    <Loader
      v-if="isLoadingTransactions"
      class="transactions-list__loader"
    />

    <MissingDataPlaceholder v-else-if="noTransactions" />

    <div
      v-else
      class="transactions-list__content"
    >
      <Table
        class="transactions-list__table"
        :columns="columns"
        :rows="transactionsList"
        @open-transaction-modal="openTransactionModal"
      />

      <Modal
        :show.sync="isDetailsModalVisible"
        :component="$options.modals.TransactionDetailsModal"
        :transaction="selectedTransaction"
        :is-transaction-loading="isDetailsModalLoading"
        @close="onCloseDetails"
      />

      <div
        v-if="canLoadMoreTransactions"
        class="transactions-list__load-more"
        :class="{
          'transactions-list__load-more--loading': isLoadingMore,
        }"
        @click="loadMore"
      >
        <div
          v-if="isLoadingMore"
          class="transactions-list__load-more-content--loading"
        >
          <i class="icon icon-spinner-black transactions-list__load-more-content-spinner-image" />
        </div>

        <div
          v-else
          class="transactions-list__load-more-content"
        >
          <i class="icon icon-load-more transactions-list__load-more-content-image" />
          <div class="transactions-list__load-more-content-text">
            {{ $t('partner-portal.transaction.table.load-more') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoggerService from '@/services/LoggerService';

import MissingDataPlaceholder from '@/components/MissingDataPlaceholder';
import Table, { DateCell, AmountCell } from '@/components/Table';
import { Modal, TransactionDetailsModal } from '@/components/Modal';
import Loader from '@/components/Loader';
import { getValueAndCurrency, isFiatToCrypto } from '@/utils/transactions';
import { formatCurrency, formatCurrencyForTransaction } from '@/utils';

import StatusCell from './Cell/StatusCell';
import DetailsCell from './Cell/DetailsCell';

const TRANSACTION_COMPLETE_STATUS = 'completed';
const DEFAULT_TRANSACTIONS_LIMIT = 10;

export default {
  name: 'TransactionsList',

  components: {
    Table,
    Modal,
    MissingDataPlaceholder,
    Loader,
  },

  modals: {
    TransactionDetailsModal,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    showLink: {
      type: Boolean,
      default: false,
    },

    reportUrl: {
      type: String,
      default: '',
    },

    showDownload: {
      type: Boolean,
      default: false,
    },

    settings: {
      type: Object,
      default: () => ({
        limit: 10,
      }),
    },

    canLoadMoreTransactions: {
      type: Boolean,
      default: false,
    },

    transactions: {
      type: Array,
      default: () => ([]),
    },

    loadTransactionDetails: {
      type: Function,
      default: () => ({}),
    },

    isLoadingMore: {
      type: Boolean,
      required: true,
    },

    isLoadingTransactions: {
      type: Boolean,
      required: true,
    },

    isLoadingReport: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isDetailsModalVisible: false,
    isDetailsModalLoading: false,

    selectedTransaction: {},
  }),

  computed: {
    shouldDisplayDownloadButton() {
      const areTransactionsVisible = !this.isLoadingTransactions
        && !this.isLoadingReport
        && this.transactions?.length;

      return this.showDownload && areTransactionsVisible;
    },

    shouldShowBigLoader() {
      return this.settings.limit > DEFAULT_TRANSACTIONS_LIMIT;
    },

    noTransactions() {
      return !this.transactions?.length && !this.isLoadingTransactions;
    },

    columns() {
      return [{
        name: this.$t('partner-portal.transaction.table.head.id'),
        key: 'id',
      }, {
        name: this.$t('partner-portal.transaction.table.head.createdAt'),
        key: 'created_at',
        component: DateCell,
      }, {
        name: this.$t('partner-portal.transaction.table.head.amount'),
        key: 'amount',
        format: row => {
          const { spent_original: { value, currency } } = row.amounts;
          return isFiatToCrypto(row.gateway) ? formatCurrencyForTransaction(value, currency) : getValueAndCurrency(row.amounts.spent_original);
        },
      }, {
        name: this.$t('partner-portal.transaction.table.head.fiatAmount'),
        key: 'fiatAmount',
        format: row => {
          const { spent_fiat: { value, currency } } = row.amounts;
          return formatCurrencyForTransaction(value, currency);
        },
      }, {
        name: this.$t('partner-portal.transaction.table.head.amountReceived'),
        key: 'amountReceived',
        format: row => {
          const { received_original: { value, currency } } = row.amounts;
          return isFiatToCrypto(row.gateway) ? getValueAndCurrency(row.amounts.received_original) : formatCurrencyForTransaction(value, currency);
        },
      }, {
        name: this.$t('partner-portal.transaction.table.head.profit'),
        key: 'profit',
        format: row => {
          const { status, amounts: { fee_fiat: feeFiat = null } } = row;
          if (status !== TRANSACTION_COMPLETE_STATUS || !feeFiat) return 0;

          return formatCurrency(
            feeFiat.value,
            feeFiat.currency,
          );
        },
        component: AmountCell,
      }, {
        name: this.$t('partner-portal.transaction.table.head.status'),
        key: 'status',
        component: StatusCell,
      }, {
        name: this.$t('partner-portal.transaction.table.head.info'),
        key: 'info',
        component: DetailsCell,
      }];
    },

    transactionsList() {
      const { openTransactionModal, transactions = [] } = this;
      const transactionsWithDetailsFunction = transactions.map(transaction => ({
        ...transaction,
        rowFunction: () => openTransactionModal(transaction),
      }));

      return transactionsWithDetailsFunction;
    },
  },

  watch: {
    reportUrl(updatedReportUrl) {
      if (updatedReportUrl) {
        this.$nextTick(() => {
          this.$refs.downloadButton.click();
        });
      }
    },
  },

  methods: {
    downloadReport() {
      this.$emit('download-report');
    },

    loadMore() {
      if (!this.isLoadingTransactions && !this.isLoadingMore) {
        const updatedLimit = this.transactions.length + this.settings.limit;

        this.$emit('load-more', {
          limit: updatedLimit,
        });
      }
    },

    openTransactionModal(transaction) {
      this.isDetailsModalVisible = true;
      this.isDetailsModalLoading = true;

      const { id } = transaction;
      this.loadTransactionDetails(id)
        .then(transactionDetails => {
          this.isDetailsModalLoading = false;
          this.selectedTransaction = transactionDetails;
        })
        .catch(error => {
          LoggerService.logToConsole(error);
          this.isDetailsModalVisible = false;
          this.isDetailsModalLoading = false;
        });
    },

    onCloseDetails() {
      this.selectedTransaction = {};
      this.isDetailsModalVisible = false;
      this.isDetailsModalLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/icons';

.transactions-list {
  width: 100%;
  background: white;
  padding: 8px 20px 4px;
  border-radius: 8px;
  box-shadow: 0 2px 8px $primary;
  box-sizing: border-box;

  @media screen and (min-width: $tablet-breakpoint) {
    padding: 22px 24px 0;
  }

  &--full {
    padding-bottom: 16px;

    @media screen and (min-width: $tablet-breakpoint) {
      padding-bottom: 22px;
    }
  }

  &__anchor {
    position: absolute;
    top: -10000px;
    left: -10000px;
  }

  &__report-loader {
    width: 22px;
    height: 22px;
    animation: spin 1s linear infinite;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
  }

  &__loader {
    padding: 90px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 90px 0;
  }

  &__loading-image {
    width: 32px;
    height: 32px;
    animation: spin 1s linear infinite;
  }

  &__title {
    font-size: 17px;
    line-height: 21px;

    @media screen and (min-width: $tablet-breakpoint) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__title--link {
    color: $secondary;
    text-decoration: none;
    border-bottom: 1px solid $link-border-color;
  }

  &__csv {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-family: $font-regular;
    cursor: pointer;
  }

  &__csv-text {
    display: none;
    border-bottom: 1px solid $link-border-color;

    @media screen and (min-width: $tablet-breakpoint) {
      display: block;
    }
  }

  &__csv-text_mobile {
    display: block;

    @media screen and (min-width: $tablet-breakpoint) {
      display: none;
    }
  }

  &__csv-image {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__content--empty {
    padding: 92px;
    text-align: center;
  }

  &__load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    width: 100%;
    cursor: pointer;

    @media screen and (min-width: $tablet-breakpoint) {
      padding: 25px 0;
    }

    &--loading {
      padding: 16px 0;

      @media screen and (min-width: $tablet-breakpoint) {
        padding: 18.5px 0;
      }
    }
  }

  &__load-more-content {
    display: flex;
  }

  &__load-more-content-image {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }

  &__load-more-content-spinner-image {
    height: 19px;
    width: 19px;
    animation: spin 1s linear infinite;

    @media screen and (min-width: $tablet-breakpoint) {
      height: 32px;
      width: 32px;
    }
  }

  &__load-more-content-text {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
    border-bottom: 1px solid $link-border-color;
  }

  ::v-deep .table__th,
  ::v-deep .table__td {
    &--id {
      flex: 0 0 180px;
      justify-content: flex-start;
    }

    &--created_at {
      flex: 0 0 140px;
      justify-content: flex-start;
    }

    &--amount {
      flex: 0 0 125px;
      justify-content: flex-end;
    }

    &--fiatAmount {
      flex: 0 0 120px;
      justify-content: flex-end;
    }

    &--amountReceived {
      flex: 0 0 155px;
      justify-content: flex-end;
    }

    &--profit {
      flex: 0 0 120px;
      padding-right: 35px;
      justify-content: flex-end;
    }

    &--status {
      flex: 0 0 60px;
      justify-content: flex-start;
    }

    &--info {
      flex: 0 0 30px;
      justify-content: flex-start;
    }
  }
}
</style>
