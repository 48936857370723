<template>
  <div class="cell">
    <i :class="`icon icon-${transactionStatus} cell__image`" />
  </div>
</template>

<script>
import { formatDate } from '@/utils';

export default {
  name: 'StatusCell',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    transactionStatus() {
      return this.value.status;
    },
    transactionUpdated() {
      return formatDate(this.value.updated_at);
    },
    transactionInProgress() {
      return this.value.status === 'in_progress';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/icons';
@import '~@/assets/scss/variables';

.cell {
  display: flex;

  &__image {
    height: 16px;
    width: 16px;
  }
}
</style>
