let head;
let table;
let tableWidth = 0;

const resetTableParams = () => {
  head = null;
  table = null;
  tableWidth = 0;
};

const portrait = window.matchMedia('(orientation: portrait)');

const getVisibleAreaWidth = () => {
  const { width: visibleAreaWidth } = table.getBoundingClientRect();
  return visibleAreaWidth;
};

const checkAppliableStyles = () => {
  const visibleAreaWidth = getVisibleAreaWidth();

  // Just the case when table fully fits the viewport
  if (visibleAreaWidth >= tableWidth) {
    table.classList.remove('table--right-shadow');
    table.classList.remove('table--left-shadow');
    table.classList.remove('table--both-shadow');
    return;
  }

  if (visibleAreaWidth < tableWidth) {
    // Just drop scroll left position to default;
    table.scrollLeft = 0;
    table.classList.add('table--right-shadow');
  }
};

const shadowListener = event => {
  const { scrollLeft: currentScrollLeft } = event.target;
  const visibleAreaWidth = getVisibleAreaWidth();

  // Full left position of the table
  if (currentScrollLeft === 0) {
    table.classList.add('table--right-shadow');
    table.classList.remove('table--left-shadow');
    table.classList.remove('table--both-shadow');
  }

  // Full right position of the table
  if (currentScrollLeft + visibleAreaWidth === tableWidth) {
    table.classList.add('table--left-shadow');
    table.classList.remove('table--right-shadow');
    table.classList.remove('table--both-shadow');
  }

  // Somewhere between left and right sides of the table
  if (currentScrollLeft > 0 && currentScrollLeft + visibleAreaWidth < tableWidth) {
    table.classList.add('table--both-shadow');
    table.classList.remove('table--right-shadow');
    table.classList.remove('table--left-shadow');
  }
};

const rotateDeviceListener = () => {
  table.removeEventListener('scroll', shadowListener);
  table.addEventListener('scroll', shadowListener);
  checkAppliableStyles();
};

export const attachShadowToTable = el => {
  table = el;
  head = table.querySelector('.table__head');
  const cells = head.querySelectorAll('.table__th');

  cells.forEach(cell => {
    const { width: cellWidth } = cell.getBoundingClientRect();
    tableWidth += Math.floor(cellWidth);
  });

  portrait.addEventListener('change', rotateDeviceListener);

  checkAppliableStyles();
  table.addEventListener('scroll', shadowListener);
};

export const removeAllListeners = () => {
  portrait.removeEventListener('change', rotateDeviceListener);
  table.removeEventListener('scroll', shadowListener);
  resetTableParams();
};
