<template>
  <div
    class="missing-data"
  >
    <i :class="`icon icon-${icon} missing-data__image`" />
    <p class="missing-data__text">
      {{ $t(description) }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'MissingDataPlaceholder',

  props: {
    icon: {
      type: String,
      default: 'ban',
      validator: icon => ['ban', 'alert-red'].includes(icon),
    },

    description: {
      type: String,
      default: 'partner-portal.errors.no-charts-data',
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.missing-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 92px;

  &__image {
    width: 32px;
    height: 32px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: $tablet-breakpoint) {
      width: 64px;
      height: 64px;
    }
  }

  &__text {
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    margin: 10px 0 0 0;
    font-family: $font-regular;
    color: $secondary-text-color;

    @media screen and (min-width: $tablet-breakpoint) {
      font-size: 18px;
      line-height: 24px;
      font-family: $font-bold;
      max-width: 270px;
    }
  }
}
</style>
