import { apiClient } from '@/network';
import LoggerService from '@/services/LoggerService';

export default {
  namespaced: true,

  state: {
    persons: [],
  },

  getters: {
    persons: state => state.persons,
  },

  mutations: {
    clearStore(state) {
      state.persons = [];
    },

    setPersons(state, persons) {
      state.persons = persons;
    },
  },

  actions: {
    clearStore({ commit }) {
      commit('clearStore');
    },

    async getPersons({ commit }) {
      try {
        const { data: { persons } } = await apiClient.get('/v1/account-persons');

        commit('setPersons', persons);
      } catch (error) {
        LoggerService.logToConsole(error);
      }
    },

    async updatePersons({ commit }, updatedPersons) {
      try {
        const { data } = await apiClient.patch('/v1/account-persons', {
          persons: updatedPersons,
        });

        commit('setPersons', updatedPersons);

        return data;
      } catch (error) {
        throw error?.response?.data || error;
      }
    },
  },
};
