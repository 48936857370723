import { apiClient } from '@/network';
import LoggerService from '@/services/LoggerService';
import { buildQueryWithFilters, buildBodyRequestForReport } from '@/utils/filters';
import { getDateInISO } from '@/utils/charts';
import ReportService from '@/services/ReportService';
import NotificationService from '@/services/NotificationService';
import SubscriptionService from '@/services/SubscriptionService';

const BASE_TOP_COUNTRIES_LIMIT = 20;
const BASE_TOP_COUNTRIES_MORE_LIMIT = 10;

const TOP_COUNTRIES_TYPE_REPORT = 'top_countries';
const TOP_COUNTRIES_PAGE_SUBSCRIPTION_KEY = 'top-countries-page-top-countries';

export default {
  namespaced: true,

  state: {
    topCountries: [],

    isLoadingTopCountries: false,
    isTopCountriesLoadingMore: false,

    canLoadMoreTopCountries: true,

    filters: {
      limit: BASE_TOP_COUNTRIES_LIMIT,
      offset: 0,
      from: null,
      to: null,
      gateways: [],
      currencies: [],
    },

    isLoadingReport: false,
    reportUrl: null,
  },

  getters: {
    topCountriesList: state => state.topCountries,

    isLoadingTopCountries: state => state.isLoadingTopCountries,
    isTopCountriesLoadingMore: state => state.isTopCountriesLoadingMore,

    canLoadMoreTopCountries: state => state.canLoadMoreTopCountries,

    selectedDateFilter: state => ({
      start: state.filters.from,
      end: state.filters.to,
    }),
    selectedGatewaysList: state => state.filters.gateways,
    selectedCurrenciesList: state => state.filters.currencies,

    isLoadingReport: state => state.isLoadingReport,
    reportUrl: state => state.reportUrl,

    activeProductId: (state, getters, rootState, rootGetters) => rootGetters['accountProductsStore/activeProductId'],
  },

  mutations: {
    clearStore(state) {
      state.topCountries = [];

      state.isLoadingTopCountries = false;
      state.isTopCountriesLoadingMore = false;

      state.canLoadMoreTopCountries = true;

      state.filters = {
        limit: BASE_TOP_COUNTRIES_LIMIT,
        offset: 0,
        from: null,
        to: null,
        gateways: [],
        currencies: [],
      };

      state.isLoadingReport = false;
      state.reportUrl = null;
    },

    setTopCountries(state, items) {
      state.topCountries = items;
    },

    setLoadingTopCountries(state, loading) {
      state.isLoadingTopCountries = loading;
    },

    setTopCountriesLoadingMore(state, loading) {
      state.isTopCountriesLoadingMore = loading;
    },

    setAbilityLoadMoreTopCountries(state, payload) {
      state.canLoadMoreTopCountries = payload;
    },

    setDateFilter(state, { from, to }) {
      state.filters.from = getDateInISO(from, true);
      state.filters.to = getDateInISO(to, false);
    },

    setGateways(state, gateways) {
      state.filters.gateways = gateways;
    },

    setCurrencies(state, currencies) {
      state.filters.currencies = currencies;
    },

    setTopCountriesOffset(state, offset) {
      state.filters.offset = offset;
    },

    setTopCountriesLimit(state, limit) {
      state.filters.limit = limit;
    },

    appendTopCountries(state, items) {
      state.topCountries = state.topCountries.concat(items);
    },

    setReportLoading(state, loading) {
      state.isLoadingReport = loading;
    },

    setReportUrl(state, url) {
      state.reportUrl = url;
    },
  },

  actions: {
    clearStore({ commit }) {
      commit('clearStore');
    },

    async loadTopCountries({ commit, dispatch }) {
      commit('setLoadingTopCountries', true);

      const topCountries = await dispatch('loadCountries', {
        offset: 0, limit: BASE_TOP_COUNTRIES_LIMIT + 1,
      });

      commit('setTopCountries', topCountries);
      commit('setLoadingTopCountries', false);
    },

    setDateFilter({ commit }, datesFilter) {
      commit('setDateFilter', datesFilter);
    },

    setGatewaysFilter({ commit }, gatewaysFilter) {
      commit('setGateways', gatewaysFilter);
    },

    setCurrenciesFilter({ commit }, currenciesFilter) {
      commit('setCurrencies', currenciesFilter);
    },

    async getMoreTopCountries({ state, commit, dispatch }) {
      commit('setTopCountriesLoadingMore', true);

      const topCountries = await dispatch('loadCountries', {
        offset: state.filters.offset + state.filters.limit,
        limit: BASE_TOP_COUNTRIES_MORE_LIMIT + 1,
      });

      commit('appendTopCountries', topCountries);
      commit('setTopCountriesLoadingMore', false);
    },

    async loadCountries({ state, commit, getters }, { offset, limit }) {
      try {
        commit('setAbilityLoadMoreTopCountries', true);
        commit('setTopCountriesOffset', offset);
        commit('setTopCountriesLimit', limit);

        const query = buildQueryWithFilters(state.filters);
        const { data: { data: topCountries } } = await apiClient.get(`/v1/analytics/top-countries?${query}`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        commit('setTopCountriesLimit', limit - 1);

        if (topCountries.length <= state.filters.limit) {
          commit('setAbilityLoadMoreTopCountries', false);

          return topCountries;
        }

        topCountries.pop();

        return topCountries;
      } catch (error) {
        LoggerService.logToConsole(error);
      }

      return [];
    },

    downloadTopCountriesReport({ state, commit, getters }) {
      commit('setReportLoading', true);
      commit('setReportUrl', null);

      const filters = {
        ...state.filters,
      };

      delete filters.offset;
      delete filters.limit;

      const reportFilters = buildBodyRequestForReport(filters);

      ReportService.requestReport({
        type: TOP_COUNTRIES_TYPE_REPORT,
        subscriptionKey: TOP_COUNTRIES_PAGE_SUBSCRIPTION_KEY,
        filters: reportFilters,
        productHeaderId: getters.activeProductId,
        callback: (error, url) => {
          commit('setReportLoading', false);

          if (error) {
            NotificationService.notifyAboutError('partner-portal.errors.error-downloading-report');
            return null;
          }

          commit('setReportUrl', url);
          return url;
        },
      });
    },

    unsubscribeFromTopCountriesReport() {
      ReportService.clearExpirationTimerId();
      SubscriptionService.unsubscribe(TOP_COUNTRIES_PAGE_SUBSCRIPTION_KEY);
    },
  },
};
