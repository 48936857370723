<template>
  <div
    class="confirmation-cell"
    :class="{
      'confirmation-cell--has-link': hasConfirmationLink,
    }"
  >
    <a
      ref="confirmationAnchor"
      :href="confirmationLink"
      class="confirmation-cell__link"
      download="payouts-confirmation-report.csv"
    />

    <span @click="getConfirmationLink">
      {{ confirmationSource }}
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ConfirmationCell',

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    confirmationLink: null,
  }),

  computed: {
    hasConfirmationLink() {
      const { confirmation } = this.value;
      return !!confirmation;
    },

    confirmationSource() {
      const { confirmation } = this.value;
      if (!this.hasConfirmationLink) return null;

      return this.$t(`partner-portal.general.confirmation.${confirmation}`);
    },
  },

  methods: {
    ...mapActions('payoutsStore', {
      loadPayoutConfirmation: 'loadConfirmation',
    }),

    getConfirmationLink() {
      if (this.hasConfirmationLink) {
        const { id } = this.value;
        this.loadPayoutConfirmation(id)
          .then(link => {
            this.confirmationLink = link;
            this.$nextTick(() => {
              this.$refs.confirmationAnchor.click();
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.confirmation-cell {
  &--has-link {
    cursor: pointer;
    border-bottom: 1px solid $link-border-color;
  }

  &__link {
    position: absolute;
    left: -100000px;
    top: -100000px;
  }
}
</style>
