<template>
  <div
    ref="wrapper"
    :class="{
      'is-open': isOpen,
      'is-bottom': bottom,
      'is-left': left,
      'is-fixed': fixed,
      'auto-width': autoWidth
    }"
    class="tooltip"
    @mouseenter="handleHover"
    @mouseleave="handleHoverOut"
    @touchstart="handleTouch"
  >
    <i class="icon icon-info tooltip__icon" />

    <div
      ref="tooltip"
      class="tooltip__content-wrapper"
    >
      <div class="tooltip__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
// TODO discuss about refactoring this Scada component
export default {
  name: 'Tooltip',

  props: {
    bottom: {
      type: Boolean, required: false, default: false,
    },
    left: {
      type: Boolean, required: false, default: false,
    },
    fixed: {
      type: Boolean, required: false, default: false,
    },
    autoWidth: {
      type: Boolean, required: false, default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      scrollableParent: null,
    };
  },

  destroyed() {
    document.removeEventListener('touchstart', this.handleCloseAll);

    window.removeEventListener('scroll', this.handleParentScroll);

    if (this.scrollableParent) {
      this.scrollableParent.removeEventListener(
        'scroll',
        this.handleParentScroll,
      );
    }
  },

  methods: {
    handleHover() {
      this.isOpen = true;

      const leftSpace = this.$refs.wrapper.getBoundingClientRect().x;
      const rightSpace = window.innerWidth
        - this.$refs.wrapper.getBoundingClientRect().x
        - this.$refs.wrapper.getBoundingClientRect().width;

      if (this.fixed) {
        this.positionFixed();
      } else {
        this.positionStatic(leftSpace, rightSpace);
      }
    },

    positionStatic(leftSpace, rightSpace) {
      const offset = this.autoWidth ? 0 : 260;

      if (leftSpace < offset) {
        if (leftSpace > rightSpace) {
          this.$refs.tooltip.style.right = `-${rightSpace - 24}px`;
          this.$refs.tooltip.style.left = 'auto';
        } else {
          this.$refs.tooltip.style.left = `-${leftSpace - 24}px`;
          this.$refs.tooltip.style.right = 'auto';
        }
      } else {
        this.$refs.tooltip.style = [];
      }
    },

    positionFixed() {
      const { wrapper } = this.$refs;
      const toggleRect = wrapper.getBoundingClientRect();
      const { tooltip } = this.$refs;
      const tooltipRect = tooltip.getBoundingClientRect();

      const xShift = this.left ? toggleRect.width - tooltipRect.width : '';
      const yShift = this.bottom ? toggleRect.height : tooltipRect.height * -1;

      tooltip.style.top = `${toggleRect.y + yShift}px`;
      tooltip.style.left = `${toggleRect.x + xShift}px`;

      this.scrollableParent = wrapper.closest('.table-scroll-wrapper');

      this.scrollableParent.addEventListener('scroll', this.handleParentScroll);

      window.addEventListener('scroll', this.handleParentScroll);
    },

    handleHoverOut() {
      this.isOpen = false;

      if (this.fixed) window.removeEventListener('scroll', this.handleParentScroll);

      if (this.scrollableParent) {
        this.scrollableParent.removeEventListener(
          'scroll',
          this.handleParentScroll,
        );
      }
    },

    handleTouch() {
      if (!this.isOpen) {
        this.handleHover();

        document.addEventListener('touchstart', this.handleCloseAll);
      } else {
        this.handleHoverOut();
      }
    },

    handleParentScroll() {
      this.handleHoverOut();
    },

    handleCloseAll(e) {
      const el = this.$refs.wrapper;

      const { target } = e;

      if (el !== target && !el.contains(target)) {
        this.isOpen = false;

        document.removeEventListener('click', this.handleCloseAll);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

$tooltip-content-color: $primary;
$tooltip-content-background-color: $secondary;

.tooltip {
  position: relative;
  display: inline-block;
  line-height: inherit;
  cursor: pointer;

  &__content-wrapper {
    position: absolute;
    top: auto;
    bottom: 100%;
    left: 0;
    right: auto;
    z-index: 990;
    padding: 6px 0;
    opacity: 0;
    transform: translate3d(0, 4px, 0);
    visibility: hidden;
    transition: opacity 0.2s linear, transform 0.2s linear,
      visibility 0s linear 0.2s;
  }

  &__icon {
    width: 16px;
    height: 16px;
  }

  &.is-bottom &__content-wrapper {
    top: 100%;
    bottom: auto;
    transform: translate3d(0, -4px, 0);
  }

  &.is-left &__content-wrapper {
    right: 20px;
    top: -20px;
    left: auto;
  }

  &.is-fixed &__content-wrapper {
    position: fixed;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }

  &.is-open &__content-wrapper {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s linear, transform 0.2s linear,
      visibility 0s linear 0s;
  }

  &__content {
    position: relative;
    background: $tooltip-content-background-color;
    opacity: 0.8;
    box-shadow: 0px 2px 4px $tooltip-border-color;
    border-radius: 6px;
    padding: 8px;
    color: $tooltip-content-color;
    width: 224px;
    max-width: 80vw;
    font-size: 13px;
    line-height: 1.33;
    text-align: left;
  }

  &.auto-width &__content {
    width: auto;
  }
}
</style>
