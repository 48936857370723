<template>
  <main>
    <DevelopersLayoutSidebar />
    <router-view />
  </main>
</template>

<script>
import DevelopersLayoutSidebar from './DevelopersLayoutSidebar';

export default {
  name: 'DevelopersLayout',
  components: {
    DevelopersLayoutSidebar,
  },
};
</script>
