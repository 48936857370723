<template>
  <div
    v-if="canToggleChildRow"
    class="expand"
  >
    <i
      class="icon icon-bottom-arrow expand__image"
      :class="{
        'expand__image--active': isChildOpened
      }"
      @click="expandChildTable"
    />
  </div>
</template>

<script>
export default {
  name: 'ExpandCell',

  props: {
    value: {
      type: Object,
      required: true,
    },

    isChildOpened: {
      type: Boolean,
      requied: true,
    },
  },

  data: () => ({
    isExpanded: false,
  }),

  computed: {
    canToggleChildRow() {
      const { is_available: isAvailable } = this.value;
      return isAvailable;
    },
  },

  methods: {
    expandChildTable() {
      this.toggleSubTable(this.value.id);
    },
  },

  inject: {
    toggleSubTable: {
      from: 'Table.ToggleSubTable',
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.expand {
  &__image {
    width: 16px;
    height: 16px;
    opacity: .5;
    cursor: pointer;

    &--active {
      opacity: 1;
      transform: rotate(180deg);
    }

    &:hover {
      opacity: 1;
    }
  }
}
</style>
