<template>
  <div class="anchor-cell">
    <div class="anchor-cell__value">
      <i class="icon icon-details anchor-cell__image" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailsCell',

  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.anchor-cell {
  cursor: pointer;

  &__image {
    height: 16px;
    width: 16px;
  }
}
</style>
