<template>
  <div class="date-cell">
    <div class="date-cell__date">
      <span>{{ datesObject.date.month }}</span>
      <span>{{ datesObject.date.day }}</span>
      <span v-if="notCurrentYear">{{ datesObject.date.year }}</span>
    </div>
    <div class="date-cell__time">
      {{ datesObject.time.hours }}:{{ datesObject.time.minutes }}
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils';

export default {
  name: 'DateCell',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    notCurrentYear() {
      const { year } = this.datesObject.date;
      const currentYear = new Date().getFullYear();
      return currentYear > year;
    },
    datesObject() {
      const { created_at: createdAt } = this.value;
      return formatDate(createdAt);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.date-cell {
  display: flex;
  align-items: center;
  font-family: $font-regular;
  font-size: 15px;
  line-height: 18px;

  &__date span {
    padding-right: 4px;
  }

  &__time {
    color: $secondary-text-color;
  }
}
</style>
