import LoggerService from '@/services/LoggerService';
import { i18n } from '@/i18n';

import { AWS_ERROR_CODES, AWS_ERROR_MESSAGES, AWS_NEW_PASSWORD_CODES, AWS_CHANGE_PASSWORD_CODES } from './Auth.constants';

const translate = string => i18n.tc(string);

export const validateAwsErrorResponse = error => {
  // TODO: Decide if there's need to make a Factory for building errors according to AWS response?
  let message = '';
  const errors = [];

  switch (error.code) {
    case AWS_ERROR_CODES.USER_NOT_FOUND:
      errors.push({
        key: 'email', error: translate('partner-portal.errors.user-not-authorized'),
      }, {
        key: 'password', error: '',
      });
      break;
    case AWS_ERROR_CODES.NOT_AUTHORIZED:
      if (error.message === AWS_ERROR_MESSAGES.ATTEMPS_EXCEEDED) {
        errors.push({
          key: 'email', error: translate('partner-portal.errors.password-attemps-exceeded'),
        }, {
          key: 'password', error: '',
        });
      } else if (error.message === AWS_ERROR_MESSAGES.INVALID_SESSION) {
        message = translate('partner-portal.errors.user-invalid-session');
      } else if (error.message === AWS_ERROR_MESSAGES.INCORRECT_CREDENTIALS) {
        errors.push({
          key: 'email', error: translate('partner-portal.errors.user-not-authorized'),
        }, {
          key: 'password', error: '',
        });
      } else {
        errors.push({
          key: 'email', error: translate('partner-portal.errors.user-not-authorized'),
        }, {
          key: 'password', error: '',
        });
      }
      break;
    case AWS_ERROR_CODES.USER_NOT_CONFIRMED:
      errors.push({
        key: 'email', error: translate('partner-portal.errors.user-not-confirmed'),
      }, {
        key: 'password', error: '',
      });
      break;
    case AWS_ERROR_CODES.PASSWORD_RESET_REQUIRED:
      errors.push({
        key: 'email', error: translate('partner-portal.errors.user-password-resetted'),
      }, {
        key: 'password', error: '',
      });
      break;
    case AWS_ERROR_CODES.INVALID_PASSWORD_EXCEPTION:
      errors.push({
        key: 'email',
        error: translate('partner-portal.errors.user-not-authorized'),
      }, {
        key: 'password',
        error: '',
      });
      break;
    default:
      LoggerService.logToConsole(error);
      break;
  }

  if (errors.length) return errors;

  return message;
};

export const validateNewPasswordAwsResponse = error => {
  const errors = [];
  switch (error.code) {
    case AWS_NEW_PASSWORD_CODES.INVALID_PASSWORD_EXCEPTION:
      errors.push({
        key: 'new-password',
        error: translate('partner-portal.errors.invalid-password-policy'),
      }, {
        key: 'new-password-confirmation',
        error: translate('partner-portal.errors.invalid-password-policy'),
      });
      break;
    case AWS_ERROR_CODES.NOT_AUTHORIZED:
      errors.push({
        key: 'new-password',
        error: translate('partner-portal.errors.session-is-expired'),
      }, {
        key: 'new-password-confirmation',
        error: translate('partner-portal.errors.session-is-expired'),
      });
      break;
    default:
      LoggerService.logToConsole(error);
      break;
  }

  return errors;
};

export const validateChangePasswordAwsResponse = error => {
  const errors = [];

  switch (error.code) {
    case AWS_CHANGE_PASSWORD_CODES.NOT_AUTHORIZED:
      errors.push({
        key: 'password',
        error: translate('partner-portal.errors.user-password-incorrect'),
      });
      break;
    case AWS_CHANGE_PASSWORD_CODES.LIMIT_EXCEEDED:
      errors.push({
        key: 'limit',
        error: '',
      });
      break;
    default:
      LoggerService.logToConsole(error);
      break;
  }

  return errors;
};
