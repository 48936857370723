export default {
  data() {
    return {
      inputCellValue: '',
    };
  },

  watch: {
    inputCellValue(newFee, oldFee) {
      if (!newFee) {
        this.inputCellValue = '';

        return;
      }

      const numericRegex = /^[0-9.,]+$/;
      const validRegex = /^([0-9]{1,2})?[.,]?([0-9]{1,2})?$/;

      const isNumberOrDot = numericRegex.test(newFee);
      const isFeeValid = validRegex.test(newFee);

      const feeNumber = parseFloat(newFee);

      const isFirstSymbolDot = newFee.startsWith('.');
      const isFirstSymbolComma = newFee.startsWith(',');
      const isFirtSymbolZero = newFee.startsWith('0');

      const hasValueComma = newFee.includes(',');
      const hasValueDot = newFee.includes('.');

      const isValueCommaOrDot = hasValueComma || hasValueDot;
      const isFirstSymbolCommaOrDot = isFirstSymbolDot || isFirstSymbolComma;

      if (isFirstSymbolCommaOrDot || !isNumberOrDot) {
        this.inputCellValue = oldFee;
      } else if (feeNumber > 100) {
        this.inputCellValue = oldFee;
      } else if (isFirtSymbolZero && newFee.length > 1 && !isValueCommaOrDot) {
        this.inputCellValue = oldFee;
      } else if (isFeeValid) {
        this.inputCellValue = newFee;
      } else {
        this.inputCellValue = newFee.replace(/.$/, '');
      }
    },
  },

  methods: {
    handleNumericOnly(e) {
      const hasValueComma = this.inputCellValue.includes(',');
      const hasValueDot = this.inputCellValue.includes('.');
      const isFirstSymbolZero = this.inputCellValue.startsWith('0');

      const isKeyValueComma = e.key === ',';
      const isKeyValueDot = e.key === '.';

      const isValueCommaOrDot = hasValueComma || hasValueDot;
      const isPressedKeyCommaOrDot = isKeyValueComma || isKeyValueDot;

      if (isFirstSymbolZero && !isPressedKeyCommaOrDot && !isValueCommaOrDot) {
        e.preventDefault();
      }
    },

    formatInputCellValue() {
      const isFirstSymbolZero = this.inputCellValue.startsWith('0');
      const isLastSymbolDot = this.inputCellValue.endsWith('.');
      const valueLength = this.inputCellValue.length;

      this.inputCellValue = this.inputCellValue.replace(',', '.');

      if (isLastSymbolDot) {
        this.inputCellValue = this.inputCellValue.substring(0, valueLength - 1);
      }

      const isSecondSymbolDot = this.inputCellValue[1] === '.';

      if (isFirstSymbolZero && valueLength > 1 && !isSecondSymbolDot) {
        this.inputCellValue = this.inputCellValue.substring(1, valueLength);
      }
    },
  },
};
