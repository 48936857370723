import AuthService from '@/services/AuthService';
import LocalStorageService from '@/services/LocalStorageService';
import { initialiseUserSession } from '@/services/AutoLogoutService';

import { HIDE_LOADER_EVENT, SHOW_LOADER_EVENT } from '@/utils/loader';

export default {
  namespaced: true,

  state: {
    user: null,

    isLoading: true,
  },

  getters: {
    user: state => state.user,
    isUserLoading: state => state.isLoading,
    isUserSignedIn: state => !!state.user?.signInUserSession,
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUserLoading(state, payload) {
      state.isLoading = payload;
    },
    clearUser(state) {
      state.user = null;
    },
  },

  actions: {
    setUser({ commit }, user) {
      AuthService.subscribeToLogOut(() => commit('setUser', null));
      commit('setUser', user);
    },

    setUserLoading({ commit }, payload) {
      commit('setUserLoading', payload);
    },

    async login({ commit, dispatch }, { email, password, token }) {
      commit('setUserLoading', true);

      try {
        const cognitoResponse = await AuthService.login(email, password, token);
        const isUserAlreadyCreated = cognitoResponse.signInUserSession;

        window.dispatchEvent(SHOW_LOADER_EVENT);

        if (isUserAlreadyCreated) {
          await dispatch('accountProductsStore/getProducts', '', {
            root: true,
          });
          await dispatch('featureFlagsStore/getFeatureFlags', '', {
            root: true,
          });
          await dispatch('rolesStore/getRoles', '', {
            root: true,
          });

          LocalStorageService.removeItem('accountSessionId');

          await initialiseUserSession();
        }

        dispatch('setUser', cognitoResponse);

        return cognitoResponse;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        commit('setUserLoading', false);

        window.dispatchEvent(HIDE_LOADER_EVENT);
      }
    },

    async logout({ commit, dispatch }) {
      await AuthService.logout();

      commit('clearUser');

      dispatch('clearStores', null, {
        root: true,
      });
    },

    async setNewPassword({ dispatch, state }, { password }) {
      const cognitoResponse = state.user;
      try {
        const cognitoUser = await AuthService.confirmPassword(
          cognitoResponse,
          password,
        );

        window.dispatchEvent(SHOW_LOADER_EVENT);

        await dispatch('accountProductsStore/getProducts', '', {
          root: true,
        });
        await dispatch('rolesStore/getRoles', '', {
          root: true,
        });
        await dispatch('featureFlagsStore/getFeatureFlags', '', {
          root: true,
        });

        LocalStorageService.removeItem('accountSessionId');

        await initialiseUserSession();

        dispatch('setUser', cognitoUser);

        return cognitoUser;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        window.dispatchEvent(HIDE_LOADER_EVENT);
      }
    },

    async changePassword({ getters }, { oldPassword, newPassword }) {
      const { user } = getters;

      try {
        const data = await AuthService.changePassword(
          user,
          oldPassword,
          newPassword,
        );

        return data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
