import Vue from 'vue';
import store from '@/store';
import NotificationService from '@/services/NotificationService';

import DevelopersCardDesktop from './DevelopersCard.desktop.vue';
import DevelopersCardMobile from './DevelopersCard.mobile.vue';

const copyToCliboard = async textToCopy => {
  if (!navigator.clipboard) {
    const input = document.createElement('input');

    input.style.top = '-1000px';
    input.style.left = '-1000px';

    input.value = textToCopy;

    input.focus();
    input.select();

    document.execCommand('copy');
    document.body.removeChild(input);

    NotificationService.notifyAboutSuccess('partner-portal.notifications.api-keys-copied');

    return true;
  }

  try {
    const copied = await navigator.clipboard.writeText(textToCopy);
    NotificationService.notifyAboutSuccess('partner-portal.notifications.api-keys-copied');
    return copied;
  } catch (error) {
    return NotificationService.notifyAboutError('partner-portal.notifications.api-keys-copy-error');
  }
};

export default Vue.component('DevelopersCard', {
  functional: true,
  render(h, context) {
    const apiKey = store.getters['accountInfoStore/apiKey'];

    const isViewOnlyMonitoringAllowed = store.getters['rolesStore/isViewOnlyMonitoringAllowed'];
    const isApiKeysMonitoringAllowed = store.getters['rolesStore/isApiKeysMonitoringAllowed'];
    const shouldDisplayCopyButton = !!apiKey && (isViewOnlyMonitoringAllowed || isApiKeysMonitoringAllowed);

    const copyApiKey = () => copyToCliboard(apiKey);

    const readmeUrl = store.getters['accountInfoStore/readmeUrl'];

    const activeProductName = store.getters['accountProductsStore/activeProductName'];

    return h('div', context.data, [h(DevelopersCardMobile, {
      props: {
        readmeUrl,
        copyApiKey,
        shouldDisplayCopyButton,
        activeProductName,
      },
    }), h(DevelopersCardDesktop, {
      props: {
        readmeUrl,
        copyApiKey,
        shouldDisplayCopyButton,
        activeProductName,
      },
    })]);
  },
});
