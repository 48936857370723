import { apiClient } from '@/network';
import LoggerService from '@/services/LoggerService';
import { buildQueryWithFilters, buildBodyRequestForReport } from '@/utils/filters';
import { getDateInISO } from '@/utils/charts';
import ReportService from '@/services/ReportService';
import NotificationService from '@/services/NotificationService';
import SubscriptionService from '@/services/SubscriptionService';

const BASE_TOP_CRYPTOS_LIMIT = 20;
const BASE_TOP_CRYPTOS_MORE_LIMIT = 10;

const TOP_CRYPTOS_TYPE_REPORT = 'top_currencies';
const TOP_CRYPTOS_PAGE_SUBSCRIPTION_KEY = 'top-cryptos-page-top-cryptos';

export default {
  namespaced: true,

  state: {
    topCryptos: [],

    isLoadingTopCryptos: false,
    isTopCryptosLoadingMore: false,

    canLoadMoreTopCryptos: true,

    filters: {
      limit: BASE_TOP_CRYPTOS_LIMIT,
      offset: 0,
      from: null,
      to: null,
      gateways: [],
    },

    isLoadingReport: false,
    reportUrl: null,
  },

  getters: {
    topCryptosList: state => state.topCryptos,

    isLoadingTopCryptos: state => state.isLoadingTopCryptos,
    isTopCryptosLoadingMore: state => state.isTopCryptosLoadingMore,

    canLoadMoreTopCryptos: state => state.canLoadMoreTopCryptos,

    selectedDateFilter: state => ({
      start: state.filters.from,
      end: state.filters.to,
    }),
    selectedGatewaysList: state => state.filters.gateways,

    isLoadingReport: state => state.isLoadingReport,
    reportUrl: state => state.reportUrl,

    activeProductId: (state, getters, rootState, rootGetters) => rootGetters['accountProductsStore/activeProductId'],
  },

  mutations: {
    clearStore(state) {
      state.topCryptos = [];

      state.isLoadingTopCryptos = false;
      state.isTopCryptosLoadingMore = false;

      state.canLoadMoreTopCryptos = true;

      state.filters = {
        limit: BASE_TOP_CRYPTOS_LIMIT,
        offset: 0,
        from: null,
        to: null,
        gateways: [],
      };

      state.isLoadingReport = false;
      state.reportUrl = null;
    },

    setTopCryptos(state, items) {
      state.topCryptos = items;
    },

    setLoadingTopCryptos(state, loading) {
      state.isLoadingTopCryptos = loading;
    },

    setTopCryptosLoadingMore(state, loading) {
      state.isTopCryptosLoadingMore = loading;
    },

    setAbilityLoadMoreTopCryptos(state, payload) {
      state.canLoadMoreTopCryptos = payload;
    },

    setDateFilter(state, { from, to }) {
      state.filters.from = getDateInISO(from, true);
      state.filters.to = getDateInISO(to, false);
    },

    setGateways(state, gateways) {
      state.filters.gateways = gateways;
    },

    setTopCryptosOffset(state, offset) {
      state.filters.offset = offset;
    },

    setTopCryptosLimit(state, limit) {
      state.filters.limit = limit;
    },

    appendTopCryptos(state, items) {
      state.topCryptos = state.topCryptos.concat(items);
    },

    setReportLoading(state, loading) {
      state.isLoadingReport = loading;
    },

    setReportUrl(state, url) {
      state.reportUrl = url;
    },
  },

  actions: {
    clearStore({ commit }) {
      commit('clearStore');
    },

    async loadTopCryptos({ commit, dispatch }) {
      commit('setLoadingTopCryptos', true);

      const topCryptos = await dispatch('loadCryptos', {
        offset: 0, limit: BASE_TOP_CRYPTOS_LIMIT + 1,
      });

      commit('setTopCryptos', topCryptos);
      commit('setLoadingTopCryptos', false);
    },

    setDateFilter({ commit }, datesFilter) {
      commit('setDateFilter', datesFilter);
    },

    setGatewaysFilter({ commit }, gatewaysFilter) {
      commit('setGateways', gatewaysFilter);
    },

    async getMoreTopCryptos({ state, commit, dispatch }) {
      commit('setTopCryptosLoadingMore', true);

      const topCryptos = await dispatch('loadCryptos', {
        offset: state.filters.offset + state.filters.limit,
        limit: BASE_TOP_CRYPTOS_MORE_LIMIT + 1,
      });

      commit('appendTopCryptos', topCryptos);
      commit('setTopCryptosLoadingMore', false);
    },

    async loadCryptos({ state, commit, getters }, { offset, limit }) {
      try {
        commit('setAbilityLoadMoreTopCryptos', true);
        commit('setTopCryptosOffset', offset);
        commit('setTopCryptosLimit', limit);

        const query = buildQueryWithFilters(state.filters);
        const { data: { data: topCryptos } } = await apiClient.get(`/v1/analytics/top-currencies?${query}`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        commit('setTopCryptosLimit', limit - 1);

        if (topCryptos.length <= state.filters.limit) {
          commit('setAbilityLoadMoreTopCryptos', false);

          return topCryptos;
        }

        topCryptos.pop();

        return topCryptos;
      } catch (error) {
        LoggerService.logToConsole(error);
      }

      return [];
    },

    downloadTopCryptosReport({ state, commit, getters }) {
      commit('setReportLoading', true);
      commit('setReportUrl', null);

      const filters = {
        ...state.filters,
      };

      delete filters.offset;
      delete filters.limit;

      const reportFilters = buildBodyRequestForReport(filters);

      ReportService.requestReport({
        type: TOP_CRYPTOS_TYPE_REPORT,
        subscriptionKey: TOP_CRYPTOS_PAGE_SUBSCRIPTION_KEY,
        filters: reportFilters,
        productHeaderId: getters.activeProductId,
        callback: (error, url) => {
          commit('setReportLoading', false);

          if (error) {
            NotificationService.notifyAboutError('partner-portal.errors.error-downloading-report');
            return null;
          }

          commit('setReportUrl', url);
          return url;
        },
      });
    },

    unsubscribeFromTopCryptosReport() {
      ReportService.clearExpirationTimerId();
      SubscriptionService.unsubscribe(TOP_CRYPTOS_PAGE_SUBSCRIPTION_KEY);
    },
  },
};
