const formatDateShort = inputDate => {
  const month = inputDate.getUTCMonth() + 1;
  return `${inputDate.getUTCDate()}.${month.toString().padStart(2, '0')}.${inputDate.getUTCFullYear()}`;
};

export const formatDate = inputDate => {
  const date = new Date(inputDate);
  const dateParts = new Intl.DateTimeFormat('en-EN', {
    dateStyle: 'full',
    timeStyle: 'long',
    timeZone: 'UTC',
    hour12: false,
    hourCycle: 'h23',
  }).formatToParts(date);

  // Safari < v15 fix
  const month = dateParts.find(part => part.type === 'month').value;
  const day = dateParts.find(part => part.type === 'day').value;
  const year = dateParts.find(part => part.type === 'year').value;

  // Safari & ios < v15 fix
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();

  const hours = String(utcHours).padStart(2, '0');
  const minutes = String(utcMinutes).padStart(2, '0');

  return {
    date: {
      month: month.slice(0, 3),
      day,
      year,
    },
    time: {
      hours,
      minutes,
    },
  };
};

export const formatDateRange = (startDate, endDate) => {
  const startDateInstance = new Date(startDate);
  const endDateInstance = new Date(endDate);

  return `
    ${formatDateShort(startDateInstance)}
    —
    ${formatDateShort(endDateInstance)}
  `;
};

export const formatCurrency = (amount = 0, currency = 'USD') => {
  if (!currency) currency = 'USD';
  return new Intl.NumberFormat('en-EN', {
    style: 'currency', currency,
  }).format(amount);
};

export const formatCurrencyForTransaction = (amount = 0, currency = 'USD') => {
  // TODO: Find out why sending empty string doesn't being replaced in auto-mode with 'USD'
  if (!currency) currency = 'USD';

  const FORMAT_OPTIONS = {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits: 2,
  };

  const formattedCurrency = new Intl.NumberFormat('en-EN', FORMAT_OPTIONS).format(amount);
  const [code, formattedAmount] = formattedCurrency.split(' ');

  return `${formattedAmount} ${code}`;
};

export const formatNumber = number => {
  if (!number) return 0;
  return new Intl.NumberFormat({
    style: 'unit',
  }).format(number);
};
