import { apiClient } from '@/network';
import LoggerService from '@/services/LoggerService';

export default {
  namespaced: true,

  state: {
    isUpdatingPaymentMethodOptions: false,
  },

  getters: {
    paymentMethods: state => state.paymentMethods,
    paymentMethodOptions: state => state.paymentMethodOptions,

    isUpdatingPaymentMethodOptions: state => state.isUpdatingPaymentMethodOptions,

    activeProductId: (state, getters, rootState, rootGetters) => rootGetters['accountProductsStore/activeProductId'],
  },

  mutations: {
    setUpdatingPaymentMethodOptions(state, payload) {
      state.isUpdatingPaymentMethodOptions = payload;
    },
  },

  actions: {
    async getPaymentMethods({ getters }, { gateway }) {
      try {
        const { data: { data: paymentMethods } } = await apiClient.get(`/v1/payment-methods?gateway=${gateway}`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        return paymentMethods;
      } catch (error) {
        return LoggerService.logToConsole(error);
      }
    },

    async getPaymentMethodOptions({ commit, getters }, paymentMethodId) {
      commit('setUpdatingPaymentMethodOptions', true);

      try {
        const { data: { data: paymentMethodOptions } } = await apiClient.get(`/v1/payment-methods/${paymentMethodId}/settings`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        return paymentMethodOptions;
      } catch (error) {
        return LoggerService.logToConsole(error);
      } finally {
        commit('setUpdatingPaymentMethodOptions', false);
      }
    },

    updatePaymentMethodOptions({ commit, getters }, { paymentMethodId, updatedOptions }) {
      commit('setUpdatingPaymentMethodOptions', true);
      return new Promise((resolve, reject) => {
        apiClient.patch(`/v1/payment-methods/${paymentMethodId}/settings`, {
          data: [
            ...updatedOptions,
          ],
        },
        {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error.response.data);
          })
          .finally(() => {
            commit('setUpdatingPaymentMethodOptions', false);
          });
      });
    },
  },
};
