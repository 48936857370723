import Vue from 'vue';
import { i18n } from '@/i18n';

const TOAST_SETTINGS = {
  position: 'top-center',
  duration: 2000,
};

const translate = string => i18n.tc(string);

export default {
  notifyAboutSuccess: translationKey => {
    Vue.toasted.clear();

    Vue.toasted.success(
      translate(translationKey),
      TOAST_SETTINGS,
    );
  },

  notifyAboutMessage: translationKey => {
    Vue.toasted.clear();

    Vue.toasted.info(
      translate(translationKey),
      TOAST_SETTINGS,
    );
  },

  notifyAboutError: (translationKey, errorToastSettings, clearToasted = true) => {
    if (clearToasted) Vue.toasted.clear();

    Vue.toasted.error(
      translate(translationKey),
      errorToastSettings || TOAST_SETTINGS,
    );
  },
};
