<template>
  <div
    class="payouts-list"
    :class="{ 'payouts-list--full': !canLoadMorePayouts }"
  >
    <div class="payouts-list__header">
      <h1 class="payouts-list__title">
        {{ title }}
      </h1>
    </div>
    <div class="payouts-list__content">
      <Loader
        v-if="isLoadingPayouts"
        class="payouts-list__loader"
      />

      <MissingDataPlaceholder v-else-if="noPayouts" />

      <Table
        v-else
        class="payouts-list__table"
        :columns="columns"
        :rows="payouts"
      />

      <div
        v-if="shouldDisplayLoadMoreButton"
        class="payouts-list__load-more"
        :class="{
          'payouts-list__load-more--loading': isLoadingMore,
        }"
        @click="loadMorePayouts"
      >
        <div
          v-if="isLoadingMore"
          class="payouts-list__load-more-content"
        >
          <i class="icon icon-spinner-black payouts-list__load-more-spinner" />
        </div>

        <div
          v-else
          class="payouts-list__load-more-content"
        >
          <i class="icon icon-load-more payouts-list__load-more-image" />
          <div class="payouts-list__load-more-text">
            {{ $t('partner-portal.payouts.table.load-more') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Table, { DateCell, CryptoAmountCell } from '@/components/Table';

import PayoutStatusCell from './Cell/PayoutStatusCell';
import ConfirmationCell from './Cell/ConfirmationCell';
import PayoutTxsCell from './Cell/PayoutTxsCell';
import MissingDataPlaceholder from '@/components/MissingDataPlaceholder';
import Loader from '@/components/Loader';

const MAX_SYMBOLS_AMOUNT = 8;

export default {
  name: 'PayoutsList',

  components: {
    Table,
    MissingDataPlaceholder,
    Loader,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    payouts: {
      type: Array,
      default: () => ([]),
    },

    isLoadingPayouts: {
      type: Boolean,
      default: false,
    },

    isLoadingMore: {
      type: Boolean,
      default: false,
    },

    canLoadMorePayouts: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    columns() {
      return [{
        name: this.$t('partner-portal.payouts.table.head.id'),
        key: 'id',
      }, {
        name: this.$t('partner-portal.payouts.table.head.time'),
        key: 'created_at',
        component: DateCell,
      }, {
        name: this.$t('partner-portal.payouts.table.head.amount'),
        key: 'amount',
        format: row => {
          const { value, currency } = row.amount;
          const [beforeComma, afterComma] = value.split('.');

          let valueAfterComma = afterComma;
          if (afterComma.length > MAX_SYMBOLS_AMOUNT) {
            valueAfterComma = afterComma.slice(0, MAX_SYMBOLS_AMOUNT);
          }
          return `${beforeComma}.${valueAfterComma} ${currency}`;
        },
        component: CryptoAmountCell,
      }, {
        name: this.$t('partner-portal.payouts.table.head.payment-details'),
        key: 'payment_details',
      }, {
        name: this.$t('partner-portal.payouts.table.head.confirmation'),
        key: 'confirmation',
        component: ConfirmationCell,
      }, {
        name: this.$t('partner-portal.payouts.table.head.list-of-txs'),
        key: 'txs',
        component: PayoutTxsCell,
      }, {
        name: this.$t('partner-portal.payouts.table.head.status'),
        key: 'status',
        component: PayoutStatusCell,
      }];
    },
    noPayouts() {
      return !this.payouts || !this.payouts.length;
    },
    shouldDisplayLoadMoreButton() {
      return this.canLoadMorePayouts && !this.isLoadingPayouts;
    },
  },

  methods: {
    ...mapActions('payoutsStore', {
      loadMorePayouts: 'loadMorePayouts',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/icons';

.payouts-list {
  width: 100%;
  background: white;
  padding: 22px 24px 0;
  border-radius: 8px;
  box-shadow: 0 2px 8px $primary;

  &--full {
    padding-bottom: 22px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
  }

  &__title {
    font-size: 17px;
    line-height: 21px;

    @media screen and (min-width: $tablet-breakpoint) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__csv {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-family: $font-regular;
    cursor: pointer;
  }

  &__csv-text {
    border-bottom: 1px solid $link-border-color;
  }

  &__csv-image {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__loader {
    padding: 90px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    width: 100%;
    cursor: pointer;

    &--loading {
      padding: 18.5px;
    }
  }

  &__load-more-content {
    display: flex;
  }

  &__load-more-image {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }

  &__load-more-text {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
    border-bottom: 1px solid $link-border-color;
  }

  ::v-deep .table__th,
  ::v-deep .table__td {
    &--id {
      flex: 0 0 84px;
    }

    &--created_at {
      flex: 0 0 151px;
      white-space: nowrap;
    }

    &--amount {
      flex: 0 0 146px;
    }

    &--payment_details {
      flex: 0 0 159px;
    }

    &--confirmation {
      flex: 0 0 148px;
    }

    &--txs {
      flex: 0 0 130px;
    }

    &--status {
      flex: 1 0 103px;
      justify-content: flex-end;
    }
  }
}
</style>
