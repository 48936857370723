import { apiClient } from '@/network';
import LoggerService from '@/services/LoggerService';

export default {
  namespaced: true,

  state: {
    account: null,
    manager: null,
    apiKey: null,

    readmeUrl: 'https://docs.payb.is/reference/introduction',
  },

  getters: {
    account: state => state.account,
    accountLegalEntity: state => state.account?.legal_entity,
    accountName: state => state.account?.name,
    accountAddress: state => state.account?.address,
    accountEmail: state => state.account?.contact_email,
    accountCreatedDate: state => state.account?.created_at,

    manager: state => state.manager,
    apiKey: state => state.apiKey,

    readmeUrl: state => state.readmeUrl,

    activeProductId: (state, getters, rootState, rootGetters) => rootGetters['accountProductsStore/activeProductId'],
    shouldLoadApiKey: (state, getters, rootState, rootGetters) => {
      const isApiKeysMonitoringAllowed = rootGetters['rolesStore/isApiKeysMonitoringAllowed'];
      const isViewOnlyMonitoringAllowed = rootGetters['rolesStore/isViewOnlyMonitoringAllowed'];

      return isViewOnlyMonitoringAllowed || isApiKeysMonitoringAllowed;
    },
  },

  mutations: {
    clearStore(state) {
      state.account = null;
      state.manager = null;
      state.apiKey = null;
    },

    setAccount(state, account) {
      state.account = account;
    },

    setManager(state, manager) {
      state.manager = manager;
    },

    setApiKey(state, apiKey) {
      state.apiKey = apiKey;
    },
  },

  actions: {
    async init({ dispatch }) {
      await dispatch('getAccountInformation');
    },

    async getAccountInformation({ commit }) {
      try {
        const { data: { account, manager } } = await apiClient.get('/v1/account-info');
        commit('setAccount', account);
        commit('setManager', manager);

        return true;
      } catch (error) {
        commit('clearStore');
        return LoggerService.logToConsole(error);
      }
    },

    async getApiKey({ commit, getters }) {
      try {
        const { data } = await apiClient.get('/v1/api-keys', {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });
        const key = data?.data[0]?.value || null;
        commit('setApiKey', key);
      } catch (error) {
        commit('clearStore');
        LoggerService.logToConsole(error);
      }
    },
  },
};
