import { apiClient } from '@/network';

import LocalStorageService from '@/services/LocalStorageService';

export default {
  namespaced: true,

  state: {
    otp: {},
    isLoading: false,
    errors: [],
    sessionExpiredError: '',
    currentStep: 1,
  },

  getters: {
    otp: state => state.otp,
    isLoading: state => state.isLoading,
    errors: state => state.errors,
    sessionExpiredError: state => state.sessionExpiredError,
    currentStep: state => state.currentStep,
  },

  mutations: {
    setOtp(state, otp) {
      state.otp = otp;
    },
    setLoading(state, loading) {
      state.isLoading = loading;
    },
    setErrors(state, error) {
      state.errors.push(error);
    },
    clearErrors(state) {
      state.errors = [];
    },
    setSessionExpiredError(state, error) {
      state.sessionExpiredError = error;
    },
    setCurrentStep(state, step) {
      state.currentStep = step;
    },
  },

  actions: {
    async sendPasswordRecoveryEmail({ commit }, email) {
      try {
        const { data } = await apiClient.post('/v1/account-password-recovery/otp', {
          email,
        }, {
          skipJwt: true,
        });

        commit('setOtp', data);

        LocalStorageService.setItem('passwordRestoreEmail', email);
        LocalStorageService.setStringifyItem('passwordRestoreOtp', data);

        commit('setCurrentStep', 2);
      } catch (error) {
        commit('setLoading', false);

        throw error;
      } finally {
        commit('setLoading', false);
      }
    },

    async sendPasswordRecoveryOtp({ commit }, { id, code }) {
      try {
        await apiClient.put('/v1/account-password-recovery/otp', {
          id,
          code,
        }, {
          skipJwt: true,
        });

        commit('setCurrentStep', 3);
      } catch (error) {
        commit('setLoading', false);

        throw error;
      } finally {
        commit('setLoading', false);
      }
    },

    async setNewPassword({ commit, dispatch, state }, newPassword) {
      try {
        await apiClient.put('/v1/account-password-recovery', {
          otp_id: state.otp.id,
          password: newPassword,
        }, {
          skipJwt: true,
        });

        commit('setCurrentStep', 1);
        dispatch('clearLocalStorage');
      } catch (error) {
        commit('setLoading', false);

        throw error;
      } finally {
        commit('setLoading', false);
      }
    },

    clearLocalStorage() {
      const localStorageItems = ['passwordRestoreEmail', 'passwordRestoreOtp', 'isOtpSent'];

      localStorageItems.forEach(item => LocalStorageService.removeItem(item));
    },
  },
};
