<template>
  <button
    :type="type"
    class="btn"
    :class="{
      'btn--is-loading': isLoading,
      'btn--is-disabled': isDisabled,
    }"
    v-on="$listeners"
  >
    <i
      v-if="isLoading"
      class="btn__icon"
    />
    <span v-else>{{ text }}</span>
  </button>
</template>

<script>
// TODO: Decide if we want to use directive for this button
export default {
  name: 'AppButton',

  props: {
    text: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      default: 'button',
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
