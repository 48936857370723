<template>
  <aside class="sidebar">
    <SidebarMenu
      v-if="!hideSidebarMenu"
      class="sidebar__card"
      :routes="routes"
    />
    <ManagerCard class="sidebar__card--secondary" />
    <DevelopersCard class="sidebar__card--secondary" />
  </aside>
</template>

<script>
import { buildNavigationTree } from './CompanyLayoutSidebar.utils';

import SidebarMenu from '@/components/SidebarMenu';
import ManagerCard from '@/components/ManagerCard';
import DevelopersCard from '@/components/DevelopersCard';

export default {
  name: 'CompanyLayoutSidebar',
  components: {
    SidebarMenu,
    ManagerCard,
    DevelopersCard,
  },
  computed: {
    routes() {
      return buildNavigationTree();
    },
    hideSidebarMenu() {
      return this.$route?.meta?.hideSidebarMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';

.sidebar {
  &__card {
    @include sidebar-card();
  }
}
</style>
