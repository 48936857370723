<template>
  <aside class="sidebar">
    <SidebarBalanceInfo
      :title="$t('partner-portal.dashboard.unpaid-balance')"
      :amount="totalUnpaidAmount"
      :is-expandable="areUnpaidBalancesNotEmpty"
      :is-loading="isUnpaidBalanceLoading"
      data-testid="unpaid-balance-sidebar"
      class="sidebar__card sidebar__card--primary"
    >
      <SidebarBalanceDetails
        :items="unpaidBalances"
        :is-all-currencies-link-visible="false"
      />
    </SidebarBalanceInfo>

    <SidebarBalanceInfo
      :title="$t('partner-portal.dashboard.total-profit')"
      :amount="totalProfitAmount"
      :is-expandable="areProfitBalancesNotEmpty"
      :is-loading="isTotalProfitBalanceLoading"
      data-testid="total-profit-sidebar"
      class="sidebar__card"
    >
      <SidebarBalanceDetails
        :items="profitBalances"
        :is-all-currencies-link-visible="false"
      />
    </SidebarBalanceInfo>

    <SidebarBalanceInfo
      :title="$t('partner-portal.dashboard.total-sales')"
      :amount="totalSalesAmount"
      :is-loading="isTotalSalesBalanceLoading"
      data-testid="total-sales-sidebar"
      class="sidebar__card"
    />

    <ManagerCard
      data-testid="manager-sidebar"
      class="sidebar__card sidebar__card--secondary"
    />

    <DevelopersCard
      data-testid="for-developers-sidebar"
      class="sidebar__card sidebar__card--secondary"
    />
  </aside>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatCurrency } from '@/utils';
import SidebarBalanceInfo from '@/components/SidebarBalanceInfo';
import DevelopersCard from '@/components/DevelopersCard';
import ManagerCard from '@/components/ManagerCard';
import SidebarBalanceDetails from '@/components/SidebarBalanceDetails';

export default {
  name: 'DashboardSidebar',

  components: {
    SidebarBalanceInfo,
    DevelopersCard,
    ManagerCard,
    SidebarBalanceDetails,
  },

  data: () => ({
    isUnpaidBalanceLoading: true,
    isTotalProfitBalanceLoading: true,
    isTotalSalesBalanceLoading: true,
  }),

  computed: {
    ...mapGetters('dashboardStore', {
      unpaidTotal: 'unpaidTotal',
      unpaidBalances: 'unpaidBalances',
      profitTotal: 'profitTotal',
      profitBalances: 'profitBalances',
      totalSales: 'totalSales',
    }),

    areUnpaidBalancesNotEmpty() {
      return !!this.unpaidBalances?.filter(balance => Number(balance.amount) > 0).length;
    },

    areProfitBalancesNotEmpty() {
      return !!this.profitBalances?.filter(balance => Number(balance.amount) > 0).length;
    },

    totalUnpaidAmount() {
      const { amount = 0, currency = 'USD' } = this.unpaidTotal || {};
      return formatCurrency(amount, currency);
    },

    totalProfitAmount() {
      const { amount = 0, currency = 'USD' } = this.profitTotal || {};
      return formatCurrency(amount, currency);
    },

    totalSalesAmount() {
      const { amount = 0, currency = 'USD' } = this.totalSales || {};
      return formatCurrency(amount, currency);
    },
  },

  destroyed() {
    this.unsubscribeFromAggregatedStats();
  },

  mounted() {
    this.subscribeToAggregatedStats()
      .finally(() => {
        this.isUnpaidBalanceLoading = false;
        this.isTotalProfitBalanceLoading = false;
        this.isTotalSalesBalanceLoading = false;
      });
  },

  methods: {
    ...mapActions('dashboardStore', {
      subscribeToAggregatedStats: 'subscribeToAggregatedStats',
      unsubscribeFromAggregatedStats: 'unsubscribeFromAggregatedStats',
    }),
  },

};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/mixins";

.sidebar {
  &__card {
    @include sidebar-card();
  }
}
</style>
