const allRoutesList = [
  'dashboard',
  'analytics',
  'transactions',
  'payouts',
  'profileSettings',
  'widgetSettings',
  'paymentSettings',
  'topChartsCountries',
  'topChartsCryptos',
  'changePassword',
];

const AUTH_ROUTE = 'auth';
const PASSWORD_RESTORE = 'passwordRestore';
const AUTH_ROUTES = [AUTH_ROUTE, PASSWORD_RESTORE];

const isOneOfAuthRoutes = routeName => AUTH_ROUTES.includes(routeName);

export default class NavigationGuardService {
  #availableRoutes = [];

  #rolesAllowance = {
    dashboard: 'rolesStore/isTransactionsMonitoringAllowed',
    analytics: 'rolesStore/isTransactionsMonitoringAllowed',
    transactions: 'rolesStore/isTransactionsMonitoringAllowed',
    topChartsCountries: 'rolesStore/isTransactionsMonitoringAllowed',
    topChartsCryptos: 'rolesStore/isTransactionsMonitoringAllowed',
    payouts: 'rolesStore/isPayoutsMonitoringAllowed',

    profileSettings: 'rolesStore/isProfileSettingsMonitoringAllowed',
    widgetSettings: 'rolesStore/isWidgetSettingsMonitoringAllowed',
    paymentSettings: 'rolesStore/isPayoutsMonitoringAllowed',
  }

  #featureFlagAllowance = {
    profileSettings: 'featureFlagsStore/isProfileSettingsPageVisible',
  }

  constructor(router, store) {
    this.router = router;
    this.store = store;
  }

  getAvailableRoutes() {
    const isViewOnlyMonitoringAllowed = this.store.getters['rolesStore/isViewOnlyMonitoringAllowed'];
    const permittedRoutes = ['changePassword', 'profileSettings'];

    const availableRoutesList = allRoutesList.filter(routeName => {
      if (permittedRoutes.includes(routeName)) return true;

      const rolesStoreGetter = this.#rolesAllowance[routeName];
      const isNextRouteAllowedByRole = this.store.getters[rolesStoreGetter];
      const featureFlagKey = this.#featureFlagAllowance[routeName];

      let isNextRouteAllowedByFeatureFlag;

      if (featureFlagKey === undefined) {
        isNextRouteAllowedByFeatureFlag = true;
      } else {
        isNextRouteAllowedByFeatureFlag = this.store.getters[featureFlagKey];
      }

      if (isViewOnlyMonitoringAllowed) {
        return isNextRouteAllowedByFeatureFlag;
      }

      return isNextRouteAllowedByRole && isNextRouteAllowedByFeatureFlag;
    });

    this.#availableRoutes = availableRoutesList;
  }

  getFirstAvailableRoute() {
    return this.#availableRoutes[0];
  }

  isNextRouteAllowed(routeName) {
    if (!routeName) return false;

    return this.#availableRoutes.includes(routeName);
  }

  setRedirectRoute(to, from, next) {
    const routeToRedirect = to.name;
    const nextAuthRoute = isOneOfAuthRoutes(routeToRedirect);

    if (!nextAuthRoute) sessionStorage.setItem('redirectAfterLogin', routeToRedirect);

    next();
  }

  goToAuthRoute(to, from, next) {
    const isUserSignedIn = this.store.getters['userStore/isUserSignedIn'];
    const routeToRedirect = to.name;
    const nextAuthRoute = isOneOfAuthRoutes(routeToRedirect);

    if (!isUserSignedIn && !nextAuthRoute) {
      next({
        name: AUTH_ROUTE,
      });
    } else {
      next();
    }
  }

  stayOnCurrentRoute(to, from, next) {
    const isUserSignedIn = this.store.getters['userStore/isUserSignedIn'];
    const routeToRedirect = to.name;
    const nextAuthRoute = isOneOfAuthRoutes(routeToRedirect);

    if (isUserSignedIn && nextAuthRoute) {
      next({
        name: from.name,
      });
    } else {
      next();
    }
  }

  goToAllowedRoute(to, from, next) {
    const isRedirectRouteAvailable = this.isNextRouteAllowed(to.name);

    if (isRedirectRouteAvailable) {
      next();
    } else {
      const routeToMoveOn = this.getFirstAvailableRoute();

      next({
        name: routeToMoveOn,
      });
    }
  }

  handleUserStoreStateUpdate(updatedState) {
    const { isUserLoading, isUserSignedIn, isRolesListEmpty, isFeatureFlagsListEmpty } = updatedState;
    const canGetAvailableRoutes = !isRolesListEmpty && !isFeatureFlagsListEmpty;

    if (isUserLoading) return;

    if (!isUserSignedIn) {
      this.#availableRoutes = [];

      return;
    }

    if (canGetAvailableRoutes) {
      this.getAvailableRoutes();
    }
  }
}
