import { apiClient } from '@/network';
import LoggerService from '@/services/LoggerService';

const PAYOUTS_BASIC_LIMIT = 20;
const PAYOUTS_LOAD_MORE_LIMIT = 10;

export default {
  namespaced: true,

  state: {
    payouts: [],
    meta: null,

    isLoadingPayouts: false,
    isLoadingMorePayouts: false,
  },

  getters: {
    payoutsList: state => state.payouts,
    canLoadMorePayouts: state => !!state.meta?.cursors.next,

    isLoadingPayouts: state => state.isLoadingPayouts,
    isLoadingMorePayouts: state => state.isLoadingMorePayouts,
  },

  mutations: {
    setPayouts(state, payload) {
      state.payouts = payload;
    },

    appendPayouts(state, payload) {
      state.payouts = state.payouts.concat(payload);
    },

    setMeta(state, payload) {
      state.meta = payload;
    },

    setLoadingPayouts(state, payload) {
      state.isLoadingPayouts = payload;
    },

    setLoadingMore(state, payload) {
      state.isLoadingMorePayouts = payload;
    },

    clearStore(state) {
      state.payouts = [];
      state.meta = null;

      state.isLoadingPayouts = false;
      state.isLoadingMorePayouts = false;
    },
  },

  actions: {
    async loadPayouts({ commit }) {
      commit('setLoadingPayouts', true);
      try {
        const { data: { data: payouts, meta } } = await apiClient.get('/v1/payouts', {
          params: {
            limit: PAYOUTS_BASIC_LIMIT,
          },
        });

        commit('setPayouts', payouts);
        commit('setMeta', meta);
      } catch (error) {
        LoggerService.logToConsole(error);
      } finally {
        commit('setLoadingPayouts', false);
      }
    },

    async loadMorePayouts({ commit, state }) {
      commit('setLoadingMore', true);
      try {
        const cursor = state.meta?.cursors.next;
        const { data: { data: payouts, meta } } = await apiClient.get('/v1/payouts', {
          params: {
            limit: PAYOUTS_LOAD_MORE_LIMIT,
            cursor,
          },
        });

        commit('appendPayouts', payouts);
        commit('setMeta', meta);
      } catch (error) {
        LoggerService.logToConsole(error);
      } finally {
        commit('setLoadingMore', false);
      }
    },

    async loadTransactions(store, id) {
      try {
        const { data: { link } } = await apiClient.get(`/v1/payouts/${id}/transactions`);

        return link;
      } catch (error) {
        LoggerService.logToConsole(error);

        return null;
      }
    },

    async loadConfirmation(store, id) {
      try {
        const { data: { link } } = await apiClient.get(`/v1/payouts/${id}/confirmation-link`);

        return link;
      } catch (error) {
        LoggerService.logToConsole(error);

        return null;
      }
    },

    clearStore({ commit }) {
      commit('clearStore');
    },
  },
};
