<template>
  <aside class="sidebar">
    <component
      :is="currentFilter"
      class="sidebar__card sidebar__card--primary"
    />

    <ManagerCard class="sidebar__card--secondary" />
    <DevelopersCard class="sidebar__card--secondary" />
  </aside>
</template>

<script>
import ManagerCard from '@/components/ManagerCard';
import DevelopersCard from '@/components/DevelopersCard';

export default {
  name: 'TopChartsSidebar',

  components: {
    ManagerCard,
    DevelopersCard,
    TopChartsCountriesFilter: () => import('@/views/TopChartsCountries/TopChartsCountriesFilter/TopChartsCountriesFilter.vue'),
    TopChartsCryptosFilter: () => import('@/views/TopChartsCryptos/TopChartsCryptosFilter/TopChartsCryptosFilter.vue'),
  },

  computed: {
    currentFilter() {
      if (this.$route.name === 'topChartsCountries') return 'TopChartsCountriesFilter';

      return 'TopChartsCryptosFilter';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';

.sidebar {
  &__card {
    @include sidebar-card();
  }
}
</style>
