import { Auth } from 'aws-amplify';

let userLogoutCallback = () => {};

export default {
  logout() {
    return Auth.signOut({
      global: true,
    });
  },
  confirmPassword(cognitoUser, password) {
    return Auth.completeNewPassword(cognitoUser, password);
  },
  changePassword(user, oldPassword, newPassword) {
    return Auth.changePassword(user, oldPassword, newPassword);
  },
  login(email, password, token) {
    return Auth.signIn(email, password, {
      captcha: token,
    });
  },
  getCurrentUser() {
    return Auth.currentAuthenticatedUser();
  },
  async getCurrentSession() {
    try {
      const session = await Auth.currentSession();

      return session;
    } catch (error) {
      userLogoutCallback(error);

      throw error;
    }
  },
  subscribeToLogOut(callback) {
    userLogoutCallback = callback;
  },
};
