<template>
  <aside class="sidebar">
    <AnalyticsSidebarFilter class="sidebar__card sidebar__card--primary" />

    <SidebarBalanceInfo
      data-testid="sales-sidebar"
      :title="$t('partner-portal.analytics.sidebar.sales')"
      :amount="salesAmountTotal"
      :is-expandable="areSalesBalancesNotEmpty"
      :is-loading="isAggregationLoading"
      class="sidebar__card"
    >
      <SidebarBalanceDetails :items="salesAmountBalances" />
    </SidebarBalanceInfo>

    <SidebarBalanceInfo
      data-testid="profit-sidebar"
      :title="$t('partner-portal.analytics.sidebar.profit')"
      :amount="profitAmountTotal"
      :is-expandable="areProfitBalancesNotEmpty"
      :is-loading="isAggregationLoading"
      class="sidebar__card"
    >
      <SidebarBalanceDetails
        :items="profitAmountBalances"
        :is-all-currencies-link-visible="false"
      />
    </SidebarBalanceInfo>

    <SidebarBalanceInfo
      data-testid="transactions-sidebar"
      :title="$t('partner-portal.analytics.sidebar.transactions')"
      :amount="transactionsAmount"
      :is-loading="isAggregationLoading"
      class="sidebar__card"
    />

    <ManagerCard class="sidebar__card sidebar__card--secondary" />
    <DevelopersCard class="sidebar__card sidebar__card--secondary" />
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatNumber, formatCurrency } from '@/utils';

import SidebarBalanceInfo from '@/components/SidebarBalanceInfo';
import DevelopersCard from '@/components/DevelopersCard';
import ManagerCard from '@/components/ManagerCard';
import SidebarBalanceDetails from '@/components/SidebarBalanceDetails';
import AnalyticsSidebarFilter from '../AnalyticsSidebarFilter';

export default {
  name: 'AnalyticsSidebar',

  components: {
    SidebarBalanceInfo,
    DevelopersCard,
    ManagerCard,
    SidebarBalanceDetails,
    AnalyticsSidebarFilter,
  },

  computed: {
    ...mapGetters('analyticsStore', {
      isAggregationLoading: 'isAggregationLoading',
      aggregatedSales: 'aggregatedSales',
      aggregatedProfit: 'aggregatedProfit',
      aggregatedTransactions: 'aggregatedTransactions',
    }),

    salesAmountTotal() {
      const { amount = 0, currency = 'USD' } = this.aggregatedSales?.total || {};
      return formatCurrency(amount, currency);
    },

    salesAmountBalances() {
      return this.aggregatedSales?.balances || [];
    },

    areProfitBalancesNotEmpty() {
      return !!this.aggregatedProfit?.balances.filter(
        balance => Number(balance.amount) > 0,
      ).length;
    },

    areSalesBalancesNotEmpty() {
      return !!this.aggregatedSales?.balances.filter(
        balance => Number(balance.amount) > 0,
      ).length;
    },

    profitAmountTotal() {
      const { amount = 0, currency = 'USD' } = this.aggregatedProfit?.total || {};
      return formatCurrency(amount, currency);
    },

    profitAmountBalances() {
      return this.aggregatedProfit?.balances || [];
    },

    transactionsAmount() {
      const amount = this.aggregatedTransactions || '0';
      return formatNumber(amount);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';

.sidebar {
  &__card {
    @include sidebar-card();
  }
}
</style>
