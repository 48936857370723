<template>
  <div class="profile-form">
    <div class="profile-form__container">
      <template v-if="showAccountInfo">
        <div class="profile-form__company">
          <h1 class="profile-form__company-name">
            {{ accountName }}
          </h1>

          <div class="profile-form__company-info">
            <div
              v-for="info in companyInfo"
              :key="info.icon"
              class="profile-form__company-info-row"
            >
              <div class="profile-form__company-info-icon-wrapper">
                <i :class="`icon icon-${info.icon} profile-form__company-info-icon`" />
              </div>

              <div class="profile-form__company-info-text">
                {{ info.text }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="shouldShowPersonsForms">
        <div
          class="profile-form__persons"
        >
          <div
            v-for="(person, personIndex) in personsForms"
            :key="`person-${personIndex}`"
            class="profile-form__person"
          >
            <div class="profile-form__person-count-wrap">
              <div class="profile-form__person-count">
                {{ $t('partner-portal.profile-settings.person') }}
                {{ personIndex + 1 }}
              </div>
              <div
                v-if="personsForms.length > 1"
                class="icon icon-grey-cross profile-form__person-remove"
                @click="removePerson(personIndex)"
              />
            </div>

            <div class="profile-form__person-form">
              <div class="profile-form__person-form-name">
                <label class="profile-form__person-form-label">
                  {{ $t('partner-portal.profile-settings.name') }}
                  &
                  {{ $t('partner-portal.profile-settings.title') }}
                </label>
                <input
                  v-model="person.name_title"
                  type="text"
                  class="profile-form__person-form-name-input"
                  :class="{'profile-form__person-form-name-input--error': getFormError(personIndex) }"
                >
                <span
                  v-if="getFormError(personIndex)"
                  class="profile-form__person-form-name-error"
                >
                  {{ getFormError(personIndex) }}
                </span>
              </div>

              <div class="profile-form__person-form-contacts">
                <div
                  v-for="(contact, contactIndex) in person.contacts"
                  :key="`contacts-${contactIndex}`"
                  class="profile-form__person-form-contact"
                >
                  <FormInputSelect
                    :select-options="selectOptions"
                    :select-value="setSelectValue(contact.contact_type)"
                    :input-value="contact.value"
                    :hide-remove-icon="personsForms[personIndex].contacts.length <= 1"
                    :error="getFormError(personIndex, contactIndex)"
                    @select="selectContactType($event, personIndex, contactIndex)"
                    @input="setContactValue($event, personIndex, contactIndex)"
                    @remove="removeContactField(personIndex, contactIndex)"
                  />
                </div>
              </div>

              <button
                v-if="showAddContactButton(personIndex)"
                class="profile-form__person-add-contact"
                @click="addContact(personIndex)"
              >
                <i class="icon icon-plus profile-form__person-add-contact-image" />
                <div class="profile-form__person-add-contact-text">
                  {{ $t('partner-portal.profile-settings.add-contact') }}
                </div>
              </button>
            </div>
          </div>

          <button
            v-if="showAddPersonButton"
            class="profile-form__add-person"
            @click="addPerson"
          >
            <i class="icon icon-plus profile-form__add-person-image" />
            <div class="profile-form__add-person-text">
              {{ $t('partner-portal.profile-settings.add-person') }}
            </div>
          </button>
        </div>

        <div
          class="profile-form__footer"
        >
          <AppButton
            type="button"
            class="btn btn--primary"
            :text="$t('partner-portal.buttons.save')"
            :is-loading="isUpdating"
            :is-disabled="isViewOnly"
            @click="saveChanges"
          />
          <AppButton
            type="button"
            class="btn btn--secondary"
            :text="$t('partner-portal.buttons.cancel')"
            :is-disabled="isViewOnly || isUpdating"
            @click="$emit('show-cancel-changes-modal')"
          />
        </div>
      </template>

      <Loader
        v-if="isLoading"
        class="profile-form__loader"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FormInputSelect from '@/components/FormInputSelect';
import AppButton from '@/components/AppButton';
import Loader from '@/components/Loader';

const DEFAULT_SELECT_VALUES = [
  {
    option: 'Phone', value: 'phone',
  },
  {
    option: 'Email', value: 'email',
  },
  {
    option: 'WhatsApp', value: 'whats_app',
  },
  {
    option: 'Viber', value: 'viber',
  },
  {
    option: 'Telegram', value: 'telegram',
  },
  {
    option: 'Skype', value: 'skype',
  },
  {
    option: 'WeChat', value: 'we_chat',
  },
  {
    option: 'QQ', value: 'qq',
  },
];

export default {
  name: 'ProfileSettingsForm',

  components: {
    FormInputSelect,
    AppButton,
    Loader,
  },

  props: {
    contactPersons: {
      type: Array,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    isUpdating: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: () => [],
    },

    resetPersonsForms: {
      type: Boolean,
      default: false,
    },

    showPersonsForms: {
      type: Boolean,
      default: false,
    },

    isViewOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      personsForms: [],

      maxContactsCount: 3,
      maxPersonsCount: 5,
    };
  },

  computed: {
    ...mapGetters('accountInfoStore', {
      accountName: 'accountName',
      accountLegalEntity: 'accountLegalEntity',
      accountAddress: 'accountAddress',
    }),

    companyInfo() {
      return [
        {
          icon: 'briefcase-solid',
          text: this.accountLegalEntity,
        }, {
          icon: 'address',
          text: this.accountAddress,
        },
      ];
    },

    selectOptions() {
      return DEFAULT_SELECT_VALUES;
    },

    showAccountInfo() {
      return this.accountName && this.accountAddress && !this.isLoading;
    },

    showAddPersonButton() {
      return this.personsForms?.length < this.maxPersonsCount;
    },

    shouldShowPersonsForms() {
      return this.showPersonsForms && !this.isLoading;
    },

    mappedErrors() {
      const mapping = {};

      this.errors.forEach(error => {
        const { property_path: propertyPath } = error;
        const fieldErrorIndexes = propertyPath.match(/\[\d*]/g);
        const nameIndex = parseInt(fieldErrorIndexes[0].slice(1), 10);

        mapping[nameIndex] = mapping[nameIndex] || {};
        mapping[nameIndex].contacts = mapping[nameIndex].contacts || {};

        if (fieldErrorIndexes.length === 1) {
          mapping[nameIndex].message = error.message;
        } else {
          const contactIndex = parseInt(fieldErrorIndexes[1].slice(1), 10);

          mapping[nameIndex].contacts[contactIndex] = error.message;
        }
      });

      return mapping;
    },
  },

  watch: {
    contactPersons: {
      immediate: true,
      handler(persons) {
        if (persons.length) this.setPersonsFormsData();
      },
    },

    personsForms: {
      handler(newValue, oldValue) {
        if (!oldValue.length) return;

        const areChangesDetected = JSON.stringify(newValue) !== JSON.stringify(this.contactPersons);

        if (areChangesDetected) {
          this.$emit('set-persons-data-changed-status', true);
          window.onbeforeunload = this.onBeforeUnload;
        } else {
          this.$emit('set-persons-data-changed-status', false);
          window.onbeforeunload = null;
        }
      },
      deep: true,
    },

    resetPersonsForms(reset) {
      if (reset) this.setPersonsFormsData();
    },
  },

  methods: {
    saveChanges() {
      this.$emit('save', this.personsForms);
    },

    selectContactType(type, personIndex, contactIndex) {
      this.personsForms[personIndex].contacts[contactIndex].contact_type = type;
    },

    setContactValue(value, personIndex, contactIndex) {
      this.personsForms[personIndex].contacts[contactIndex].value = value;
    },

    removePerson(personIndex) {
      this.$emit('drop-errors');
      this.personsForms.splice(personIndex, 1);
    },

    removeContactField(personIndex, contactIndex) {
      this.$emit('drop-errors');
      this.personsForms[personIndex].contacts.splice(contactIndex, 1);
    },

    setSelectValue(contactType) {
      return this.selectOptions.find(option => option.value === contactType).option;
    },

    addContact(personIndex) {
      this.$emit('drop-errors');

      const defaultContactData = {
        contact_type: 'email',
        value: '',
      };

      this.personsForms[personIndex].contacts.push(defaultContactData);
    },

    addPerson() {
      this.$emit('drop-errors');

      const defaultPersonData = {
        name_title: '',
        contacts: [
          {
            contact_type: 'email',
            value: '',
          },
        ],
      };

      this.personsForms.push(defaultPersonData);
    },

    setPersonsFormsData() {
      this.personsForms = [];

      this.contactPersons.forEach(person => {
        const personForm = {};
        const { name_title: personName, contacts: personContacts } = person;

        personForm.name_title = personName;
        personForm.contacts = personContacts.map(contact => ({
          ...contact,
        }));

        this.personsForms.push(personForm);
      });
    },

    showAddContactButton(personIndex) {
      return this.personsForms[personIndex].contacts?.length < this.maxContactsCount;
    },

    getFormError(personIndex, contactIndex) {
      if (typeof contactIndex === 'undefined') {
        return this.mappedErrors[personIndex]?.message || '';
      }

      return this.mappedErrors[personIndex]?.contacts[contactIndex] || '';
    },

    onBeforeUnload(event) {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to exit?';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/mixins';

%text-regular {
  font-size: 15px;
  line-height: 18px;
  font-family: $font-regular;
}

.profile-form {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border-radius: 8px;
  flex-direction: column;
  background: $primary;
  padding: 24px 20px;

  &__container {
    width: 100%;
  }

  &__company {
    display: flex;
    flex-direction: column;
  }

  &__company-name {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 40px;

    @media screen and (min-width: $tablet-breakpoint) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 48px;
    }
  }

  &__company-info-row {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__company-info-icon-wrapper {
    width: 16px;
    margin-right: 8px;
  }

  &__company-info-icon {
    height: 16px;
    width: 16px;
    margin-right: 8px;

    &--stretched {
      background-size: auto 100%;
    }
  }

  &__company-info-text {
    @extend %text-regular;
  }

  &__person {
    border-bottom: 1px solid $secondary-grey;
    margin-bottom: 32px;

    &:first-of-type {
      margin-top: 40px;

      @media screen and (min-width: $tablet-breakpoint) {
        margin-top: 65px;
      }
    }
  }

  &__person-count {
    font-size: 17px;
    line-height: 22px;
  }

  &__person-count-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__person-remove {
    display: flex;
    height: 16px;
    width: 16px;

    @include hover {
      cursor: pointer;
    }
  }

  &__person-form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  &__person-form-label {
    display: flex;
    font-size: 13px;
    line-height: 16px;
    font-family: $font-regular;
    margin-bottom: 4px;
  }

  &__person-form-name-input {
    width: 100%;
    height: 42px;
    border-radius: 6px;
    border: 2px solid $input-border-grey;
    padding-left: 16px;
    margin-bottom: 16px;
    box-sizing: border-box;

    &:active,
    &:focus {
      outline: none;
    }

    @extend %text-regular;

    &--error {
      border: 2px solid $error-color;
      margin-bottom: 3px;
    }
  }

  &__person-form-name-error {
    display: block;
    font-size: 12px;
    line-height: 16px;
    font-family: $font-regular;
    color: $error-color;
    margin-bottom: 16px;
  }

  &__person-form-contact {
    width: 100%;
  }

  &__person-add-contact {
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 32px;
    width: max-content;

    @include hover {
      cursor: pointer;
    }
  }

  &__person-add-contact-image,
  &__add-person-image {
    height: 16px;
    width: 16px;
  }

  &__person-add-contact-text,
  &__add-person-text {
    margin-left: 8px;
    border-bottom: 1px solid $link-border-color;

    @extend %text-regular;
  }

  &__add-person {
    display: flex;
    margin: 32px 0 0;
    cursor: pointer;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    .btn--primary {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }

  &__loader {
    margin: 24px auto 0;
  }
}

@media screen and (min-width: $desktop-breakpoint) {
  .profile-form {
    padding: 64px 0px;
    align-items: center;

    &__container {
      max-width: 472px;
    }

    &__form-input {
      flex-direction: column;
    }

    &__company-info-row {
      align-items: flex-start;
    }

    &__footer {
      flex-direction: row;

      .btn--primary {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }

    &__loader {
      margin: 64px auto 0;
    }
  }
}
</style>
