<template>
  <div class="dashboard">
    <ChartWrapper
      :title="$t('partner-portal.charts.profit-chart')"
      :digits-title="profitAndTurnoverTitle"
      :has-chart-data="profitAndTurnoverChartHasChartData"
      :is-loading="isProfitAndTurnoverChartLoading"
      :is-legend-visible="true"
      data-testid="profit-turnover-section"
      class="dashboard__section"
      @filters-changed="requestProfitAndTurnoverWithFilters"
    >
      <ProfitAndTurnoverChart
        :chart-options="profitAndTurnoverChartOptions"
        :chart-data="profitAndTurnoverChartData"
        :is-legend-visible="true"
      />
    </ChartWrapper>

    <ChartWrapper
      :title="$t('partner-portal.charts.transactions-chart')"
      :digits-title="transactionsTitle"
      :has-chart-data="transactionsChartHasChartData"
      :is-loading="isTransactionsChartLoading"
      data-testid="transactions-section"
      class="dashboard__section"
      @filters-changed="requestTransactionsWithFilters"
    >
      <LinearChart
        :chart-options="transactionsChartOptions"
        :chart-data="storeTransactionsChartData"
      />
    </ChartWrapper>

    <ChartWrapper
      :title="$t('partner-portal.charts.success-rate-chart')"
      :digits-title="successRateTitle"
      :has-chart-data="successRateChartHasChartData"
      :is-loading="isSuccessRateChartLoading"
      data-testid="success-rate-section"
      class="dashboard__section"
      @filters-changed="requestSuccessRateWithFilters"
    >
      <LinearChart
        :chart-options="successRateChartOptions"
        :chart-data="storeSuccessRateChartData"
      />
    </ChartWrapper>

    <div class="dashboard__section toplist-charts">
      <ToplistChart
        class="toplist-charts__chart"
        :description="$t('partner-portal.dashboard.current-week-data')"
        :title="$t('partner-portal.dashboard.top-countries')"
        :items="storeTopCountriesList"
        :is-loading="isTopCountriesLoading"
        data-testid="top-countries-chart"
        header-link="topChartsCountries"
      />

      <ToplistChart
        class="toplist-charts__chart"
        :description="$t('partner-portal.dashboard.current-week-data')"
        :title="$t('partner-portal.dashboard.top-cryptocurrencies')"
        :items="storeTopCurrenciesList"
        :is-loading="isTopCurrenciesLoading"
        data-testid="top-currencies-chart"
        header-link="topChartsCryptos"
      />
    </div>

    <!-- TODO: Enable link to transactions page when it's ready -->
    <TransactionsList
      class="dashboard__section"
      data-testid="transaction-history-chart"
      :title="$t('partner-portal.dashboard.transaction-history')"
      :transactions="storeTransactionsList"
      :can-load-more-transactions="canLoadMoreTransactions"
      :show-link="true"
      :show-download="false"
      :settings="transactionsListSettings"

      :is-loading-transactions="isTransactionsListLoading"
      :is-loading-more="isLoadingMoreTransactions"

      :load-transaction-details="loadTransactionDetails"
      @load-more="requestAdditionalTransactions"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ChartWrapper from '@/components/ChartWrapper';
import ToplistChart from '@/components/ToplistChart';
import TransactionsList from '@/components/TransactionsList';
import { formatCurrency } from '@/utils';
import { convertDateIntoChartISO } from '@/utils/charts';
import { ProfitAndTurnoverChart, LinearChart } from '@/components/ChartCanvas';

export default {
  name: 'Dashboard',

  components: {
    ChartWrapper,
    ToplistChart,
    TransactionsList,
    ProfitAndTurnoverChart,
    LinearChart,
  },

  data: () => ({
    isProfitAndTurnoverChartLoading: true,
    isTransactionsChartLoading: true,
    isSuccessRateChartLoading: true,

    isTopCountriesLoading: true,
    isTopCurrenciesLoading: true,

    isTransactionsListLoading: true,
    isLoadingMoreTransactions: true,
  }),

  computed: {
    ...mapGetters('dashboardStore', {
      storeProfitTitle: 'profitChartTitle',
      storeProfitChartData: 'profitChartData',

      storeTurnoverTitle: 'turnoverChartTitle',
      storeTurnoverChartData: 'turnoverChartData',

      storeTransactionsTitle: 'transactionsChartTitle',
      storeTransactionsChartData: 'transactionsChartData',

      storeSuccessRateTitle: 'successRateChartTitle',
      storeSuccessRateChartData: 'successRateChartData',

      storeTopCountriesList: 'topCountriesList',
      storeTopCurrenciesList: 'topCurrenciesList',

      storeTransactionsList: 'transactionsList',
      canLoadMoreTransactions: 'canLoadMoreTransactions',
    }),

    profitAndTurnoverTitle() {
      if (this.isProfitAndTurnoverChartLoading) return '';

      const { value: profitAmount = 0, unit: profitCurrency = 'USD' } = this.storeProfitTitle || {};
      const { value: turnoverAmount = 0, unit: turnoverCurrency = 'USD' } = this.storeTurnoverTitle || {};

      const profitTitle = formatCurrency(profitAmount, profitCurrency);
      const turnoverTitle = formatCurrency(turnoverAmount, turnoverCurrency);

      return `${profitTitle} / ${turnoverTitle}`;
    },

    profitAndTurnoverChartHasChartData() {
      const hasProfitData = this.storeProfitChartData?.some(series => Number(series.y) > 0);
      const hasTurnoverData = this.storeTurnoverChartData?.some(series => Number(series.y) > 0);
      return hasProfitData || hasTurnoverData;
    },

    profitAndTurnoverChartOptions() {
      return [{
        label: this.$t('partner-portal.charts.turnover'),
        chartColor: '#5F70DB',
        styles: {
          pointHoverBackgroundColor: '#5F70DB',
          pointHoverRadius: 5,
        },
      }, {
        label: this.$t('partner-portal.charts.profit'),
        chartColor: '#C75CF9',
        styles: {
          pointHoverBackgroundColor: '#C75CF9',
          pointHoverRadius: 5,
        },
      }];
    },

    profitAndTurnoverChartData() {
      return [
        this.storeTurnoverChartData,
        this.storeProfitChartData,
      ];
    },

    transactionsTitle() {
      if (this.isTransactionsChartLoading) return '';

      return this.storeTransactionsTitle?.value || '0';
    },

    transactionsChartHasChartData() {
      return this.storeTransactionsChartData?.some(series => Number(series.y) > 0);
    },

    transactionsChartOptions() {
      return {
        label: this.$t('partner-portal.charts.transactions-chart'),
        chartColor: '#27AE60',
        styles: {
          pointHoverBackgroundColor: '#27AE60',
          pointHoverRadius: 5,
        },
      };
    },

    successRateTitle() {
      if (this.isSuccessRateChartLoading) return '';

      if (!this.storeSuccessRateTitle) return '0%';

      return `${this.storeSuccessRateTitle?.value}%`;
    },

    successRateChartHasChartData() {
      return this.storeSuccessRateChartData?.some(series => Number(series.y) > 0);
    },

    successRateChartOptions() {
      return {
        label: this.$t('partner-portal.charts.success-rate-chart'),
        chartColor: '#ED3DDC',
        styles: {
          pointHoverBackgroundColor: '#ED3DDC',
          pointHoverRadius: 5,
        },
      };
    },

    transactionsListSettings() {
      return {
        limit: 10,
      };
    },
  },

  destroyed() {
    this.unsubscribeFromProfitAndTurnoverData();
    this.unsubscribeFromTransactionsData();
    this.unsubscribeFromSuccessRateData();

    this.unsubscribeFromTopCountriesData();
    this.unsubscribeFromTopCurrenciesData();

    this.unsubscribeFromTransactionsList();
  },

  mounted() {
    const { limit } = this.transactionsListSettings;
    const defaultLastWeekrange = convertDateIntoChartISO(false);

    this.subscribeToProfitAndTurnoverData(defaultLastWeekrange)
      .finally(() => {
        this.isProfitAndTurnoverChartLoading = false;
      });

    this.subscribeToTransactionsData(defaultLastWeekrange)
      .finally(() => {
        this.isTransactionsChartLoading = false;
      });

    this.subscribeToSuccessRateData(defaultLastWeekrange)
      .finally(() => {
        this.isSuccessRateChartLoading = false;
      });

    this.subscribeToCountriesData(defaultLastWeekrange)
      .finally(() => {
        this.isTopCountriesLoading = false;
      });

    this.subscribeToTopCurrenciesData(defaultLastWeekrange)
      .finally(() => {
        this.isTopCurrenciesLoading = false;
      });

    this.subscribeToTransactionsList(limit)
      .finally(() => {
        this.isTransactionsListLoading = false;
        this.isLoadingMoreTransactions = false;
      });
  },

  methods: {
    ...mapActions('dashboardStore', {
      subscribeToProfitAndTurnoverData: 'subscribeToProfitAndTurnoverData',
      unsubscribeFromProfitAndTurnoverData: 'unsubscribeFromProfitAndTurnoverData',
      subscribeToTransactionsData: 'subscribeToTransactionsData',
      unsubscribeFromTransactionsData: 'unsubscribeFromTransactionsData',
      subscribeToSuccessRateData: 'subscribeToSuccessRateData',
      unsubscribeFromSuccessRateData: 'unsubscribeFromSuccessRateData',

      subscribeToCountriesData: 'subscribeToCountriesData',
      unsubscribeFromTopCountriesData: 'unsubscribeFromTopCountriesData',
      subscribeToTopCurrenciesData: 'subscribeToTopCurrenciesData',
      unsubscribeFromTopCurrenciesData: 'unsubscribeFromTopCurrenciesData',

      subscribeToTransactionsList: 'subscribeToTransactionsList',
      unsubscribeFromTransactionsList: 'unsubscribeFromTransactionsList',
      loadTransactionDetails: 'loadTransactionDetails',
    }),

    requestAdditionalTransactions(transactionsOptions) {
      this.isLoadingMoreTransactions = true;
      this.subscribeToTransactionsList(transactionsOptions)
        .finally(() => {
          this.isLoadingMoreTransactions = false;
        });
    },

    requestProfitAndTurnoverWithFilters(filtersOptions) {
      this.isProfitAndTurnoverChartLoading = true;

      const profitAndTurnoverDateSettings = convertDateIntoChartISO(filtersOptions);
      this.subscribeToProfitAndTurnoverData(profitAndTurnoverDateSettings)
        .finally(() => {
          this.isProfitAndTurnoverChartLoading = false;
        });
    },

    requestTransactionsWithFilters(filtersOptions) {
      this.isTransactionsChartLoading = true;

      const transactionsDateSettings = convertDateIntoChartISO(filtersOptions);
      this.subscribeToTransactionsData(transactionsDateSettings)
        .finally(() => {
          this.isTransactionsChartLoading = false;
        });
    },

    requestSuccessRateWithFilters(filtersOptions) {
      this.isSuccessRateChartLoading = true;

      const successRateDateSettings = convertDateIntoChartISO(filtersOptions);
      this.subscribeToSuccessRateData(successRateDateSettings)
        .finally(() => {
          this.isSuccessRateChartLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.dashboard {
  &__section {
    margin-bottom: 32px;

    box-shadow: 0px 2px 8px $secondary-border-color;
  }
}

.toplist-charts {
  display: flex;
  flex-wrap: wrap;

  &__chart {
    width: 100%;

    &:not(:first-child) {
      margin-top: 32px;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      width: calc(50% - 16px);

      &:not(:first-child) {
        margin-top: 0;
      }

      &:not(:last-child) {
        margin-right: 32px;
      }
    }
  }
}
</style>
