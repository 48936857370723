const getOrCreateTooltip = chart => {
  const tooltipElement = chart.canvas.parentNode.querySelector('.tooltip');

  if (!tooltipElement) {
    const newTooltipElement = document.createElement('div');
    newTooltipElement.classList.add('tooltip');
    newTooltipElement.style.minWidth = '79px';
    newTooltipElement.style.background = 'rgba(0,0,0,0.8)';
    newTooltipElement.style.borderRadius = '4px';
    newTooltipElement.style.padding = '4px 8px';
    newTooltipElement.style.opacity = 1;
    newTooltipElement.style.pointerEvents = 'none';
    newTooltipElement.style.position = 'absolute';
    newTooltipElement.style.transform = 'translate(-50%, 0)';
    newTooltipElement.style.transition = 'all .1s ease';

    const tooltipContainer = document.createElement('div');
    tooltipContainer.style.display = 'flex';
    tooltipContainer.style.flexDirection = 'column';
    tooltipContainer.classList.add('tooltip__container');

    newTooltipElement.appendChild(tooltipContainer);
    chart.canvas.parentNode.appendChild(newTooltipElement);

    return newTooltipElement;
  }

  return tooltipElement;
};

export const tooltipHandler = context => {
  const { chart, tooltip } = context;
  const tooltipElement = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipElement.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(element => element.lines);

    const tooltipLabel = document.createElement('div');
    tooltipLabel.classList.add('tooltip__label');
    tooltipLabel.style.textAlign = 'center';
    tooltipLabel.style.color = '#fff';
    tooltipLabel.style.fontSize = '12px';
    tooltipLabel.style.lineHeight = '16px';
    tooltipLabel.style.paddingBottom = '4px';
    tooltipLabel.style.borderBottom = '1px solid rgba(255, 255, 255, 255, 0.1)';

    titleLines.forEach(title => {
      const titleText = document.createTextNode(title);
      tooltipLabel.appendChild(titleText);
    });

    // For some reason tooltipLabel.style.border not working
    const tooltipBorder = document.createElement('tooltip__border');
    tooltipBorder.classList.add('tooltip__border');
    tooltipBorder.style.width = '100%';
    tooltipBorder.style.height = '1px';
    tooltipBorder.style.background = 'rgba(255, 255, 255, 0.1)';

    const tooltipValues = document.createElement('div');
    tooltipValues.classList.add('tooltip__values');
    bodyLines.forEach((body, index) => {
      const [bodyTitle, bodyValue] = body[0].split(':');
      const colors = tooltip.labelColors[index];

      const valueElement = document.createElement('div');
      valueElement.classList.add('tooltip__values-element');
      valueElement.style.display = 'flex';
      valueElement.style.flexDirection = 'column';
      valueElement.style.textAlign = 'center';

      const valueElementTitle = document.createElement('div');
      valueElementTitle.style.color = colors.borderColor;
      valueElementTitle.style.fontSize = '12px';
      valueElementTitle.style.lineHeight = '16px';
      const valueElementTitleText = document.createTextNode(bodyTitle);
      valueElementTitle.appendChild(valueElementTitleText);

      const valueElementBody = document.createElement('div');
      valueElementBody.style.color = '#fff';
      valueElementBody.style.fontSize = '15px';
      valueElementBody.style.lineHeight = '18px';
      const valueElementBodyText = document.createTextNode(bodyValue);
      valueElementBody.appendChild(valueElementBodyText);

      valueElement.appendChild(valueElementTitle);
      valueElement.appendChild(valueElementBody);
      tooltipValues.appendChild(valueElement);
    });

    const tooltipContainer = tooltipElement.querySelector('.tooltip__container');

    while (tooltipContainer.firstChild) {
      tooltipContainer.firstChild.remove();
    }

    tooltipContainer.appendChild(tooltipLabel);
    tooltipContainer.appendChild(tooltipBorder);
    tooltipContainer.appendChild(tooltipValues);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  tooltipElement.style.opacity = 1;
  tooltipElement.style.left = `${positionX + tooltip.caretX}px`;
  tooltipElement.style.top = `${positionY + tooltip.caretY}px`;

  tooltipElement.style.font = tooltip.options.bodyFont.string;
  tooltipElement.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
};
