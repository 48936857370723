import pickBy from 'lodash.pickby';
import { apiClient } from '@/network';
import LoggerService from '@/services/LoggerService';
import SubscriptionService from '@/services/SubscriptionService';

const AGGREGATED_STATS_SUBSCRIPTION_KEY = 'aggregatedStatsData';
const PROFIT_AND_TURNOVER_SUBSCRIPTION_KEY = 'profitAndTurnoverChartData';
const TRANSACTIONS_SUBSCRIPTION_KEY = 'transactionsChartData';
const SUCCESS_RATE_SUBSCRIPTION_KEY = 'successRateChartData';
const TOP_COUNTRIES_SUBSCRIPTION_KEY = 'topCountriesData';
const TOP_CURRENCIES_SUBSCRIPTION_KEY = 'topCurrenciesData';
const TRANSACTIONS_LIST_SUBSCRIPTION_KEY = 'transactionsData';
const DASHBOARD_TRANSACTIONS_LIMIT = 100;

export default {
  namespaced: true,

  state: {
    aggregation: {
      unpaid: null,
      profit: null,
      totalSales: null,
    },

    profitAndTurnoverChart: {
      profitTitle: null,
      profitChartData: [],

      turnoverTitle: null,
      turnoverChartData: [],
    },
    transactionsChart: {
      title: null,
      data: [],
    },
    successRateChart: {
      title: null,
      data: [],
    },

    topCountries: [],
    topCurrencies: [],

    transactions: {
      meta: null,
      data: [],
    },
  },

  getters: {
    unpaidTotal: state => state.aggregation.unpaid?.total,
    unpaidBalances: state => state.aggregation.unpaid?.balances,
    profitTotal: state => state.aggregation.profit?.total,
    profitBalances: state => state.aggregation.profit?.balances,
    totalSales: state => state.aggregation.totalSales?.total,
    totalSalesBalances: state => state.aggregation.totalSales?.balances,

    profitChartTitle: state => state.profitAndTurnoverChart.profitTitle,
    turnoverChartTitle: state => state.profitAndTurnoverChart.turnoverTitle,
    profitChartData: state => state.profitAndTurnoverChart.profitChartData,
    turnoverChartData: state => state.profitAndTurnoverChart.turnoverChartData,

    transactionsChartTitle: state => state.transactionsChart.title,
    transactionsChartData: state => state.transactionsChart.data,

    successRateChartTitle: state => state.successRateChart.title,
    successRateChartData: state => state.successRateChart.data,

    topCountriesList: state => state.topCountries,
    topCurrenciesList: state => state.topCurrencies,

    transactionsList: state => state.transactions.data,
    canLoadMoreTransactions: state => !!state.transactions.meta?.cursors.next,

    activeProductId: (state, getters, rootState, rootGetters) => rootGetters['accountProductsStore/activeProductId'],
  },

  mutations: {
    clearStore(state) {
      state.aggregation = {
        unpaid: null,
        profit: null,
        totalSales: null,
      };

      state.profitAndTurnoverChart = {
        profitTitle: null,
        profitChartData: [],

        turnoverTitle: null,
        turnoverChartData: [],
      };

      state.transactionsChart = {
        title: null,
        data: [],
      };

      state.successRateChart = {
        title: null,
        data: [],
      };

      state.topCountries = [];
      state.topCurrencies = [];
    },

    setAggregationUnpaid(state, unpaidStats) {
      state.aggregation.unpaid = unpaidStats;
    },

    setAggregationProfit(state, profitState) {
      state.aggregation.profit = profitState;
    },

    setAggregationTotalSales(state, totalSalesStats) {
      state.aggregation.totalSales = totalSalesStats;
    },

    setProfitChartTitle(state, payload) {
      state.profitAndTurnoverChart.profitTitle = payload;
    },

    setProfitChartData(state, payload) {
      state.profitAndTurnoverChart.profitChartData = payload;
    },

    setTurnoverChartTitle(state, payload) {
      state.profitAndTurnoverChart.turnoverTitle = payload;
    },

    setTurnoverChartData(state, payload) {
      state.profitAndTurnoverChart.turnoverChartData = payload;
    },

    setTransactionsChartTitle(state, payload) {
      state.transactionsChart.title = payload;
    },

    setTransactionsChartData(state, payload) {
      state.transactionsChart.data = payload;
    },

    setSuccessRateChartTitle(state, payload) {
      state.successRateChart.title = payload;
    },

    setSuccessRateChartData(state, payload) {
      state.successRateChart.data = payload;
    },

    setTopCountries(state, payload) {
      state.topCountries = payload;
    },

    setTopCurrencies(state, payload) {
      state.topCurrencies = payload;
    },

    setTransactionsList(state, payload) {
      state.transactions.data = payload;
    },

    setTransactionsMeta(state, payload) {
      state.transactions.meta = payload;
    },
  },

  actions: {
    clearStore({ commit }) {
      commit('clearStore');
    },

    async getAggregatedStats({ commit, getters }, addHeader = true) {
      try {
        let headers = {};

        if (addHeader) {
          headers = {
            'X-Product-Id': getters.activeProductId,
          };
        }

        const { data: { unpaid, profit, sales } } = await apiClient.get('/v1/analytics/aggregated-statistics', {
          headers,
        });
        commit('setAggregationUnpaid', unpaid);
        commit('setAggregationProfit', profit);
        commit('setAggregationTotalSales', sales);
      } catch (error) {
        LoggerService.logToConsole(error);
      }
    },

    async subscribeToAggregatedStats({ commit, getters }) {
      const getAggregatedStatsFunction = async () => {
        try {
          const { data: { unpaid, profit, sales } } = await apiClient.get('/v1/analytics/aggregated-statistics', {
            headers: {
              'X-Product-ID': getters.activeProductId,
            },
          });
          commit('setAggregationUnpaid', unpaid);
          commit('setAggregationProfit', profit);
          commit('setAggregationTotalSales', sales);
        } catch (error) {
          LoggerService.logToConsole(error);
        }
      };

      await SubscriptionService.subscribe({
        subscriptionKey: AGGREGATED_STATS_SUBSCRIPTION_KEY,
        functionToInvoke: getAggregatedStatsFunction,
      });
    },

    async unsubscribeFromAggregatedStats() {
      await SubscriptionService.unsubscribe(AGGREGATED_STATS_SUBSCRIPTION_KEY);
    },

    async subscribeToProfitAndTurnoverData({ commit, getters }, { from, to }) {
      const getProfitAndTurnoverData = async () => {
        try {
          const { data: { datasets } } = await apiClient.get('/v1/analytics/profit-turnover', {
            params: {
              from, to,
            },
            headers: {
              'X-Product-ID': getters.activeProductId,
            },
          });

          const profitDataset = datasets.find(dataset => dataset.type === 'profit');
          const turnoverDataset = datasets.find(dataset => dataset.type === 'turnover');

          commit('setProfitChartTitle', profitDataset.aggregated);
          commit('setTurnoverChartTitle', turnoverDataset.aggregated);
          commit('setProfitChartData', profitDataset.data);
          commit('setTurnoverChartData', turnoverDataset.data);
        } catch (error) {
          LoggerService.logToConsole(error);
        }
      };

      await SubscriptionService.subscribe({
        subscriptionKey: PROFIT_AND_TURNOVER_SUBSCRIPTION_KEY,
        functionToInvoke: getProfitAndTurnoverData,
      });
    },

    unsubscribeFromProfitAndTurnoverData() {
      SubscriptionService.unsubscribe(PROFIT_AND_TURNOVER_SUBSCRIPTION_KEY);
    },

    async subscribeToTransactionsData({ commit, getters }, { from, to }) {
      const getTransactionsData = async () => {
        try {
          const { data: { datasets } } = await apiClient.get('/v1/analytics/transactions', {
            params: {
              from, to,
            },
            headers: {
              'X-Product-ID': getters.activeProductId,
            },
          });

          commit('setTransactionsChartTitle', datasets[0].aggregated);
          commit('setTransactionsChartData', datasets[0].data);
        } catch (error) {
          LoggerService.logToConsole(error);
        }
      };

      await SubscriptionService.subscribe({
        subscriptionKey: TRANSACTIONS_SUBSCRIPTION_KEY,
        functionToInvoke: getTransactionsData,
      });
    },

    unsubscribeFromTransactionsData() {
      SubscriptionService.unsubscribe(TRANSACTIONS_SUBSCRIPTION_KEY);
    },

    async subscribeToSuccessRateData({ commit, getters }, { from, to }) {
      const getSuccessRateData = async () => {
        try {
          const { data: { datasets } } = await apiClient.get('/v1/analytics/success-rate', {
            params: {
              from, to,
            },
            headers: {
              'X-Product-ID': getters.activeProductId,
            },
          });

          commit('setSuccessRateChartTitle', datasets[0].aggregated);
          commit('setSuccessRateChartData', datasets[0].data);
        } catch (error) {
          LoggerService.logToConsole(error);
        }
      };

      await SubscriptionService.subscribe({
        subscriptionKey: SUCCESS_RATE_SUBSCRIPTION_KEY,
        functionToInvoke: getSuccessRateData,
      });
    },

    unsubscribeFromSuccessRateData() {
      SubscriptionService.unsubscribe(SUCCESS_RATE_SUBSCRIPTION_KEY);
    },

    async subscribeToCountriesData({ commit, getters }, { from, to }) {
      const getTopCountries = async () => {
        try {
          const { data: { data: topCountries } } = await apiClient.get('/v1/analytics/top-countries', {
            params: {
              from, to,
            },
            headers: {
              'X-Product-ID': getters.activeProductId,
            },
          });

          commit('setTopCountries', topCountries);
        } catch (error) {
          LoggerService.logToConsole(error);
        }
      };

      await SubscriptionService.subscribe({
        subscriptionKey: TOP_COUNTRIES_SUBSCRIPTION_KEY,
        functionToInvoke: getTopCountries,
      });
    },

    unsubscribeFromTopCountriesData() {
      SubscriptionService.unsubscribe(TOP_COUNTRIES_SUBSCRIPTION_KEY);
    },

    async subscribeToTopCurrenciesData({ commit, getters }, { from, to }) {
      const getTopCurrencies = async () => {
        try {
          const { data: { data: topCurrencies } } = await apiClient.get('/v1/analytics/top-currencies', {
            params: {
              from, to,
            },
            headers: {
              'X-Product-ID': getters.activeProductId,
            },
          });

          commit('setTopCurrencies', topCurrencies);
        } catch (error) {
          LoggerService.logToConsole(error);
        }
      };

      await SubscriptionService.subscribe({
        subscriptionKey: TOP_CURRENCIES_SUBSCRIPTION_KEY,
        functionToInvoke: getTopCurrencies,
      });
    },

    unsubscribeFromTopCurrenciesData() {
      SubscriptionService.unsubscribe(TOP_CURRENCIES_SUBSCRIPTION_KEY);
    },

    async subscribeToTransactionsList({ commit, getters }, { limit = null }) {
      const getTransactions = async () => {
        try {
          const { data: { data: transactions, meta } } = await apiClient.get('/v1/transactions', {
            params: pickBy({
              limit,
              with_payment: 1,
            }),
            headers: {
              'X-Product-ID': getters.activeProductId,
            },
          });

          commit('setTransactionsList', transactions);
          commit('setTransactionsMeta', meta);

          if (limit >= DASHBOARD_TRANSACTIONS_LIMIT) {
            commit('setTransactionsMeta', null);
          }
        } catch (error) {
          LoggerService.logToConsole(error);
        }
      };

      await SubscriptionService.subscribe({
        subscriptionKey: TRANSACTIONS_LIST_SUBSCRIPTION_KEY,
        functionToInvoke: getTransactions,
      });
    },

    unsubscribeFromTransactionsList() {
      SubscriptionService.unsubscribe(TRANSACTIONS_LIST_SUBSCRIPTION_KEY);
    },

    async loadTransactionDetails({ getters }, id) {
      try {
        const { data } = await apiClient.get(`/v1/transactions/${id}`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });
        return data;
      } catch (error) {
        return LoggerService.logToConsole(error);
      }
    },
  },
};
