import { apiClient } from '@/network';
import SubscriptionService from '@/services/SubscriptionService';

const REPORT_INITIALIZED_CODE = 201;
const REPORT_CREATION_IN_PROGRESS = 202;

const REPORT_REQUEST_INTERVAL = 5000;
const BASIC_TIME_TO_UNSUBSCRIBE = 1000 * 60 * 5;

let expirationTimerId = null;

const performUnsubscribe = (subscriptionKey, callback) => {
  clearTimeout(expirationTimerId);
  SubscriptionService.unsubscribe(subscriptionKey);
  callback();
};

const checkReportStatus = async (id, subscriptionKey, callback) => {
  try {
    const { data: { url } } = await apiClient.get(`/v1/reports/${id}`);

    if (url) {
      performUnsubscribe(subscriptionKey, () => callback(null, url));
    }
  } catch (error) {
    performUnsubscribe(subscriptionKey, () => callback(new Error(), null));
  }
};

export default {
  async requestReport({ type, subscriptionKey, filters, callback, productHeaderId }) {
    try {
      const { status, data: { id, url } } = await apiClient.post('/v1/reports', {
        type,
        ...filters,
      }, {
        headers: {
          'X-Product-ID': productHeaderId,
        },
      });

      const isUrlExist = url ?? false;

      const statusCodesThatRequireSubscription = [REPORT_CREATION_IN_PROGRESS, REPORT_INITIALIZED_CODE];
      const shouldSubscribeToReport = statusCodesThatRequireSubscription.includes(status) && !isUrlExist;

      expirationTimerId = setTimeout(() => {
        performUnsubscribe(subscriptionKey, () => callback(new Error(), null));
      }, BASIC_TIME_TO_UNSUBSCRIBE);

      if (shouldSubscribeToReport) {
        await SubscriptionService.subscribe({
          subscriptionKey,
          functionToInvoke: () => checkReportStatus(
            id,
            subscriptionKey,
            callback,
          ),
          immediate: false,
          interval: REPORT_REQUEST_INTERVAL,
        });
      }

      if (isUrlExist) {
        performUnsubscribe(subscriptionKey, () => callback(null, url));
      }
    } catch (error) {
      performUnsubscribe(subscriptionKey, () => callback(new Error(), null));
    }
  },

  clearExpirationTimerId() {
    clearTimeout(expirationTimerId);
  },
};
