import Vue from 'vue';
import NavDesktop from './Nav.desktop.vue';
import NavMobile from './Nav.mobile.vue';
import { buildNavigationTree } from './Nav.utils';

export default Vue.component('Nav', {
  functional: true,
  render(h) {
    const NavRoutes = buildNavigationTree();
    return h('div', [
      h(NavMobile, {
        props: {
          routes: NavRoutes,
        },
      }),
      h(NavDesktop, {
        props: {
          routes: NavRoutes,
        },
      }),
    ]);
  },
});
