import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/lang/en.json';

Vue.use(VueI18n);

const defaultLocale = 'en';
export const i18n = new VueI18n({
  messages: {
    en,
  },
  locale: 'en',
  fallbackLocale: defaultLocale,
});

// TODO: Find the way to load locale when changing it progrmmatically
