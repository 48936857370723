import { apiClient } from '@/network';
import LoggerService from '@/services/LoggerService';
import { isFeatureFlagEnabled } from '@/utils/featureFlags';

const PROFILE_SETTINGS_PAGE_FF = 'display-profile-settings-page';
const PAYMENT_METHODS_FF = 'display-payment-methods';
const CONTACT_PERSONS_FF = 'display-contact-persons';

export default {
  namespaced: true,

  state: {
    featureFlags: [],
  },

  getters: {
    isProfileSettingsPageVisible: state => isFeatureFlagEnabled(
      state.featureFlags, PROFILE_SETTINGS_PAGE_FF,
    ),
    isPaymentMethodsEnabled: state => isFeatureFlagEnabled(state.featureFlags, PAYMENT_METHODS_FF),
    isContactPersonsEnable: state => isFeatureFlagEnabled(state.featureFlags, CONTACT_PERSONS_FF),

    featureFlagsList: state => state.featureFlags,
    isFeatureFlagsListEmpty: state => !state.featureFlags.length,
  },

  mutations: {
    setFeatureFlags(state, payload) {
      state.featureFlags = payload;
    },
  },

  actions: {
    getFeatureFlags: async ({ commit }) => {
      try {
        const { data: { data: featureFlags } } = await apiClient.get('/v1/feature-flags');

        commit('setFeatureFlags', featureFlags);
      } catch (error) {
        LoggerService.captureSentryError(error, 'Was trying to get feature flags');
      }
    },
  },
};
