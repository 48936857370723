<template>
  <transition name="modal">
    <div
      v-if="isShown"
      class="modal-holder__background"
      @click.self="holderDismiss"
    >
      <component :is="ModalContentComponent" />
    </div>
  </transition>
</template>

<script>
import modalManager from './modalManager';

export default {
  name: 'ModalHolder',

  props: {
    name: {
      type: String,
      default: 'default',
    },
  },

  data: () => ({
    holderPropsLink: {},
  }),

  computed: {
    isShown() {
      return this.holderPropsLink.isShown;
    },

    ModalContentComponent() {
      return this.holderPropsLink.component;
    },
  },

  created() {
    this.holderPropsLink = modalManager.registerTarget({
      name: this.name,
    });
  },

  beforeDestroy() {
    modalManager.unregisterTarget({
      name: this.name,
    });
  },

  methods: {
    holderDismiss() {
      this.ModalContentComponent.holderDismiss();
    },
  },
};
</script>

<style lang="scss">
$dim-color: rgba(0, 0, 0, 0.25);

.modal-holder {
  &__background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $dim-color;

    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modal-enter-active, .modal-leave-active {
  transition: opacity .5s;
}
.modal-enter, .modal-leave-to {
  opacity: 0;
}
</style>
