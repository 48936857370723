<template>
  <main>
    <CompanyLayoutSidebar />
    <router-view />
  </main>
</template>

<script>
import CompanyLayoutSidebar from './CompanyLayoutSidebar';

export default {
  name: 'CompanyLayout',
  components: {
    CompanyLayoutSidebar,
  },
};
</script>
