import Vue from 'vue';
import VCalendar from 'v-calendar';
import VueToasted from 'vue-toasted';
import Amplify from 'aws-amplify';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './i18n';
import StoreRouterMediator from '@/plugins/StoreRouterMediator';
import { initSentry } from './sentry';
import './directives/clickOutside';

import './assets/scss/main.scss';
import config from './awsConfig';

if (!IS_DEVELOPMENT) initSentry(router);

Amplify.configure(config);

Vue.config.productionTip = false;

const startApp = async () => {
  Vue.use(VueToasted);

  await store.dispatch('init');

  Vue.use(VCalendar);
  Vue.use(VueToasted);
  Vue.use(StoreRouterMediator, {
    store, router,
  });

  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app');
};

startApp();
