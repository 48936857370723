const KEYS_LIST = {
  currencies: 'currencies[]',
  statuses: 'statuses[]',
  countries: 'countries[]',
  gateways: 'gateways[]',
  from: 'from',
  to: 'to',
  query: 'query',
  limit: 'limit',
  meta: 'meta[]',
  offset: 'offset',
  payment_methods: 'payment_methods[]',
  with_payment: 'with_payment',
};

export const buildQueryWithFilters = filters => {
  const entries = Object.entries(filters);
  let queryString = '';

  entries.forEach(entry => {
    const [key, value] = entry;
    const isValueArray = Array.isArray(value);

    if (value) {
      if (isValueArray) {
        value.forEach(element => {
          queryString += `&${KEYS_LIST[key]}=${element}`;
        });
      } else {
        queryString += `&${KEYS_LIST[key]}=${value}`;
      }
    }
  });

  return queryString;
};

export const buildBodyRequestForReport = filters => {
  const entries = Object.entries(filters);

  const filtersList = {};
  entries.forEach(([key, value]) => {
    if (value) {
      const isValueArray = Array.isArray(value);
      if (isValueArray) {
        const valuesList = [];
        value.forEach(element => {
          valuesList.push(element);
        });

        filtersList[key] = valuesList;
      } else {
        filtersList[key] = value;
      }
    }
  });

  return filtersList;
};
