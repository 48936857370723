<template>
  <form
    class="password-restore__form"
    @submit.prevent="sendEmail"
  >
    <i class="icon icon-password-restore password-restore__form-icon" />

    <span class="password-restore__form-title">
      {{ $t('partner-portal.password-restore.forgot-password') }}
    </span>

    <div class="password-restore__form-input-group">
      <div class="password-restore__form-label-group">
        <label class="password-restore__form-label">{{ $t('partner-portal.common.email') }}</label>
      </div>

      <AppInput
        v-model="email"
        :is-disabled="isLoading"
        :errors="errors"
        class="password-restore__form-input"
        name="email"
        type="email"
        @keydown.native.space.prevent
      />
    </div>

    <AppButton
      type="submit"
      :text="$t('partner-portal.password-restore.send-code')"
      :is-loading="isLoading"
      class="btn--primary password-restore__form-button"
    />
  </form>
</template>

<script>
import AppInput from '@/components/AppInput';
import AppButton from '@/components/AppButton';

export default {
  name: 'PasswordRestoreEmailForm',

  components: {
    AppInput,
    AppButton,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      email: '',
    };
  },

  methods: {
    sendEmail() {
      this.email = this.email.trim();

      this.$emit('send-email', this.email);
    },
  },
};
</script>
