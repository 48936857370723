<template>
  <div class="top-charts">
    <ToplistChart
      class="top-charts__chart"
      :title="$t('partner-portal.top-charts.titles.top-countries')"

      :items="topCountriesList"
      :report-url="topCountriesReportUrl"

      :show-back-button="true"
      :show-load-more="canLoadMoreTopCountries"
      :show-download-csv="true"

      :is-loading="isLoadingTopCountries"
      :is-loading-more="isTopCountriesLoadingMore"
      :is-loading-report="isLoadingReport"

      @load-more="getMoreTopCountries"
      @download-report="downloadTopCountriesReport"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ToplistChart from '@/components/ToplistChart';

import { getLastMonth } from '@/utils/charts';

export default {
  name: 'TopChartsCountries',

  components: {
    ToplistChart,
  },

  computed: {
    ...mapGetters('topCountriesStore', {
      topCountriesList: 'topCountriesList',

      isLoadingTopCountries: 'isLoadingTopCountries',
      isTopCountriesLoadingMore: 'isTopCountriesLoadingMore',
      isLoadingReport: 'isLoadingReport',

      topCountriesReportUrl: 'reportUrl',

      canLoadMoreTopCountries: 'canLoadMoreTopCountries',
    }),
  },

  mounted() {
    const lastMonthRange = getLastMonth();

    this.setDateFilter(lastMonthRange);
    this.loadTopCountries();
  },

  destroyed() {
    this.unsubscribeFromTopCountriesReport();
    this.clearStore();
  },

  methods: {
    ...mapActions('topCountriesStore', {
      clearStore: 'clearStore',

      setDateFilter: 'setDateFilter',

      loadTopCountries: 'loadTopCountries',
      getMoreTopCountries: 'getMoreTopCountries',
      downloadTopCountriesReport: 'downloadTopCountriesReport',
      unsubscribeFromTopCountriesReport: 'unsubscribeFromTopCountriesReport',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.top-charts {
  flex-grow: 1;
  width: 100%;
}
</style>
