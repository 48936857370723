<template>
  <div
    id="app"
    class="app"
    :class="{ 'app--logged-out': !isUserSignedIn }"
  >
    <div class="header-area">
      <AppHeader class="container" />
    </div>

    <router-view class="content-area container" />

    <div class="footer-area">
      <AppFooter class="container" />
    </div>

    <ModalHolder />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import { ModalHolder } from '@/components/Modal';

export default {
  name: 'App',

  components: {
    AppHeader,
    AppFooter,
    ModalHolder,
  },

  computed: {
    ...mapGetters('userStore', {
      isUserSignedIn: 'isUserSignedIn',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

$login-background: $primary-balance-background;

.app {
  background-color: $background-color;
  min-height: 100vh;

  &--logged-out {
    display: flex;
    flex-direction: column;
    background-color: $login-background;
  }
}

.container {
  max-width: 1348px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
  box-sizing: border-box;
}

.header-area {
  @media screen and (min-width: $tablet-breakpoint) {
    background: $primary;
    margin-bottom: 48px;
  }
}

.content-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: $tablet-breakpoint) {
    flex-direction: row;
  }
}

.footer-area {
  display: flex;
  align-items: center;
  text-align: center;
  height: 96px;
  padding: 0 32px;
  margin: 16px auto 0;

  @media screen and (min-width: $tablet-breakpoint) {
    margin-top: 50px;
    height: 64px;
    text-align: left;
    padding: 0 18px;
  }
}
</style>
