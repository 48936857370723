<template>
  <div class="cell">
    <div
      v-if="transcationInProgress"
      class="cell__status"
    >
      <i class="icon icon-in_progress cell__image" />
      <div>{{ $t('partner-portal.payouts.table.statuses.in_progress' ) }}</div>
    </div>
    <div
      v-else
      class="cell__paid"
    >
      {{ $t('partner-portal.payouts.table.statuses.paid') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayoutStatusCell',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    transcationInProgress() {
      const { status } = this.value;
      return status === 'in_progress';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/icons';
@import '~@/assets/scss/variables';

.cell {

  &__status {
    display: flex;
    align-items: center;
  }

  &__text {
    margin: 0;
  }

  &__image {
    height: 16px;
    width: 16px;
    margin-right: 6px;
  }

  &__paid {
    color: $secondary-text-color;
  }
}
</style>
