<template>
  <aside class="sidebar">
    <SidebarFilter
      data-testid="transactions-filters-sidebar"
      class="sidebar__card sidebar__card--primary"
    />
    <SidebarBalanceInfo
      data-testid="transactions-sidebar"
      :title="$t('partner-portal.transaction.sidebar.transactions-title')"
      :amount="transactionsCount"
      :is-loading="isTransactionsListLoading"
      class="sidebar__card"
    />
    <ManagerCard
      class="sidebar__card sidebar__card--secondary"
    />
    <DevelopersCard class="sidebar__card sidebar__card--secondary" />
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarFilter from '../TransactionsSidebarFilter';
import SidebarBalanceInfo from '@/components/SidebarBalanceInfo';
import DevelopersCard from '@/components/DevelopersCard';
import ManagerCard from '@/components/ManagerCard';
import { formatNumber } from '@/utils';

export default {
  name: 'TransactionsSidebar',

  components: {
    SidebarFilter,
    SidebarBalanceInfo,
    DevelopersCard,
    ManagerCard,
  },

  computed: {
    ...mapGetters('transactionsStore', {
      isTransactionsListLoading: 'isLoadingTransactions',
      transactionsAmount: 'transactionsAmount',
    }),

    transactionsCount() {
      const amount = this.transactionsAmount || '0';
      return formatNumber(amount);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';

.sidebar {
  &__card {
    @include sidebar-card();
  }
}
</style>
