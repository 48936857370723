import { apiClient } from '@/network';
import LoggerService from '@/services/LoggerService';
import { buildQueryWithFilters, buildBodyRequestForReport } from '@/utils/filters';
import { getDateInISO } from '@/utils/charts';
import ReportService from '@/services/ReportService';
import NotificationService from '@/services/NotificationService';
import SubscriptionService from '@/services/SubscriptionService';

const TRANSACTIONS_PAGE_BASIC_LIMIT = 20;
const TRANSACTIONS_PAGE_LOAD_MORE_LIMIT = 10;

const TRANSACTIONS_TYPE_REPORT = 'transactions';
const TRANSACTIONS_PAGE_SUBSCRIPTION_KEY = 'transactions-page-transactions';

export default {
  namespaced: true,

  state: {
    meta: null,
    transactions: [],

    isLoadingTransactions: false,
    isLoadingMore: false,

    filters: {
      query: '',
      from: null,
      to: null,
      currencies: [],
      statuses: [],
      payment_methods: [],
      with_payment: null,
    },

    paymentAttempts: [],

    isLoadingReport: false,
    reportUrl: null,
  },

  getters: {
    transactionsList: state => state.transactions,
    transactionsAmount: state => state.meta?.total,
    isLoadingTransactions: state => state.isLoadingTransactions,
    isLoadingMore: state => state.isLoadingMore,
    canLoadMoreTransactions: state => !!state.meta?.cursors.next,

    selectedDateFilter: state => ({
      start: state.filters.from,
      end: state.filters.to,
    }),
    selectedStatusesList: state => state.filters.statuses,
    selectedCurrenciesList: state => state.filters.currencies,
    selectedPaymentMethodsList: state => state.filters.payment_methods,
    selectedPaymentAttemptsList: state => state.paymentAttempts,

    isLoadingReport: state => state.isLoadingReport,
    reportUrl: state => state.reportUrl,

    activeProductId: (state, getters, rootState, rootGetters) => rootGetters['accountProductsStore/activeProductId'],
  },

  mutations: {
    appendTransactions(state, payload) {
      state.transactions = state.transactions.concat(payload);
    },

    setTransactions(state, payload) {
      state.transactions = payload;
    },

    setMeta(state, payload) {
      state.meta = payload;
    },

    setLoadingTransactions(state, payload) {
      state.isLoadingTransactions = payload;
    },

    clearReportUrl(state) {
      state.reportUrl = null;
    },

    setLoadingMore(state, payload) {
      state.isLoadingMore = payload;
    },

    setDateFilter(state, { from, to }) {
      state.filters.from = getDateInISO(from, true);
      state.filters.to = getDateInISO(to, false);
    },

    setTransactionsStatusFilter(state, payload) {
      state.filters.statuses = payload;
    },

    setCurrenciesFilter(state, payload) {
      state.filters.currencies = payload;
    },

    setPaymentMethodsFilter(state, payload) {
      state.filters.payment_methods = payload;
    },

    setPaymentAttemptsFilter(state, payload) {
      state.filters.with_payment = payload;
    },

    setPaymentAttemptsList(state, payload) {
      state.paymentAttempts = payload;
    },

    setQuery(state, payload) {
      state.filters.query = payload;
    },

    setReportUrl(state, payload) {
      state.reportUrl = payload;
    },

    setReportLoading(state, payload) {
      state.isLoadingReport = payload;
    },

    clearStore(state) {
      state.meta = null;
      state.transactions = [];
      state.paymentAttemptsList = [];
      state.transactionsAmount = '0';
      state.isLoadingReport = false;
      state.isLoadingTransactions = false;
      state.isLoadingMore = false;
      state.isLoadingReport = false;
      state.reportUrl = null;
      state.filters = {
        query: '',
        from: null,
        to: null,
        currencies: [],
        statuses: [],
        payment_methods: [],
        with_payment: null,
      };
    },
  },

  actions: {
    async downloadTransactionsReport({ commit, state: { filters }, getters }) {
      commit('setReportLoading', true);
      commit('clearReportUrl');

      const reportFilters = buildBodyRequestForReport(filters);

      await ReportService.requestReport({
        type: TRANSACTIONS_TYPE_REPORT,
        subscriptionKey: TRANSACTIONS_PAGE_SUBSCRIPTION_KEY,
        filters: reportFilters,
        productHeaderId: getters.activeProductId,
        callback: (error, url) => {
          commit('setReportLoading', false);

          if (error) {
            NotificationService.notifyAboutError('partner-portal.errors.error-downloading-report');
            return null;
          }

          commit('setReportUrl', url);
          return url;
        },
      });
    },

    unsubscribeFromTransactionsReport() {
      ReportService.clearExpirationTimerId();
      SubscriptionService.unsubscribe(TRANSACTIONS_PAGE_SUBSCRIPTION_KEY);
    },

    setQuery({ commit }, payload) {
      commit('setQuery', payload);
    },

    setDateFilter({ commit }, payload) {
      commit('setDateFilter', payload);
    },

    setTransactionsStatusFilter({ commit }, payload) {
      commit('setTransactionsStatusFilter', payload);
    },

    setCurrenciesFilter({ commit }, payload) {
      commit('setCurrenciesFilter', payload);
    },

    setPaymentMethodsFilter({ commit }, payload) {
      commit('setPaymentMethodsFilter', payload);
    },

    setPaymentAttemptsFilter({ commit }, payload) {
      commit('setPaymentAttemptsFilter', payload);
    },

    setPaymentAttemptsList({ commit }, payload) {
      commit('setPaymentAttemptsList', payload);
    },

    clearStore({ commit }) {
      commit('clearStore');
    },

    async loadTransactions({ state, commit, getters }) {
      commit('setLoadingTransactions', true);
      try {
        const filters = {
          ...state.filters,
          meta: 'total',
          limit: TRANSACTIONS_PAGE_BASIC_LIMIT,
        };

        const query = buildQueryWithFilters(filters);
        const { data: { data: transactions, meta } } = await apiClient.get(`/v1/transactions?${query}`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        commit('setTransactions', transactions);
        commit('setMeta', meta);
      } catch (error) {
        LoggerService.logToConsole(error);
      } finally {
        commit('setLoadingTransactions', false);
      }
    },

    async loadMore({ state, commit, getters }) {
      commit('setLoadingMore', true);
      try {
        const loadMoreFilters = {
          ...state.filters,
          meta: 'total',
          limit: TRANSACTIONS_PAGE_LOAD_MORE_LIMIT,
        };

        const query = buildQueryWithFilters(loadMoreFilters);
        const { data: { data: transactions, meta } } = await apiClient.get(`/v1/transactions?${query}`, {
          params: {
            cursor: state.meta.cursors.next,
          },
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });

        commit('appendTransactions', transactions);
        commit('setMeta', meta);
      } catch (error) {
        LoggerService.logToConsole(error);
      } finally {
        commit('setLoadingMore', false);
      }
    },

    async loadTransactionDetails({ getters }, id) {
      try {
        const { data } = await apiClient.get(`/v1/transactions/${id}`, {
          headers: {
            'X-Product-ID': getters.activeProductId,
          },
        });
        return data;
      } catch (error) {
        return LoggerService.logToConsole(error);
      }
    },
  },
};
