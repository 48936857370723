<template>
  <div
    class="mobile-nav"
  >
    <ul class="mobile-nav__list">
      <li
        v-for="route in routes"
        :key="route.name"
        class="mobile-nav__list-item"
      >
        <router-link
          v-t="route.name"
          :to="route.link"
          :data-testid="route.testId"
          class="mobile-nav__list-item-link"
          @click.native.stop="closeMobileNav"
        />
      </li>
      <li class="mobile-nav__list-item">
        <div
          class="mobile-nav__list-item-link mobile-nav__list-item-link--dropdown"
          :class="{ 'mobile-nav__list-item-link--dropdown-active': managerMenuOpened }"
          @click="toggleManagerMenu"
        >
          Your manager
        </div>
        <div
          class="mobile-nav__list-item-content"
          :class="{ 'mobile-nav__list-item-content--opened': managerMenuOpened }"
        >
          <ManagerCard />
        </div>
      </li>
      <li class="mobile-nav__list-item">
        <div
          class="mobile-nav__list-item-link mobile-nav__list-item-link--dropdown"
          :class="{ 'mobile-nav__list-item-link--dropdown-active': developersMenuOpened }"
          @click="toggleDevelopersMenu"
        >
          For developers
        </div>
        <div
          class="mobile-nav__list-item-content"
          :class="{ 'mobile-nav__list-item-content--opened': developersMenuOpened }"
        >
          <DevelopersCard />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ManagerCard from '@/components/ManagerCard';
import DevelopersCard from '@/components/DevelopersCard';

export default {
  name: 'MobileNav',
  components: {
    ManagerCard,
    DevelopersCard,
  },
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
  inject: {
    closeMobileNav: {
      from: 'Header.CloseMobileNav',
      default: () => ({}),
    },
  },
  data() {
    return {
      managerMenuOpened: false,
      developersMenuOpened: false,
    };
  },
  methods: {
    toggleManagerMenu() {
      this.managerMenuOpened = !this.managerMenuOpened;
    },
    toggleDevelopersMenu() {
      this.developersMenuOpened = !this.developersMenuOpened;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.mobile-nav {
  display: flex;
  flex-direction: column;
  background: $secondary;

  &__list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin: 0;

    &-item {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      border-top: 1px solid $mobile-border-color;
      padding: 16px;

      &-content {
        max-height: 0;
        width: 100%;
        position: absolute;
        top: 100%;
        overflow: hidden;

        &--opened {
          max-height: 300px;
          position: relative;
        }
      }

      &-link {
        color: $primary;
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        text-decoration: none;

        &--dropdown {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:after {
            content: ' ';
            display: flex;
            height: 20px;
            width : 20px;
            background: url('~@/assets/icons/bottom-white-arrow.svg') no-repeat center center;
          }
        }

        &--dropdown-active {
          &:after {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .mobile-nav {
    display: none;
  }
}
</style>
