<template>
  <div class="loader">
    <i class="icon icon-spinner-black loader__icon" />
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    height: 36px;
    width: 36px;
    animation: spin 1s linear infinite;
  }
}
</style>
