<template>
  <div class="manager">
    <div class="manager__photo-container">
      <img
        v-if="avatar"
        :srcset="`${avatar}, ${avatar} 2x`"
        :src="avatar"
        class="manager__photo"
      >
      <img
        v-else
        class="manager__photo"
        src="@/assets/images/manager-avatar.svg"
      >
    </div>
    <div class="manager__info-container">
      <div class="manager__info-row">
        <p class="manager__info-row-text manager__info-row-text--name">
          {{ name }}
        </p>
      </div>
      <div
        v-for="(route, index) in routes"
        :key="`${route.name}-${index}`"
        class="manager__info-row"
      >
        <a
          :href="route.link"
          :target="route.target"
          class="manager__info-row-link"
        >
          <i :class="`icon icon-${route.icon} manager__info-row-image`" />
          <div class="manager__info-row-text">
            {{ route.name }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ManagerCardMobile',
  props: {
    avatar: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    routes: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.manager {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 15px;
  color: $primary;

  @media (min-width: $tablet-breakpoint) {
    display: none;
  }

  &__photo-container {
    margin-right: 24px;
  }

  &__photo {
    height: 56px;
    width: 56px;
    border-radius: 50%;
  }

  &__info-container {
    display: flex;
    flex-direction: column;
  }

  &__info-row {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 8px;
  }

  &__info-row-link {
    display: flex;
    align-items: center;
    color: $primary;
    text-decoration: none;
  }

  &__info-row-text {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    flex: 1 0;
    word-break: break-all;

    &--name {
      font-size: 17px;
      line-height: 22px;
    }
  }

  &__info-row-image {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
}
</style>
