const BASIC_INTERVAL = DASHBOARD_UPDATE_TIME;
const subscriptions = {};

export default {
  async subscribe({ subscriptionKey, functionToInvoke, immediate = true, interval = false }) {
    if (subscriptions[subscriptionKey]) clearInterval(subscriptions[subscriptionKey]);

    const subscriptionInterval = interval || BASIC_INTERVAL;
    subscriptions[subscriptionKey] = setInterval(() => {
      functionToInvoke();
    }, subscriptionInterval);

    if (immediate) await functionToInvoke();
  },

  unsubscribe(subscriptionKey) {
    clearInterval(subscriptions[subscriptionKey]);
  },

  unsubscribeFromAll() {
    const activeSubscriptions = Object.values(subscriptions);
    activeSubscriptions.forEach(subscriptionId => {
      clearInterval(subscriptionId);
    });
  },
};
