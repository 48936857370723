import { captureException, captureMessage } from '@sentry/minimal';

export default {
  captureSentryMessage: (message, extra = {}) => captureMessage(message, {
    extra,
  }),
  captureSentryError: (error, extra = {}) => captureException(error, {
    extra,
  }),
  logToConsole: valueToLog => console.log(valueToLog),
};
