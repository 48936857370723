<template>
  <div
    class="form-input"
    :class="{'form-input--error': error}"
  >
    <div class="form-input__wrap">
      <div
        v-click-outside="closeMenu"
        class="form-input__select"
        :class="{
          'form-input__select--active': optionsMenuOpened,
          'form-input__select--error': error
        }"
        @click="toggleOptionsMenu"
      >
        <div
          class="form-input__select-active"
        >
          {{ selectValue }}
        </div>
        <div
          v-if="optionsMenuOpened"
          class="form-input__select-menu"
          :class="{'form-input__select-menu--error': error}"
        >
          <div
            v-for="selectOption in selectOptions"
            :key="selectOption.value"
            class="form-input__select-menu-option"
            @click="handleSelectOption(selectOption.value)"
          >
            {{ selectOption.option }}
          </div>
        </div>
      </div>

      <div
        class="form-input__input"
        :class="{'form-input__input--error': error}"
      >
        <input
          v-model="value"
          type="text"
          class="form-input__input-value"
          autocomplete="off"
        >
        <div
          v-if="!hideRemoveIcon"
          class="icon icon-grey-cross form-input__input-remove"
          @click="removeFormField"
        />
      </div>
    </div>

    <span
      v-if="error"
      class="form-input__error"
    >
      {{ error }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'FormInputSelect',
  props: {
    selectOptions: {
      type: Array,
      required: true,
    },
    inputValue: {
      type: String,
      required: true,
    },
    selectValue: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
    hideRemoveIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      optionsMenuOpened: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.inputValue;
      },
      set(modifiedValue) {
        this.$emit('input', modifiedValue);
      },
    },
  },
  methods: {
    toggleOptionsMenu() {
      this.optionsMenuOpened = !this.optionsMenuOpened;
    },
    closeMenu() {
      this.optionsMenuOpened = false;
    },
    handleSelectOption(option) {
      this.$emit('select', option);
      this.closeMenu();
    },
    removeFormField() {
      this.$emit('remove');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/mixins';

$border-width: 2px;

.form-input {
  display: flex;
  flex-direction: column;
  height: 84px;
  margin-bottom: 16px;

  &--error {
    margin-bottom: 25px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__select {
    height: 42px;
    border: $border-width solid $input-border-grey;
    border-radius: 6px 6px 0 0;
    border-bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 16px;
    position: relative;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      right: 12px;
      height: 42px;
      width: 16px;
      background: url('~@/assets/icons/bottom-arrow.svg') no-repeat center center;
    }

    &--active {
      &:after {
        transform: rotate(180deg);
      }
    }

    &--error {
      border: $border-width solid $error-color;
      border-bottom: 0;
    }
  }

  &__select-menu {
    position: absolute;
    top: 100%;
    width: 100%;
    left: -$border-width;
    background: $primary;
    border: $border-width solid $input-border-grey;
    border-radius: 0 0 6px 6px;
    box-sizing: content-box;
    z-index: 1;
    box-shadow: 0px 2px 8px 0px #00000005;

    &--error {
      border: $border-width solid $error-color;
    }
  }

  &__select-menu-option {
    padding: 12px 16px;
    font-size: 15px;
    font-weight: $font-regular;

    &:hover,
    &:active {
      background: $item-active-background;
    }
  }

  &__input {
    height: 42px;
    border: $border-width solid $input-border-grey;
    border-radius: 0 0 6px 6px;
    padding: 0 12px 0 16px;
    display: flex;
    align-items: center;

    &--error {
      border: $border-width solid $error-color;
    }
  }

  &__input-value {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
    border: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex: 1 0;

    &:focus,
    &:active {
      outline: none;
    }
  }

  &__input-remove {
    display: flex;
    height: 16px;
    width: 16px;

    @include hover {
      cursor: pointer;
    }
  }

  &__error {
    font-size: 12px;
    line-height: 16px;
    font-family: $font-regular;
    color: $error-color;
    margin-top: 3px;
  }
}

@media screen and (min-width: $desktop-breakpoint) {
  .form-input {
    height: 42px;

    &__wrap {
      flex-direction: row;
    }

    &__select {
      flex: 0 0 140px;
      border: $border-width solid $input-border-grey;
      border-radius: 6px 0 0 6px;

      &--error {
        border: $border-width solid $error-color;
      }
    }

    &__input {
      flex: 1 0 auto;
      border: $border-width solid $input-border-grey;
      border-radius: 0 6px 6px 0;
      border-left: 0;
      padding-right: 14px;

      &--error {
        border: $border-width solid $error-color;
        border-left: 0;
      }
    }
  }
}
</style>
